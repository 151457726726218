import Icon from '@ant-design/icons';
import { Tag, Tooltip } from "antd";
import Cookies from "js-cookie";
import moment from "moment";
import React from "react"
import { CloseOutlined, EyeInvisibleOutlined, GlobalOutlined, TeamOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { settings } from "../data";

export const successColor = "#4f9818"; // green
export const warningColor = "#E77E21"; // orange
export const disabledColor = "#6b6b6b"; // grey, was 999999
export const futureColor = "#189285"; // teal
export const bestsellerColor = "#F12A46"; // red
export const peerColor = "#A52A2A"; // burgandy
export const favColor = "#ffcf17" // yellow
export const highlightColor = "#49b7e0" // lightBlue
export const returnedColor = "#9932cc" // lightBlue
export const rawDataColor = "#800080" // purple from V1


export const getListPrice = (list_price_can, list_price_usa, is_canadian, _br = false) => {
  let _can = (list_price_can > 0) ? "$" + list_price_can : "";
  let _us = (list_price_usa > 0) ? "$" + list_price_usa : "";
  // Add suffix if both exist..
  if (_can && _us) {
      _can = _can + " CA";
      _us = _us + " US"
  }
  let _sep = (_can && _us)  ? " / " : " ";

  if(_br){
    _sep = "";
  }

  return(<div style={{ "fontWeight": "600" }}>{(is_canadian && (_can || _us)) ? <>{_can}{(_br && <br />)}{_sep}{_us}</> : <>{_us}{_sep}{_can}</>}<small> {((_can || _us) && <>{(_br) ? "" : "list price"}</>)}</small></div>)
}

export const handleTermSlash = (_str, _encode = false) => {
  if (_encode) {
    return _str.replace("/", "%2f");
  } else {
    return _str.replace("%2f", "/");
  }
}

export const findMedian = (_arr = []) => {
  // sort
  _arr.sort(function (a, b) {
    return a - b;
  });
  if (_arr.length % 2 !== 0) {
    return _arr[Math.floor(_arr.length / 2)];
  }
  else {
    let mid = _arr.length / 2;
    return (_arr[mid - 1] + _arr[mid]) / 2;
  }
}

export const containsISBN = (_str) => {
  // Regular expression to match ISBNs
  const isbnRegex = /(?:ISBN(?:: ?| ))?((?:97[89])?\d{9}[\dx])/i;
  // Search the string for a match and return the result
  return isbnRegex.test(_str);
}

export const isValidUPC = (_str) => {
  // check if the str is 12 digit number
  let pattern = /^\d{12}$/;
  if (!pattern.test(_str)) {
    return false;
  }
  // calculate the check digit
  let sum = 0;
  for (let i = 0; i < 11; i++) {
    sum += (i % 2 === 0 ? 3 : 1) * _str[i];
  }
  let checkDigit = 10 - (sum % 10);
  // ensure check digit matches the last digit
  if (checkDigit === parseInt(_str[11])) {
    return true;
  } else {
    return false;
  }
}


export const serialize = (obj) => {
  if (Array.isArray(obj)) {
    return JSON.stringify(obj.map(i => serialize(i)))
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj)
      .sort()
      .map(k => `${k}:${serialize(obj[k])}`)
      .join('|')
  }
  return obj
}

export const flattenObject = (ob) => {
  var toReturn = {};
  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;
    if ((typeof ob[i]) == 'object') {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};


export const serializeJSON = (obj) => {
  return JSON.stringify(obj, (key, value) => {
    if (typeof value === 'object' && value !== null) {
      return serializeJSON(value);
    }
    return value;
  }, 2);
}

export const getISBNCount = (_str) => {
  // Regular expression to match ISBNs
  const isbnRegex = /(?:ISBN(?:: ?| ))?((?:97[89])?\d{9}[\dx])/gi;
  // Use the regular expression to search the string and get all matches
  const matches = _str.match(isbnRegex);
  // If there are no matches, return 0
  if (!matches) {
    return 0;
  }
  // Return the length of the matches array
  return matches.length;
}

export const sum = (_arr, _key) => {
  return _arr.reduce((a, b) => a + (b[_key] || 0), 0);
}

export const convertLineBreaks = (_str) => {
  const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
  return _str.replace(regex, '<br />');
}

export const getPercentageIncrease = (numA, numB, persision = 2) => {
  if (numA === 0 || numB === 0) {
    return "N/A";
  }
  return (((numA - numB) / numB) * 100).toFixed(persision);
}

export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
export const getSan = (_corp_id, _stores = []) => {
  let _f = _stores.find(item => item.cid === _corp_id);
  if (_f) {
    return _f.san;
  } else {
    return "";
  }
}

export const bmDate = (_date) => {
  return moment(_date).format("MMM DD-YY")
}

export const getFlag = (_country) => {
  let flag = "https://cdn1.bookmanager.com/i/CA.png"
  switch (_country) {
    case "Canada":
      flag = "CA"
      break;
    case "United Kingdom":
      flag = "CA"
      break;
    case "USA":
      flag = "US"
      break;
    default:
      flag = "CA"
      break;
  }
  return "https://cdn1.bookmanager.com/i/" + flag + ".png";
}

export const cacheBuster = () => {
  let cb = Math.round(new Date().getTime() / 1000);
  return "&cache=" + cb.toString();
}

export const getCanIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="-2015 -2000 4030 4030"><title>maple leaf from the flag of Canada</title><path fill="#e00" d="m-90 2030 45-863a95 95 0 0 0-111-98l-859 151 116-320a65 65 0 0 0-20-73l-941-762 212-99a65 65 0 0 0 34-79l-186-572 542 115a65 65 0 0 0 73-38l105-247 423 454a65 65 0 0 0 111-57l-204-1052 327 189a65 65 0 0 0 91-27l332-652 332 652a65 65 0 0 0 91 27l327-189-204 1052a65 65 0 0 0 111 57l423-454 105 247a65 65 0 0 0 73 38l542-115-186 572a65 65 0 0 0 34 79l212 99-941 762a65 65 0 0 0-20 73l116 320-859-151a95 95 0 0 0-111 98l45 863z"></path></svg>
  )
}

export const getDragIcon = () => {
  return (

    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#ccc"><path d="M360-190.77q-20.31 0-34.77-14.46-14.46-14.46-14.46-34.77 0-20.31 14.46-34.77 14.46-14.46 34.77-14.46 20.31 0 34.77 14.46 14.46 14.46 14.46 34.77 0 20.31-14.46 34.77-14.46 14.46-34.77 14.46Zm240 0q-20.31 0-34.77-14.46-14.46-14.46-14.46-34.77 0-20.31 14.46-34.77 14.46-14.46 34.77-14.46 20.31 0 34.77 14.46 14.46 14.46 14.46 34.77 0 20.31-14.46 34.77-14.46 14.46-34.77 14.46Zm-240-240q-20.31 0-34.77-14.46-14.46-14.46-14.46-34.77 0-20.31 14.46-34.77 14.46-14.46 34.77-14.46 20.31 0 34.77 14.46 14.46 14.46 14.46 34.77 0 20.31-14.46 34.77-14.46 14.46-34.77 14.46Zm240 0q-20.31 0-34.77-14.46-14.46-14.46-14.46-34.77 0-20.31 14.46-34.77 14.46-14.46 34.77-14.46 20.31 0 34.77 14.46 14.46 14.46 14.46 34.77 0 20.31-14.46 34.77-14.46 14.46-34.77 14.46Zm-240-240q-20.31 0-34.77-14.46-14.46-14.46-14.46-34.77 0-20.31 14.46-34.77 14.46-14.46 34.77-14.46 20.31 0 34.77 14.46 14.46 14.46 14.46 34.77 0 20.31-14.46 34.77-14.46 14.46-34.77 14.46Zm240 0q-20.31 0-34.77-14.46-14.46-14.46-14.46-34.77 0-20.31 14.46-34.77 14.46-14.46 34.77-14.46 20.31 0 34.77 14.46 14.46 14.46 14.46 34.77 0 20.31-14.46 34.77-14.46 14.46-34.77 14.46Z" /></svg>
  )
}


export const getNavPermission = (pathname, navigation) => {
  const searchSection = (section) => {
    if (section.subnav) {
      for (const subItem of section.subnav) {
        if (pathname.startsWith(`/${subItem.slug}`)) {
          return subItem.permission || null;
        }
      }
    }
    return null;
  };

  for (const item of navigation) {
    const permission = searchSection(item);
    if (permission) {
      return permission;
    }
  }
  return null; 
}

export const checkPermission = (data, keys) => {

  let ret = false;
  keys.forEach(key => {
   
    if(data.subscriptions[key] || data.permissions[key]){
      ret = true; 
    } 
  });
  return ret; 
}


export const bytesToSize = (bytes) => {
  let sizes = ['', 'k', 'm', 'gb', 'tb'];
  if (bytes == 0) return '0 Byte';
  let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + '' + sizes[i];
}

export const addCommas = (_num) => {
  return _num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// React likes unique id's for listed elements so it knows what to draw / update
export const addKeys = (_arr, _key = false) => {
  return (_arr.map((item) => {
    if (_key) {
      item["key"] = item[_key];
    } else {
      item["key"] = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);;
    }
    return item;
  }))
}

export const makeKey = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);;
}

export const ucfirst = (string) => {
  if (string.includes("-")) {
    string = string.split("-");
    string = string.map(item => ucfirst(item));
    return string.join(" ");
  }
  if (string.includes("_")) {
    string = string.split("_");
    string = string.map(item => ucfirst(item));
    return string.join(" ");
  }
  return (string) ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

export const parseAuthors = (_authors) => {
  if (!_authors || _authors.length === 0) { return "" }
  return _authors.map((_author, index) => {
    let flag = (_author.canadian && settings.is_canadian);
    return (<a style={{ "color": "#1581e9", "fontSize": "14px" }} key={index}>{_author.name} {(flag && getCanIcon())}{(index !== _authors.length - 1) ? ", " : ""} </a>);
  });
}

export const parseAuthorsPlain = (_authors) => {
  if (!_authors || _authors.length === 0) { return "" }
  return _authors.map((_author, index) => {
    return (<span key={index}>{_author.name}{(index !== _authors.length - 1) ? ", " : ""} </span>);
  });
}

export const parseSubjectsPlain = (_subjects) => {
  if (!_subjects || _subjects.length === 0) { return "" }
  return _subjects.map((_subject, index) => {
    if (_subject.sub_labels.length > 0) {
      return (<span key={index}>{_subject.subject} / {_subject.sub_labels.map((_sub) => { return (_sub.label) }).join(" / ")}{(index !== _subject.sub_labels.length && " ")} </span>)
    } else {
      return (<span key={index}>{_subject.subject}</span>);
    }
  })
}

export const parseSubjectsWeird = (_subjects, _limit = 30) => {
  if (!_subjects || _subjects.length === 0) { return "" }
  let ret = "";
  // limit to first
  _subjects = [_subjects[0]];
  return _subjects.map((_subject, index) => {
    if (_subject.sub_labels.length > 0) {
      // limit to first sub
      if (_subject.sub_labels.length > 0) {
        ret += _subject.sub_labels[0].bisac.substr(0, 3) + ": " + _subject.sub_labels[0].label;
      }
      return <>{ret}</>
    } else {
      return (<>{_subject.subject.substring(0, _limit)}</>);
    }
  })
}

export const parseSubjectsBisacThreeCode = (_subjects) => {
  if (!_subjects || _subjects.length === 0) { return "" }
  let three_letter_bisac_code = _subjects[0].sub_labels[0].bisac.substr(0, 3);
  return three_letter_bisac_code;
}

export const parseSubjects = (_subjects) => {
  if (!_subjects || _subjects.length === 0) { return "" }
  return _subjects.map((_subject, index) => {
    let ss = _subject.sub_labels.filter(item => item.label !== "");
    if (ss.length > 0) {
      return (<div key={index}><span className="c" style={{ "fontWeight": "600" }}>{_subject.subject}</span> / {ss.map((_sub) => { return (_sub.label) }).join(" / ")}
        {((index + 1) !== _subjects.length && " ")}
      </div>)
    } else {
      return (<div key={index} className="c" style={{ "fontWeight": "600" }}>{_subject.subject}</div>);
    }
  })
}

export const parseSubjectsList = (_subjects) => {
  if (!_subjects || _subjects.length === 0) { return "" }
  return _subjects.map((_subject, index) => {
    if (_subject.sub_labels.length > 0) {
      // has sub
      return (<span key={index}><strong className="c">{_subject.subject}</strong> <br /> {_subject.sub_labels.map((_sub, i) => { return ((_sub.label) ? <span key={i}>{_sub.label}<br /></span> : <></>) })}</span>)
    } else {
      return (<span key={index}><strong className="c">{_subject.subject}</strong><br /></span>);
    }
  })
}

export const parseRank = (_rank) => {
  if (!_rank) { return "" }
  if (_rank.hasOwnProperty('bestseller')) {
    return (<span style={{ "color": bestsellerColor }}>{"#" + _rank.bestseller + " in bestsellers"}</span>);
  }
  if (_rank.hasOwnProperty('future')) {
    return (<span style={{ "color": futureColor }}>{"#" + _rank.future + " in future releases"}</span>);
  }
  return "";
}

export const getQuantity = (_isbn, _cart) => {
  let find = _cart.find((item => item.isbn == _isbn));
  if (!find) {
    return [];
  }

  return find.suppliers.map((itm => {
    return {
      quantity: itm.quantity,
      supplier: itm.location
    }
  }))
}

export const drawQuantity = (_isbn, _cart) => {
  let btn_arr = getQuantity(_isbn, _cart)
  let _color = (btn_arr.length > 0) ? "#4f9818" : "#333333"
  return (
    <Tag style={{ "lineHeight": "16px", "cursor": "pointer", "textAlign": "center", "marginRight": "0px" }} color={_color}>
      {((btn_arr.length === 0) && "Order")}
      {btn_arr.map((itm, index) => {
        return <span key={index}>{itm.quantity} - {itm.supplier}<br /></span>
      })}
    </Tag>
  )
}

export const isNumeric = (str) => {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}


//For taking in a shared_status and giving a more human-readable string to display in return
export const writeSharedReadable = (shared_status, store_count = false, store_name = false) => {
  let return_value = ""
  switch (shared_status) {
    case "all_my":
    case "all":
      return_value = "All my accounts"
      if (store_count !== false) {
        return_value += " (" + store_count + " accounts shared with)";
      }
      return (
        <Tooltip title={<small>{return_value}</small>}>
          <TeamOutlined style={{ "fontSize": "24px", "marginRight": "10px", "color": "#00FF00" }} />
        </Tooltip>
      )

      break;
    case "some_my":
      return_value = "Some of my accounts"
      if (store_count !== false) {
        return_value += " (" + store_count + " stores shared with)";
      }
      return (
        <Tooltip title={<small>{return_value}</small>}>
          <UserAddOutlined style={{ "fontSize": "24px", "marginRight": "10px", "color": "#C2B30C" }} />
        </Tooltip>
      )
      break;
    case "some":
      return_value = "Shared with some stores"
      return (
        <Tooltip title={<small>{return_value}</small>}>
          <GlobalOutlined style={{ "fontSize": "24px", "marginRight": "10px", "color": "#C2B30C" }} />
        </Tooltip>
      )
      break;
    case "one_my":
      if (store_name !== false && store_count !== false) {
        return_value += "(" + store_count + ") " + store_name;
      } else {
        return_value = "One of my accounts"
      }
      return (
        <Tooltip title={<small>{return_value}</small>}>
          <UserOutlined style={{ "fontSize": "24px", "marginRight": "10px", "color": "#D16004" }} />
        </Tooltip>
      )
      break;
    case "everyone":
      return_value = "Every bookseller";
      return (
        <Tooltip title={<small>{return_value}</small>}>
          <GlobalOutlined style={{ "fontSize": "24px", "marginRight": "10px", "color": "#00FF00" }} />
        </Tooltip>
      )
      break;
    default:
      return_value = "Not shared with anyone";
      return (
        <Tooltip title={<small>{return_value}</small>}>
          <EyeInvisibleOutlined style={{ "fontSize": "24px", "marginRight": "10px", "color": "#FF0000" }} />
        </Tooltip>
      )
      break;
  }
}

export const getGroups = (_stores = true) => {
  const blank = [""];
  const stores = window.sitesettings.stores.map((item, index) => { return item.store_number.toString() })
  const groups = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
  if (stores.length > 1) {
    return stores.concat(blank.concat(groups))
  } else {
    return blank.concat(groups);
  }
}


export const pathToObj = (_path, _map) => {
  let _arr = _path.split("/");
  let _keys = Object.keys(_map).map(item => _map[item]);
  let _obj = {};
  _keys.forEach(_key => {
    _obj[_key] = "";
  })
  for (let i = 0; i < _arr.length; i++) {
    if (i < (_arr.length - 1)) {
      if (_obj.hasOwnProperty(_arr[i])) {
        _obj[_arr[i]] = _arr[(i + 1)];
      }
    }
  }
  let _ret = {}
  Object.keys(_map).forEach(_key => {
    if (_obj.hasOwnProperty(_map[_key])) {
      _ret[_key] = _obj[_map[_key]];
    }
  })
  return _ret;
}

export const objToPath = (_obj, _map) => {
  let _path = "filter";
  Object.keys(_obj).forEach(_key => {
    if (_obj[_key]) {
      _path += "/" + _map[_key] + "/" + _obj[_key];
    }
  })
  return _path;
}

export const objectToPath = (_obj, firstpage = false) => {
  let location = "/filter";
  if (firstpage) {
    _obj.o = 0;
  }
  //(September 25th, 2023) George - 
  //Brought this loop over from Biz site to be reused here and to be implemented as time goes on
  Object.keys(_obj).forEach((key) => {

    //runs if value is an array to properly append all values to url
    if (Array.isArray(_obj[key]) && _obj[key].length > 0) {
      if (_obj[key].includes("AND") && _obj[key].length < 3) {
        // strip and
        _obj[key] = _obj[key].filter(itm => itm !== "AND");
      }
      location += "/" + key.toString() + "/" + _obj[key].join("/")
      //runs for non-array values, like limit and offset
    } else if (_obj.hasOwnProperty(key) && _obj[key]) {
      //checks to see if the value at key is a string for encoding purposes
      if (typeof _obj[key] !== "string") {
        //run when value is not a string, so it can be turned into one for the sake of the url
        //examples are limit or offset, which are numbers
        //specific if to make sure firstpage values aren't being added to the url
        if ((key === 'l' && _obj[key] !== 26) || (key === 'o' && _obj[key] !== 0)) {
          location += "/" + key.toString() + "/" + _obj[key].toString();
        } else if (key === "q" && encodeURI(_obj[key])) {
          location += "/" + key.toString() + "/" + encodeURI(_obj[key]);
        }
      } else {
        //runs when it is a string, so it can be properly encoded
        //example would be filter for subject
        location += "/" + key.toString() + "/" + encodeURI(_obj[key]);
      }
    }
  })
  return location;
}

export const getFilterSet = (_key) => {
  let _saved = Cookies.get("filtersets");
  if (!_saved) return false;
  _saved = JSON.parse(_saved);
  if (_saved.hasOwnProperty(_key)) {
    return JSON.parse(_saved[_key]);
  } else {
    return false;
  }
}

export const saveFilterSet = (_key, _value) => {
  let _saved = Cookies.get("filtersets");
  let filtersets = {}
  // no memory at all - save first
  if (!_saved) {
    filtersets = {
      [_key]: JSON.stringify(_value)
    }
    Cookies.set("filtersets", JSON.stringify(filtersets))
    return filtersets[_key];
  }
  _saved = JSON.parse(_saved);
  // memory exists, no key 
  _saved[_key] = JSON.stringify(_value);
  Cookies.set("filtersets", JSON.stringify(_saved))
  return _saved[_key];
}

export const getMemory = (_key) => {
  let _saved = Cookies.get("memory");
  if (!_saved) return [];
  _saved = JSON.parse(_saved);
  if (_saved.hasOwnProperty(_key)) {
    return _saved[_key];
  } else {
    return []
  }
}

export const saveMemory = (_key, _value) => {
  let _saved = Cookies.get("memory");
  let memory = {}
  // no memory at all - save first
  if (!_saved) {
    memory = {
      [_key]: [_value]
    }
    Cookies.set("memory", JSON.stringify(memory))
    return memory[_key];
  }
  _saved = JSON.parse(_saved);
  // memory exists, no key 
  if (!_saved.hasOwnProperty(_key)) {
    _saved[_key] = [_value]
    Cookies.set("memory", JSON.stringify(_saved))
    return _saved[_key];
  }
  if (_saved[_key].includes(_value)) {
    // not unique, put it at the top
    _saved[_key] = _saved[_key].filter(item => item !== _value);
    _saved[_key].unshift(_value);
    Cookies.set("memory", JSON.stringify(_saved));
    return _saved[_key];
  }
  _saved[_key].unshift(_value);
  _saved[_key] = _saved[_key].slice(0, 5); // limit to 5
  Cookies.set("memory", JSON.stringify(_saved));
  return _saved[_key];
}

export const getFilterCount = (_path) => {
  let obj = pathToObject(_path);
  let count = 0;
  Object.keys(obj).forEach(key => {
    if (Array.isArray(obj[key])) {
      count += obj[key].length;
    }
  })
  return count;
}

function isLetter(c) {
  return c.toLowerCase() != c.toUpperCase();
}

export const pathToObject = (_path, _save = "", is_company_page, company_id) => {
  let returnObject = {
    o: 0, // offset
    l: 20, // limit
    t: "", // term
    k: "", // type
    v: "", // order
  }
  // need to add the q parameter (looks like an ID for the company/user)
  if (is_company_page) {
    if(company_id){
      returnObject.q = company_id
    }
  }
  if (_save) {
    let _lm = parseInt(Cookies.get(_save));
    if (!isNaN(_lm)) {
      returnObject.l = _lm;
    }
  }
  let startIndex = _path.search(/filter/i);
  let p = _path.substr(startIndex, _path.length);
  let split = p.split("/");
  let key;
  let ka = ["b"];
  for (let i = 0; i < split.length; i++) {
    if (split[i].length === 1 && isLetter(split[i])) {
      key = split[i];
    } else {
      if (!returnObject.hasOwnProperty(key)) {
        if (key) {
          returnObject[key] = [];
        } else {
          continue;
        }
      }
      if (Array.isArray(returnObject[key])) {
        returnObject[key] = [...returnObject[key], split[i]];
      } else {
        if (key === "o" || key === "l") {
          returnObject[key] = parseInt(split[i]);
        } else {
          returnObject[key] = split[i];
        }
      }
      (ka.indexOf(key) === -1 && ka.push(key));
    }
  }
  return returnObject;
}

export const pluralize = (count, noun, suffix = 's') => {
  return (count === 1) ? noun : noun + suffix;
}

export const adjustOpacity = (hexCode, amount) => {
  let hex = hexCode.replace('#', '');
  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r},${g},${b},${amount / 100})`;
};

export const adjustColor = (col = "", amt) => {
  var usePound = false;
  if (!col) {
    return ("");
  }
  if (col[0] === "#") {
    col = col.slice(1);
    usePound = true;
  }
  var num = parseInt(col, 16);
  var r = (num >> 16) + amt;
  if (r > 255) {
    r = 255;
  }
  else if (r < 0) {
    r = 0;
  }
  var b = ((num >> 8) & 0x00FF) + amt;
  if (b > 255) {
    b = 255;
  }
  else if (b < 0) {
    b = 0;
  }
  var g = (num & 0x0000FF) + amt;
  if (g > 255) {
    g = 255;
  }
  else if (g < 0) {
    g = 0;
  }
  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

export const toggleMultiSelect = (ms_value, checked, ms_list) => {
  let tempSelectedKeys = [...ms_list];
  checked ? tempSelectedKeys.push(ms_value) : tempSelectedKeys.splice(tempSelectedKeys.indexOf(ms_value), 1);
  return tempSelectedKeys;
}

export const sales_history_tooltip = () => {
  return (
    <div className='bc'>
      We collect weekly sales and daily on hand/on order data from indie bookstores across the country (250+). This information is compiled into the inventory data that both you and the stores can use in all sorts of ways. The numbers you see here are the raw info, and not weighted in any way. (FYI: The peer data that stores see is weighted). These numbers are also where our Ranks are built from (the ranks are using a weighted formula however.)
      <br />
      OH = Total number currently on hand; The superscript number is the number of stores with it on hand, and the larger number is the total amount on hand for all those stores combined.
      <br />
      OO = Total number currently on order;  The superscript number is the number of stores with it on order, and the larger number is the total amount on order for all those stores combined.
      <br />
      LOC = number of stores that have sold this book in the last 14 months (including current month)
      <br />
      Most recent 3 month sales snapshot = actual monthly sales at all stores
      <br />
      Wk = Total number for the most recent week
    </div>)
}

export const nameSort = (nameA, nameB) => {
  let return_value = 0;
  if (nameA > nameB) {
    return_value = 1;
  }
  if (nameA < nameB) {
    return_value = -1
  }
  return return_value;
}

const XSVG = () => (<svg fill="currentColor" width="16px" height="16px" viewBox="0 0 1200 1227"><path d="M714.163,519.284L1160.89,0h-105.86L667.137,450.887L357.328,0H0l468.492,681.821L0,1226.37h105.866l409.625-476.152 l327.181,476.152H1200L714.137,519.284H714.163z M569.165,687.828l-47.468-67.894L144.011,79.694h162.604l304.797,435.991 l47.468,67.894l396.2,566.721H892.476L569.165,687.854V687.828z" /></svg>);
export const XIcon = props => <Icon component={XSVG} {...props} />