import { FacebookOutlined } from '@ant-design/icons'
import { Button, Input, message, Modal, Space, Tag, Typography } from "antd";
import React, { useState } from "react";

import { XIcon } from "../../utils/Utils";

export default function TitleShare(props) {

    const {  
        title = "", 
        isbn = 0 
    } = props;
    const [copied, setCopied] = useState(false);
    const url = "https://share.bookmanager.com/b2b/browse/filter/t/" + isbn ;

    const twitter_url = "https://twitter.com/intent/tweet?text="+title+"&url="+url;
    const facebook_url = "https://www.facebook.com/sharer/sharer.php?u=" + url;

    const copyToClipboard = (e) =>{
        if(!navigator.clipboard){
            message.error("Can't copy");
            return; 
        }
        navigator.clipboard.writeText(url);
        setCopied(true);
        setTimeout(() => {
            setCopied(false)
        },1000);
    }

    const [visible, setVisible] = useState(false);
    const drawModal = () => {
        return (
            <>
                <Modal destroyOnClose footer={null} onCancel={() => setVisible(false)} visible={visible}>
                    <Typography.Title level={5}>Share</Typography.Title>
                    <Typography.Paragraph>To share this title with others, copy this link (e.g. Ctrl-C) and then paste it into an email.</Typography.Paragraph>
                    <Input defaultValue={url} />
                    <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                    <Space size={0}>
                        <Tag style={{"padding": "5px"}} onClick={() => window.open(twitter_url, "_blank")} className="tag-btn" icon={<XIcon />} color="black">Share on X</Tag>
                        <Tag style={{"padding": "5px"}} onClick={() => window.open(facebook_url, "_blank")} className="tag-btn" icon={<FacebookOutlined />} color="#3b5999">Share on Facebook</Tag>
                    </Space>
                    <div style={{ "float": "right" }}>
                        <Space>
                            <Button onClick={() => setVisible(false)}>Cancel</Button>
                            <Button onClick={(e) => copyToClipboard(e)} type={(copied ? "default" : "primary")}>{(copied) ? "Copied" : "Copy"}</Button>
                        </Space>
                    </div>
                    <br clear="all" />
                </Modal>
            </>
        )
    }

    return (
        <>
            {drawModal()}
            <Button onClick={() => setVisible(true)} style={{ "width": "36px" }} size="small" className="mini-btn" ><small>Share</small></Button>
        </>
    )
}