import { SelectOutlined } from '@ant-design/icons';
import { Button, Col, Input, Menu, Row, Select, Space, Tooltip, Upload } from 'antd';
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { navigation } from "../data";
import bm_logo from "../media/bm_reps_logo.png";
import { apiCall } from "../utils/Api";
import { useSession } from "../utils/Session";
import { getISBNCount, handleTermSlash, objectToPath, pathToObject } from "../utils/Utils";
import AccountBox from "./AccountBox";
import Notifications from "./Notifications";

export default function Nav(props) {

    const history = useHistory();
    const [opened, setOpened] = useState("");
    const [selected, setSelected] = useState("");
    const [session, setSession] = useSession();
    const [processing, setProcessing] = useState(false);

    const showSearchBar = (history.location.pathname.includes("/browse"))
    // BARCODE SCANNER
    let inputString = "";
    let reading = false;
    useEffect(() => {
        function handleScan(event) {
            if (event.key === "~") {
                inputString = "";
                reading = true;
                setTimeout(() => {
                    reading = false;
                }, 1500)
            } else if (event.key === "Enter") {
                if (reading) {
                    setKeyword(inputString);
                    linkToBrowse(inputString, true);
                    reading = false;
                }
            } else {
                if (reading) {
                    inputString += (event.key === "Shift") ? "" : event.key;
                }
            }
        }

        document.addEventListener('keydown', handleScan, true);
        return () => {
            document.removeEventListener('keydown', handleScan, true);
        };
    }, []);

    const getTerm = () => {
        if (history.location.pathname.includes("/t/")) {
            let _arr = history.location.pathname.split("/t/");
            if (_arr.length > 1) {
                let _spt = _arr[1].split("/");
                if (_spt.length > 0) {
                    return handleTermSlash(_spt[0], false);
                } else {
                    return handleTermSlash(_arr[1], false);
                }
            }
        } else {
            return "";
        }
    }

    const [keyword, setKeyword] = useState(getTerm());
    const [searchType, setSearchType] = useState("keyword");

    const selectBefore = (
        <Select getPopupContainer={trigger => trigger.parentElement} className='ant-select-with-custom-arrow' onChange={(_v) => setSearchType(_v)} style={{ "border": "none", "width": "90px" }} defaultValue={searchType}>
            <Select.Option key="keyword" value="keyword"><small>Keyword</small></Select.Option>
            <Select.Option key="title" value="title"><small>Title</small></Select.Option>
            <Select.Option key="author" value="author"><small>Author</small></Select.Option>
            <Select.Option key="publisher" value="publisher"><small>Publisher</small></Select.Option>
            <Select.Option key="url" value="url"><small>URL</small></Select.Option>
            <Select.Option key="file" value="file"><small>File</small></Select.Option>
        </Select>
    );

    const resetKeyword = () => {
        setKeyword(getTerm());
    }

    useEffect(resetKeyword, [history.location.pathname]);

    const hadBetaAccess = (_item) => {
        // first check beta
        if (_item.hasOwnProperty("beta")) {
            if (!window.sitesettings.beta_access[_item.beta]) {
                return false;
            }
        } else {
            return true;
        }
    }

    const hasPermission = (_item) => {
        // ensure permission exists on nav item and is inin session
        // multiple subscriptions can apply to the same subnav menu item
        if (_item.hasOwnProperty("permission")) {
            let has = false;

            _item.permission.forEach(element => {
                if ((session.subscriptions.hasOwnProperty(element) && session.subscriptions[element]) || session.permissions.hasOwnProperty(element) && session.permissions[element]) {
                    has = true;
                }
            });
            return has;
        }
        return true;
    }

    function catalogueMenuTooltips(menu_item_slug) {
        let tooltip_text = "";
        if (menu_item_slug === "mylists") {
            tooltip_text = "Your personal catalogues, shared or not";
        } else if (menu_item_slug === "corplists") {
            tooltip_text = "Catalogues created by anyone in your company that are visible to all staff, shared or not";
        } else {
            tooltip_text = "All catalogues created by any publisher or sales rep that all stores can see";
        }
        return tooltip_text;
    }

    function individualMenuItem(_item) {
        let menu_item;
        if (!hadBetaAccess(_item)) {
            menu_item = (<Tooltip title={<small>Your login does not have permission to access this area. Please login with a different account that has access, or go to the Settings → Permissions menu to modify which accounts have access to specific areas.</small>}>
                {_item.name}
            </Tooltip>
                // {(_item.hasOwnProperty("link") && <small>&nbsp;<SelectOutlined style={{ "transform": "scaleX(-1)", "fontSize": "12px" }} /></small>)}
            )
        }
        else if (_item.slug === "mylists" || _item.slug === "corplists" || _item.slug === "listcat") {
            menu_item = (
                <Tooltip placement='left' title={catalogueMenuTooltips(_item.slug)}>
                    {_item.name}
                </Tooltip>
            )
        } else {
            menu_item = _item.name
        }
        return menu_item
    }

    const drawMenu = () => {

        // Hide navigation items if they don't have permission for all subnav items; 
        return navigation.filter(item => {
            let can_see = false;
            if(item?.subnav){
                item.subnav.forEach(i => {
                    if(hasPermission(i)){
                        can_see = true; 
                    }
                })
            } else {
                can_see = true; 
            }
            return can_see; 
        }).map((item) => {


            if (item.hasOwnProperty("subnav")) {
                return (
                    <Menu.SubMenu style={{ "padding": "0px 11px" }} onTitleClick={() => setOpened(item.slug)} key={item.slug} title={item.name}>
                        {item.subnav.map((_item) => {
                            _item.active = hadBetaAccess(_item);
                            if (_item.active) {
                                _item.active = hasPermission(_item);
                            }
                            if (_item.active) {
                                let return_item = <Menu.Item disabled={(!_item.active)} onMouseDown={(e) => {

                                    if (e.button > 0 && _item.active) {
                                        linkToPage(_item, true, e);
                                        e.stopPropagation();
                                        e.preventDefault();
                                        return;
                                    }
                                }} onClick={(e) => linkToPage(_item, false, e)} key={_item.slug}>
                                    {individualMenuItem(_item)}
                                </Menu.Item>
                                return return_item;
                            }
                        })}
                    </Menu.SubMenu>
                )
                // for menu items that have no submenu/subnav 
            } else {
                item.active = hadBetaAccess(item);
                if (item.active) {
                    item.active = hasPermission(item);
                }
                return (
                    <Menu.Item
                        disabled={(!item.active)}
                        onMouseDown={(e) => {
                            if (e.button > 0 && item.active) {
                                linkToPage(item, true, e);
                                e.stopPropagation();
                                e.preventDefault();
                                return;
                            }
                        }}
                        onClick={(e) => linkToPage(item, false, e)} key={item.slug}>
                        <Tooltip title={(!hadBetaAccess(item)) ? <small>Your login does not have permission to access this area. Please login with a different account that has access, or go to the Settings → Permissions menu to modify which accounts have access to specific areas.</small> : ""}>{item.name} </Tooltip>
                        {(item.hasOwnProperty("link") && <small>&nbsp;<SelectOutlined style={{ "transform": "scaleX(-1)", "fontSize": "12px" }} /></small>)}</Menu.Item>
                )
            }
        })
    }

    const linkToPage = (_item, _newtab = false, e) => {


        if (_item.hasOwnProperty("link")) {
            window.open(_item.slug, "_blank");
            return;
        }
        // Route defaults for browse, mylist, categories
        let _retpath = _item.slug;
        let _pushstate = false;
        // eslint-disable-next-line default-case
        switch (_item.slug) {
            case "browse":
                _pushstate = true;
                _retpath = "browse" + objectToPath(session.filter_preset_defaults.status.selections);
                break;
            case "listcat":
                _pushstate = true;
                _retpath = "catalogues/public-catalogues" + objectToPath(session.filter_preset_defaults.listcat.selections);
                break;
            case "mylists":
                _pushstate = true;
                _retpath = "catalogues/my-catalogues" + objectToPath(session.filter_preset_defaults.mylists.selections);
                break;
            case "corplists":
                _pushstate = true;
                _retpath = "catalogues/my-company-catalogues" + objectToPath(session.filter_preset_defaults.corplists.selections);
                break;
        }



        if (e.button > 0) {
            window.open("/" + _retpath, "_blank");
            return;
        } else {

            setSelected(_retpath);
            if (_newtab) {
                window.open("/" + _retpath, "_blank");
            }
            if (_pushstate) {
                history.push({ pathname: "/" + _retpath, state: true });
            } else {
                history.push({ pathname: "/" + _retpath });
            }
        }




    }

    const generateFile = (_val) => {
        var filename = 'pasted.txt';
        let _file = new File([new Blob([_val])], filename)
        let f = {}
        f.file = _file;
        return f;
    }

    const getCacheKeyForFile = (_file) => {
        setProcessing(true);
        apiCall("browse/getCacheKeyForFile", { file: _file.file }, (_status, _result) => {
            if (_status) {
                history.push({ pathname: "/browse/filter/v/sequence/x/" + _result.id, state: Math.random().toString() })
            }
            setProcessing(false);
        });
    }

    const convertURL = (_url) => {
        apiCall("url_converter/convertCustomerUrlToBiz", { url: _url }, (_status, _result) => {
            if (_status) {
                // history.push(_result.url);
                history.push({ pathname: _result.url, state: Math.random().toString() })
            } else {
                linkToBrowse(_url, false, true);
            }
        })
    }

    const linkToBrowse = (_val, _clear = false, _skipURL = false) => {
        if (!_skipURL && (_val.substr(0, 5) === "http:" || _val.substr(0, 6) === "https:")) {
            convertURL(_val);
            return;
        }
        _val = handleTermSlash(_val, true);
        // if search contains 1 or more ISBNS 
        if (getISBNCount(_val) > 1) {
            getCacheKeyForFile(generateFile(_val));
            return;
        }
        let _pathname = "";
        if (_val) {
            if (history.location.pathname.includes("browse/filter") && !_clear) {
                let obj = pathToObject(history.location.pathname);
                obj.v = "";
                obj.x = "";
                obj.t = _val;
                if (!obj.hasOwnProperty("k")) {
                    if (searchType !== "keyword") {
                        obj.k = searchType;
                        _pathname = "/browse" + objectToPath(obj);
                    }
                } else {
                    obj.k = searchType;
                    _pathname = "/browse" + objectToPath(obj);
                }
            } else {
                _pathname = "/browse/filter/t/" + _val;
                if (searchType !== "keyword") {
                    _pathname += "/k/" + searchType;
                }
            }
        } else {
            history.push("/browse");
        }
        history.push({ pathname: _pathname, state: _val + Math.random().toString() })
    }

    const getCurrentKey = () => {
        if (history.location.pathname.includes("browse")) {
            return "status";
        }
        if (history.location.pathname.includes("public-catalogues")) {
            return "listcat";
        }
        if (history.location.pathname.includes("my-catalogues")) {
            return "mylists"
        }
        if (history.location.pathname.includes("my-company-catalogues")) {
            return "corplists"
        }
        let m = history.location.pathname.split("/");
        let s = "";
        if (m.length > 1) {
            s = m[m.length - 2] + "/" + m[m.length - 1];
        }
        return s;
    }

    function checkSuggestedCartPermissions() {
        let can_view = false;
        if (session.subscriptions.pubdata || session.subscriptions.smdata) {
            can_view = true;
        }
        return can_view;
    }

    return (
        <div >
            <Row align="middle" >
                <Col> <img alt="bookmanager" width={180} src={bm_logo} /></Col>
                <Col flex={"400px"}><Menu style={{ "width": "400px" }} activeKey={getCurrentKey()} mode="horizontal" theme="dark" >{drawMenu()}</Menu></Col>
                <Col flex={"auto"} >
                    {(showSearchBar &&
                        <div>
                            {(searchType === "file" && <>
                                <Input.Search className="clean-input" style={{ "minWidth": "100px", "maxWidth": "450px" }} enterButton disabled addonAfter={<div style={{ "display": "inline-block", "position": "relative", "right": "193px", "width": "0px" }}><Upload showUploadList={false} customRequest={(_file) => getCacheKeyForFile(_file)}><Button loading={processing} disabled={(processing)} type="primary" size="small"><small>Choose a file <small> &nbsp;(max 50MB)</small></small></Button></Upload></div>} value={keyword} addonBefore={selectBefore} />
                            </>)}
                            {((searchType !== "file" && (getCurrentKey() !== "mylists" && getCurrentKey() !== "listcat" && getCurrentKey() !== "corplists")) && <>
                                <Input.Search
                                    value={keyword}
                                    addonBefore={selectBefore}
                                    onChange={
                                        (e) => setKeyword(e.target.value)
                                    }
                                    onSearch={(e) => linkToBrowse(e, true)}
                                    className="clean-input"
                                    allowClear
                                    enterButton
                                    placeholder="Keywords, author, title or series..."
                                    style={{ "minWidth": "100px", "maxWidth": "450px" }}
                                />
                            </>)}
                        </div>
                    )}
                </Col>
                <Col flex={"15px"}><div style={{ "color": "#888", "textAlign": "right" }}>&nbsp;</div></Col>
                <Col flex={"210px"} style={{ "maxWidth": "230px" }}>
                    <div style={{ "float": "right" }}>
                        <Space size={0}>
                            <div style={{ "width": "13px" }}>&nbsp;</div>
                            {/* Must not have salesisbn subscription to see notifications */}
                            {((!session?.subscriptions.salesisbn && !session?.subscriptions.catalogue) &&
                                <Notifications />
                            )}
                            <AccountBox />
                        </Space>
                    </div>
                </Col>
            </Row>
            <br clear="all" />
        </div>
    )
}