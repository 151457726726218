import { QuestionCircleTwoTone } from '@ant-design/icons';
import { Alert, Button, Checkbox, Col, DatePicker, Divider, Form, Input, message, Modal, Popconfirm, Radio, Result, Row, Select, Space, Spin, Tooltip, Typography } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";

import { apiCall } from "../../utils/Api";
import conditional from "../../utils/conditional";
import { useSession } from "../../utils/Session";
import MultiSelectListEditTable from "../title/multi_select/MultiSelectListEditTable";
import ListImageUploader from './ListImageUploader';
import ListPDFUploader from './ListPDFUploader';
import ListUpload from "./ListUpload";
import FormItem from 'antd/lib/form/FormItem';

export default function ListCreateEdit(props) {

    const {
        padding = 20,
        callback = () => { },
        create = false,
        getCatalogues = () => { },
        close = () => { },
        results = {},
        setResults = () => { },
        data = {},
        fromQP = false,
        fetchMyLists = () => { },
        hideimport = false,
        
    } = props;

    const [imageCacheKey, setImageCacheKey] = useState(data.image_cache_key !== 0 ? data.image_cache_key : 0)
    const [session, setSession] = useSession();
    const [reverse, setReverse] = useState(false);
    const [uploading, setUploading] = useState(false);

    const toggleQuickPick = (_list_id, _checked) => {
        apiCall("titlelist/toggleQuickPick", { list_id: _list_id, is_picked: _checked }, (_status, _result) => {
            if (_status) {
                setSession(_result);
            }
        })
    }

    const is_dynamic = false;

    const {
        list_id = "",
        actual_id = "",
        name = [],
        subcategory = "",
        category = "",
        sort_by = "none",
        description = "",
        isbns_full_data = [],
        has_image = false,
        has_linked_pdf = false,
        on_expiry_actions = ["nothing"],
        effective_type = "month",
        sort_by_reversed = false,
        is_private = true,
    } = data

    const getExpireAction = () => {
        if (Array.isArray(on_expiry_actions) && on_expiry_actions.length > 0) {
            return on_expiry_actions[0]
        } else {
            return "nothing";
        }
    }

    const on_expire_json = data.on_expiry_actions ? data.on_expiry_actions : [];

    const [vendorAcct, setVendorAcct] = useState(data.vendor ? data.vendor.acct : "");
    const [agencyAcct, setAgencyAcct] = useState(data.agency ? data.agency.acct : "");
    const [saving, setSaving] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const [form] = Form.useForm()
    const [isbns, setIsbns] = useState(data.isbns ? data.isbns : []);
    const [sort, setSort] = useState(sort_by);
    const [dynamic, setDynamic] = useState(is_dynamic);
    const [loading, setLoading] = useState(false);
    const [initLoaded, setInitLoaded] = useState(false);
    const [startTs, setStartTs] = useState(data.date_start ? moment.unix(data.date_start) : "");
    const [expiryTs, setExpiryTs] = useState(data.date_expired ? moment.unix(data.date_expired) : "");
    const [onExpire, setOnExpire] = useState(on_expire_json);
    const [doNothing, setDoNothing] = useState(on_expiry_actions.length === 0 ? true : false);
    const [selectedRowKeys, setSelectedRowKeys] = useState(data.selectedRowKeys ? data.selectedRowKeys : []);
    const [effectiveDateSelect, setEffectiveDateSelect] = useState(data.effective_type || "season");
    const [showImage, setShowImage] = useState(has_image);
    const [showPdf, setShowPdf] = useState(has_linked_pdf);
    const [changeImage, setChangeImage] = useState(false);
    const [changePdf, setChangePdf] = useState(false);
    const [imageFile, setImageFile] = useState({ file: false, url: false });
    const [pdfFile, setPdfFile] = useState({ file: false, url: false });
    const [foundChosenEDOption, setFoundChosenEDOption] = useState(false);
    const [suppliers, setSuppliers] = useState([]);
    const [agencies, setAgencies] = useState([]);
    const [effectiveDates, setEffectiveDates] = useState({ seasons: [], years: [], months: [] });
    const [categories, setCategories] = useState({ categories: [], subcategories: [] });
    const [chosenEDOption, setChosenEDOption] = useState(data.effective_string);

    const getStartingOptions = () => {
        setLoading(true);
        let options_results = {};
        apiCall("titlelist/getOptions", {}, (_status, _result) => {
            if (_status) {
                options_results = _result;
                setCategories({ categories: _result.categories, subcategories: _result.subcategories });
                setEffectiveDates(_result.effective_dates);
                setAgencies(_result.agencies);
                setSuppliers(_result.suppliers);
                getStartingValues(options_results);
            }
        })
    }

    const getStartingValues = (options_results) => {
        if (options_results.agencies.length === 1) {
            setAgencyAcct(options_results.agencies[0]);
        }
        if (options_results.suppliers.length === 1) {
            setVendorAcct(options_results.suppliers[0]);
        }
        let effective_date_default_found = false;
        if (data.effective_type === "season") {
            setChosenEDOption(options_results.effective_dates.seasons.find((item) => item.name === data.effective_string));
            effective_date_default_found = true;
        } else if (data.effective_type === "year") {
            const year = data.effective_string.substr(data.effective_string.indexOf("2"), 4);
            setChosenEDOption(options_results.effective_dates.years.find((item) => item.name.toString() === year))
            effective_date_default_found = true;
        } else {
            setChosenEDOption(options_results.effective_dates.months.find((item) => item.name.toString() === data.effective_string))
            effective_date_default_found = true;
        }
        if (effective_date_default_found) {
            setFoundChosenEDOption(true);
        }
        if (create) {
            setLoading(false);
            setInitLoaded(true);
            return;
        }
        apiCall("titlelist/get", { list_id: list_id }, (_status, _result) => {
            if (_status) {
                setIsbns(_result.isbns);
            } else {
                message.error(_result.error)
            }
            setInitLoaded(true);
            setLoading(false);
        })

    }

    useEffect(getStartingOptions, []);

    const uploadFile = (file_type, upload_type, new_list_id = 0) => {
        setUploading(true);
        let args = {
            list_id: create ? new_list_id : list_id
        }
        let api_text = ""
        const is_file_upload = upload_type === "file" ? true : false;
        if (is_file_upload) {
            api_text = file_type === "cover_image" ? "titlelist/uploadImage" : "titlelist/uploadPdf"
            args.file = file_type === "cover_image" ? imageFile.file : pdfFile.file;
        } else {
            api_text = file_type === "cover_image" ? "titlelist/setImageByUrl" : "titlelist/setPdfByUrl"
            args.url = file_type === "cover_image" ? imageFile.url : pdfFile.url;
        }
        apiCall(api_text, args, (_status, _result) => {
            if (_status) {
                setImageCacheKey(_result.image_cache_key);
                setUploading(false);
                setCanSave(true);
            } else {
                if (_result.error.includes("File is not an image")) {
                    message.error("URL used is not an image. Make sure it ends in .png, .jpeg, or .jpg");
                    setShowImage(false)
                } else {
                    message.error(_result.error);
                }
            }
        });
    }

    const removeFile = (file_type) => {
        setUploading(true);
        let api_text = file_type === "cover_image" ? "titlelist/deleteImage" : "titlelist/deletePdf"
        apiCall(api_text, { list_id: list_id }, (_status, _result) => {
            setUploading(false);
            setCanSave(true);
        });
    }

    const convertList = () => {
        let obj = { ...data }
        obj.is_dynamic = false;
        obj.isbns_json = JSON.stringify(isbns.map(item => { return item.isbn }));
        apiCall("titlelist/edit", obj, (_status, _result) => {
            if (_status) {
                setDynamic(_result.is_dynamic);
                message.success("Catalogue converted successfully.")
            } else {
                message.error(_result.error);
            }
        })
    }

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current < moment().startOf('day');
        // return current && current < moment().endOf('day');
    };

    const shrinkISBNS = (_arr) => {
        return _arr.map(item => {
            let obj = { isbn: item.isbn }
            if (item.hasOwnProperty("highlight")) {
                obj.highlight = item.highlight
            }
            return obj;
        })
    }

    const onFinish = (_f) => {
        setSaving(true)
        const isWhitespaceString = str => !str.replace(/\s/g, '').length
        let args = {
            category: _f.category,
            sort_by_reversed: _f.sort_by_reversed,
            blurb: _f.blurb,
            has_oh_oo: false,
            filter_preset_id: 0,
            isbns_json: JSON.stringify((create && selectedRowKeys.length > 0) ? shrinkISBNS(selectedRowKeys) : shrinkISBNS(isbns)),
            headline_visible: false,
            is_dynamic: is_dynamic,
            show_expiry: true,
        }

        args.is_private = _f.is_private;  

        if (_f !== "" && sort !== "") {
            args.sort_by = sort;
        } else {
            args.sort_by = "none";
        }
        if (!doNothing) {
            args.on_expire_json = JSON.stringify(onExpire)
        }
        if (list_id !== "") {
            args.list_id = list_id;
        }
        if (chosenEDOption !== null || chosenEDOption !== undefined) {
            if (effectiveDateSelect === "season" || effectiveDateSelect === "year" || effectiveDateSelect === "month") {
                if (chosenEDOption !== null && chosenEDOption !== undefined) {
                    args.effective_from_ts = chosenEDOption.start;
                    args.effective_to_ts = chosenEDOption.end;
                }
            }
        }
        if (_f.subcategory.length !== 0 && _f.subcategory !== undefined) {
            args.subcategory = _f.subcategory;
        }
        if (startTs !== null && startTs !== "" && startTs !== data.date_start) {
            if (startTs.isValid()) {
                args.start_ts = moment(startTs).unix()
            }
        }
        if (expiryTs !== null && expiryTs !== "" && expiryTs !== data.date_start) {
            if (expiryTs.isValid()) {
                args.expiry_ts = moment(expiryTs).unix();
            }
        }
        if (_f.agency_acct !== null && _f.agency_acct !== "") {
            if (agencyAcct !== "") {
                args.agency_acct = _f.agency_acct.acct
            }
        } else if (agencyAcct !== "") {
            args.agency_acct = agencyAcct.acct;
        } else if (agencies.length === 1) {
            args.agency_acct = agencies[0].acct
        }

    
        if (_f.vendor_acct !== null && _f.vendor_acct !== "") {
            if(_f.vendor_acct?.acct){
                // is obj
                args.vendor_acct = _f.vendor_acct?.acct
            } else {
                args.vendor_acct = _f.vendor_acct
            }
        } else if (vendorAcct?.acct && vendorAcct?.acct !== "") {
            args.vendor_acct = vendorAcct.acct;
        } else if (suppliers.length === 1) {
            args.vendor_acct = suppliers[0].acct
        }

        if (typeof _f.name === 'string' && !isWhitespaceString(_f.name)) {
            args.name = _f.name;
        }


        apiCall((create) ? "titlelist/create" : "titlelist/edit", args, (_status, _result) => {
            if (_status) {
                if (create) {
                    callback();
                    message.success("Catalogue created successfully.");
                    if (fromQP) {
                        toggleQuickPick(_result.list_id, true)
                        fetchMyLists()
                    }
                } else {
                    let newArr = { ...results };
                    let update = results.rows.find(item => item.list_id === _result.list_id);
                    Object.keys(update).map(key => {
                        update[key] = _result[key];
                    })
                    newArr.rows[results.rows.findIndex(item => item.list_id === _result.list_id)] = update;
                    setResults({ ...results, "rows": newArr.rows })
                    message.success("Catalogue saved successfully.");
                }
                if (changeImage) {
                    uploadFile("cover_image", imageFile.file !== false ? "file" : "url", _result.list_id);
                }
                if (changePdf) {
                    uploadFile("pdf", pdfFile.file !== false ? "file" : "url", _result.list_id);
                }
                if (!uploading) {
                    getCatalogues(true);
                }
                close();
            } else {
                if (_result.error.includes("effective_from_ts") || _result.error.includes("effective_to_ts")) {
                    message.error("You must select a season, month, or year from the dropdown before saving")
                } else if (_result.error.includes("name")) {
                    message.error("You must enter a name before saving");
                } else if (_result.error.includes("category")) {
                    message.error("You must choose a category from the dropdown before saving");
                } else {
                    message.error(_result.error)
                }
            }
            setSaving(false)
        })
            ;
    }

    const updateList = (_list) => {
        form.setFieldsValue({ "isbns_json": JSON.stringify(_list) })
    }

    const drawDynamicWarning = () => {
        return (
            <Result
                icon={<></>}
                title=""
                type="warning"
                extra={
                    <Alert style={{ "maxWidth": "300px", "margin": "0 auto" }} description={
                        <>
                            <div>
                                <strong>List is dynamic</strong>
                                <div>This list is generated from search filters<br /> You can convert to a static, editable list by clicking below</div>
                                <br />
                                <div>
                                    <Popconfirm onConfirm={() => convertList()} title={<>Are you sure? <br />This action is irreversible</>} ><Button type="primary" size="small"><small>Convert to static list</small></Button></Popconfirm>
                                </div>
                            </div>
                        </>}
                    />
                }
            />
        )
    }

    function changeHighlights(changed_isbn, checked) {
        let tempAddHighlights = [...selectedRowKeys]
        tempAddHighlights = tempAddHighlights.map((isbn, index) => {
            if (checked !== isbn.highlight && changed_isbn === isbn.isbn) {
                isbn.highlight = checked
            }
            return isbn;
        })
        setSelectedRowKeys(tempAddHighlights)
    }

    function checkIfFoundDefault(selected_period, default_value) {
        if (foundChosenEDOption && effective_type !== "custom" && selected_period === effective_type) {
            return selected_period === effective_type ? default_value : chosenEDOption.name
        }
        return null;
    }

    const drawEDOptions = (selected_period) => {
        let default_value = "";
        let select_options = [];
        let index_of_space = 0;
        if (!create) {
            switch (selected_period) {
                case "season":
                    select_options = effectiveDates.seasons;
                    default_value = data.effective_string;
                    break;
                case "year":
                    select_options = effectiveDates.years;
                    index_of_space = data.effective_string.indexOf(" ");
                    default_value = data.effective_string.substr(index_of_space + 1, 4);
                    break;
                case "month":
                    select_options = effectiveDates.months;
                    default_value = data.effective_string;
                    break;
                case "custom":
                    default_value = data.effective_string;
                    break;
                default:
                    default_value = ""
            }
            return (
                <Select
                    style={{ "width": "180px" }}
                    className='ant-select-with-custom-arrow'
                    getPopupContainer={trigger => trigger.parentElement}
                    placeholder="Select"
                    optionLabelProp="label"
                    defaultValue={checkIfFoundDefault(selected_period, default_value)}
                    onChange={(e) => {
                        setChosenEDOption(select_options.find((item) => item.name === e))
                    }}
                >
                    {select_options.map((item, index) => {
                        return (
                            <Select.Option key={index} label={item.name} value={item.name}>
                                {item.name}
                            </Select.Option>
                        )
                    })}
                </Select>
            )
        }
        switch (selected_period) {
            case "season":
                select_options = effectiveDates.seasons;
                default_value = "";
                break;
            case "year":
                select_options = effectiveDates.years;
                default_value = "";
                break;
            case "month":
                select_options = effectiveDates.months;
                default_value = ""
                break;
            case "custom":
                default_value = ""
                break;
            default:
                default_value = ""
        }
        return (
            <Select
                style={{ "width": "180px" }}
                getPopupContainer={trigger => trigger.parentElement}
                placeholder="Select"
                className='ant-select-with-custom-arrow'
                optionLabelProp="label"
                onChange={(e) => {
                    setChosenEDOption(select_options.find((item) => item.name === e))
                }}
            >
                {select_options.map((item, index) => {
                    return (
                        <Select.Option key={index} label={item.name} value={item.name}>
                            {item.name}
                        </Select.Option>
                    )
                })}
            </Select>
        )
    }

    const drawSortByLabel = () => {
        return (
            <Space >
                <strong>Sort by</strong>
                <FormItem initialValue={sort_by_reversed} valuePropName='checked' noStyle name="sort_by_reversed">
                    <Checkbox style={{ "marginLeft": "10px" }} defaultChecked={reverse}>&nbsp;Reverse Order?</Checkbox>
                </FormItem>
            </Space>
        )
    }

    const isbn_warning = <>Your catalogue contains some items that do not exist in our database. <div className='shim' /><div className='shim' /> Those appear as * title not found. This means that these items will not be visible after saving / sharing. <div className='shim' /><div className='shim' />Please contact <a href='mailto:data@bookmanager.com'>data@bookmanager.com</a> if you would like to learn how to get these titles added!</>

    // const drawAccountName = () => {
    //     let _name = "";
    //     let _prefix = ""
    //     if (suppliers.length === 1) {
    //         _prefix = "Supplier"
    //         _name = vendorAcct.name;
    //     } else {
    //         _prefix = "Sales & Marketing"
    //         _name = agencyAcct.name;
    //     }
    //     return (<div style={{ "opacity": "0.7" }}><small>{_prefix}: {_name}</small></div>)
    // }




    return (
        <>
            {(!initLoaded &&
                <Spin spinning={!initLoaded}>
                    <div style={{ "width": "1310px", "margin": "0 auto", "height": "500px" }}></div>
                </Spin>
            )}
            {(initLoaded && <div style={{ "padding": padding + "px" }} >
                <Typography.Title level={5}>{(create) ? "Create new" : "Edit"} Catalogue</Typography.Title>
                <Divider />
                <Form form={form}
                    onFinish={(e) => (create && isbns.filter(item => !item.success).length > 0) ? Modal.confirm({ title: "Unknown titles", okText: "Save anyway", content: isbn_warning, onOk: () => onFinish(e) }) : onFinish(e)}
                    layout="vertical">
                    <Form.Item hidden initialValue={list_id} name="list_id" label={<strong>List id</strong>}>
                        <Input />
                    </Form.Item>
                    <Space wrap size={20} align="start">
                        {/* onChange={setCheckingSave([...checkingSave], "name", true)}  onChange={updatingCheckingSave("name")}*/}
                        <Form.Item required="true" initialValue={name} name="name" label={<strong>Name</strong>}>
                            <Input placeholder="Catalogue name" style={{ "width": "460px" }} />
                        </Form.Item>
                        <conditional.true value={suppliers.length != 1}>
                            <Form.Item initialValue={agencyAcct} name="agency_acct" hidden />
                            <Space id="create-edit-modal">
                                <Form.Item
                                    initialValue={vendorAcct}
                                    name="vendor_acct"
                                    label={<strong>Supplier</strong>}>
                                    <Select
                                        className='ant-select-with-custom-arrow'
                                        listHeight={512}
                                        getPopupContainer={trigger => trigger.parentElement}
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        style={{ "width": "360px" }}
                                        options={suppliers.map(acct => {
                                            const label = acct.acct + " - " + acct.name
                                            return {
                                                value: acct.acct,
                                                label: label
                                            }
                                        })}
                                    />
                                </Form.Item>
                            </Space>
                        </conditional.true>
                        <conditional.true value={agencies.length !== 1}>
                            <Form.Item initialValue={vendorAcct} name="vendor_acct" hidden />
                            <Space>
                                <Form.Item
                                    initialValue={agencyAcct}
                                    name="agency_acct"
                                    style={{ "paddingRight": "10px" }}
                                    label={<strong>Sales & Marketing</strong>}>
                                    <Select
                                        className='ant-select-with-custom-arrow'
                                        style={{ "width": "360px" }}
                                        placeholder="Select Agency"
                                        getPopupContainer={trigger => trigger.parentElement}
                                        listHeight={512}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={agencies.map(acct => {
                                            const label = acct.name
                                            return {
                                                value: acct.acct,
                                                label: label
                                            }
                                        })}
                                    />
                                </Form.Item>
                            </Space>
                        </conditional.true>
                    </Space>
                    <Space size={20} align="start">


                        <Form.Item required="true" label={drawSortByLabel()}>

                            <Select
                                defaultValue={sort_by === "none" ? "" : sort}
                                className='ant-select-with-custom-arrow'
                                getPopupContainer={trigger => trigger.parentElement}
                                onChange={(e) => setSort(e)}
                                style={{ "width": "220px" }}>
                                <Select.Option value={""} >None</Select.Option>
                                <Select.Option value={"rank"} >Rank</Select.Option>
                                <Select.Option value={"title"} >Alphabetical by Title</Select.Option>
                                <Select.Option value={"author"} >Alphabetical by Author</Select.Option>
                                <Select.Option value={"pubdate"} >Pubdate</Select.Option>
                                <Select.Option value={"subject"} >Subject</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item hidden noStyle value={sort} name="sort_by"><Input /></Form.Item>
                        <Form.Item
                            initialValue={category}
                            required="true"
                            style={{ "marginLeft": "-20px" }}
                            name="category"
                            label={<strong>Category</strong>}>
                            <Select
                                placeholder="Select category..."
                                className='ant-select-with-custom-arrow'
                                style={{ "width": "220px" }}
                                getPopupContainer={trigger => trigger.parentElement}
                                showSearch
                                listHeight={512}
                                options={categories.categories.map(item => {
                                    return { value: item }
                                })}
                            />
                        </Form.Item>
                        <Form.Item
                            initialValue={subcategory}
                            name="subcategory"
                            label={<strong>Subcategory</strong>}>
                            <Select
                                className='ant-select-with-custom-arrow'
                                style={{ "width": "200px" }}
                                getPopupContainer={trigger => trigger.parentElement}
                                listHeight={400}
                                options={categories.subcategories.map(item => {
                                    return { value: item }
                                })}
                            />
                        </Form.Item>

                        <Form.Item
                            initialValue={is_private}
                            valuePropName='checked'
                            name="is_private"
                            label={<strong>Privacy</strong>}>
                            <Checkbox>Private to me</Checkbox>
                        </Form.Item>

                    </Space>
                    <Divider style={{ "marginTop": "0px", "marginBottom": "15px" }} dashed />
                    <Space size={20} >
                        <Space direction='vertical'>
                            <Typography.Title level={5}><span style={{ "paddingRight": "5px", "color": "red", "float": "left" }}>* </span> <small>Effective Period</small></Typography.Title>
                            <Radio.Group defaultValue={"season"}>
                                <Space>
                                    <Radio onClick={(e) => setEffectiveDateSelect(e.target.value)} name="season" value="season" >Season</Radio>
                                    <Radio onClick={(e) => setEffectiveDateSelect(e.target.value)} name="year" value="year">Year</Radio>
                                    <Radio onClick={(e) => setEffectiveDateSelect(e.target.value)} name="month" value="month" >Month</Radio>
                                </Space>
                            </Radio.Group>
                            <div>
                                <conditional.true value={effectiveDateSelect === "season"}>
                                    {drawEDOptions("season")}
                                </conditional.true>
                                <conditional.true value={effectiveDateSelect === "year"}>
                                    {drawEDOptions("year")}
                                </conditional.true>
                                <conditional.true value={effectiveDateSelect === "month"}>
                                    {drawEDOptions("month")}
                                </conditional.true>
                                <conditional.true value={effectiveDateSelect === "custom"}>
                                    {drawEDOptions("custom")}
                                </conditional.true>
                            </div>
                        </Space>

                        <Space size={20}>
                            <Space size={0} direction='vertical'>
                                <Typography.Title level={5}>
                                    <small>Start Date</small>
                                </Typography.Title>
                                <DatePicker
                                    style={{ "width": "190px" }}
                                    defaultValue={startTs}
                                    format={"MMMM D, YYYY"}
                                    disabledDate={disabledDate}
                                    onChange={(e) => { setStartTs(moment(e)) }} />
                            </Space >
                            <Space size={0} direction='vertical'>
                                <Typography.Title level={5}>
                                    <small>End Date</small>
                                    <Tooltip title={<small>The end date means that day at 12:00am of your timezone, or midnight, of the date chosen. If you chose January 1st, that means it would expire right at the start of January 1st at 12:00am</small>}>
                                        <QuestionCircleTwoTone style={{ "paddingLeft": "5px", "cursor": "pointer", "fontSize": "14px" }} />
                                    </Tooltip>
                                </Typography.Title>
                                <DatePicker
                                    style={{ "width": "190px" }}
                                    defaultValue={expiryTs}
                                    format={'MMMM D, YYYY'}
                                    disabledDate={disabledDate}
                                    onChange={(e) => { setExpiryTs(moment(e)) }} />
                            </Space>
                        </Space>
                        <div>
                            <Typography.Title level={5}><small>Expiry Action</small></Typography.Title>
                            <div>
                                <Select
                                    className='ant-select-with-custom-arrow'
                                    getPopupContainer={trigger => trigger.parentElement}
                                    defaultValue={getExpireAction}
                                    onChange={(e) => {
                                        if (e === "nothing") {
                                            setDoNothing(true);
                                            setOnExpire([]);
                                        } else {
                                            setDoNothing(false);
                                            setOnExpire([e]);
                                        }
                                    }}
                                    style={{ "width": "200px" }}>
                                    <Select.Option value={"nothing"} >
                                        Do Nothing
                                    </Select.Option>
                                    <Select.Option value={"archive"} >
                                        <Tooltip placement="left" title={<small>Moves this catalogue to the Archived folder. Catalogue will be unshared if it is shared.</small>}>
                                            Archive <QuestionCircleTwoTone style={{ "cursor": "pointer" }} />
                                        </Tooltip>
                                    </Select.Option>
                                    <Select.Option value={"delete"} >
                                        <Tooltip placement="left" title={<small>Deletes the catalogue. You will no longer be able to edit or recover it. Stores that have viewed a catalogue that has previously been published will still be able to view it, even after it has been deleted.</small>}>
                                            Delete <QuestionCircleTwoTone style={{ "cursor": "pointer" }} />
                                        </Tooltip>
                                    </Select.Option>
                                    <Select.Option value={"unshare"} >
                                        <Tooltip placement="left" title={<small>Catalogue will be unshared, if it is shared with anybody. Stores who have previously viewed a shared catalogue will still be able to view it even after unsharing.</small>}>
                                            Unshare <QuestionCircleTwoTone style={{ "cursor": "pointer" }} />
                                        </Tooltip>
                                    </Select.Option>
                                    <Select.Option value={"trash"} >
                                        <Tooltip placement="left" title={<small>Moves this catalogue to the Trash folder. Catalogue will be unshared if it is shared. Trashed catalogues can be recovered. (Stores who have previously viewed a shared catalogue will still be able to view it even after unsharing)</small>}>
                                            Trash <QuestionCircleTwoTone style={{ "cursor": "pointer" }} />
                                        </Tooltip>
                                    </Select.Option>
                                </Select>
                            </div>
                        </div>
                    </Space>
                    <Divider style={{ "marginTop": "15px", "marginBottom": "20px" }} dashed />
                    <Form.Item
                        style={{ "margin-top": "10px" }}
                        initialValue={description}
                       
                        label={
                            <div>
                                <label>Description</label>
                                <Tooltip title={<small>The description may include <strong>'&lt;br /&gt;'</strong>. <br />This is normal, and is used to create line breaks in the description. <div className='shim' />Basic html is allowed: {`<b> <i> <u> and <a>`}</small>}>
                                    <QuestionCircleTwoTone style={{ "paddingLeft": "5px", "cursor": "pointer" }} />
                                </Tooltip>
                            </div>}
                        name={"blurb"}>
                        <Input.TextArea
                            placeholder="Description..."
                            rows={3}
                            showCount
                            maxLength={2000}>
                        </Input.TextArea>
                    </Form.Item>
                    {((!loading && !hideimport) &&
                        <Spin indicator={<>{drawDynamicWarning()}</>} spinning={dynamic}>
                            <ListUpload
                                create={create}
                                candelete
                                drag
                                canSave={canSave}
                                resetSort={() => {
                                    setSort("");
                                }}
                                setCanSave={setCanSave}
                                list_id={list_id}
                                updateList={updateList}
                                isbns={isbns}
                                setIsbns={setIsbns}
                            />
                            <Form.Item hidden initialValue={JSON.stringify(isbns.map(item => { return item.isbn }))} noStyle name={"isbns_json"}>
                                <Input.TextArea rows={3} showCount maxLength={2000}></Input.TextArea>
                            </Form.Item>
                        </Spin>
                    )}
                    {((create && selectedRowKeys.length > 0) && <MultiSelectListEditTable
                        isbns={selectedRowKeys}
                        isbns_full_data={isbns_full_data}
                        changeHighlights={changeHighlights} />)}

                    <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                    <div className="float-flex">
                        <ListImageUploader
                            create={create}
                            image_cache_key={imageCacheKey}
                            uploadFile={uploadFile}
                            removeFile={removeFile}
                            actual_id={actual_id}
                            has_image={has_image}
                            imageFile={imageFile}
                            setImageFile={setImageFile}
                            showImage={showImage}
                            setShowImage={setShowImage}
                            setChangeImage={setChangeImage}
                        />
                        <div style={{ "width": "40px" }}>&nbsp;</div>
                        <ListPDFUploader
                            create={create}
                            uploadFile={uploadFile}
                            removeFile={removeFile}
                            actual_id={actual_id}
                            has_linked_pdf={has_linked_pdf}
                            pdfFile={pdfFile}
                            setPdfFile={setPdfFile}
                            showPDF={showPdf}
                            setShowPDF={setShowPdf}
                            setChangePdf={setChangePdf}
                        />

                    </div>

                    <Divider />

                    <Alert style={{ "padding": "3px 10px" }} type="warning" description={<small>The time to save data increases with the number of titles.  After saving a catalog cover image or PDF, you may need to refresh the page to see updates.</small>} />
                    <br />
                    <div className="float-flex">
                        <div>&nbsp;</div>
                        <Space>
                            <Button onClick={(e) => close()}>Cancel</Button>
                            <Button loading={saving} htmlType="submit" type="primary">Save</Button>
                        </Space>
                    </div>



                </Form>
            </div>)}
        </>
    );
}