import { DownOutlined } from '@ant-design/icons';
import { Badge, Button, Checkbox, Divider, Form, Input, message, Modal, Radio, Select, Space, Switch, Table, Tooltip, Typography } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";

import conditional from "../../../tools/conditional";
import { apiCall } from "../../../utils/Api";
import { writeSharedReadable } from "../../../utils/Utils";
import CommentOptions from "../../CommentOptions";

export default function CommentMultiSelect(props) {

    const { 
        all_comments = [],
        non_list_comments = [],
        list_comments = [],
        close = () => { }, 
        selectedRowKeys = [], 
        setSelectedRowKeys = () => {},
        getComments = () => {},
        drawIcon = () => {}
    } = props;

    const [data, setData] = useState({"store" : ""})
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editOptions, setEditOptions] = useState({});
    const [initLoaded, setInitLoaded] = useState(false);
    /*values for viewWhichComments:
        all: View all comments
        not: View all comments that aren't in a list
        in:  View all comments that exist only in lists
    */
    const [currentSelectedRowKeys, setCurrentSelectedRowKeys] = useState(selectedRowKeys);
    const [viewWhichComments, setViewWhichComments] = useState("all");
    const [tableData, setTableData] = useState(all_comments);

    const getEditOptions = (_new = false, _store = "") => {
        setLoading(true);
        apiCall("comment/getEditOptions", {}, (_status, _result) => {
            if (_status) {
                setEditOptions(_result);
                setLoading(false);
            }
        })
        setInitLoaded(true);
    }

    useEffect(getEditOptions, [])

    const columns = [
        {
            title: <small></small>, className: "cellBorder", dataIndex: 'comment_action', width: "40px", key: 'comment_action', render: (e, f) => {
                return drawIcon(f);
            }
        },
        {
            title: "Author", dataIndex: 'author', key: 'author'
        },
        {
            title: "Catalogue Name", dataIndex: 'list_name', key: 'list_name', render: (e, f) => {
                return (
                    <>
                        <div>{f.list_name  ? f.list_name : "Not in a list"}</div>
                    </>
                )
            }
        },
        {
            title: "Title", dataIndex: 'title', key: 'title', render: (e, f) => {
                return (
                    <>
                        <div>{f.title_info.title}</div>
                    </>
                )
            }
        },
        {
            title: "ISBN", dataIndex: 'isbn', key: 'isbn'
        },
        {
            title: "Expiry", dataIndex: 'expiry', key: 'expiry', render: (e, f) => {
                if (e) {
                    return <div style={{ "maxWidth": "100px" }} className="ellipsis">{moment(e * 1000).format("MMM D, YYYY")}</div>
                } else {
                    return (f.list_name) ? "List" : "Never";
                }
            }
        },
        {
            title: "Last Updated", dataIndex: 'modified', key: 'modified', render: (e, f) => {
                return (
                    <>
                        <div><nobr>{moment(e * 1000).format("MMMDD-YY")}</nobr></div>
                    </>
                )
            }
        }
    ];

    const swapSelectedKeys = (comments_to_work_with) => {
        let temp_keys = comments_to_work_with.map((comment) => {return comment.id});
        setCurrentSelectedRowKeys(temp_keys);
    }

    const onChangeActions = (e) => {
        setViewWhichComments(e.target.value);
        switch(e.target.value){
            case "not":
                setTableData(non_list_comments);
                swapSelectedKeys(non_list_comments);
                break;
            case "in":
                setTableData(list_comments);
                swapSelectedKeys(list_comments);
                break;
            default:
                setTableData(all_comments);
                setCurrentSelectedRowKeys(selectedRowKeys);
        }
    }

    const listParsing = (e) => {
        setTableData(list_comments.filter((row) => row.list_name === e));
        swapSelectedKeys(list_comments.filter((row) => row.list_name === e));
    }

    const listFilterOptions = () => {
        let unique_list_names = [];
        return(
            <Select 
                getPopupContainer={trigger => trigger.parentElement} 
                className='ant-select-with-custom-arrow' 
                style={{"width": "362px"}} 
                onChange={(e) => listParsing(e)}>
                {list_comments.map((item, index) => {
                    if(!unique_list_names.some((name) => name === item.list_name)){
                        unique_list_names.push(item.list_name)
                        return (<Select.Option key={item.id} value={item.list_name}>{item.list_name}</Select.Option>)
                    }
                })}
            </Select>
        ) 
    }

    const resetList = () => {
        setTableData(list_comments);
    }

    function submitCommentChanges(edit){
        setSaving(true);
        let args = {
            comment_ids: JSON.stringify(currentSelectedRowKeys),
        }
        
        if(edit){
            args.shared_with = editOptions.current_shared_with;
            args.expiry_days = editOptions.expiry_date;
            args.expiry_type = editOptions.expiry_type;
            args.as_author = editOptions.current_author;
            args.visible_anywhere = editOptions.visible_anywhere;
        }

        if(edit && data.store){
            args.store = data.store; 
        }

        apiCall(edit ? "comment/editArray" : "comment/deleteArray", args,(_status, _result) => {
            if(_status){
                message.success("Comments successfully updated!");
                setSelectedRowKeys([]);
                getComments();
            }
        });
        setSaving(false);
        close();
    }

    return (
        <>
        {(initLoaded && 
        <div style={{ "padding": "20px" }}>
            <Typography.Title level={5}>Mass Edit Comments</Typography.Title>
            <div>
                <Radio.Group 
                    value={viewWhichComments} 
                    defaultValue={viewWhichComments} 
                    onChange={(e) => onChangeActions(e)}>
                    <Tooltip title="All comments selected, list agnostic">
                        <Radio.Button value={"all"}>All Comments</Radio.Button>
                    </Tooltip>
                    <Tooltip title="Comments that are not in catalogues">
                        <Radio.Button value={"not"}>Comments Not In Catalogues</Radio.Button>
                    </Tooltip>
                    <Tooltip title="Just comments that are viewable in catalogues">
                        <Radio.Button value={"in"}>Comments In Catalogues</Radio.Button>
                    </Tooltip>
                </Radio.Group>
            </div>
            <conditional.true value={viewWhichComments === "in"}>
                <div style={{"paddingTop": "10px"}}>
                    <Space>
                        {listFilterOptions()}
                        <Button type="danger" onClick={() => resetList()}>Reset List Selection</Button>
                    </Space>
                </div>
            </conditional.true>
            <div style={{"marginTop": "20px", "marginBottom": "10px"}}>
                <Typography.Title level={5}>Table of chosen comments</Typography.Title>
                <Table
                    size="small"
                    dataSource={tableData}
                    columns={columns}>
                </Table>
            </div>
            <conditional.true value={viewWhichComments === "all" || viewWhichComments === "not"}>
                <CommentOptions
                    multiselect_view={true}
                    editOptions={editOptions} 
                    setEditOptions={setEditOptions}
                    max_width="700px"
                    data={data}
                    setData={setData}
                    />
            </conditional.true>
            <conditional.true value={viewWhichComments === "in"}>
                <CommentOptions
                    in_catalogue={true}
                    multiselect_view={true}
                    editOptions={editOptions} 
                    setEditOptions={setEditOptions}
                    max_width="700px"
                    data={data}
                    setData={setData}
                    />
            </conditional.true>
            <div>
                <Button onClick={() => close()} type="danger">Cancel</Button>
                <Space style={{ "float": "right" }}> 
                    <Button loading={saving} onClick={() => submitCommentChanges(false)} type="danger">Delete Comments</Button>
                    <Button loading={saving} onClick={() => submitCommentChanges(true)} type="primary">Submit Changes</Button>
                </Space>
            </div>
        </div>
            )}
        </>
    )
}