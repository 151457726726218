import { Badge, Col, Form, Input, message, Radio, Row, Select, Space, Switch } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

import conditional from "../tools/conditional";
import { apiCall } from "../utils/Api";
import { useSession } from "../utils/Session";

export default function CommentOptions(props) {

    const [session, setSession] = useSession(); 
    
    const {
        isbn = false,
        comment_id = false,
        isnew = false,
        in_catalogue = false,
        editOptions = () => { },
        setEditOptions = () => { },
        max_width = "300px",
        multiselect_view = false,
        specific_title_in_list_id = 0,
        setData = () => {},
        data = {}
    } = props;

    const is_with_list = (editOptions.titlelist_id || in_catalogue || specific_title_in_list_id > 0)

    const colors = {
        "personal": "#d1b3e5",
        "webstore": "#f47ddc",
        "contact": "#ffef00",
        "clients": "#fa9050",
        "booksellers": "#90EE90",
        "staff": "#ffef00",
        "visitors": "#70e2ff",
        "everyone": "#def1f6",
        "unknown": "#bad4e1",
    }

    const [loading, setLoading] = useState(true);
    const [sharedStores, setSharedStores] = useState([]);
  

    const getEditOptions = (_new = false, _store = "") => {
        if (multiselect_view) {
            apiCall("comment/getEditOptions", {}, (_status, _result) => {
                if (_status) {
                    setEditOptions(_result);
                    setLoading(false);
                }
            })
            return;
        }
        let args = {}

        if(_store){
            args.store = _store;
        }
         
        if (isbn) {
            args.isbn = isbn;
        }
        if (!isnew) {
            if (comment_id) { args.comment_id = comment_id; }
        } else {
            if (editOptions.titlelist_id || specific_title_in_list_id > 0) {
                args.titlelist_id = editOptions.titlelist_id ? editOptions.titlelist_id : specific_title_in_list_id;
            }
        }
        if (specific_title_in_list_id > 0) {
            args.titlelist_id = specific_title_in_list_id;
        }
        apiCall("comment/getEditOptions", args, (_status, _result) => {
            if (_status) {
                setEditOptions(_result);
            } else {
                message.error(_result.error)
            }
            setLoading(false);
        })
    }



    const fetchStores = (_new = false) => {

        let api_object = {
            my_accounts: true,
            approved: false,
            pending: false,
            declined: false,
            potential: false,
        }

        apiCall("rep_control/getAll", api_object, (_status, _result) => {
            if (_status) {
                setSharedStores(_result);
                let _store = "";

                if(_result.length>0){
                    if(_result.find(item => item.contact_id === data.store)){
                        _store = data.store;
                    } 
                } 

               if(data.store){
                _store = data.store; 
               }
                

                setData({...data, "store" : _store});
                getEditOptions(_new, _store); 
            } else {
                message.error(_result.error)
            }
          
        })

    }

    useEffect(fetchStores, [isnew])



    const drawStore = () => {

        return (
            <Form.Item label="Store">
                <Select
                    getPopupContainer={trigger => trigger.parentElement}
                    showSearch
                    allowClear
                    placeholder="Select store..."
                    filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    className='ant-select-with-custom-arrow'
                    value={(data.store) ? data.store : null}
                    onChange={(e) => {
                        
                        if(e){
                            setLoading(true);
                            getEditOptions(isnew, e);
                        }
                       
                        setData({...data, "store" : e})
                    }}
                    style={{ width: 250 }}>
                    {sharedStores.map(item => {
                        return (
                            <Select.Option
                                key={item.contact_id}
                                value={item.contact_id}
                                label={item.name}> {item.name}
                            </Select.Option>
                        )
                    })}
                </Select>
            </Form.Item>
        )

    }

    const drawAuthor = () => {
        return (
            <Form.Item label="Author">
                <Select getPopupContainer={trigger => trigger.parentElement} className='ant-select-with-custom-arrow' onChange={(e) => setEditOptions({ ...editOptions, "current_author": e })} value={(editOptions.shared_with[editOptions.current_shared_with].matrix[editOptions.current_author]) ? editOptions.current_author : setEditOptions({ ...editOptions, "current_author": "personal" })}>
                    {
                        Object.keys(editOptions.shared_with[editOptions.current_shared_with].names).map((item, index) => {
                            // hide any if it's empty. 
                            if (!editOptions.shared_with[editOptions.current_shared_with].matrix[item]) { return; }
                            return (<Select.Option disabled={(!editOptions.shared_with[editOptions.current_shared_with].matrix[item])} value={item}>{editOptions.shared_with[editOptions.current_shared_with].names[item]}</Select.Option>)
                        })
                    }
                </Select>
            </Form.Item>
        )
    }

    const drawSharedWith = () => {
        return (
            <>
            
            <Form.Item label="Visibility">
                <Select className='ant-select-with-custom-arrow' value={editOptions.current_shared_with.toString()} onChange={(e) => setEditOptions({ ...editOptions, "current_shared_with": e })}>
                    {
                        Object.keys(editOptions.shared_with).map((item, index) => {
                            return (<Select.Option key={item.contact_id} value={item.toString()}><Badge dot color={colors[editOptions.shared_with[item].colour_code]} />{editOptions.shared_with[item].text}</Select.Option>)
                        })
                    }
                </Select>
            </Form.Item>
            </>
        );
    }

    const drawExpires = () => {
        return (
            <Form.Item label="Expires">
                <Radio.Group value={(!editOptions.visible_anywhere && is_with_list) ? 0 : editOptions.expiry_type} onChange={(e) => setEditOptions({ ...editOptions, "expiry_type": e.target.value })}>
                    <Space direction="vertical">
                        <conditional.true value={is_with_list}>
                            <Radio value={0} >With list</Radio>
                        </conditional.true>
                        <Radio disabled={(!editOptions.visible_anywhere && is_with_list)} value={1}>In &nbsp;
                            <Form.Item noStyle>
                                <Input
                                    type="number"
                                    onChange={(e) => setEditOptions({ ...editOptions, "expiry_date": e.target.value })}
                                    value={(editOptions.expiry_date) ? editOptions.expiry_date : 1095}
                                    disabled={(!editOptions.visible_anywhere && (editOptions.titlelist_id))}
                                    style={{ "width": "70px" }}
                                    size="small"
                                />
                            </Form.Item>
                            &nbsp; day(s)
                        </Radio>
                    </Space>
                </Radio.Group>
                <conditional.true value={(editOptions.expiry_date !== null && editOptions.expiry_date !== false && editOptions.expiry_type === 1)}>
                    <div className="c">
                        Expires on: {moment().add(editOptions.expiry_date, 'd').format("MMM Do, YYYY")}
                    </div>
                </conditional.true>
            </Form.Item>
        )
    }

    const drawListLimit = () => {
        return (
            <conditional.true value={(in_catalogue || specific_title_in_list_id > 0)}>
                <Form.Item initialValue={(!editOptions.visible_anywhere)} valuePropName="checked" name="list_visible" label="Only visible from this list">
                    <Switch onChange={(e) => setEditOptions({ ...editOptions, "visible_anywhere": (!e) })} size="small" />
                </Form.Item>
            </conditional.true>
        )
    }

    if (loading) {
        return (<></>)
    }

    return (<>

    
        <conditional.true value={max_width === "300px"}>
            <div style={{ "maxWidth": max_width }}>
                {drawAuthor()}
                {drawListLimit()}
                {drawStore()}
                {drawSharedWith()}
                {drawExpires()}
            </div>
        </conditional.true>
        <conditional.true value={max_width !== "300px"}>
            <Row >
                <Col span={12} style={{ "padding": "5px" }}>
                    {drawAuthor()}
                    {drawStore()}
                    {drawSharedWith()}
                </Col>
                <Col span={12} style={{ "padding": "5px" }}>
                    {drawListLimit()}
                    {drawExpires()}
                </Col>
            </Row>
        </conditional.true>
    </>)
}