import { Button, Input, message, Space, Typography } from "antd";
import React, { useState } from "react";

import { objectToPath } from "../utils/Utils";

export default function ListShare(props) {

    const { close = () => { }, searchFilters = {} } = props;
    const [copied, setCopied] = useState(false);

    const url = "https://share.bookmanager.com/b2b/browse" + objectToPath(searchFilters, true);

    const copyToClipboard = (e) =>{
        if(!navigator.clipboard){
            message.error("Can't copy");
            return; 
        }
        navigator.clipboard.writeText(url);
        setCopied(true);
        setTimeout(() => {
            setCopied(false)
        },1000);
    }

    return (
        <>
            <div style={{ "padding": "20px" }}>
                <Typography.Title level={5}>Share Browse</Typography.Title>
                <Typography.Paragraph>To share with others, copy this link (e.g. Ctrl-C or click the Copy button) and then paste it wherever.</Typography.Paragraph>
                <Input defaultValue={url} />
                <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                <div style={{ "float": "right" }}>
                    <Space>
                        <Button onClick={() => close()}>Cancel</Button>
                        <Button onClick={(e) => copyToClipboard(e)} type={(copied ? "default" : "primary")}>{(copied) ? "Copied" : "Copy"}</Button>
                    </Space>
                </div>
                <br clear="all" />
            </div>
        </>
    )
    
}