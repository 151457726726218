import { QuestionCircleTwoTone, UploadOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Divider, Form, Row, Select, Space, Spin, Switch, Table, Tooltip, Typography, Upload } from "antd";
import React, { useState } from "react";

import conditional from "../../tools/conditional";
import { apiCall } from "../../utils/Api";
import CommentOptions from "../CommentOptions";

export default function ListCommentManagement(props) {
    const { 
        close = () => { }, 
        actual_id = false,
        list_name = "",
        date_expired = null,
    } = props;

    
    const [editOptions, setEditOptions] = useState({});
    const [uploading, setUploading] = useState(false);
    const [selectOptions, setSelectOptions] = useState([]);
    const [isbnCol, setIsbnCol] = useState("");
    const [commentCol, setCommentCol] = useState("");
    const [storeData, setStoreData] = useState({store : ""})
    const [data, setData] = useState({ headers: [], rows: [] });
    const [dataLoaded, setDataLoaded] = useState(false);
    const [complete, setComplete] = useState(false);
    const [completeRows, setCompleteRows] = useState([]);
    const [removePrevious, setRemovePrevious] = useState(false);

    //Uploading Files and Comments Functions
    const uploadFile = (_file) => {
        setUploading(true);
        apiCall("comment/importFile", { file: _file.file }, (_status, _result) => {
            if (_status) {
                setData(_result);
                if(_result.headers.some((header) => header.toLowerCase() === "isbn")){
                    const isbn_header = _result.headers.filter(header => header.toLowerCase() === "isbn");
                    setIsbnCol(isbn_header)
                }
                if(_result.headers.some((header) => (header.toLowerCase() === "comments" || header.toLowerCase() === "notes" || header.toLowerCase() === "comment"))){
                    const comment_header = _result.headers.filter(header => (header.toLowerCase() === "comments" || header.toLowerCase() === "notes" || header.toLowerCase() === "comment"));
                    setCommentCol(comment_header)
                }
                setStartingSelectOptions(_result) 
                setUploading(false);
                setDataLoaded(true);
            }
        });
    }

    function setStartingSelectOptions(){
        let temp_select_options = []
        temp_select_options = data.headers.filter(item => item !== isbnCol).map((item, index) => {
            return (<Select.Option key={index} value={item}>{item}</Select.Option>)
        })
        temp_select_options += <Select.Option key="blank" value="Blank"></Select.Option>
        setSelectOptions(temp_select_options)
    }

    function drawHeaderOfCommentColumn(){
        return (
            <small> {removePrevious ? "Replacement" : ""} Comment <small style={{ "fontWeight": "normal" }}>({commentCol})</small></small>
        )
    }

    const importComments = (_f) => {
        let fields = {};
        fields.remove_previous = _f.remove_previous;
        // grab the isbn/comment from the selected fields
        fields.values = data.rows.map((item, index) => {
            return { isbn: item[isbnCol], comment: item[commentCol] }
        });
        fields.visible_anywhere = false;
        // encode
        fields.values = JSON.stringify(fields.values);
        if (editOptions.hasOwnProperty("current_shared_with")) { fields.shared_with = editOptions.current_shared_with }
        if (editOptions.hasOwnProperty("visible_anywhere")) { fields.visible_anywhere = editOptions.visible_anywhere }
        if (editOptions.hasOwnProperty("expiry_type")) { fields.expiry_type = editOptions.expiry_type }
        if (editOptions.hasOwnProperty("expiry_date") && (editOptions.expiry_date)) { fields.expiry_days = editOptions.expiry_date }
        if (editOptions.hasOwnProperty("current_author")) { fields.as_author = editOptions.current_author }
        fields.titlelist_def_id = actual_id
        if(_f.list_visible && editOptions.visible_anywhere){
            fields.expiry_days = date_expired;
        }
        apiCall("comment/importComments", fields, (_status, _results) => {
            if (_status) {
                setComplete(true)
                setCompleteRows(_results);
            }
        })
    }
    
    const getHeaders = () => {
        let ret = [];
        if (isbnCol) {
            ret.push({ title: <small>ISBN <small style={{ "fontWeight": "normal" }}>({isbnCol})</small></small>, dataIndex: isbnCol, key: isbnCol })
        }
        if (commentCol) {
            ret.push(
                { 
                    title: drawHeaderOfCommentColumn(), dataIndex: commentCol, key: commentCol, render: (e, f) =>{
                        let return_value = (e) ? <span style={{"color" : "#228b22"}}>{e}</span> : <span style={{"color" : "#ff4d4f"}}>No comment</span>
                        return removePrevious ? return_value : <span style={{"color" : "#228b22"}}>{e}</span>
                    } 
                }
            )
        }
        return ret;
    }

    const drawResults = () => {
        return (<>
            <div className="shim"></div><div className="shim"></div><div className="shim"></div>
            <span>Import preview <small>({data.rows.length} rows)</small></span>
            <div className="shim"></div><div className="shim"></div>
            <Table
                style={{"maxWidth" : "450px"}}
                scroll={{ y: 300 }} size="small" pagination={false}
                columns={getHeaders(data.headers)}
                dataSource={data.rows}
                className={"tableBorder"}
            />
        </>
        )
    }

    function changeColumnText(_event){
        setRemovePrevious(_event)
    }

    const drawSelection = () => {
        return (
            <>
                <Space size="large">
                    <Form.Item label="ISBN">
                        <Select 
                            getPopupContainer={trigger => trigger.parentElement} 
                            className='ant-select-with-custom-arrow' 
                            placeholder="Select ISBN Column" 
                            defaultValue={(isbnCol) ? isbnCol : undefined} 
                            onChange={(e) => setIsbnCol(e)} 
                            style={{ "width": "200px" }}>
                            {data.headers.filter(item => item !== commentCol).map((item, index) => {
                                return (<Select.Option key={index} value={item}>{item}</Select.Option>)
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Comment">
                        <Select getPopupContainer={trigger => trigger.parentElement} className='ant-select-with-custom-arrow' placeholder="Select Comment Column" value={(commentCol) ? commentCol : undefined} onChange={(e) => setCommentCol(e)} style={{ "width": "200px" }}>
                            {data.headers.filter(item => item !== isbnCol).map((item, index) => {
                                return (<Select.Option key={index} value={item}>{item}</Select.Option>)
                            })}
                            <Select.Option key="blank" value="Blank">Blank</Select.Option>
                        </Select>
                    </Form.Item>
                </Space>
                <conditional.true value={commentCol === "Blank"}>
                    <div style={{"color" : "#ff4d4f"}}>
                        Only use the Blank column to remove all comments from every ISBN in this list
                    </div>
                </conditional.true>
                <br />
                <span>
                    Remove previous comments (if any)
                    <Tooltip
                        overlayStyle={{maxWidth: '500px'}}
                        title="Previous comments will ONLY be removed where all of the previous options for Author, Visibility (both the list and comments) and Expiry all match the ones you are trying to import. The same rules apply to any column you use as the comment column, including the Blank column option. Comments applicable outside of a particular list can also be created/changed/removed individually or in groups from the CATALOGUES -> MANAGE COMMENTS menu.">
                        <QuestionCircleTwoTone style={{"paddingLeft": "5px"}}/>
                    </Tooltip>
                </span>
                <br />
                <Form.Item initialValue={removePrevious} name="remove_previous" noStyle>
                    <Switch size="small" onChange={(e) => {changeColumnText(e)}}/>
                </Form.Item>
            </>
        )
    }

    const drawComplete = () => {
        let cols = [
            { title: <small>ISBN</small>, dataIndex: "isbn", key: "isbn" },
            { title: <small>Comment</small>, dataIndex: "comment", key: "comment" },
            { title: <small>Status</small>, dataIndex: "status", key: "status", render : (e) =>{
                return (e) ? <span style={{"color" : "#228b22"}}>Imported</span> : <span style={{"color" : "#ff4d4f"}}></span>
            } }
        ]
        let msg = completeRows.filter(item => item.status).length.toString() + " comment(s) in " + completeRows.length.toString() + " row(s) added."
        return (
            <>
                <div>
                <Alert message={msg} type="success" />
                <br />
                <Table
                    scroll={{ y: 300 }} size="small" pagination={false}
                    columns={cols}
                    dataSource={completeRows}
                    className={"tableBorder"}
                />
                <Divider />
                <div style={{ "float": "right" }}>
                    <Space>
                        <Button type="primary" onClick={() => close(false)}>Close</Button>
                    </Space>
                </div>
                <br clear="all" />
                </div>
            </>
        )
    }

    const drawUpload = () => {
        return (
            <Upload.Dragger 
                accept=".txt, .xls, .xlsx, .tsv, .doc, .docx" 
                showUploadList={false} 
                customRequest={(e) => uploadFile(e)} 
                style={{ "padding": "0px 20px" }}
            >
                <div >
                    {(uploading) ? <Spin style={{ height: "35px" }} /> : <UploadOutlined style={{ "fontSize": "30px", height: "35px" }} />}
                </div>
                <p className="ant-upload-text">Click or drag to upload</p>
                <p className="ant-upload-hint">
                    Upload an Excel file (.xls or .xlsx) or tab delimited file.
                </p>
            </Upload.Dragger>
        )
    }

    return (
        <>
            <div style={{ "padding": "20px" }}>
                <Typography.Title level={5}>Import Comments for: <Typography.Title level={4}>{list_name}</Typography.Title></Typography.Title>
                <conditional.true value={(complete)}>
                    {drawComplete()}
                </conditional.true>
                <conditional.true value={(!complete)}>
                    <Form onFinish={(f) => importComments(f)} layout="vertical" >
                        <conditional.true value={(!dataLoaded)}>
                            {drawUpload()}
                        </conditional.true>
                        <conditional.true value={(dataLoaded)}>
                            <Row gutter={25}>
                                <Col flex="400px" >
                                    {drawSelection()}
                                    <br />
                                    {drawResults()}
                                    <br />
                                </Col>
                                <Col flex="auto" >
                                    <div style={{ "width": "250px" }}>
                                        <CommentOptions 
                                            data={storeData}
                                            setData={setStoreData}
                                            in_catalogue={true}
                                            isbn={false} 
                                            comment_id={false} 
                                            editOptions={editOptions} 
                                            setEditOptions={setEditOptions} />
                                    </div>
                                </Col>
                            </Row>
                        </conditional.true>
                        <Divider />
                        <div style={{ "float": "right" }}>
                            <Space>
                                <Button onClick={() => close(false)}>Cancel</Button>
                                <Button disabled={(!isbnCol || !commentCol)} htmlType="submit" type="primary">Import</Button>
                            </Space>
                        </div>
                        <br clear="all" />
                    </Form>
                </conditional.true>
            </div>
        </>
    )
}