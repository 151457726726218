import { Table } from "antd";

export default function ListReportCardDetailedView(props) {

    const {
        reportCardData = {},
        vendor = {},
    } = props

    const {acct = ""} = vendor; 

    const columns = [
        {
            title: <small>ISBN</small>, dataIndex: 'isbn', key: 'isbn', render: (e, f) => {
                return <strong>{f.isbn}</strong> 
            }
        },
        {
            title: <small>Titles</small>, dataIndex: 'titles', key: 'titles', render: (e, f) => {
                return f.titles ? "Missing" : ""
            }
        },
        {
            title: <small>Covers</small>, dataIndex: 'covers', key: 'covers', render: (e, f) => {
                return f.covers ? "Missing" : ""
            }
        },
        {
            title: <small>Interiors</small>, dataIndex: 'interiors', key: 'interiors', render: (e, f) => {
                return f.interiors ? "Missing" : ""
            }
        },
        {
            title: <small>Author Bios</small>, dataIndex: 'author_bios', key: 'author_bios', render: (e, f) => {
                return f.author_bios ? "Missing" : ""
            }
        },
        {
            title: <small>Descriptions</small>, dataIndex: 'description', key: 'description', render: (e, f) => {
                return f.description ? "Missing" : ""
            }
        },
        {
            title: <small>Reviews</small>, dataIndex: 'reviews', key: 'reviews', render: (e, f) => {
                return f.reviews ? "Missing" : ""
            }
        },
        {
            title: <small>Comparables</small>, dataIndex: 'related', key: 'related', render: (e, f) => {
                return f.related ? "Missing" : ""
            }
        },
        {
            title: <small>Marketing Info</small>, dataIndex: 'marketing', key: 'marketing', render: (e, f) => {
                return f.marketing ? "Missing" : ""
            }
        },
        {
            title: <small>{(acct) ? acct + " " : ""}Pubstock</small>, dataIndex: 'pubstock', key: 'pubstock',  render: (e, f) => {
                return f.pubstock ? "Missing" : ""
            }
        },
    ];

    return (
        <div>
          
            <Table
                size="small"
                dataSource={reportCardData}
                columns={columns}
                pagination={{
                    defaultPageSize: 20,
                    showSizeChanger: true,
                    position: ['topRight', 'bottomRight'],
                }}>
            </Table>
        </div>
    )
}