import { Button, Divider, message, Modal, Table, Typography } from "antd";
import React, { useState } from "react";

import { apiCall } from "../../utils/Api";
import { bmDate } from "../../utils/Utils";

export default function CatalogueCount(props) {
    const { isbn = "", count = 0 } = props;
    const [results, setResults] = useState([])
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [fetched, setFetched] = useState(false);


    const linkOut = (_id, _obj) => {
        
        if(_obj.can_delete){
            window.open("/catalogues/my-catalogues/filter/t/" + _id , "_blank");
        } else {
            if(_obj.can_edit){
                window.open("/catalogues/my-company-catalogues/filter/t/" + _id , "_blank");
            } else {
                window.open("/catalogues/public-catalogues/filter/t/" + _id , "_blank");
            }
           
        }
       
    }

    const fetchCatalogues = () => {
        setVisible(true);
        if(fetched){return; }
        setLoading(true);
        apiCall("title/getPublicCatalogues", { isbn: isbn }, (_status, _result) => {
            if (_status) {
                setResults(_result);
                setFetched(true);
            } else {
                message.error(_result.error);
            }
            setLoading(false);
        })
    }

    const drawCatalogues = () => {
        return (
            <Modal destroyOnClose onCancel={() => setVisible(false)} footer={null} visible={visible}>
                <div style={{ "padding": "0px" }} >
                    <Typography.Title style={{ "lineHeight": "17px" }} level={5}>
                        <div>Catalogues ({count.toString()})</div>
                        <Divider dashed style={{ "marginTop": "10px", "marginBottom": "5px" }} />
                    </Typography.Title>
                    <p>Catalogues and lists that contain this item:</p>
                    <Table
                        className="bdr"
                        loading={loading}
                        size="small"
                        pagination={false}
                        columns={columns}
                        scroll={{ y: 250 }}
                        dataSource={results}
                    />
                    <br />
                    <div style={{ "float": "right" }}>
                        <Button onClick={() => setVisible(false)}>Close</Button>
                    </div>
                    <br clear="all" />
                </div>
            </Modal>
        )
    }

    const columns = [
        { title: <small>Supplier</small>, width: 60, dataIndex: 'vendor', key: 'vendor' },
        {
            title: <small>Name</small>, dataIndex: 'name', key: 'name', render: (e, f) => {
                return (e) ? <a onClick={() => linkOut(f.id, f)} >{e}</a> : "";
            }
        },
        {
            title: <small>Published</small>, width: 85, dataIndex: 'posted', key: 'posted', render: (e) => {
                return (e) ? <nobr>{bmDate(e * 1000)}</nobr> : ""
            }
        },
        { title: <small>Category</small>, width: 85, dataIndex: 'group', key: 'group' },
    ]

    if (!count) {
        return <></>;
    }

    return (
        <>
            {drawCatalogues()}
            <Button onClick={() => fetchCatalogues()} size="small" className="mini-btn" ><small>Public Catalogues ({count}) </small></Button>
        </>
    )
}