import { Card, Table } from "antd";
import React, { useEffect, useRef } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
const ItemType = "BOX";
export default function PublisherRow(props) {

    const {
        id = "",
        onDrop = () => { },
        dragging = false,
        setDragging = () => { }
    } = props;

    const [{ isDragging }, dragRef] = useDrag(() => ({
        type: ItemType,
        item: { id },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    const [{ isOver }, dropRef] = useDrop(() => ({
        accept: ItemType,
        drop: (item) => onDrop(item, id),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    }));


    useEffect(() => {
        // setDragging(isDragging);
    }, [isDragging, setDragging]);

    return (
        <div
            ref={(node) => dragRef(dropRef(node))}
            style={{
                padding: "0px 0px",
                backgroundColor: isOver ? "#e6f7ff" : isDragging ? "#fafafa" : "white",
                cursor: "grab",

            }}
        >
            {props.children}
        </div>
    );
}
