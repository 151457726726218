import { Button, Input, message, Space, Typography } from 'antd';
import { useState } from 'react';

export default function ListMSEmail (props){
    
    const {
        selectedRowKeys = [],
    } = props

    const [copied, setCopied] = useState(false);
    const [changedTextAreaText, setChangedTextAreaText] = useState("")
    
    const initialTextAreaValue = () => {
        let initial_text = selectedRowKeys.map((item,index) => {
            if(index < 1){
                return "https://share.bookmanager.com/b2b/browse/filter/x/" + item + "/v/sequence";
            }else {
                return "\nhttps://share.bookmanager.com/b2b/browse/filter/x/" + item + "/v/sequence";
            }
            
        })
        setChangedTextAreaText(initial_text.toString())
        return initial_text.join("")
    }

    const copyToClipboard = (e) =>{
        if(!navigator.clipboard){
            message.error("Can't copy");
            return; 
        }
        navigator.clipboard.writeText(changedTextAreaText);
        setCopied(true);
        setTimeout(() => {
            setCopied(false)
        },1000);
    }

    return (
        <div style={{"marginBottom": "10px"}}>
            <Typography.Paragraph>
                Using the button below, you can copy the links in the box and paste them into an email to share.
            </Typography.Paragraph>
            <Input.TextArea
                style={{"maxHeight" : "180px"}}
                rows={selectedRowKeys.length + 2}
                defaultValue={initialTextAreaValue}>
            </Input.TextArea>
            <div className="shim" /> <div className="shim" />
            <div style={{ "float": "right", "marginTop": "10px" }}>
                <Space>
                    <Button onClick={(e) => copyToClipboard(e)} type={(copied ? "default" : "primary")}>{(copied) ? "Copied" : "Copy Your Links"}</Button>
                </Space>
            </div> 
        </div>
    );
}