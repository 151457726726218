import { Button, Divider, Popover, Select, Space } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie';
import { apiCall } from "../utils/Api";
import { useSession } from "../utils/Session";
import AccountCard from "./AccountCard";
import LogOutLocations from "./LogOutLocations";
import SelectStores from "./SelectStores";
import { usePrefs } from "../utils/Prefs";
import Newsletters from "./Newsletters";

export default function AccountBox() {

    const history = useHistory();
    const [session, setSession] = useSession();
    const [trigger, setTrigger] = useState(false);
    const [storeChanged, setStoreChanged] = useState(false);
    const [prefs, setPrefs] = usePrefs();

    const drawCorps = () => {
        return session.available_corps.filter(item => item.name !== "My Personal Account").map(item => {
            return (<Select.Option key={item.id} value={item.id} label={item.name}>{item.name}</Select.Option>)
        })
    }

    const switchStore = (_id) => {
        history.push("/reload", { id: _id, path: history.location.pathname });
    }

    const logout = () => {
        apiCall("account/logout", {}, (_status, _result) => {
            window.store_id = false;
            setSession(_result);
        })
    }

    const content = (
        <Space direction="vertical">
            <label>Account</label>
            <Select
                className='ant-select-with-custom-arrow'
                onChange={(e) => switchStore(e)}
                getPopupContainer={trigger => trigger.parentElement}
                showSearch
                filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                }
                defaultValue={session.corp_id}
                style={{ width: 250 }}>
                {drawCorps()}
            </Select>
            <Divider dashed style={{ "margin": "5px 0px" }} />
            <Newsletters />

            {/* Must not have salesisbn subscription to see store data */}
            {((!session?.subscriptions.salesisbn && !session?.subscriptions.catalogue) &&
                <SelectStores onChange={(e) => {
                    if (e) {
                        setPrefs({ ...prefs, "selected": "ind-store-data" });
                        Cookies.set("selected", "ind-store-data");
                    }
                    setStoreChanged(true);
                }} />
            )}

            <LogOutLocations trigger={trigger} />
            <Button onClick={() => logout()} danger type="primary" block>Log out</Button>
        </Space>
    );

    const drawWorkingWith = () => {
        if (session.hasOwnProperty("stores_working_with") && Array.isArray(session.stores_working_with) && session.stores_working_with.length > 0) {
            let _name = session.stores_working_with[0].name;
            let _more = false;
            if (session.stores_working_with.length > 1) {
                _more = session.stores_working_with.length - 1
            }
            return (<div style={{ "textAlign": "right", "color": "#FDDA0D", "marginTop": "-8px", "fontWeight": "normal" }}><small><em>Viewing <strong>{_name}</strong> {(_more && <> + {_more} more</>)}&nbsp;</em></small></div>)
        } else {
            return (<></>);
        }
    }

    const refreshPage = () => {
        if (storeChanged) {

            window.location.reload();
        }
    }

    return (
        <>
            <Popover onVisibleChange={(e) => {
                if (e) {
                    setTrigger(!trigger);
                } else {
                    refreshPage();
                }
            }}
                placement="bottomRight"
                content={content}
                trigger="click">
                <div style={{ "cursor": "pointer" }}>
                    <Space size={0} direction="vertical">
                        <AccountCard store_name={session.picklist_identity} contact={session.contact} />
                        {drawWorkingWith()}
                    </Space>
                </div>
            </Popover>
        </>
    );
}