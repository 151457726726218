import { Card } from "antd";
import React from "react";
import { useDrop } from "react-dnd";


export default function PublisherCard({ id, onDrop, drop_type = "", dragging = false, setDragging = () => { }, children }) {

  const [{ isOver }, dropRef] = useDrop(() => ({
    accept: drop_type,
    drop: (item) => onDrop(item, id),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return (
    <div ref={(node) => dropRef(node)}>
      <Card bodyStyle={{"padding" : "5px 10px"}} style={{ backgroundColor: (isOver || dragging) ? "#e6f7ff" : "#fbfbfb" }}>
        {children}
      </Card>
    </div>
  );
}
