export const transformFinal = (inputArray) => {
    return inputArray.map(item => ({
        label: item.v,
        id: item.k,
        type: item.t,
        imprints: item.s.map(imprint => ({
            label: imprint.v,
            id: imprint.k,
            type: imprint.t,
            status: imprint.f,
            count: imprint.c,
            title_count: imprint.cnt_titles
        })),
        status: item.f,
        count: item.c,
        imprint_count: item.cnt_imprints,
        title_count: item.cnt_titles
    }));
}

export const transformLinked = (inputArray) => {
    return inputArray.map(item => ({
        label: item.v,
        id: item.k,
        type: item.t,
        imprints: item.s.map(imprint => ({
            label: imprint.v,
            id: imprint.k,
            type: imprint.t,
            status: imprint.f,
            count: imprint.c,
            title_count: imprint.cnt_titles
        })),
        status: item.f,
        count: item.c,
        imprint_count: item.cnt_imprints,
        title_count: item.cnt_titles
    }));
}





export const final_publishers = {
    "rows": [
        {
            "v": "Klutz",
            "k": "Klutz",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Chicken Socks",
                    "k": "131259",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "Klutz",
                    "k": "9119",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "283",
                    "cnt_titles": "283"
                }
            ],
            "f": "moved finished",
            "c": 291,
            "cnt_imprints": 2,
            "cnt_titles": 291
        },
        {
            "v": "Imagine &amp; Wonder",
            "k": "Imagine & Wonder",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Imagine and Wonder",
                    "k": "590528",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "86",
                    "cnt_titles": "86"
                },
                {
                    "v": "Leadership Lit",
                    "k": "639883",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Lightning Source Inc",
                    "k": "815726",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "10",
                    "cnt_titles": "10"
                }
            ],
            "f": "moved finished",
            "c": 97,
            "cnt_imprints": 3,
            "cnt_titles": 97
        },
        {
            "v": "3dtotal Publishing",
            "k": "3dtotal Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "3DTotal Kids",
                    "k": "815723",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "3DTotal Publishing",
                    "k": "815719",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "132",
                    "cnt_titles": "132"
                },
                {
                    "v": "Bryce Kho",
                    "k": "815721",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Canopy Press",
                    "k": "815724",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "moved",
            "c": 138,
            "cnt_imprints": 4,
            "cnt_titles": 138
        },
        {
            "v": "Ayin Press",
            "k": "Ayin Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Aora",
                    "k": "815718",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Ayin Press",
                    "k": "409211",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "18",
                    "cnt_titles": "18"
                }
            ],
            "f": "moved finished",
            "c": 19,
            "cnt_imprints": 2,
            "cnt_titles": 19
        },
        {
            "v": "Fashionary",
            "k": "Fashionary",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Fashionary",
                    "k": "791871",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "18",
                    "cnt_titles": "18"
                }
            ],
            "f": "finished",
            "c": 18,
            "cnt_imprints": 1,
            "cnt_titles": 18
        },
        {
            "v": "McNally Editions",
            "k": "McNally Editions",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Goods for the Study",
                    "k": "812533",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "14",
                    "cnt_titles": "14"
                },
                {
                    "v": "Marginalian Editions",
                    "k": "811122",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "McNally Editions",
                    "k": "575500",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "43",
                    "cnt_titles": "43"
                }
            ],
            "f": "finished",
            "c": 61,
            "cnt_imprints": 3,
            "cnt_titles": 61
        },
        {
            "v": "Tilted Axis Press",
            "k": "Tilted Axis Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Tilted Axis Press",
                    "k": "812500",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "11",
                    "cnt_titles": "11"
                }
            ],
            "f": "moved finished",
            "c": 11,
            "cnt_imprints": 1,
            "cnt_titles": 11
        },
        {
            "v": "LittlePuss Press",
            "k": "LittlePuss Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "LittlePuss Press",
                    "k": "683910",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "7",
                    "cnt_titles": "7"
                }
            ],
            "f": "finished",
            "c": 7,
            "cnt_imprints": 1,
            "cnt_titles": 7
        },
        {
            "v": "Hemeria",
            "k": "Hemeria",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Editions Hemeria",
                    "k": "815717",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Hemeria",
                    "k": "802877",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "9",
                    "cnt_titles": "9"
                }
            ],
            "f": "moved finished",
            "c": 14,
            "cnt_imprints": 2,
            "cnt_titles": 14
        },
        {
            "v": "Autumn House Press",
            "k": "Autumn House Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Autumn House Press",
                    "k": "118123",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "130",
                    "cnt_titles": "130"
                }
            ],
            "f": "moved finished",
            "c": 130,
            "cnt_imprints": 1,
            "cnt_titles": 130
        },
        {
            "v": "Alabaster",
            "k": "Alabaster",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "159352",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "6",
                    "cnt_titles": "6"
                }
            ],
            "f": "finished",
            "c": 6,
            "cnt_imprints": 1,
            "cnt_titles": 6
        },
        {
            "v": "Penguin Young Readers Group",
            "k": "Penguin Young Readers Group",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "11137",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "13",
                    "cnt_titles": "13"
                },
                {
                    "v": "Cartoon Network Books",
                    "k": "282941",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "13",
                    "cnt_titles": "13"
                },
                {
                    "v": "Crash Course Books",
                    "k": "807758",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Dial Books for Young Readers",
                    "k": "11787",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "1232",
                    "cnt_titles": "1232"
                },
                {
                    "v": "Dutton Books for Young Readers",
                    "k": "6139",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Firebird",
                    "k": "51342",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "103",
                    "cnt_titles": "103"
                },
                {
                    "v": "Flamingo Books",
                    "k": "530859",
                    "t": "pip_id",
                    "f": "",
                    "c": "72",
                    "cnt_titles": "72"
                },
                {
                    "v": "G.P. Putnam's Sons Books for Young Readers",
                    "k": "4903",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1074",
                    "cnt_titles": "1074"
                },
                {
                    "v": "Grosset &amp; Dunlap",
                    "k": "5506",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1481",
                    "cnt_titles": "1481"
                },
                {
                    "v": "Kathy Dawson Books",
                    "k": "202818",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "22",
                    "cnt_titles": "22"
                },
                {
                    "v": "Kokila",
                    "k": "409893",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "165",
                    "cnt_titles": "165"
                },
                {
                    "v": "Ladybird",
                    "k": "479869",
                    "t": "pip_id",
                    "f": "",
                    "c": "29",
                    "cnt_titles": "29"
                },
                {
                    "v": "Mad Libs",
                    "k": "327871",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "292",
                    "cnt_titles": "292"
                },
                {
                    "v": "Minedition",
                    "k": "129919",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "10",
                    "cnt_titles": "10"
                },
                {
                    "v": "Nancy Paulsen Books",
                    "k": "157894",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "456",
                    "cnt_titles": "456"
                },
                {
                    "v": "Penguin Books",
                    "k": "202812",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "105",
                    "cnt_titles": "105"
                },
                {
                    "v": "Penguin Workshop",
                    "k": "335549",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1361",
                    "cnt_titles": "1361"
                },
                {
                    "v": "Penguin Young Readers",
                    "k": "2031",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "558",
                    "cnt_titles": "558"
                },
                {
                    "v": "Penguin Young Readers Licenses",
                    "k": "336577",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "276",
                    "cnt_titles": "276"
                },
                {
                    "v": "Philomel Books",
                    "k": "4906",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "822",
                    "cnt_titles": "822"
                },
                {
                    "v": "Planet Dexter",
                    "k": "332739",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Poptropica",
                    "k": "247524",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Price Stern Sloan",
                    "k": "11136",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "233",
                    "cnt_titles": "233"
                },
                {
                    "v": "Puffin Books",
                    "k": "2024",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "2181",
                    "cnt_titles": "2181"
                },
                {
                    "v": "Razorbill",
                    "k": "129920",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "628",
                    "cnt_titles": "628"
                },
                {
                    "v": "Rise x Penguin Workshop",
                    "k": "468173",
                    "t": "pip_id",
                    "f": "",
                    "c": "104",
                    "cnt_titles": "104"
                },
                {
                    "v": "Rocky Pond Books",
                    "k": "609108",
                    "t": "pip_id",
                    "f": "",
                    "c": "225",
                    "cnt_titles": "225"
                },
                {
                    "v": "Speak",
                    "k": "51405",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "448",
                    "cnt_titles": "448"
                },
                {
                    "v": "Viking Books",
                    "k": "613207",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Viking Books for Young Readers",
                    "k": "7136",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1674",
                    "cnt_titles": "1674"
                },
                {
                    "v": "Warne",
                    "k": "2041",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "361",
                    "cnt_titles": "361"
                },
                {
                    "v": "Wee Sing",
                    "k": "332740",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "World of Eric Carle",
                    "k": "414557",
                    "t": "pip_id",
                    "f": "",
                    "c": "181",
                    "cnt_titles": "181"
                }
            ],
            "f": "moved",
            "c": 14145,
            "cnt_imprints": 33,
            "cnt_titles": 14145
        },
        {
            "v": "Medicine Wheel Publishing",
            "k": "Medicine Wheel Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Medicine Wheel Pubishing",
                    "k": "786435",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "126",
                    "cnt_titles": "126"
                }
            ],
            "f": "moved finished",
            "c": 126,
            "cnt_imprints": 1,
            "cnt_titles": 126
        },
        {
            "v": "Jack Allen's Kitchen Restaurants",
            "k": "Jack Allen's Kitchen Restaurants",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Jack Allen's Kitchen Restaurants",
                    "k": "279247",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "University of Delaware Press",
            "k": "University of Delaware Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "University of Delaware Press",
                    "k": "432544",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "174",
                    "cnt_titles": "174"
                }
            ],
            "f": "moved finished",
            "c": 174,
            "cnt_imprints": 1,
            "cnt_titles": 174
        },
        {
            "v": "University of Arizona Libraries",
            "k": "University of Arizona Libraries",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "University of Arizona Libraries",
                    "k": "127979",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "US Latino/a WWII Oral Hist Prj UT-Austin",
            "k": "US Latino/a WWII Oral Hist Prj UT-Austin",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "US Latino/a WWII Oral Hist Prj UT-Austin",
                    "k": "537606",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Tucson Museum of Art",
            "k": "Tucson Museum of Art",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Tucson Museum of Art",
                    "k": "19145",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "finished",
            "c": 2,
            "cnt_imprints": 1,
            "cnt_titles": 2
        },
        {
            "v": "Tobin Theatre Arts Fund",
            "k": "Tobin Theatre Arts Fund",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Tobin Theatre Arts Fund",
                    "k": "191304",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Tixcacalcupul Press",
            "k": "Tixcacalcupul Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Tixcacalcupul Press",
                    "k": "537612",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Tiengui del Norte Publishing",
            "k": "Tiengui del Norte Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Tiengui del Norte Publishing",
                    "k": "32749",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Ray Nasher Sculpture Garden",
            "k": "Ray Nasher Sculpture Garden",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Ray Nasher Sculpture Garden",
                    "k": "537605",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "The Museum of Fine Arts, Houston",
            "k": "The Museum of Fine Arts, Houston",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Buffalo Bayou Partnership",
                    "k": "814242",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "The Museum of Fine Arts, Houston",
                    "k": "17632",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "18",
                    "cnt_titles": "18"
                }
            ],
            "f": "moved",
            "c": 19,
            "cnt_imprints": 2,
            "cnt_titles": 19
        },
        {
            "v": "The Charles W. Moore Center for the Study of Place",
            "k": "The Charles W. Moore Center for the Study of Place",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "The Charles W. Moore Center for the Study of Place",
                    "k": "143225",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "7",
                    "cnt_titles": "7"
                }
            ],
            "f": "finished",
            "c": 7,
            "cnt_imprints": 1,
            "cnt_titles": 7
        },
        {
            "v": "Texas Parks and Wildlife Press",
            "k": "Texas Parks and Wildlife Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Texas Parks and Wildlife Press",
                    "k": "42537",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "4",
                    "cnt_titles": "4"
                }
            ],
            "f": "finished",
            "c": 4,
            "cnt_imprints": 1,
            "cnt_titles": 4
        },
        {
            "v": "Templeton Press",
            "k": "Templeton Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Templeton Press",
                    "k": "13285",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "227",
                    "cnt_titles": "227"
                }
            ],
            "f": "finished",
            "c": 227,
            "cnt_imprints": 1,
            "cnt_titles": 227
        },
        {
            "v": "SWCA, Inc.",
            "k": "SWCA, Inc.",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "SWCA, Inc.",
                    "k": "127944",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "22",
                    "cnt_titles": "22"
                }
            ],
            "f": "moved finished",
            "c": 22,
            "cnt_imprints": 1,
            "cnt_titles": 22
        },
        {
            "v": "Statistical Research Inc.",
            "k": "Statistical Research Inc.",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Statistical Research, Inc.",
                    "k": "433117",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "11",
                    "cnt_titles": "11"
                }
            ],
            "f": "moved finished",
            "c": 11,
            "cnt_imprints": 1,
            "cnt_titles": 11
        },
        {
            "v": "Southwestern Mission Research Center",
            "k": "Southwestern Mission Research Center",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Southwestern Mission Research Center",
                    "k": "90066",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Scarlet Tanager Books",
            "k": "Scarlet Tanager Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Scarlet Tanager Books",
                    "k": "31103",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "12",
                    "cnt_titles": "12"
                }
            ],
            "f": "finished",
            "c": 12,
            "cnt_imprints": 1,
            "cnt_titles": 12
        },
        {
            "v": "San Antonio Museum of Art",
            "k": "San Antonio Museum of Art",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "San Antonio Museum of Art",
                    "k": "41877",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "finished",
            "c": 2,
            "cnt_imprints": 1,
            "cnt_titles": 2
        },
        {
            "v": "Primer Publishers",
            "k": "Primer Publishers",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Amer Traveler Pr",
                    "k": "19707",
                    "t": "pip_id",
                    "f": "",
                    "c": "24",
                    "cnt_titles": "24"
                },
                {
                    "v": "Primer Publ",
                    "k": "582781",
                    "t": "pip_id",
                    "f": "",
                    "c": "11",
                    "cnt_titles": "11"
                },
                {
                    "v": "Primer Publishers",
                    "k": "141264",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Renaissance House Publ",
                    "k": "439781",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "",
            "c": 38,
            "cnt_imprints": 4,
            "cnt_titles": 38
        },
        {
            "v": "Primavera Foundation",
            "k": "Primavera Foundation",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Primavera Foundation",
                    "k": "149396",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Paul W. Bryant Museum",
            "k": "Paul W. Bryant Museum",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Paul W. Bryant Museum",
                    "k": "257643",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "3",
                    "cnt_titles": "3"
                }
            ],
            "f": "finished",
            "c": 3,
            "cnt_imprints": 1,
            "cnt_titles": 3
        },
        {
            "v": "Parmenides Publishing",
            "k": "Parmenides Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Parmenides Publishing",
                    "k": "132771",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "53",
                    "cnt_titles": "53"
                }
            ],
            "f": "finished",
            "c": 53,
            "cnt_imprints": 1,
            "cnt_titles": 53
        },
        {
            "v": "Oregon State University Press",
            "k": "Oregon State University Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "JORDAN SCHNITZER MUSEUM OF ART",
                    "k": "814208",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Oregon Council for the Humanities",
                    "k": "814207",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Oregon State University Press",
                    "k": "15173",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "464",
                    "cnt_titles": "464"
                }
            ],
            "f": "moved finished",
            "c": 469,
            "cnt_imprints": 3,
            "cnt_titles": 469
        },
        {
            "v": "New Mexico Medical Society",
            "k": "New Mexico Medical Society",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "New Mexico Medical Society",
                    "k": "224472",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "New Mexico Magazine",
            "k": "New Mexico Magazine",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "New Mexico Magazine",
                    "k": "814206",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "25",
                    "cnt_titles": "25"
                }
            ],
            "f": "moved finished",
            "c": 25,
            "cnt_imprints": 1,
            "cnt_titles": 25
        },
        {
            "v": "Marion Koogler McNay Art Museum",
            "k": "Marion Koogler McNay Art Museum",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Marion Koogler McNay Art Museum",
                    "k": "53888",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Les Presses de l'Universite Laval",
            "k": "Les Presses de l'Universite Laval",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Les presses de l'universite Laval",
                    "k": "539106",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "15",
                    "cnt_titles": "15"
                }
            ],
            "f": "moved finished",
            "c": 15,
            "cnt_imprints": 1,
            "cnt_titles": 15
        },
        {
            "v": "Legacy Publishing",
            "k": "Legacy Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Legacy Pub",
                    "k": "784911",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Legacy Publishing",
                    "k": "183347",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Lightning Source Inc",
                    "k": "697992",
                    "t": "pip_id",
                    "f": "",
                    "c": "22",
                    "cnt_titles": "22"
                }
            ],
            "f": "",
            "c": 26,
            "cnt_imprints": 3,
            "cnt_titles": 26
        },
        {
            "v": "La Frontera Publishing",
            "k": "La Frontera Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "La Frontera Publishing",
                    "k": "64227",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "12",
                    "cnt_titles": "12"
                }
            ],
            "f": "finished",
            "c": 12,
            "cnt_imprints": 1,
            "cnt_titles": 12
        },
        {
            "v": "Ironwood Press",
            "k": "Ironwood Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Ironwood Press",
                    "k": "26062",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "5",
                    "cnt_titles": "5"
                }
            ],
            "f": "finished",
            "c": 5,
            "cnt_imprints": 1,
            "cnt_titles": 5
        },
        {
            "v": "Institute for Mesoamerican Studies",
            "k": "Institute for Mesoamerican Studies",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Institute for Mesoamerican Studies",
                    "k": "23739",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "15",
                    "cnt_titles": "15"
                }
            ],
            "f": "finished",
            "c": 15,
            "cnt_imprints": 1,
            "cnt_titles": 15
        },
        {
            "v": "Inuvialuit Communications Society",
            "k": "Inuvialuit Communications Society",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Inuvialuit Communications Society",
                    "k": "811059",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Eye of Newt Books",
            "k": "Eye of Newt Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Eye of Newt Books",
                    "k": "498719",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "19",
                    "cnt_titles": "19"
                }
            ],
            "f": "moved finished",
            "c": 19,
            "cnt_imprints": 1,
            "cnt_titles": 19
        },
        {
            "v": "Arvaaq Press",
            "k": "Arvaaq Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Arvaaq Press",
                    "k": "716713",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "556",
                    "cnt_titles": "556"
                }
            ],
            "f": "finished",
            "c": 556,
            "cnt_imprints": 1,
            "cnt_titles": 556
        },
        {
            "v": "Houston Center for Contemporary Craft",
            "k": "Houston Center for Contemporary Craft",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Houston Center for Contemporary Craft",
                    "k": "157517",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Houston Artists Fund",
            "k": "Houston Artists Fund",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Houston Artists Fund",
                    "k": "149301",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Harwood Museum; Distributed by Fresco Fine Art Publications LLC",
            "k": "Harwood Museum; Distributed by Fresco Fine Art Publications LLC",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Harwood Museum; Distributed by Fresco Fine Art Publications LLC",
                    "k": "137117",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Harry Ransom Center UT-Austin",
            "k": "Harry Ransom Center UT-Austin",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Harry Ransom Center UT-Austin",
                    "k": "535980",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "3",
                    "cnt_titles": "3"
                }
            ],
            "f": "finished",
            "c": 3,
            "cnt_imprints": 1,
            "cnt_titles": 3
        },
        {
            "v": "Grand Canyon Association",
            "k": "Grand Canyon Association",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Grand Canyon Association",
                    "k": "22934",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "11",
                    "cnt_titles": "11"
                }
            ],
            "f": "finished",
            "c": 11,
            "cnt_imprints": 1,
            "cnt_titles": 11
        },
        {
            "v": "Gila River Indian Community",
            "k": "Gila River Indian Community",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Gila River Indian Community",
                    "k": "153220",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "7",
                    "cnt_titles": "7"
                }
            ],
            "f": "finished",
            "c": 7,
            "cnt_imprints": 1,
            "cnt_titles": 7
        },
        {
            "v": "SF Design, llc / FrescoBooks",
            "k": "SF Design, llc / FrescoBooks",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "SF Design, llc / FrescoBooks",
                    "k": "199143",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "57",
                    "cnt_titles": "57"
                }
            ],
            "f": "moved",
            "c": 57,
            "cnt_imprints": 1,
            "cnt_titles": 57
        },
        {
            "v": "Essex Press",
            "k": "Essex Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Essex Press",
                    "k": "212441",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "finished",
            "c": 2,
            "cnt_imprints": 1,
            "cnt_titles": 2
        },
        {
            "v": "Ctr for Mex American Studies UT-Austin",
            "k": "Ctr for Mex American Studies UT-Austin",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Ctr for Mex American Studies UT-Austin",
                    "k": "537607",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "3",
                    "cnt_titles": "3"
                }
            ],
            "f": "finished",
            "c": 3,
            "cnt_imprints": 1,
            "cnt_titles": 3
        },
        {
            "v": "Center for Literary Publishing",
            "k": "Center for Literary Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Center for Literary Publishing",
                    "k": "15203",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "47",
                    "cnt_titles": "47"
                }
            ],
            "f": "finished",
            "c": 47,
            "cnt_imprints": 1,
            "cnt_titles": 47
        },
        {
            "v": "Canadian Forest Service",
            "k": "Canadian Forest Service",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Canadian Forest Service",
                    "k": "50784",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "21",
                    "cnt_titles": "21"
                },
                {
                    "v": "Canadian Forestry - Ottawa",
                    "k": "791435",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "moved finished",
            "c": 23,
            "cnt_imprints": 2,
            "cnt_titles": 23
        },
        {
            "v": "Briscoe Ctr for Amer History UT-Austin",
            "k": "Briscoe Ctr for Amer History UT-Austin",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Briscoe Ctr for Amer History UT-Austin",
                    "k": "535982",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "19",
                    "cnt_titles": "19"
                }
            ],
            "f": "moved finished",
            "c": 19,
            "cnt_imprints": 1,
            "cnt_titles": 19
        },
        {
            "v": "BowArrow Publishing Company",
            "k": "BowArrow Publishing Company",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "BowArrow Publishing Company",
                    "k": "199142",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "4",
                    "cnt_titles": "4"
                }
            ],
            "f": "finished",
            "c": 4,
            "cnt_imprints": 1,
            "cnt_titles": 4
        },
        {
            "v": "Alaska Sea Grant College Program",
            "k": "Alaska Sea Grant College Program",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Alaska Sea Grant College Progr",
                    "k": "529845",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "7",
                    "cnt_titles": "7"
                }
            ],
            "f": "moved finished",
            "c": 7,
            "cnt_imprints": 1,
            "cnt_titles": 7
        },
        {
            "v": "Alaska Native Language Center",
            "k": "Alaska Native Language Center",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Alaska Native Language Center",
                    "k": "34961",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "11",
                    "cnt_titles": "11"
                }
            ],
            "f": "moved finished",
            "c": 11,
            "cnt_imprints": 1,
            "cnt_titles": 11
        },
        {
            "v": "University of Hawaii Press",
            "k": "University of Hawaii Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Huia Pub",
                    "k": "814203",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Latitude 20",
                    "k": "439192",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "52",
                    "cnt_titles": "52"
                },
                {
                    "v": "University of Hawaii Press",
                    "k": "439179",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "2711",
                    "cnt_titles": "2711"
                }
            ],
            "f": "moved",
            "c": 2766,
            "cnt_imprints": 3,
            "cnt_titles": 2766
        },
        {
            "v": "Huia Publishers",
            "k": "Huia Publishers",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Huia Publishers",
                    "k": "478224",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "83",
                    "cnt_titles": "83"
                }
            ],
            "f": "moved",
            "c": 83,
            "cnt_imprints": 1,
            "cnt_titles": 83
        },
        {
            "v": "University Press of Florida",
            "k": "University Press of Florida",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Brian Canin Urban Design Award",
                    "k": "815817",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Florida Humanities",
                    "k": "665674",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Gatorbytes",
                    "k": "791363",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "12",
                    "cnt_titles": "12"
                },
                {
                    "v": "Library Press at UF",
                    "k": "791364",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "54",
                    "cnt_titles": "54"
                },
                {
                    "v": "University of Florida College of Journalism and Communications",
                    "k": "674938",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "University Press of Florida",
                    "k": "440346",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "2549",
                    "cnt_titles": "2549"
                }
            ],
            "f": "moved",
            "c": 2618,
            "cnt_imprints": 6,
            "cnt_titles": 2618
        },
        {
            "v": "University of Arizona Press",
            "k": "University of Arizona Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Sentinel Peak Books",
                    "k": "537629",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "University of Arizona Press",
                    "k": "12645",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1819",
                    "cnt_titles": "1819"
                }
            ],
            "f": "moved finished",
            "c": 1825,
            "cnt_imprints": 2,
            "cnt_titles": 1825
        },
        {
            "v": "University Of Alabama Press",
            "k": "University Of Alabama Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Fiction Collective 2",
                    "k": "19654",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "196",
                    "cnt_titles": "196"
                },
                {
                    "v": "Fire Ant Books",
                    "k": "12702",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "65",
                    "cnt_titles": "65"
                },
                {
                    "v": "Pebble Hill Books",
                    "k": "165591",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "University Alabama Press",
                    "k": "12697",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "2028",
                    "cnt_titles": "2028"
                }
            ],
            "f": "moved finished",
            "c": 2292,
            "cnt_imprints": 4,
            "cnt_titles": 2292
        },
        {
            "v": "Forewinds Inc.",
            "k": "Forewinds Inc.",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "807743",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "3",
                    "cnt_titles": "3"
                }
            ],
            "f": "moved finished",
            "c": 3,
            "cnt_imprints": 1,
            "cnt_titles": 3
        },
        {
            "v": "Astra Books for Young Readers",
            "k": "Astra Books for Young Readers",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Astra Young Readers",
                    "k": "653859",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "584",
                    "cnt_titles": "584"
                },
                {
                    "v": "Boyds Mills Press",
                    "k": "575701",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "159",
                    "cnt_titles": "159"
                },
                {
                    "v": "Calkins Creek",
                    "k": "575702",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "163",
                    "cnt_titles": "163"
                },
                {
                    "v": "Hippo Park",
                    "k": "575706",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "47",
                    "cnt_titles": "47"
                },
                {
                    "v": "mineditionUS",
                    "k": "575703",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "113",
                    "cnt_titles": "113"
                },
                {
                    "v": "TOON Books",
                    "k": "653858",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "116",
                    "cnt_titles": "116"
                },
                {
                    "v": "Wordsong",
                    "k": "575704",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "80",
                    "cnt_titles": "80"
                }
            ],
            "f": "moved finished",
            "c": 1262,
            "cnt_imprints": 7,
            "cnt_titles": 1262
        },
        {
            "v": "Scala Arts Publishers Inc.",
            "k": "Scala Arts Publishers Inc.",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Scala Books",
                    "k": "39604",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "270",
                    "cnt_titles": "270"
                }
            ],
            "f": "moved finished",
            "c": 270,
            "cnt_imprints": 1,
            "cnt_titles": 270
        },
        {
            "v": "Lerner Publishing Group",
            "k": "Lerner Publishing Group",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Carolrhoda Books",
                    "k": "15990",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "351",
                    "cnt_titles": "351"
                },
                {
                    "v": "Carolrhoda Lab",
                    "k": "677599",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "172",
                    "cnt_titles": "172"
                },
                {
                    "v": "Darby Creek",
                    "k": "118308",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "548",
                    "cnt_titles": "548"
                },
                {
                    "v": "Ediciones Lerner",
                    "k": "798444",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "469",
                    "cnt_titles": "469"
                },
                {
                    "v": "First Avenue Editions",
                    "k": "9742",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "220",
                    "cnt_titles": "220"
                },
                {
                    "v": "Gecko Press",
                    "k": "802303",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "154",
                    "cnt_titles": "154"
                },
                {
                    "v": "Graphic Universe",
                    "k": "336923",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "474",
                    "cnt_titles": "474"
                },
                {
                    "v": "Kar-Ben",
                    "k": "21206",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "515",
                    "cnt_titles": "515"
                },
                {
                    "v": "Lerner Classroom",
                    "k": "12906",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1469",
                    "cnt_titles": "1469"
                },
                {
                    "v": "Lerner Publications",
                    "k": "364751",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "6904",
                    "cnt_titles": "6904"
                },
                {
                    "v": "Millbrook Press",
                    "k": "9746",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "625",
                    "cnt_titles": "625"
                },
                {
                    "v": "Runestone",
                    "k": "12903",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Twenty-First Century Books",
                    "k": "11881",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "183",
                    "cnt_titles": "183"
                },
                {
                    "v": "Zest Books",
                    "k": "442288",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "123",
                    "cnt_titles": "123"
                }
            ],
            "f": "renamed moved",
            "c": 12210,
            "cnt_imprints": 14,
            "cnt_titles": 12210
        },
        {
            "v": "University of Texas Press",
            "k": "University of Texas Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Center for Middle Eastern Studies, University of Texas at Austin",
                    "k": "3132",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "University of Texas Press",
                    "k": "3111",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "3397",
                    "cnt_titles": "3397"
                }
            ],
            "f": "moved finished",
            "c": 3398,
            "cnt_imprints": 2,
            "cnt_titles": 3398
        },
        {
            "v": "Assembly Press",
            "k": "Assembly Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Assembly Press",
                    "k": "693565",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "15",
                    "cnt_titles": "15"
                },
                {
                    "v": "Hush Harbour Press",
                    "k": "806470",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "The McLuhan Institute Books",
                    "k": "815716",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 17,
            "cnt_imprints": 3,
            "cnt_titles": 17
        },
        {
            "v": "Kogan Page",
            "k": "Kogan Page",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Kogan Page",
                    "k": "9173",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "2100",
                    "cnt_titles": "2100"
                }
            ],
            "f": "moved finished",
            "c": 2100,
            "cnt_imprints": 1,
            "cnt_titles": 2100
        },
        {
            "v": "Wattpad Webtoon Studios, Inc.",
            "k": "Wattpad Webtoon Studios, Inc.",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Frayed Pages x Wattpad Books",
                    "k": "780740",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "13",
                    "cnt_titles": "13"
                },
                {
                    "v": "W by Wattpad Books",
                    "k": "759431",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "51",
                    "cnt_titles": "51"
                },
                {
                    "v": "Wattpad Books",
                    "k": "770661",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "95",
                    "cnt_titles": "95"
                },
                {
                    "v": "WEBTOON Unscrolled",
                    "k": "759432",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "85",
                    "cnt_titles": "85"
                }
            ],
            "f": "moved finished",
            "c": 244,
            "cnt_imprints": 4,
            "cnt_titles": 244
        },
        {
            "v": "Republic Book Publishers",
            "k": "Republic Book Publishers",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Republic Book Publishers",
                    "k": "437913",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "43",
                    "cnt_titles": "43"
                }
            ],
            "f": "finished",
            "c": 43,
            "cnt_imprints": 1,
            "cnt_titles": 43
        },
        {
            "v": "AK Press",
            "k": "AK Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Ak Pr Inc",
                    "k": "757735",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "AK Press",
                    "k": "40137",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "393",
                    "cnt_titles": "393"
                },
                {
                    "v": "Charles Kerr",
                    "k": "751658",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Commune Editions",
                    "k": "288255",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "14",
                    "cnt_titles": "14"
                },
                {
                    "v": "Legacy Left Books",
                    "k": "798516",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Les Pages Noires",
                    "k": "189298",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Nabat Books",
                    "k": "45340",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Ralph Myles",
                    "k": "86771",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "World War 3 Illustrated",
                    "k": "343643",
                    "t": "pip_id",
                    "f": "",
                    "c": "6",
                    "cnt_titles": "6"
                }
            ],
            "f": "moved",
            "c": 420,
            "cnt_imprints": 9,
            "cnt_titles": 420
        },
        {
            "v": "She Writes Press",
            "k": "She Writes Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "She Writes Press",
                    "k": "206845",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "937",
                    "cnt_titles": "937"
                },
                {
                    "v": "Sparkpress",
                    "k": "403050",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "233",
                    "cnt_titles": "233"
                }
            ],
            "f": "moved finished",
            "c": 1170,
            "cnt_imprints": 2,
            "cnt_titles": 1170
        },
        {
            "v": "Little, Brown and Company (US)",
            "k": "Little, Brown and Company (US)",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "389425",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "Algonquin Books",
                    "k": "799840",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1075",
                    "cnt_titles": "1075"
                },
                {
                    "v": "Back Bay Books",
                    "k": "3750",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1239",
                    "cnt_titles": "1239"
                },
                {
                    "v": "Bulfinch",
                    "k": "139988",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "194",
                    "cnt_titles": "194"
                },
                {
                    "v": "Hachette Digital",
                    "k": "677557",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "Jimmy Patterson",
                    "k": "81636",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "153",
                    "cnt_titles": "153"
                },
                {
                    "v": "Joy Street",
                    "k": "343588",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Lee Boudreaux Books",
                    "k": "233503",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "16",
                    "cnt_titles": "16"
                },
                {
                    "v": "Little, Brown and Company",
                    "k": "1448",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4081",
                    "cnt_titles": "4081"
                },
                {
                    "v": "Little, Brown Lab",
                    "k": "411069",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "Little, Brown Spark",
                    "k": "383376",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "426",
                    "cnt_titles": "426"
                },
                {
                    "v": "Mulholland Books",
                    "k": "4209",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "544",
                    "cnt_titles": "544"
                },
                {
                    "v": "Reagan Arthur Books",
                    "k": "81633",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "62",
                    "cnt_titles": "62"
                },
                {
                    "v": "The Bridge Street Press",
                    "k": "677556",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Voracious",
                    "k": "435772",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "124",
                    "cnt_titles": "124"
                }
            ],
            "f": "renamed moved",
            "c": 7940,
            "cnt_imprints": 15,
            "cnt_titles": 7940
        },
        {
            "v": "Little, Brown Books for Young Readers",
            "k": "Little, Brown Books for Young Readers",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Algonquin Young Readers",
                    "k": "799841",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "330",
                    "cnt_titles": "330"
                },
                {
                    "v": "Christy Ottaviano Books",
                    "k": "519702",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "206",
                    "cnt_titles": "206"
                },
                {
                    "v": "jimmy patterson",
                    "k": "544020",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "332",
                    "cnt_titles": "332"
                },
                {
                    "v": "LB Kids",
                    "k": "3786",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "430",
                    "cnt_titles": "430"
                },
                {
                    "v": "Little, Brown Books for Young Readers",
                    "k": "3753",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4677",
                    "cnt_titles": "4677"
                },
                {
                    "v": "Little, Brown Ink",
                    "k": "651069",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "217",
                    "cnt_titles": "217"
                },
                {
                    "v": "Poppy",
                    "k": "71171",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "248",
                    "cnt_titles": "248"
                },
                {
                    "v": "previously Disney-Hyperion",
                    "k": "469995",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "4",
                    "cnt_titles": "4"
                }
            ],
            "f": "moved",
            "c": 6444,
            "cnt_imprints": 8,
            "cnt_titles": 6444
        },
        {
            "v": "Llewellyn Worldwide, LTD.",
            "k": "Llewellyn Worldwide, LTD.",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Flux",
                    "k": "799865",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "148",
                    "cnt_titles": "148"
                },
                {
                    "v": "Llewellyn Publications",
                    "k": "5238",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "3083",
                    "cnt_titles": "3083"
                },
                {
                    "v": "Midnight Ink",
                    "k": "14228",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "277",
                    "cnt_titles": "277"
                }
            ],
            "f": "moved finished",
            "c": 3508,
            "cnt_imprints": 3,
            "cnt_titles": 3508
        },
        {
            "v": "Workman Publishing Co",
            "k": "Workman Publishing Co",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Black Dog &amp; Leventhal",
                    "k": "799839",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "612",
                    "cnt_titles": "612"
                },
                {
                    "v": "Workman Publishing Company",
                    "k": "4818",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "2266",
                    "cnt_titles": "2266"
                }
            ],
            "f": "moved finished",
            "c": 2878,
            "cnt_imprints": 2,
            "cnt_titles": 2878
        },
        {
            "v": "Timber Press",
            "k": "Timber Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "799838",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Timber Press",
                    "k": "634511",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "805",
                    "cnt_titles": "805"
                }
            ],
            "f": "moved",
            "c": 806,
            "cnt_imprints": 2,
            "cnt_titles": 806
        },
        {
            "v": "Mango Media",
            "k": "Mango Media",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "AP Editions",
                    "k": "17008",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "Books That Save Lives",
                    "k": "741215",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "15",
                    "cnt_titles": "15"
                },
                {
                    "v": "D.O.P.E.",
                    "k": "676223",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Dragonfruit",
                    "k": "589604",
                    "t": "pip_id",
                    "f": "",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "FIG",
                    "k": "233553",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Granadilla Press",
                    "k": "233124",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Herald Books",
                    "k": "309912",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Independent Print Limited",
                    "k": "299503",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "Key Lime Press",
                    "k": "206908",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Kumquat",
                    "k": "178661",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Mango",
                    "k": "219119",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "205",
                    "cnt_titles": "205"
                },
                {
                    "v": "Mangosteen Media",
                    "k": "689276",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "National Baseball Hall of Fame Books",
                    "k": "676226",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "News Books",
                    "k": "218526",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Passion Fruit Press",
                    "k": "219118",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Reef Smart Guides",
                    "k": "676225",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "The Tiny Press",
                    "k": "420060",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Yellow Pear Press",
                    "k": "676227",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                }
            ],
            "f": "moved",
            "c": 266,
            "cnt_imprints": 18,
            "cnt_titles": 266
        },
        {
            "v": "ARCHA",
            "k": "ARCHA",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "798471",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "",
            "c": 2,
            "cnt_imprints": 1,
            "cnt_titles": 2
        },
        {
            "v": "BOOM! Studios",
            "k": "BOOM! Studios",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Archaia",
                    "k": "339046",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "262",
                    "cnt_titles": "262"
                },
                {
                    "v": "Boom Adult Tp",
                    "k": "159133",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Boom Kids Tp",
                    "k": "339045",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "BOOM! Box",
                    "k": "339051",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "156",
                    "cnt_titles": "156"
                },
                {
                    "v": "BOOM! Studios",
                    "k": "159127",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "869",
                    "cnt_titles": "869"
                },
                {
                    "v": "KaBOOM!",
                    "k": "208887",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "249",
                    "cnt_titles": "249"
                }
            ],
            "f": "moved",
            "c": 1538,
            "cnt_imprints": 6,
            "cnt_titles": 1538
        },
        {
            "v": "Canadian Broadcasting Corp",
            "k": "Canadian Broadcasting Corp",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Canadian Broadcasting Corp",
                    "k": "7068",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "27",
                    "cnt_titles": "27"
                }
            ],
            "f": "moved",
            "c": 27,
            "cnt_imprints": 1,
            "cnt_titles": 27
        },
        {
            "v": "Portage &amp; Main Press",
            "k": "Portage & Main Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "HighWater Press",
                    "k": "34978",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "110",
                    "cnt_titles": "110"
                },
                {
                    "v": "Portage &amp; Main Press",
                    "k": "20674",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "119",
                    "cnt_titles": "119"
                }
            ],
            "f": "moved finished",
            "c": 229,
            "cnt_imprints": 2,
            "cnt_titles": 229
        },
        {
            "v": "Red Hen Press",
            "k": "Red Hen Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Arktoi Books",
                    "k": "337546",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "Boreal Books",
                    "k": "337547",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "31",
                    "cnt_titles": "31"
                },
                {
                    "v": "Canis Major Books",
                    "k": "658362",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Crooked Hearts Press",
                    "k": "495252",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "DJS Books",
                    "k": "320304",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Pighog",
                    "k": "423239",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "Red Hen Press",
                    "k": "43319",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "616",
                    "cnt_titles": "616"
                },
                {
                    "v": "True Roses Books",
                    "k": "660545",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Xeno Books",
                    "k": "223710",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "13",
                    "cnt_titles": "13"
                }
            ],
            "f": "moved finished",
            "c": 686,
            "cnt_imprints": 9,
            "cnt_titles": 686
        },
        {
            "v": "Story Line Press",
            "k": "Story Line Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Story Line Pr",
                    "k": "798460",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "290",
                    "cnt_titles": "290"
                }
            ],
            "f": "moved finished",
            "c": 290,
            "cnt_imprints": 1,
            "cnt_titles": 290
        },
        {
            "v": "HarperCollins Publishers",
            "k": "HarperCollins Publishers",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "12",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "2076",
                    "cnt_titles": "2076"
                },
                {
                    "v": "4th Estate",
                    "k": "65809",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "45",
                    "cnt_titles": "45"
                },
                {
                    "v": "ABC Books",
                    "k": "400473",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "148",
                    "cnt_titles": "148"
                },
                {
                    "v": "ACADEMIC",
                    "k": "337423",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "ADULT FICTION",
                    "k": "337289",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Akan Books",
                    "k": "801253",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Amistad",
                    "k": "337228",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "432",
                    "cnt_titles": "432"
                },
                {
                    "v": "Amistad Pr {AMISB}",
                    "k": "53171",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "AMISTAD PRESS",
                    "k": "337290",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Angus &amp; Robert",
                    "k": "337234",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "16",
                    "cnt_titles": "16"
                },
                {
                    "v": "Anthony Bourdain/Ecco",
                    "k": "337257",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "21",
                    "cnt_titles": "21"
                },
                {
                    "v": "AUDIO, US ADUL",
                    "k": "337291",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Authonomy",
                    "k": "258743",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Avon",
                    "k": "337229",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4738",
                    "cnt_titles": "4738"
                },
                {
                    "v": "AvoNova Fantasy",
                    "k": "470522",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Balzer &amp; Bray",
                    "k": "804",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "37",
                    "cnt_titles": "37"
                },
                {
                    "v": "Bard {BARD}",
                    "k": "82698",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Beautiful Cookbook",
                    "k": "129",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Beautiful Cookbooks",
                    "k": "337262",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "BI-LINGUAL DIC",
                    "k": "337292",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Blue Door",
                    "k": "189722",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Brenda Bowen",
                    "k": "892",
                    "t": "pip_id",
                    "f": "",
                    "c": "16",
                    "cnt_titles": "16"
                },
                {
                    "v": "Broadside Books",
                    "k": "162141",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "131",
                    "cnt_titles": "131"
                },
                {
                    "v": "Caedmon",
                    "k": "337264",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "27",
                    "cnt_titles": "27"
                },
                {
                    "v": "Camelot {CAMLT}",
                    "k": "4486",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Canada Audio",
                    "k": "337479",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "CANWEST BOOKS",
                    "k": "337427",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "CDN ADULT HC",
                    "k": "337428",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "69",
                    "cnt_titles": "69"
                },
                {
                    "v": "CDN ADULT MM",
                    "k": "337429",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "15",
                    "cnt_titles": "15"
                },
                {
                    "v": "CDN ADULT TP",
                    "k": "337430",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "35",
                    "cnt_titles": "35"
                },
                {
                    "v": "CDN KIDS MM",
                    "k": "337432",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "11",
                    "cnt_titles": "11"
                },
                {
                    "v": "CDN KIDS TP",
                    "k": "337433",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "20",
                    "cnt_titles": "20"
                },
                {
                    "v": "CHILDRENS",
                    "k": "337434",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Clarion Books",
                    "k": "682665",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Cliff Street Books {CLFST}",
                    "k": "337435",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Collins",
                    "k": "117",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4705",
                    "cnt_titles": "4705"
                },
                {
                    "v": "Collins &amp; Brown",
                    "k": "678073",
                    "t": "pip_id",
                    "f": "",
                    "c": "51",
                    "cnt_titles": "51"
                },
                {
                    "v": "COLLINS BUSINE",
                    "k": "337436",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Collins Business",
                    "k": "838",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Collins Canada",
                    "k": "337480",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "15",
                    "cnt_titles": "15"
                },
                {
                    "v": "Collins Cobuild",
                    "k": "198301",
                    "t": "pip_id",
                    "f": "",
                    "c": "22",
                    "cnt_titles": "22"
                },
                {
                    "v": "Collins Crime",
                    "k": "232751",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "Collins Crime Club",
                    "k": "62093",
                    "t": "pip_id",
                    "f": "",
                    "c": "150",
                    "cnt_titles": "150"
                },
                {
                    "v": "Collins Design",
                    "k": "913",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "Collins Education",
                    "k": "337341",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "21",
                    "cnt_titles": "21"
                },
                {
                    "v": "Collins Educational",
                    "k": "141094",
                    "t": "pip_id",
                    "f": "",
                    "c": "161",
                    "cnt_titles": "161"
                },
                {
                    "v": "Collins Flamingo",
                    "k": "309793",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Collins Music",
                    "k": "442192",
                    "t": "pip_id",
                    "f": "",
                    "c": "180",
                    "cnt_titles": "180"
                },
                {
                    "v": "Collins Refere",
                    "k": "191139",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Collins Reference",
                    "k": "197",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "330",
                    "cnt_titles": "330"
                },
                {
                    "v": "Collins Voyager",
                    "k": "309794",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "COLLINS WELLNE",
                    "k": "337438",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Collins-Janes",
                    "k": "139602",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "CONSIGNMENT",
                    "k": "337522",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Cookbooks",
                    "k": "131",
                    "t": "pip_id",
                    "f": "",
                    "c": "98",
                    "cnt_titles": "98"
                },
                {
                    "v": "Crc Pr Inc",
                    "k": "81063",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Createspace",
                    "k": "230927",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Custom House",
                    "k": "337265",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "Daybreak Books {DAYBO}",
                    "k": "337446",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Dean",
                    "k": "512766",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "20",
                    "cnt_titles": "20"
                },
                {
                    "v": "Dey Street Books",
                    "k": "337231",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "689",
                    "cnt_titles": "689"
                },
                {
                    "v": "DITLO",
                    "k": "337420",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "DO NOT USE",
                    "k": "337293",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Dorchester Lovespell",
                    "k": "337447",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Ecco",
                    "k": "337232",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "2124",
                    "cnt_titles": "2124"
                },
                {
                    "v": "Element",
                    "k": "39179",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "38",
                    "cnt_titles": "38"
                },
                {
                    "v": "Element Books Ltd {ELEME}",
                    "k": "337448",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "17",
                    "cnt_titles": "17"
                },
                {
                    "v": "Eminent Lives",
                    "k": "337237",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "11",
                    "cnt_titles": "11"
                },
                {
                    "v": "Eos",
                    "k": "987",
                    "t": "pip_id",
                    "f": "",
                    "c": "81",
                    "cnt_titles": "81"
                },
                {
                    "v": "Eos {EOSMM}",
                    "k": "4489",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Eos {EOSTR}",
                    "k": "835",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Expanse",
                    "k": "627950",
                    "t": "pip_id",
                    "f": "",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "Festival",
                    "k": "776",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "82",
                    "cnt_titles": "82"
                },
                {
                    "v": "FICTION",
                    "k": "337295",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "18",
                    "cnt_titles": "18"
                },
                {
                    "v": "Findaway World Llc",
                    "k": "487719",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Flamingo",
                    "k": "74528",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "64",
                    "cnt_titles": "64"
                },
                {
                    "v": "Folens",
                    "k": "337342",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "Fontana Press",
                    "k": "309792",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "12",
                    "cnt_titles": "12"
                },
                {
                    "v": "Fourth Estate",
                    "k": "324",
                    "t": "pip_id",
                    "f": "",
                    "c": "318",
                    "cnt_titles": "318"
                },
                {
                    "v": "Fourth Estate {FOEST}",
                    "k": "337449",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "GENERAL",
                    "k": "337297",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "20",
                    "cnt_titles": "20"
                },
                {
                    "v": "Geographers' A-Z Map Co Ltd",
                    "k": "459789",
                    "t": "pip_id",
                    "f": "",
                    "c": "129",
                    "cnt_titles": "129"
                },
                {
                    "v": "GIFT &amp; STATION",
                    "k": "337450",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Grafton Books",
                    "k": "669434",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Greenwillow",
                    "k": "829",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "128",
                    "cnt_titles": "128"
                },
                {
                    "v": "Greenwillow Books",
                    "k": "337238",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1443",
                    "cnt_titles": "1443"
                },
                {
                    "v": "GREENWILLOW LB",
                    "k": "337451",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Harper",
                    "k": "134",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4215",
                    "cnt_titles": "4215"
                },
                {
                    "v": "Harper Business",
                    "k": "933",
                    "t": "pip_id",
                    "f": "",
                    "c": "10",
                    "cnt_titles": "10"
                },
                {
                    "v": "HARPER BY DESIGN AU",
                    "k": "792594",
                    "t": "pip_id",
                    "f": "",
                    "c": "19",
                    "cnt_titles": "19"
                },
                {
                    "v": "Harper Canada",
                    "k": "337239",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Harper Collins",
                    "k": "53169",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "9",
                    "cnt_titles": "9"
                },
                {
                    "v": "Harper Design",
                    "k": "851",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "34",
                    "cnt_titles": "34"
                },
                {
                    "v": "Harper Element",
                    "k": "132636",
                    "t": "pip_id",
                    "f": "",
                    "c": "9",
                    "cnt_titles": "9"
                },
                {
                    "v": "Harper Entertainment {HRPEN}",
                    "k": "337253",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Harper Festival",
                    "k": "158776",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "97",
                    "cnt_titles": "97"
                },
                {
                    "v": "Harper Fire",
                    "k": "723800",
                    "t": "pip_id",
                    "f": "",
                    "c": "25",
                    "cnt_titles": "25"
                },
                {
                    "v": "Harper India",
                    "k": "337254",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Harper Influence",
                    "k": "798446",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "12",
                    "cnt_titles": "12"
                },
                {
                    "v": "Harper Paperback",
                    "k": "960",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "13",
                    "cnt_titles": "13"
                },
                {
                    "v": "Harper Paperbacks",
                    "k": "314",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1841",
                    "cnt_titles": "1841"
                },
                {
                    "v": "Harper Perennial",
                    "k": "287",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4893",
                    "cnt_titles": "4893"
                },
                {
                    "v": "Harper Perennial Canada",
                    "k": "337337",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "29",
                    "cnt_titles": "29"
                },
                {
                    "v": "Harper Perennial Modern Classics",
                    "k": "142205",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "244",
                    "cnt_titles": "244"
                },
                {
                    "v": "Harper Perennial U.S.",
                    "k": "169426",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Harper Perennial US",
                    "k": "963",
                    "t": "pip_id",
                    "f": "",
                    "c": "192",
                    "cnt_titles": "192"
                },
                {
                    "v": "Harper Prism {HRPRP}",
                    "k": "998",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "35",
                    "cnt_titles": "35"
                },
                {
                    "v": "Harper San Francisco {HRPSF}",
                    "k": "821",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "56",
                    "cnt_titles": "56"
                },
                {
                    "v": "Harper Thorsons",
                    "k": "353",
                    "t": "pip_id",
                    "f": "",
                    "c": "36",
                    "cnt_titles": "36"
                },
                {
                    "v": "Harper Torch",
                    "k": "68987",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "22",
                    "cnt_titles": "22"
                },
                {
                    "v": "HARPER TRADE",
                    "k": "337298",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "85",
                    "cnt_titles": "85"
                },
                {
                    "v": "Harper Trophy Canada",
                    "k": "337240",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "32",
                    "cnt_titles": "32"
                },
                {
                    "v": "Harper UK",
                    "k": "337421",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Harper Voyager",
                    "k": "996",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1054",
                    "cnt_titles": "1054"
                },
                {
                    "v": "Harper Voyager Impulse",
                    "k": "337268",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "62",
                    "cnt_titles": "62"
                },
                {
                    "v": "Harper Wave",
                    "k": "337269",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "19",
                    "cnt_titles": "19"
                },
                {
                    "v": "HarperAudio",
                    "k": "337271",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "719",
                    "cnt_titles": "719"
                },
                {
                    "v": "Harperaudio {HARPC}",
                    "k": "8042",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "HarperAvenue",
                    "k": "337272",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "123",
                    "cnt_titles": "123"
                },
                {
                    "v": "HarperBusiness",
                    "k": "337241",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Harperbusiness {HRPBS}",
                    "k": "1070",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "HarperCollins",
                    "k": "154",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "30165",
                    "cnt_titles": "30165"
                },
                {
                    "v": "Harpercollins 360",
                    "k": "671746",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "HarperCollins AU",
                    "k": "792578",
                    "t": "pip_id",
                    "f": "",
                    "c": "213",
                    "cnt_titles": "213"
                },
                {
                    "v": "Harpercollins Australia {HRPCA}",
                    "k": "2752",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "HarperCollins Business",
                    "k": "222450",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Harpercollins Canada {HRPCC}",
                    "k": "974",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "27",
                    "cnt_titles": "27"
                },
                {
                    "v": "Harpercollins Children's",
                    "k": "337340",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "26",
                    "cnt_titles": "26"
                },
                {
                    "v": "Harpercollins Childrens Books {HAPAP}",
                    "k": "8046",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Harpercollins Childrens Books {HARJU}",
                    "k": "809",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "9",
                    "cnt_titles": "9"
                },
                {
                    "v": "Harpercollins College Div {HARCG}",
                    "k": "7490",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "36",
                    "cnt_titles": "36"
                },
                {
                    "v": "HarperCollins Entertainment",
                    "k": "147745",
                    "t": "pip_id",
                    "f": "",
                    "c": "10",
                    "cnt_titles": "10"
                },
                {
                    "v": "HarperCollins Espanol",
                    "k": "337333",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "13",
                    "cnt_titles": "13"
                },
                {
                    "v": "HarperCollins Iberica S.A.",
                    "k": "337478",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Harpercollins India",
                    "k": "786948",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "HarperCollins NZ",
                    "k": "792592",
                    "t": "pip_id",
                    "f": "",
                    "c": "62",
                    "cnt_titles": "62"
                },
                {
                    "v": "Harpercollins Pub Ltd {HRPRC}",
                    "k": "157269",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "247",
                    "cnt_titles": "247"
                },
                {
                    "v": "Harpercollins Publ Austra",
                    "k": "2750",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "HarperCollins Publishers",
                    "k": "82696",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "HarperCollins Publishers Ltd",
                    "k": "337242",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "HarperCollins Publishers U.K.",
                    "k": "337343",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Harpercollins Trade Sales Dept",
                    "k": "337244",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "10",
                    "cnt_titles": "10"
                },
                {
                    "v": "Harpercollins Uk",
                    "k": "8685",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "393",
                    "cnt_titles": "393"
                },
                {
                    "v": "Harpercollins {HARPE}",
                    "k": "856",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "832",
                    "cnt_titles": "832"
                },
                {
                    "v": "Harpercollins {HARPK}",
                    "k": "994",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "448",
                    "cnt_titles": "448"
                },
                {
                    "v": "Harpercollins {HARPZ}",
                    "k": "824",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "70",
                    "cnt_titles": "70"
                },
                {
                    "v": "Harpercollins {HPCLP}",
                    "k": "973",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "517",
                    "cnt_titles": "517"
                },
                {
                    "v": "Harpercollins {HRPCS}",
                    "k": "337344",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "9",
                    "cnt_titles": "9"
                },
                {
                    "v": "Harpercollins {HRPER}",
                    "k": "337491",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Harpercollins {HRPEX}",
                    "k": "337345",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Harpercollins; Smithsonian Boo",
                    "k": "337492",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Harpercollinscanadaltd",
                    "k": "337346",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Harpercollinschildren's",
                    "k": "337487",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "HarperCollinsChildren'sBooks",
                    "k": "327",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1398",
                    "cnt_titles": "1398"
                },
                {
                    "v": "HarperCollinsIreland",
                    "k": "530942",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Harperelement",
                    "k": "361327",
                    "t": "pip_id",
                    "f": "",
                    "c": "214",
                    "cnt_titles": "214"
                },
                {
                    "v": "HarperElixir",
                    "k": "337273",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "HarperEntertainment",
                    "k": "337274",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "176",
                    "cnt_titles": "176"
                },
                {
                    "v": "HarperFestival",
                    "k": "8047",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "698",
                    "cnt_titles": "698"
                },
                {
                    "v": "Harperfestival {HARFE}",
                    "k": "8038",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "Harperflamingo {HRPFL}",
                    "k": "337454",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "HarperImpulse",
                    "k": "207920",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Harperkids Ent",
                    "k": "787",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "88",
                    "cnt_titles": "88"
                },
                {
                    "v": "Harperluxe",
                    "k": "140498",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "HarperNorth",
                    "k": "488795",
                    "t": "pip_id",
                    "f": "",
                    "c": "146",
                    "cnt_titles": "146"
                },
                {
                    "v": "Harperone",
                    "k": "282",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "2793",
                    "cnt_titles": "2793"
                },
                {
                    "v": "Harperone {HARON}",
                    "k": "632217",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "HarperPress",
                    "k": "154979",
                    "t": "pip_id",
                    "f": "",
                    "c": "66",
                    "cnt_titles": "66"
                },
                {
                    "v": "HarperSport",
                    "k": "132763",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "29",
                    "cnt_titles": "29"
                },
                {
                    "v": "HarperSports",
                    "k": "400479",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "HarperSports AU",
                    "k": "792593",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Harperteen",
                    "k": "798",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1587",
                    "cnt_titles": "1587"
                },
                {
                    "v": "Harperteen {HRPTN}",
                    "k": "1064",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "11",
                    "cnt_titles": "11"
                },
                {
                    "v": "HarperTempest",
                    "k": "337332",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "HarperTorch",
                    "k": "337245",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "533",
                    "cnt_titles": "533"
                },
                {
                    "v": "HarperTrophy",
                    "k": "337246",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "294",
                    "cnt_titles": "294"
                },
                {
                    "v": "HarperTrue",
                    "k": "178391",
                    "t": "pip_id",
                    "f": "",
                    "c": "13",
                    "cnt_titles": "13"
                },
                {
                    "v": "HarperVoyager",
                    "k": "357022",
                    "t": "pip_id",
                    "f": "",
                    "c": "133",
                    "cnt_titles": "133"
                },
                {
                    "v": "HarperWeekend",
                    "k": "337276",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "59",
                    "cnt_titles": "59"
                },
                {
                    "v": "HCC MIXED SETS",
                    "k": "337299",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Hearst Magazine",
                    "k": "578578",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Hemlock Press",
                    "k": "739953",
                    "t": "pip_id",
                    "f": "",
                    "c": "21",
                    "cnt_titles": "21"
                },
                {
                    "v": "HOME &amp; GARDEN",
                    "k": "337300",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "HQ",
                    "k": "333367",
                    "t": "pip_id",
                    "f": "",
                    "c": "414",
                    "cnt_titles": "414"
                },
                {
                    "v": "HQ Digital",
                    "k": "381876",
                    "t": "pip_id",
                    "f": "",
                    "c": "110",
                    "cnt_titles": "110"
                },
                {
                    "v": "HQ Fiction",
                    "k": "413764",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "HQ Fiction AU",
                    "k": "792597",
                    "t": "pip_id",
                    "f": "",
                    "c": "9",
                    "cnt_titles": "9"
                },
                {
                    "v": "HQ Non Fiction AU",
                    "k": "792595",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "HQ Young Adult",
                    "k": "358384",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "I Can Read",
                    "k": "805",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "17",
                    "cnt_titles": "17"
                },
                {
                    "v": "Icon Editions",
                    "k": "666734",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Icon {ICON}",
                    "k": "81056",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Igniter",
                    "k": "185321",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Inspirio {ISPRO}",
                    "k": "337455",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "It Books",
                    "k": "132",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "464",
                    "cnt_titles": "464"
                },
                {
                    "v": "JUVENILE B B",
                    "k": "337457",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "Katherine Tegen",
                    "k": "781",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "38",
                    "cnt_titles": "38"
                },
                {
                    "v": "Katherine Tegen Books",
                    "k": "147150",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1088",
                    "cnt_titles": "1088"
                },
                {
                    "v": "Keen Kite Books",
                    "k": "306257",
                    "t": "pip_id",
                    "f": "",
                    "c": "22",
                    "cnt_titles": "22"
                },
                {
                    "v": "KID CLOTH/LB/B",
                    "k": "337301",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "101",
                    "cnt_titles": "101"
                },
                {
                    "v": "Killer Reads",
                    "k": "690691",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Kumusha Books",
                    "k": "692163",
                    "t": "pip_id",
                    "f": "",
                    "c": "10",
                    "cnt_titles": "10"
                },
                {
                    "v": "larua Geringer Books",
                    "k": "764777",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Laura Geringer Books",
                    "k": "146682",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Leckie",
                    "k": "445912",
                    "t": "pip_id",
                    "f": "",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "Letts",
                    "k": "225516",
                    "t": "pip_id",
                    "f": "",
                    "c": "47",
                    "cnt_titles": "47"
                },
                {
                    "v": "Lifestyle",
                    "k": "839",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Lothrop Lee &amp; Shepard {LOTHR}",
                    "k": "337458",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "29",
                    "cnt_titles": "29"
                },
                {
                    "v": "Magpie",
                    "k": "693592",
                    "t": "pip_id",
                    "f": "",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "MARSHALL PICKE",
                    "k": "337304",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "10",
                    "cnt_titles": "10"
                },
                {
                    "v": "Maze",
                    "k": "210638",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Middle Grade",
                    "k": "772",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "186",
                    "cnt_titles": "186"
                },
                {
                    "v": "Mills &amp; Boon",
                    "k": "685176",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Mira",
                    "k": "426736",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Mira AU",
                    "k": "792596",
                    "t": "pip_id",
                    "f": "",
                    "c": "13",
                    "cnt_titles": "13"
                },
                {
                    "v": "Mischief",
                    "k": "174159",
                    "t": "pip_id",
                    "f": "",
                    "c": "20",
                    "cnt_titles": "20"
                },
                {
                    "v": "MM, US ADULT",
                    "k": "337305",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "120",
                    "cnt_titles": "120"
                },
                {
                    "v": "Morrow Cookbook",
                    "k": "7823",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Mudlark",
                    "k": "421613",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "44",
                    "cnt_titles": "44"
                },
                {
                    "v": "MYSTERY",
                    "k": "337460",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "NARNIA HC",
                    "k": "337461",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "NATURAL HISTOR",
                    "k": "337306",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Newmarket Press",
                    "k": "35235",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "94",
                    "cnt_titles": "94"
                },
                {
                    "v": "Nicholson",
                    "k": "294571",
                    "t": "pip_id",
                    "f": "",
                    "c": "9",
                    "cnt_titles": "9"
                },
                {
                    "v": "NON-FICTION",
                    "k": "337307",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "57",
                    "cnt_titles": "57"
                },
                {
                    "v": "One More Chapter",
                    "k": "440734",
                    "t": "pip_id",
                    "f": "",
                    "c": "395",
                    "cnt_titles": "395"
                },
                {
                    "v": "Patrick Crean Editions",
                    "k": "337280",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "43",
                    "cnt_titles": "43"
                },
                {
                    "v": "Perennial",
                    "k": "68991",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "122",
                    "cnt_titles": "122"
                },
                {
                    "v": "PERENNIAL RACK",
                    "k": "337308",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Perennial {PEREL}",
                    "k": "1051",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "144",
                    "cnt_titles": "144"
                },
                {
                    "v": "Phyllis Bruce",
                    "k": "337481",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Phyllis Bruce Books",
                    "k": "337247",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "61",
                    "cnt_titles": "61"
                },
                {
                    "v": "Phyllis Bruce Books Perennial",
                    "k": "337248",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "58",
                    "cnt_titles": "58"
                },
                {
                    "v": "Picture Books",
                    "k": "792",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "64",
                    "cnt_titles": "64"
                },
                {
                    "v": "PLENUM",
                    "k": "337462",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "22",
                    "cnt_titles": "22"
                },
                {
                    "v": "Programs and Genres",
                    "k": "774",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "146",
                    "cnt_titles": "146"
                },
                {
                    "v": "Rayo",
                    "k": "845",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "163",
                    "cnt_titles": "163"
                },
                {
                    "v": "Regan Books {RGNBK}",
                    "k": "908",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "RELIGIOUS",
                    "k": "337464",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "RESOURCE REF",
                    "k": "337309",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "ROAD ATLAS MAP",
                    "k": "337310",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Robson Books",
                    "k": "680106",
                    "t": "pip_id",
                    "f": "",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "Rocket Bird Books",
                    "k": "736748",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Scholastic",
                    "k": "489660",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "SCI-FI",
                    "k": "337465",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "Snicket",
                    "k": "337282",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "9",
                    "cnt_titles": "9"
                },
                {
                    "v": "Tegen Other",
                    "k": "799",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "18",
                    "cnt_titles": "18"
                },
                {
                    "v": "The Borough Press",
                    "k": "218520",
                    "t": "pip_id",
                    "f": "",
                    "c": "105",
                    "cnt_titles": "105"
                },
                {
                    "v": "The Friday Project",
                    "k": "169394",
                    "t": "pip_id",
                    "f": "",
                    "c": "60",
                    "cnt_titles": "60"
                },
                {
                    "v": "THORSON HEALTH",
                    "k": "337311",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "10",
                    "cnt_titles": "10"
                },
                {
                    "v": "Thorsons",
                    "k": "352",
                    "t": "pip_id",
                    "f": "",
                    "c": "154",
                    "cnt_titles": "154"
                },
                {
                    "v": "THORSONS MBS",
                    "k": "337312",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "39",
                    "cnt_titles": "39"
                },
                {
                    "v": "Thorsons Pub {THORS}",
                    "k": "337467",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "35",
                    "cnt_titles": "35"
                },
                {
                    "v": "Times Books",
                    "k": "385",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "276",
                    "cnt_titles": "276"
                },
                {
                    "v": "TLS Books",
                    "k": "438947",
                    "t": "pip_id",
                    "f": "",
                    "c": "15",
                    "cnt_titles": "15"
                },
                {
                    "v": "TOLKIEN",
                    "k": "337313",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "TRADE",
                    "k": "337468",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "Trophy",
                    "k": "49483",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "TROPHY JR PAPE",
                    "k": "337314",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "27",
                    "cnt_titles": "27"
                },
                {
                    "v": "Trophy Pr {TROPY}",
                    "k": "1040",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "Tween (Tw)",
                    "k": "784",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "147",
                    "cnt_titles": "147"
                },
                {
                    "v": "Twilight {TWLGT}",
                    "k": "51629",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "TY - Adult Book",
                    "k": "337469",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "TYC JR HARDBAC",
                    "k": "337470",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "UK ADULT AUDIO",
                    "k": "337315",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "UK ADULT MM",
                    "k": "337316",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "31",
                    "cnt_titles": "31"
                },
                {
                    "v": "UK ADULT MM CD",
                    "k": "337471",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "UK ARTS &amp; CRAF",
                    "k": "337317",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "UK CHILD FICTN",
                    "k": "337319",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "18",
                    "cnt_titles": "18"
                },
                {
                    "v": "UK ENGLISH DIC",
                    "k": "337320",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "UK FICTION HC",
                    "k": "337321",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "15",
                    "cnt_titles": "15"
                },
                {
                    "v": "UK FICTION PB",
                    "k": "337322",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "11",
                    "cnt_titles": "11"
                },
                {
                    "v": "UK General Books",
                    "k": "337249",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "32",
                    "cnt_titles": "32"
                },
                {
                    "v": "UK GENERAL REF",
                    "k": "337323",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "36",
                    "cnt_titles": "36"
                },
                {
                    "v": "UK MM, CDN PRI",
                    "k": "337520",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "UK NARNIA",
                    "k": "337472",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "UK NON-FICT HC",
                    "k": "337325",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "UK NON-FICT PB",
                    "k": "337326",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "UK PICTURE BKS",
                    "k": "337252",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "15",
                    "cnt_titles": "15"
                },
                {
                    "v": "UK RELIGIS GEN",
                    "k": "337327",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Voyager",
                    "k": "59957",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Voyager AU",
                    "k": "792581",
                    "t": "pip_id",
                    "f": "",
                    "c": "12",
                    "cnt_titles": "12"
                },
                {
                    "v": "VOYAGER PB",
                    "k": "337474",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Walden Pond Press",
                    "k": "170171",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "154",
                    "cnt_titles": "154"
                },
                {
                    "v": "Wellness",
                    "k": "51630",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "William Collins",
                    "k": "362",
                    "t": "pip_id",
                    "f": "",
                    "c": "759",
                    "cnt_titles": "759"
                },
                {
                    "v": "William Morrow",
                    "k": "795",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "8723",
                    "cnt_titles": "8723"
                },
                {
                    "v": "William Morrow &amp; Co {MORLB}",
                    "k": "7793",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "13",
                    "cnt_titles": "13"
                },
                {
                    "v": "William Morrow &amp; Co {MROOT}",
                    "k": "4516",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Willow",
                    "k": "110",
                    "t": "pip_id",
                    "f": "",
                    "c": "21",
                    "cnt_titles": "21"
                },
                {
                    "v": "Witness",
                    "k": "337285",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Witness Impulse",
                    "k": "337286",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "62",
                    "cnt_titles": "62"
                },
                {
                    "v": "Working Title Press",
                    "k": "400485",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Working Title Press AU",
                    "k": "792603",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "WORLD ATLAS",
                    "k": "337475",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Youth Specialties {YOUSC}",
                    "k": "337476",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Zondervan",
                    "k": "405769",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                }
            ],
            "f": "moved",
            "c": 89307,
            "cnt_imprints": 297,
            "cnt_titles": 89307
        },
        {
            "v": "University of Toronto Press",
            "k": "University of Toronto Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Aevo UTP",
                    "k": "563631",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "34",
                    "cnt_titles": "34"
                },
                {
                    "v": "New Jewish Press",
                    "k": "567067",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "13",
                    "cnt_titles": "13"
                },
                {
                    "v": "Rotman-UTP Publishing",
                    "k": "607709",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "142",
                    "cnt_titles": "142"
                },
                {
                    "v": "University of Toronto Press",
                    "k": "607711",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "7837",
                    "cnt_titles": "7837"
                }
            ],
            "f": "moved finished",
            "c": 8026,
            "cnt_imprints": 4,
            "cnt_titles": 8026
        },
        {
            "v": "A24",
            "k": "A24",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "815707",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "A24",
                    "k": "780700",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "24",
                    "cnt_titles": "24"
                },
                {
                    "v": "MACK",
                    "k": "805430",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 26,
            "cnt_imprints": 3,
            "cnt_titles": 26
        },
        {
            "v": "University of Alaska Press",
            "k": "University of Alaska Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Alaska Donning Company",
                    "k": "569530",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Alaska Dryad Press",
                    "k": "570596",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Alaska Limestone Press",
                    "k": "564848",
                    "t": "pip_id",
                    "f": "",
                    "c": "15",
                    "cnt_titles": "15"
                },
                {
                    "v": "Alaska Native Knowledge Network",
                    "k": "569538",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Alaska Smithsonian Institute Arctic",
                    "k": "569517",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Alaska UA Museum",
                    "k": "569510",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Alaska Vanessa Press",
                    "k": "569512",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Anchorage Museum of History and Art",
                    "k": "569540",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Center for Cross Cultural Education",
                    "k": "569537",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Ember Press",
                    "k": "569527",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Ester Republic Press",
                    "k": "569519",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Health Nursing",
                    "k": "569515",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Kuskokwim Corp",
                    "k": "569529",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "OK Publishing",
                    "k": "570574",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Pandion Books",
                    "k": "443069",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "PHS Commissioned Officers Foundation",
                    "k": "569521",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Spirit Mountain Press",
                    "k": "569509",
                    "t": "pip_id",
                    "f": "",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "Todd Communications",
                    "k": "253093",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Trail Breaker Kennel",
                    "k": "569520",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "UA Foundation",
                    "k": "569539",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Univ of Alaska Pr",
                    "k": "43557",
                    "t": "pip_id",
                    "f": "",
                    "c": "10",
                    "cnt_titles": "10"
                },
                {
                    "v": "University of Alaska Press",
                    "k": "589560",
                    "t": "pip_id",
                    "f": "",
                    "c": "375",
                    "cnt_titles": "375"
                },
                {
                    "v": "Wolf Creek Books",
                    "k": "569518",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 436,
            "cnt_imprints": 23,
            "cnt_titles": 436
        },
        {
            "v": "Herald Press",
            "k": "Herald Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Herald Press",
                    "k": "418699",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "573",
                    "cnt_titles": "573"
                }
            ],
            "f": "moved finished",
            "c": 573,
            "cnt_imprints": 1,
            "cnt_titles": 573
        },
        {
            "v": "Shine Curriculum",
            "k": "Shine Curriculum",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Shine",
                    "k": "345495",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "6",
                    "cnt_titles": "6"
                }
            ],
            "f": "renamed moved finished",
            "c": 6,
            "cnt_imprints": 1,
            "cnt_titles": 6
        },
        {
            "v": "Denver Art Museum",
            "k": "Denver Art Museum",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Denver Art Museum",
                    "k": "126625",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "24",
                    "cnt_titles": "24"
                }
            ],
            "f": "moved finished",
            "c": 24,
            "cnt_imprints": 1,
            "cnt_titles": 24
        },
        {
            "v": "Crow Canyon Archaeological Center",
            "k": "Crow Canyon Archaeological Center",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Crow Canyon Archaeological Center",
                    "k": "25754",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "5",
                    "cnt_titles": "5"
                }
            ],
            "f": "finished",
            "c": 5,
            "cnt_imprints": 1,
            "cnt_titles": 5
        },
        {
            "v": "Conf of Latin Americanist Geographers",
            "k": "Conf of Latin Americanist Geographers",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Conf of Latin Americanist Geographers",
                    "k": "537608",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Colorado Historical Society",
            "k": "Colorado Historical Society",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Colorado Historical Society",
                    "k": "15205",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "10",
                    "cnt_titles": "10"
                }
            ],
            "f": "moved finished",
            "c": 10,
            "cnt_imprints": 1,
            "cnt_titles": 10
        },
        {
            "v": "CO State Univ Cooperative Extension",
            "k": "CO State Univ Cooperative Extension",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "CO State Univ Cooperative Extension",
                    "k": "67297",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "finished",
            "c": 2,
            "cnt_imprints": 1,
            "cnt_titles": 2
        },
        {
            "v": "Center for Sustainable Environments",
            "k": "Center for Sustainable Environments",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Center for Sustainable Environments",
                    "k": "137084",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Center for Mineral Resources",
            "k": "Center for Mineral Resources",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Center for Mineral Resources",
                    "k": "29634",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Ctr for Middle Eastern Studies UT-Austin",
            "k": "Ctr for Middle Eastern Studies UT-Austin",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Ctr for Middle Eastern Studies UT-Austin",
                    "k": "535979",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "48",
                    "cnt_titles": "48"
                }
            ],
            "f": "finished",
            "c": 48,
            "cnt_imprints": 1,
            "cnt_titles": 48
        },
        {
            "v": "University of Arizona Mexican American Studies and Research Center",
            "k": "University of Arizona Mexican American Studies and Research Center",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "University of Arizona",
                    "k": "539095",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Center for Literary Computing",
            "k": "Center for Literary Computing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Center for Literary Computing",
                    "k": "206658",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Center for Desert Archaeology",
            "k": "Center for Desert Archaeology",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Center for Desert Archaeology",
                    "k": "42701",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "finished",
            "c": 2,
            "cnt_imprints": 1,
            "cnt_titles": 2
        },
        {
            "v": "Boyhood, Inc. and IFC Productions",
            "k": "Boyhood, Inc. and IFC Productions",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Boyhood, Inc. and IFC Productions",
                    "k": "287475",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Boffo Books",
            "k": "Boffo Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Boffo Books",
                    "k": "139146",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "4",
                    "cnt_titles": "4"
                }
            ],
            "f": "finished",
            "c": 4,
            "cnt_imprints": 1,
            "cnt_titles": 4
        },
        {
            "v": "Bob Bullock Texas State History Museum",
            "k": "Bob Bullock Texas State History Museum",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Bob Bullock Texas State History Museum",
                    "k": "235997",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "finished",
            "c": 2,
            "cnt_imprints": 1,
            "cnt_titles": 2
        },
        {
            "v": "Blanton Museum of Art, University of Texas at Austin, University of Texas Press",
            "k": "Blanton Museum of Art, University of Texas at Austin, University of Texas Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "University of Texas Press",
                    "k": "254034",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "9",
                    "cnt_titles": "9"
                }
            ],
            "f": "moved finished",
            "c": 9,
            "cnt_imprints": 1,
            "cnt_titles": 9
        },
        {
            "v": "Bilby Research Center, NAU",
            "k": "Bilby Research Center, NAU",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Bilby Research Center, NAU",
                    "k": "79117",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Arizona State Museum",
            "k": "Arizona State Museum",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Arizona State Museum",
                    "k": "791360",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "48",
                    "cnt_titles": "48"
                }
            ],
            "f": "moved finished",
            "c": 48,
            "cnt_imprints": 1,
            "cnt_titles": 48
        },
        {
            "v": "Alberta Native Plant Council",
            "k": "Alberta Native Plant Council",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Alberta Native Plant Council",
                    "k": "590468",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Paul Holberton Publishing",
            "k": "Paul Holberton Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Ad Ilissvm",
                    "k": "454317",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "51",
                    "cnt_titles": "51"
                },
                {
                    "v": "Paul Holberton Publishing",
                    "k": "71899",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "327",
                    "cnt_titles": "327"
                }
            ],
            "f": "moved finished",
            "c": 378,
            "cnt_imprints": 2,
            "cnt_titles": 378
        },
        {
            "v": "University of Massachusetts Press",
            "k": "University of Massachusetts Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "University of Massachusetts Press",
                    "k": "481257",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1230",
                    "cnt_titles": "1230"
                }
            ],
            "f": "moved finished",
            "c": 1230,
            "cnt_imprints": 1,
            "cnt_titles": 1230
        },
        {
            "v": "Warring States Project",
            "k": "Warring States Project",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Warring States Project",
                    "k": "200979",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "8",
                    "cnt_titles": "8"
                }
            ],
            "f": "moved",
            "c": 8,
            "cnt_imprints": 1,
            "cnt_titles": 8
        },
        {
            "v": "West Virginia University Press",
            "k": "West Virginia University Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Fitness Info Tech,West Virginia University",
                    "k": "464270",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "18",
                    "cnt_titles": "18"
                },
                {
                    "v": "Storer College Books",
                    "k": "227226",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Vandalia Press",
                    "k": "22747",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "43",
                    "cnt_titles": "43"
                },
                {
                    "v": "West Virginia Classics",
                    "k": "132184",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "West Virginia University Press",
                    "k": "22746",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "332",
                    "cnt_titles": "332"
                }
            ],
            "f": "moved finished",
            "c": 399,
            "cnt_imprints": 5,
            "cnt_titles": 399
        },
        {
            "v": "Utah State University Press",
            "k": "Utah State University Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Utah State University Press",
                    "k": "6946",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "554",
                    "cnt_titles": "554"
                }
            ],
            "f": "moved finished",
            "c": 554,
            "cnt_imprints": 1,
            "cnt_titles": 554
        },
        {
            "v": "Utah History Atlas {UTHHA}",
            "k": "Utah History Atlas {UTHHA}",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Utah History Atlas {UTHHA}",
                    "k": "781648",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Utah State Div of Indian Affairs",
                    "k": "791374",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "moved",
            "c": 2,
            "cnt_imprints": 2,
            "cnt_titles": 2
        },
        {
            "v": "University Press of Colorado",
            "k": "University Press of Colorado",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "University Press of Colorado",
                    "k": "9766",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "665",
                    "cnt_titles": "665"
                }
            ],
            "f": "moved finished",
            "c": 665,
            "cnt_imprints": 1,
            "cnt_titles": 665
        },
        {
            "v": "Editors' Association of Canada",
            "k": "Editors' Association of Canada",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Editors Canada",
                    "k": "379127",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "finished",
            "c": 2,
            "cnt_imprints": 1,
            "cnt_titles": 2
        },
        {
            "v": "University Press of Mississippi",
            "k": "University Press of Mississippi",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Memphis Brooks Museum of Art",
                    "k": "361934",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Silkmont and Count/Louisiana Endowment for the Humanities",
                    "k": "361939",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "University Press of Mississippi",
                    "k": "16191",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "3399",
                    "cnt_titles": "3399"
                },
                {
                    "v": "University Press of Mississippi/Aquarius Press/Willow Books",
                    "k": "760929",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "University Press of Mississippi/Capitola Art Press",
                    "k": "361940",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "University Press of Mississippi/Center for French Colonial Studies",
                    "k": "361964",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "University Press of Mississippi/Craftsmen's Guild of Mississippi",
                    "k": "361953",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "University Press of Mississippi/Dusti Bonge Art Foundation",
                    "k": "667986",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "University Press of Mississippi/Edinburgh University Press",
                    "k": "361927",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "University Press of Mississippi/J.P.C., L.L.C.",
                    "k": "361966",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "University Press of Mississippi/Mississippi College",
                    "k": "361963",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "University Press of Mississippi/Mississippi Department of Archives and History",
                    "k": "574767",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "University Press of Mississippi/Mississippi Department of Environmental Quality",
                    "k": "361947",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "University Press of Mississippi/Mississippi Humanities Council",
                    "k": "361935",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "University Press of Mississippi/Mississippi Museum of Art",
                    "k": "165216",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "University Press of Mississippi/Mississippi State University Extension Service",
                    "k": "645662",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "University Press of Mississippi/New Stage Theatre",
                    "k": "361955",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "University Press of Mississippi/Ogden Museum of Southern Art",
                    "k": "361938",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "University Press of Mississippi/Ohr-O'Keefe Museum of Art",
                    "k": "361952",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "University Press of Mississippi/Robert Hinckley",
                    "k": "361945",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "University Press of Mississippi/Roseau Company LLC",
                    "k": "361965",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "University Press of Mississippi/Signal Books, Ltd.",
                    "k": "361949",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "University Press of Mississippi/Talking Fingers Publications",
                    "k": "361960",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "University Press of Mississippi/The Art Museum of the University of Memphis",
                    "k": "361958",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "University Press of Mississippi/The Association for Cultural Equity",
                    "k": "571093",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "University Press of Mississippi/The Dreyfus Health Foundation/The Rogosin Institute",
                    "k": "445998",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "University Press of Mississippi/The Elphinstone Institute",
                    "k": "157264",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "University Press of Mississippi/University of Southern Mississippi",
                    "k": "190866",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "University Press of Mississippi/Wildlife Mississippi",
                    "k": "361957",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "moved finished",
            "c": 3445,
            "cnt_imprints": 29,
            "cnt_titles": 3445
        },
        {
            "v": "University of New Mexico Press",
            "k": "University of New Mexico Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "High Desert Field Guides",
                    "k": "619184",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "High Road Books",
                    "k": "497996",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "25",
                    "cnt_titles": "25"
                },
                {
                    "v": "University of New Mexico Press",
                    "k": "13261",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1990",
                    "cnt_titles": "1990"
                }
            ],
            "f": "moved finished",
            "c": 2023,
            "cnt_imprints": 3,
            "cnt_titles": 2023
        },
        {
            "v": "University of New Mexico Press published in cooperation with the Clements Center for Southwest Studi",
            "k": "University of New Mexico Press published in cooperation with the Clements Center for Southwest Studi",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "University of New Mexico Press in cooperation with the Clements Center for Southwest Studies at Sout",
                    "k": "445640",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "La Alameda Press",
            "k": "La Alameda Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "La Alameda Press",
                    "k": "26419",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "22",
                    "cnt_titles": "22"
                }
            ],
            "f": "moved",
            "c": 22,
            "cnt_imprints": 1,
            "cnt_titles": 22
        },
        {
            "v": "University of Hawaii at Manoa",
            "k": "University of Hawaii at Manoa",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Social Process in Hawai'i",
                    "k": "791357",
                    "t": "pip_id",
                    "f": "",
                    "c": "9",
                    "cnt_titles": "9"
                },
                {
                    "v": "University of Hawaii at Manoa",
                    "k": "791358",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                }
            ],
            "f": "",
            "c": 14,
            "cnt_imprints": 2,
            "cnt_titles": 14
        },
        {
            "v": "National Foreign Langauge Resource Center",
            "k": "National Foreign Langauge Resource Center",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Natl Foreign Language Resource",
                    "k": "791348",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "36",
                    "cnt_titles": "36"
                }
            ],
            "f": "moved",
            "c": 36,
            "cnt_imprints": 1,
            "cnt_titles": 36
        },
        {
            "v": "Korea Development Inst",
            "k": "Korea Development Inst",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Korea Development Inst",
                    "k": "66394",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "5",
                    "cnt_titles": "5"
                }
            ],
            "f": "moved",
            "c": 5,
            "cnt_imprints": 1,
            "cnt_titles": 5
        },
        {
            "v": "Kolowalu Books",
            "k": "Kolowalu Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Kolowalu Books",
                    "k": "791353",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "5",
                    "cnt_titles": "5"
                }
            ],
            "f": "moved",
            "c": 5,
            "cnt_imprints": 1,
            "cnt_titles": 5
        },
        {
            "v": "Kauai Historical Society",
            "k": "Kauai Historical Society",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Kauai Historical Society",
                    "k": "439203",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "4",
                    "cnt_titles": "4"
                }
            ],
            "f": "moved",
            "c": 4,
            "cnt_imprints": 1,
            "cnt_titles": 4
        },
        {
            "v": "Hawaiin Historical Society",
            "k": "Hawaiin Historical Society",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Hawaiian Historical Society",
                    "k": "441456",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "6",
                    "cnt_titles": "6"
                }
            ],
            "f": "renamed moved finished",
            "c": 6,
            "cnt_imprints": 1,
            "cnt_titles": 6
        },
        {
            "v": "US_HI",
            "k": "US_HI",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "791349",
                    "t": "pip_id",
                    "f": "",
                    "c": "55",
                    "cnt_titles": "55"
                }
            ],
            "f": "",
            "c": 55,
            "cnt_imprints": 1,
            "cnt_titles": 55
        },
        {
            "v": "Island Press",
            "k": "Island Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Island Press",
                    "k": "791171",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "802",
                    "cnt_titles": "802"
                }
            ],
            "f": "finished",
            "c": 802,
            "cnt_imprints": 1,
            "cnt_titles": 802
        },
        {
            "v": "Island Pr",
            "k": "Island Pr",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Bobolink Foundation",
                    "k": "522948",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Commission for Environmental Cooperation",
                    "k": "194816",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Community Activators",
                    "k": "791167",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Danish Architectural Press",
                    "k": "173754",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Foundation for Deep Ecology",
                    "k": "35582",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "IAASTD",
                    "k": "175660",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Island Pr",
                    "k": "117620",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "NCA Regional Input Reports",
                    "k": "256901",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Shearwater",
                    "k": "68276",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "16",
                    "cnt_titles": "16"
                },
                {
                    "v": "US Green Building Council",
                    "k": "51619",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "World Conservation Union",
                    "k": "2355",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "28",
                    "cnt_titles": "28"
                }
            ],
            "f": "renamed moved",
            "c": 66,
            "cnt_imprints": 11,
            "cnt_titles": 66
        },
        {
            "v": "World Conservation Union",
            "k": "World Conservation Union",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "World Conservation Union",
                    "k": "791170",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Conservation International",
            "k": "Conservation International",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Conservation International",
                    "k": "791169",
                    "t": "pip_id",
                    "f": "",
                    "c": "46",
                    "cnt_titles": "46"
                }
            ],
            "f": "",
            "c": 46,
            "cnt_imprints": 1,
            "cnt_titles": 46
        },
        {
            "v": "Island Pr {ISLPR}",
            "k": "Island Pr {ISLPR}",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Island Pr {ISLPR}",
                    "k": "791166",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "UBC Press",
            "k": "UBC Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "On Campus",
                    "k": "532732",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "On Point Press",
                    "k": "293805",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "21",
                    "cnt_titles": "21"
                },
                {
                    "v": "Pacific Educational Press",
                    "k": "334293",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "26",
                    "cnt_titles": "26"
                },
                {
                    "v": "Purich Publishing",
                    "k": "317084",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "71",
                    "cnt_titles": "71"
                },
                {
                    "v": "UBC Press",
                    "k": "3131",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "2177",
                    "cnt_titles": "2177"
                }
            ],
            "f": "moved finished",
            "c": 2301,
            "cnt_imprints": 5,
            "cnt_titles": 2301
        },
        {
            "v": "Page Two Books, Inc.",
            "k": "Page Two Books, Inc.",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Page Two",
                    "k": "370696",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "166",
                    "cnt_titles": "166"
                },
                {
                    "v": "Page Two Books",
                    "k": "370873",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "32",
                    "cnt_titles": "32"
                }
            ],
            "f": "moved finished",
            "c": 198,
            "cnt_imprints": 2,
            "cnt_titles": 198
        },
        {
            "v": "Taffy Tales",
            "k": "Taffy Tales",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Taffy Tales",
                    "k": "785009",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "33",
                    "cnt_titles": "33"
                }
            ],
            "f": "finished",
            "c": 33,
            "cnt_imprints": 1,
            "cnt_titles": 33
        },
        {
            "v": "Baronel Books",
            "k": "Baronel Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Baronel Books",
                    "k": "785008",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "7",
                    "cnt_titles": "7"
                }
            ],
            "f": "finished",
            "c": 7,
            "cnt_imprints": 1,
            "cnt_titles": 7
        },
        {
            "v": "Gytha Press",
            "k": "Gytha Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "760932",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "6",
                    "cnt_titles": "6"
                }
            ],
            "f": "finished",
            "c": 6,
            "cnt_imprints": 1,
            "cnt_titles": 6
        },
        {
            "v": "Callisto Publishing",
            "k": "Callisto Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Callisto",
                    "k": "701080",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2159",
                    "cnt_titles": "2159"
                },
                {
                    "v": "Callisto Kids",
                    "k": "701079",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "812",
                    "cnt_titles": "812"
                },
                {
                    "v": "Callisto Teens",
                    "k": "704029",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "110",
                    "cnt_titles": "110"
                }
            ],
            "f": "finished",
            "c": 3081,
            "cnt_imprints": 3,
            "cnt_titles": 3081
        },
        {
            "v": "Tielmour Press",
            "k": "Tielmour Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Independent Cat",
                    "k": "782946",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Tielmour Pr",
                    "k": "802872",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Tielmour Press",
                    "k": "762271",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "10",
                    "cnt_titles": "10"
                }
            ],
            "f": "moved",
            "c": 17,
            "cnt_imprints": 3,
            "cnt_titles": 17
        },
        {
            "v": "Blue Star Press",
            "k": "Blue Star Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Blue Star Press",
                    "k": "367085",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "137",
                    "cnt_titles": "137"
                },
                {
                    "v": "Little Bigfoot",
                    "k": "785023",
                    "t": "pip_id",
                    "f": "",
                    "c": "167",
                    "cnt_titles": "167"
                },
                {
                    "v": "Paige Tate &amp; Co",
                    "k": "437390",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "125",
                    "cnt_titles": "125"
                },
                {
                    "v": "Sasquatch Books",
                    "k": "785026",
                    "t": "pip_id",
                    "f": "",
                    "c": "237",
                    "cnt_titles": "237"
                },
                {
                    "v": "Spruce Books",
                    "k": "785027",
                    "t": "pip_id",
                    "f": "",
                    "c": "26",
                    "cnt_titles": "26"
                }
            ],
            "f": "moved",
            "c": 692,
            "cnt_imprints": 5,
            "cnt_titles": 692
        },
        {
            "v": "Tor Publishing Group",
            "k": "Tor Publishing Group",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Aerie",
                    "k": "636992",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "22",
                    "cnt_titles": "22"
                },
                {
                    "v": "Bramble",
                    "k": "778607",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "81",
                    "cnt_titles": "81"
                },
                {
                    "v": "Forge Books",
                    "k": "632228",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "2242",
                    "cnt_titles": "2242"
                },
                {
                    "v": "Orb Books",
                    "k": "632367",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "152",
                    "cnt_titles": "152"
                },
                {
                    "v": "Starscape",
                    "k": "632237",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "222",
                    "cnt_titles": "222"
                },
                {
                    "v": "Tor Books",
                    "k": "632229",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4877",
                    "cnt_titles": "4877"
                },
                {
                    "v": "Tor Classics",
                    "k": "639015",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "49",
                    "cnt_titles": "49"
                },
                {
                    "v": "Tor Fantasy",
                    "k": "632230",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "648",
                    "cnt_titles": "648"
                },
                {
                    "v": "Tor Nightfire",
                    "k": "632239",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "120",
                    "cnt_titles": "120"
                },
                {
                    "v": "Tor Paranormal Romance",
                    "k": "647613",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "59",
                    "cnt_titles": "59"
                },
                {
                    "v": "Tor Science Fiction",
                    "k": "632455",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "466",
                    "cnt_titles": "466"
                },
                {
                    "v": "Tor Teen",
                    "k": "632240",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "341",
                    "cnt_titles": "341"
                },
                {
                    "v": "Tor/Forge",
                    "k": "647614",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "24",
                    "cnt_titles": "24"
                },
                {
                    "v": "Tor/Seven Seas",
                    "k": "640651",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Tordotcom",
                    "k": "632238",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "354",
                    "cnt_titles": "354"
                }
            ],
            "f": "moved finished",
            "c": 9662,
            "cnt_imprints": 15,
            "cnt_titles": 9662
        },
        {
            "v": "Pushkin Press",
            "k": "Pushkin Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "ONE / Pushkin",
                    "k": "425524",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Pushkin Children's Books",
                    "k": "425523",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "126",
                    "cnt_titles": "126"
                },
                {
                    "v": "Pushkin Collection",
                    "k": "425521",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "42",
                    "cnt_titles": "42"
                },
                {
                    "v": "Pushkin Press",
                    "k": "425520",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "207",
                    "cnt_titles": "207"
                },
                {
                    "v": "Pushkin Vertigo",
                    "k": "425522",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "68",
                    "cnt_titles": "68"
                }
            ],
            "f": "moved finished",
            "c": 446,
            "cnt_imprints": 5,
            "cnt_titles": 446
        },
        {
            "v": "Lexington Books",
            "k": "Lexington Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Fortress Academic",
                    "k": "710620",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "495",
                    "cnt_titles": "495"
                },
                {
                    "v": "Lexington Books",
                    "k": "660",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "12807",
                    "cnt_titles": "12807"
                },
                {
                    "v": "Lightning Source Inc",
                    "k": "780851",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Political Economic Forum",
                    "k": "778616",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "3",
                    "cnt_titles": "3"
                }
            ],
            "f": "moved",
            "c": 13306,
            "cnt_imprints": 4,
            "cnt_titles": 13306
        },
        {
            "v": "Princeton University Press",
            "k": "Princeton University Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Princeton University Art Museum",
                    "k": "780696",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "Princeton University Press",
                    "k": "161673",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "15268",
                    "cnt_titles": "15268"
                }
            ],
            "f": "moved finished",
            "c": 15274,
            "cnt_imprints": 2,
            "cnt_titles": 15274
        },
        {
            "v": "G&amp;D Media",
            "k": "G&D Media",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Burman/G&amp;D",
                    "k": "778520",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "G&amp;d Media",
                    "k": "415736",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "602",
                    "cnt_titles": "602"
                }
            ],
            "f": "finished",
            "c": 605,
            "cnt_imprints": 2,
            "cnt_titles": 605
        },
        {
            "v": "Enitharmon Press",
            "k": "Enitharmon Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Enitharmon Editions",
                    "k": "437893",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Enitharmon Press",
                    "k": "40056",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "10",
                    "cnt_titles": "10"
                }
            ],
            "f": "moved finished",
            "c": 11,
            "cnt_imprints": 2,
            "cnt_titles": 11
        },
        {
            "v": "Little, Brown Book Group (UK)",
            "k": "Little, Brown Book Group (UK)",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Abacus",
                    "k": "4253",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "523",
                    "cnt_titles": "523"
                },
                {
                    "v": "Atom",
                    "k": "56183",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "31",
                    "cnt_titles": "31"
                },
                {
                    "v": "Blackfriars",
                    "k": "710762",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Corsair",
                    "k": "323854",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "34",
                    "cnt_titles": "34"
                },
                {
                    "v": "Fleet",
                    "k": "571084",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "33",
                    "cnt_titles": "33"
                },
                {
                    "v": "Little Brown Uk",
                    "k": "323802",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "486",
                    "cnt_titles": "486"
                },
                {
                    "v": "Orbit UK",
                    "k": "710764",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "150",
                    "cnt_titles": "150"
                },
                {
                    "v": "Piatkus Constable Robinson",
                    "k": "323822",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "2304",
                    "cnt_titles": "2304"
                },
                {
                    "v": "Sphere",
                    "k": "201267",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "912",
                    "cnt_titles": "912"
                },
                {
                    "v": "The Bridge Street Press",
                    "k": "710743",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "Virago",
                    "k": "323855",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "454",
                    "cnt_titles": "454"
                }
            ],
            "f": "renamed moved finished",
            "c": 4935,
            "cnt_imprints": 11,
            "cnt_titles": 4935
        },
        {
            "v": "HOLTZBRINCK DBA MPS-TOR/M/M",
            "k": "HOLTZBRINCK DBA MPS-TOR/M/M",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "778605",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Forge {FORGE}",
            "k": "Forge {FORGE}",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Forge {FORGE}",
                    "k": "778603",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                }
            ],
            "f": "",
            "c": 5,
            "cnt_imprints": 1,
            "cnt_titles": 5
        },
        {
            "v": "Tor Books {TORBK}",
            "k": "Tor Books {TORBK}",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Tor Books {TORBK}",
                    "k": "778601",
                    "t": "pip_id",
                    "f": "",
                    "c": "8",
                    "cnt_titles": "8"
                }
            ],
            "f": "",
            "c": 8,
            "cnt_imprints": 1,
            "cnt_titles": 8
        },
        {
            "v": "Tor Books {TORBH}",
            "k": "Tor Books {TORBH}",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Tor Books {TORBH}",
                    "k": "778600",
                    "t": "pip_id",
                    "f": "",
                    "c": "7",
                    "cnt_titles": "7"
                }
            ],
            "f": "",
            "c": 7,
            "cnt_imprints": 1,
            "cnt_titles": 7
        },
        {
            "v": "TOR_B",
            "k": "TOR_B",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "778599",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                }
            ],
            "f": "",
            "c": 4,
            "cnt_imprints": 1,
            "cnt_titles": 4
        },
        {
            "v": "TOR BOOKS (HC) CONSIGNMENT",
            "k": "TOR BOOKS (HC) CONSIGNMENT",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "778598",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Forge {FORGE}",
                    "k": "778596",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "St Martins Pr {STMAR}",
                    "k": "81598",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Tor Books {TORBH}",
                    "k": "778597",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                }
            ],
            "f": "",
            "c": 12,
            "cnt_imprints": 4,
            "cnt_titles": 12
        },
        {
            "v": "Forge",
            "k": "Forge",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "778594",
                    "t": "pip_id",
                    "f": "",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "Forge",
                    "k": "778595",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 7,
            "cnt_imprints": 2,
            "cnt_titles": 7
        },
        {
            "v": "TOR BOOKS  (HC)",
            "k": "TOR BOOKS  (HC)",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "778592",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Forge {FORGE}",
                    "k": "778591",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Tor Books {TORBH}",
                    "k": "778590",
                    "t": "pip_id",
                    "f": "",
                    "c": "41",
                    "cnt_titles": "41"
                }
            ],
            "f": "",
            "c": 47,
            "cnt_imprints": 3,
            "cnt_titles": 47
        },
        {
            "v": "TOR BOOKS (HC)",
            "k": "TOR BOOKS (HC)",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "778589",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Forge {FORGE}",
                    "k": "778587",
                    "t": "pip_id",
                    "f": "",
                    "c": "14",
                    "cnt_titles": "14"
                },
                {
                    "v": "St Martins Pr {STMAR}",
                    "k": "778588",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Tor Books",
                    "k": "726769",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Tor Books {TORBH}",
                    "k": "778586",
                    "t": "pip_id",
                    "f": "",
                    "c": "64",
                    "cnt_titles": "64"
                }
            ],
            "f": "",
            "c": 83,
            "cnt_imprints": 5,
            "cnt_titles": 83
        },
        {
            "v": "TOR BOOKS (M/M)",
            "k": "TOR BOOKS (M/M)",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "778582",
                    "t": "pip_id",
                    "f": "",
                    "c": "70",
                    "cnt_titles": "70"
                },
                {
                    "v": "Forge {FORGE}",
                    "k": "778584",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Pinnacle Books {PINNA}",
                    "k": "6136",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Tom Doherty Assoc Llc {DOHET}",
                    "k": "778583",
                    "t": "pip_id",
                    "f": "",
                    "c": "12",
                    "cnt_titles": "12"
                },
                {
                    "v": "Tor Books {TORBH}",
                    "k": "778585",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Tor Books {TORBK}",
                    "k": "778581",
                    "t": "pip_id",
                    "f": "",
                    "c": "162",
                    "cnt_titles": "162"
                },
                {
                    "v": "TORB Tor Books",
                    "k": "653074",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 251,
            "cnt_imprints": 7,
            "cnt_titles": 251
        },
        {
            "v": "St. Martin's Press",
            "k": "St. Martin's Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "A Thomas Dunne Book for St. Martin's Griffin",
                    "k": "225461",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "89",
                    "cnt_titles": "89"
                },
                {
                    "v": "Academy Editions",
                    "k": "643324",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "All Points Books",
                    "k": "382086",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "21",
                    "cnt_titles": "21"
                },
                {
                    "v": "Encarta",
                    "k": "3611",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Golden Books Adult Publishing",
                    "k": "3396",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "76",
                    "cnt_titles": "76"
                },
                {
                    "v": "Golden Guides from St. Martin's Press",
                    "k": "3397",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "56",
                    "cnt_titles": "56"
                },
                {
                    "v": "L.A. Weekly Books",
                    "k": "3615",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "Minotaur",
                    "k": "310716",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "3139",
                    "cnt_titles": "3139"
                },
                {
                    "v": "National Lampoon Press",
                    "k": "151122",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "New York Times Books",
                    "k": "45975",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "Renaissance Books",
                    "k": "3638",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "37",
                    "cnt_titles": "37"
                },
                {
                    "v": "St. Martin's Castle Point",
                    "k": "315128",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "552",
                    "cnt_titles": "552"
                },
                {
                    "v": "St. Martin's Dead Letter",
                    "k": "3673",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "24",
                    "cnt_titles": "24"
                },
                {
                    "v": "St. Martin's Essentials",
                    "k": "399995",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "319",
                    "cnt_titles": "319"
                },
                {
                    "v": "St. Martin's Griffin",
                    "k": "3527",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "6807",
                    "cnt_titles": "6807"
                },
                {
                    "v": "St. Martin's Paperbacks",
                    "k": "3662",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "2757",
                    "cnt_titles": "2757"
                },
                {
                    "v": "St. Martin's Press",
                    "k": "3391",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4708",
                    "cnt_titles": "4708"
                },
                {
                    "v": "St. Martin's True Crime",
                    "k": "3668",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "119",
                    "cnt_titles": "119"
                },
                {
                    "v": "St. Martin's True Crime Classics",
                    "k": "3670",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "Stonewall Inn Editions",
                    "k": "3594",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "19",
                    "cnt_titles": "19"
                },
                {
                    "v": "Swerve",
                    "k": "341568",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "22",
                    "cnt_titles": "22"
                },
                {
                    "v": "Thomas Dunne Books",
                    "k": "3588",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "792",
                    "cnt_titles": "792"
                },
                {
                    "v": "Truman Talley Books",
                    "k": "3599",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "Wednesday Books",
                    "k": "336959",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "363",
                    "cnt_titles": "363"
                }
            ],
            "f": "moved",
            "c": 19933,
            "cnt_imprints": 24,
            "cnt_titles": 19933
        },
        {
            "v": "Bedford/St. Martin's",
            "k": "Bedford/St. Martin's",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Bedford Books",
                    "k": "315993",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1258",
                    "cnt_titles": "1258"
                },
                {
                    "v": "Bedford/St. Martin's",
                    "k": "3545",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "445",
                    "cnt_titles": "445"
                }
            ],
            "f": "moved finished",
            "c": 1703,
            "cnt_imprints": 2,
            "cnt_titles": 1703
        },
        {
            "v": "Entangled Publishing, LLC",
            "k": "Entangled Publishing, LLC",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "759744",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "11",
                    "cnt_titles": "11"
                },
                {
                    "v": "Entangled Pub",
                    "k": "402429",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "171",
                    "cnt_titles": "171"
                },
                {
                    "v": "Entangled: Amara",
                    "k": "397437",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "208",
                    "cnt_titles": "208"
                },
                {
                    "v": "Entangled: Bliss",
                    "k": "402432",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "Entangled: Brazen",
                    "k": "402431",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Entangled: Embrace",
                    "k": "397440",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "10",
                    "cnt_titles": "10"
                },
                {
                    "v": "Entangled: Ignite",
                    "k": "402433",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "Entangled: Indulgence",
                    "k": "402402",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "10",
                    "cnt_titles": "10"
                },
                {
                    "v": "Entangled: Little Lark",
                    "k": "677633",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Entangled: Lovestruck",
                    "k": "397433",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Entangled: Red Tower Books",
                    "k": "677632",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "42",
                    "cnt_titles": "42"
                },
                {
                    "v": "Entangled: Scandalous",
                    "k": "593706",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Entangled: Select",
                    "k": "397418",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "63",
                    "cnt_titles": "63"
                },
                {
                    "v": "Entangled: Teen",
                    "k": "397422",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "261",
                    "cnt_titles": "261"
                },
                {
                    "v": "Sideways Books",
                    "k": "411118",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4",
                    "cnt_titles": "4"
                }
            ],
            "f": "moved",
            "c": 799,
            "cnt_imprints": 15,
            "cnt_titles": 799
        },
        {
            "v": "Book*hug Press",
            "k": "Book*hug Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Book*hug Press",
                    "k": "713188",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "348",
                    "cnt_titles": "348"
                },
                {
                    "v": "Bookhug Pr",
                    "k": "749655",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "moved",
            "c": 349,
            "cnt_imprints": 2,
            "cnt_titles": 349
        },
        {
            "v": "Wanda Dionne",
            "k": "Wanda Dionne",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Anchorage Pr",
                    "k": "759445",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "3",
                    "cnt_titles": "3"
                }
            ],
            "f": "moved",
            "c": 3,
            "cnt_imprints": 1,
            "cnt_titles": 3
        },
        {
            "v": "Beta-Plus",
            "k": "Beta-Plus",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Beta-Plus",
                    "k": "432415",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "49",
                    "cnt_titles": "49"
                }
            ],
            "f": "moved finished",
            "c": 49,
            "cnt_imprints": 1,
            "cnt_titles": 49
        },
        {
            "v": "Seven Seas",
            "k": "Seven Seas",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Airship",
                    "k": "561376",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "464",
                    "cnt_titles": "464"
                },
                {
                    "v": "Ghost Ship",
                    "k": "561375",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "417",
                    "cnt_titles": "417"
                },
                {
                    "v": "Seven Seas",
                    "k": "72678",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "3635",
                    "cnt_titles": "3635"
                },
                {
                    "v": "Steamship",
                    "k": "777269",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "87",
                    "cnt_titles": "87"
                },
                {
                    "v": "Waves of Color",
                    "k": "358244",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "15",
                    "cnt_titles": "15"
                }
            ],
            "f": "moved finished",
            "c": 4618,
            "cnt_imprints": 5,
            "cnt_titles": 4618
        },
        {
            "v": "Square Fish",
            "k": "Square Fish",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Square Fish",
                    "k": "316021",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1967",
                    "cnt_titles": "1967"
                },
                {
                    "v": "St Martins Pr",
                    "k": "711124",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "moved",
            "c": 1968,
            "cnt_imprints": 2,
            "cnt_titles": 1968
        },
        {
            "v": "Henry Holt and Co.",
            "k": "Henry Holt and Co.",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Andy Cohen Books",
                    "k": "434155",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Cortina",
                    "k": "347046",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "Godwin Books",
                    "k": "670526",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "59",
                    "cnt_titles": "59"
                },
                {
                    "v": "Henry Holt and Co.",
                    "k": "347047",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1481",
                    "cnt_titles": "1481"
                },
                {
                    "v": "Holt Paperbacks",
                    "k": "347055",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "766",
                    "cnt_titles": "766"
                },
                {
                    "v": "Metropolitan Books",
                    "k": "347051",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "272",
                    "cnt_titles": "272"
                },
                {
                    "v": "Times Books",
                    "k": "347054",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "140",
                    "cnt_titles": "140"
                }
            ],
            "f": "moved finished",
            "c": 2730,
            "cnt_imprints": 7,
            "cnt_titles": 2730
        },
        {
            "v": "First Second",
            "k": "First Second",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "23rd St.",
                    "k": "791440",
                    "t": "pip_id",
                    "f": "",
                    "c": "13",
                    "cnt_titles": "13"
                },
                {
                    "v": "First Second",
                    "k": "128178",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "808",
                    "cnt_titles": "808"
                }
            ],
            "f": "moved",
            "c": 821,
            "cnt_imprints": 2,
            "cnt_titles": 821
        },
        {
            "v": "Feiwel &amp; Friends",
            "k": "Feiwel & Friends",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Feiwel &amp; Friends",
                    "k": "81595",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "960",
                    "cnt_titles": "960"
                },
                {
                    "v": "Swoon Reads",
                    "k": "213639",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "111",
                    "cnt_titles": "111"
                }
            ],
            "f": "moved finished",
            "c": 1071,
            "cnt_imprints": 2,
            "cnt_titles": 1071
        },
        {
            "v": "Farrar, Straus and Giroux",
            "k": "Farrar, Straus and Giroux",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "AUWA",
                    "k": "714754",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Farrar, Straus and Giroux",
                    "k": "309059",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "3750",
                    "cnt_titles": "3750"
                },
                {
                    "v": "FSG Originals",
                    "k": "309066",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "94",
                    "cnt_titles": "94"
                },
                {
                    "v": "Hill and Wang",
                    "k": "309062",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "152",
                    "cnt_titles": "152"
                },
                {
                    "v": "MCD",
                    "k": "330329",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "117",
                    "cnt_titles": "117"
                },
                {
                    "v": "MCD x FSG Originals",
                    "k": "381538",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "30",
                    "cnt_titles": "30"
                },
                {
                    "v": "Noonday",
                    "k": "4380",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "31",
                    "cnt_titles": "31"
                },
                {
                    "v": "North Point Press",
                    "k": "309063",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "139",
                    "cnt_titles": "139"
                },
                {
                    "v": "Sarah Crichton Books",
                    "k": "309067",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "88",
                    "cnt_titles": "88"
                },
                {
                    "v": "Scientific American / Farrar, Straus and Giroux",
                    "k": "309068",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "46",
                    "cnt_titles": "46"
                },
                {
                    "v": "Sunburst Books",
                    "k": "338431",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "11",
                    "cnt_titles": "11"
                }
            ],
            "f": "renamed moved finished",
            "c": 4463,
            "cnt_imprints": 11,
            "cnt_titles": 4463
        },
        {
            "v": "Picador",
            "k": "Picador",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Picador",
                    "k": "1908",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "2968",
                    "cnt_titles": "2968"
                }
            ],
            "f": "moved finished",
            "c": 2968,
            "cnt_imprints": 1,
            "cnt_titles": 2968
        },
        {
            "v": "MaTok",
            "k": "MaTok",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "MaTok",
                    "k": "437981",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "16",
                    "cnt_titles": "16"
                }
            ],
            "f": "moved finished",
            "c": 16,
            "cnt_imprints": 1,
            "cnt_titles": 16
        },
        {
            "v": "Wayne State University Press",
            "k": "Wayne State University Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Wayne State University",
                    "k": "777259",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "129",
                    "cnt_titles": "129"
                },
                {
                    "v": "Wayne State University Press",
                    "k": "777272",
                    "t": "pip_id",
                    "f": "",
                    "c": "1191",
                    "cnt_titles": "1191"
                }
            ],
            "f": "moved",
            "c": 1320,
            "cnt_imprints": 2,
            "cnt_titles": 1320
        },
        {
            "v": "Red Comet Press",
            "k": "Red Comet Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Red Comet Press",
                    "k": "534117",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "68",
                    "cnt_titles": "68"
                }
            ],
            "f": "finished",
            "c": 68,
            "cnt_imprints": 1,
            "cnt_titles": 68
        },
        {
            "v": "Amazon Publishing",
            "k": "Amazon Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "47North",
                    "k": "184224",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "527",
                    "cnt_titles": "527"
                },
                {
                    "v": "Amazon Crossing",
                    "k": "430233",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "658",
                    "cnt_titles": "658"
                },
                {
                    "v": "Amazon Crossing Kids",
                    "k": "426353",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "33",
                    "cnt_titles": "33"
                },
                {
                    "v": "Amazon Publishing",
                    "k": "45901",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "80",
                    "cnt_titles": "80"
                },
                {
                    "v": "AmazonClassics",
                    "k": "386029",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "10",
                    "cnt_titles": "10"
                },
                {
                    "v": "AmazonEncore",
                    "k": "244096",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "589",
                    "cnt_titles": "589"
                },
                {
                    "v": "Brilliance Audio",
                    "k": "336335",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Grand Harbor Press",
                    "k": "270305",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "20",
                    "cnt_titles": "20"
                },
                {
                    "v": "Jet City Comics",
                    "k": "209224",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "12",
                    "cnt_titles": "12"
                },
                {
                    "v": "Lake Union Publishing",
                    "k": "68668",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1073",
                    "cnt_titles": "1073"
                },
                {
                    "v": "Little A",
                    "k": "204929",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "300",
                    "cnt_titles": "300"
                },
                {
                    "v": "Mindy's Book Studio",
                    "k": "665695",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "20",
                    "cnt_titles": "20"
                },
                {
                    "v": "Montlake Romance",
                    "k": "184226",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1682",
                    "cnt_titles": "1682"
                },
                {
                    "v": "Powered by Amazon",
                    "k": "141394",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Skyscape",
                    "k": "184223",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "262",
                    "cnt_titles": "262"
                },
                {
                    "v": "Thomas &amp; Mercer",
                    "k": "137458",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1662",
                    "cnt_titles": "1662"
                },
                {
                    "v": "TOPPLE Books",
                    "k": "458908",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "14",
                    "cnt_titles": "14"
                },
                {
                    "v": "Two Lions",
                    "k": "199065",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "612",
                    "cnt_titles": "612"
                },
                {
                    "v": "Waterfall Press",
                    "k": "283624",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "68",
                    "cnt_titles": "68"
                },
                {
                    "v": "Westland Publication Limited",
                    "k": "367667",
                    "t": "pip_id",
                    "f": "",
                    "c": "6",
                    "cnt_titles": "6"
                }
            ],
            "f": "moved",
            "c": 7631,
            "cnt_imprints": 20,
            "cnt_titles": 7631
        },
        {
            "v": "Sacred Bones Books",
            "k": "Sacred Bones Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Sacred Bones Books",
                    "k": "765176",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "10",
                    "cnt_titles": "10"
                }
            ],
            "f": "finished",
            "c": 10,
            "cnt_imprints": 1,
            "cnt_titles": 10
        },
        {
            "v": "Radius Books",
            "k": "Radius Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Radius Books",
                    "k": "149824",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "180",
                    "cnt_titles": "180"
                }
            ],
            "f": "finished",
            "c": 180,
            "cnt_imprints": 1,
            "cnt_titles": 180
        },
        {
            "v": "Lanternfish Press",
            "k": "Lanternfish Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Lanternfish Press",
                    "k": "775018",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "39",
                    "cnt_titles": "39"
                }
            ],
            "f": "finished",
            "c": 39,
            "cnt_imprints": 1,
            "cnt_titles": 39
        },
        {
            "v": "Delphinium",
            "k": "Delphinium",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Delphinium",
                    "k": "467791",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "121",
                    "cnt_titles": "121"
                }
            ],
            "f": "moved finished",
            "c": 121,
            "cnt_imprints": 1,
            "cnt_titles": 121
        },
        {
            "v": "Quarto",
            "k": "Quarto",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "577769",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "Aurum",
                    "k": "705803",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "139",
                    "cnt_titles": "139"
                },
                {
                    "v": "becker&amp;mayer! Books",
                    "k": "705814",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "60",
                    "cnt_titles": "60"
                },
                {
                    "v": "becker&amp;mayer! kids",
                    "k": "705804",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "48",
                    "cnt_titles": "48"
                },
                {
                    "v": "Carnival",
                    "k": "764737",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "Chartwell Books",
                    "k": "705818",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1051",
                    "cnt_titles": "1051"
                },
                {
                    "v": "Cool Springs Press",
                    "k": "705820",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "424",
                    "cnt_titles": "424"
                },
                {
                    "v": "Design Eye",
                    "k": "760898",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "16",
                    "cnt_titles": "16"
                },
                {
                    "v": "Epic Ink",
                    "k": "705822",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "67",
                    "cnt_titles": "67"
                },
                {
                    "v": "Fair Winds Press",
                    "k": "705823",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "501",
                    "cnt_titles": "501"
                },
                {
                    "v": "Frances Lincoln",
                    "k": "705826",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "395",
                    "cnt_titles": "395"
                },
                {
                    "v": "Frances Lincoln Children's Books",
                    "k": "705825",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "599",
                    "cnt_titles": "599"
                },
                {
                    "v": "Happy Yak",
                    "k": "705827",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "192",
                    "cnt_titles": "192"
                },
                {
                    "v": "Harvard Common Press",
                    "k": "705828",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "433",
                    "cnt_titles": "433"
                },
                {
                    "v": "Ivy Kids",
                    "k": "705834",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "45",
                    "cnt_titles": "45"
                },
                {
                    "v": "Ivy Press",
                    "k": "705832",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "84",
                    "cnt_titles": "84"
                },
                {
                    "v": "Jacqui Small",
                    "k": "760900",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "49",
                    "cnt_titles": "49"
                },
                {
                    "v": "Kaddo",
                    "k": "705835",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "34",
                    "cnt_titles": "34"
                },
                {
                    "v": "Leaping Hare Press",
                    "k": "705833",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "125",
                    "cnt_titles": "125"
                },
                {
                    "v": "Motorbooks",
                    "k": "705836",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "703",
                    "cnt_titles": "703"
                },
                {
                    "v": "New Shoe Press",
                    "k": "718313",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "90",
                    "cnt_titles": "90"
                },
                {
                    "v": "Quarry Books",
                    "k": "705837",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "668",
                    "cnt_titles": "668"
                },
                {
                    "v": "Quiver",
                    "k": "705824",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "30",
                    "cnt_titles": "30"
                },
                {
                    "v": "Rock Point",
                    "k": "705821",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "296",
                    "cnt_titles": "296"
                },
                {
                    "v": "Rockport Publishers",
                    "k": "705839",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "260",
                    "cnt_titles": "260"
                },
                {
                    "v": "Voyageur Press",
                    "k": "705829",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "320",
                    "cnt_titles": "320"
                },
                {
                    "v": "Walter Foster Jr.",
                    "k": "705841",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "269",
                    "cnt_titles": "269"
                },
                {
                    "v": "Walter Foster Publishing",
                    "k": "705840",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "429",
                    "cnt_titles": "429"
                },
                {
                    "v": "Wellfleet Press",
                    "k": "705845",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "272",
                    "cnt_titles": "272"
                },
                {
                    "v": "White Lion Publishing",
                    "k": "705846",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "191",
                    "cnt_titles": "191"
                },
                {
                    "v": "Wide Eyed Editions",
                    "k": "705847",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "258",
                    "cnt_titles": "258"
                },
                {
                    "v": "words &amp; pictures",
                    "k": "705848",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "245",
                    "cnt_titles": "245"
                },
                {
                    "v": "Young Voyageur",
                    "k": "705831",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "20",
                    "cnt_titles": "20"
                }
            ],
            "f": "moved finished",
            "c": 8329,
            "cnt_imprints": 33,
            "cnt_titles": 8329
        },
        {
            "v": "Concord Theatricals",
            "k": "Concord Theatricals",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Concord Theatricals",
                    "k": "772288",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "15",
                    "cnt_titles": "15"
                },
                {
                    "v": "Samuel French Trade",
                    "k": "772287",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1767",
                    "cnt_titles": "1767"
                }
            ],
            "f": "moved finished",
            "c": 1782,
            "cnt_imprints": 2,
            "cnt_titles": 1782
        },
        {
            "v": "Bitmap Books",
            "k": "Bitmap Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Bitmap Books",
                    "k": "776196",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "8",
                    "cnt_titles": "8"
                }
            ],
            "f": "finished",
            "c": 8,
            "cnt_imprints": 1,
            "cnt_titles": 8
        },
        {
            "v": "Rowman &amp; Littlefield Publishers",
            "k": "Rowman & Littlefield Publishers",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Alban Inst",
                    "k": "51600",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Amadeus",
                    "k": "423762",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "167",
                    "cnt_titles": "167"
                },
                {
                    "v": "Applause",
                    "k": "780770",
                    "t": "pip_id",
                    "f": "",
                    "c": "323",
                    "cnt_titles": "323"
                },
                {
                    "v": "Applause Theatre and Cinema Books",
                    "k": "423756",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "104",
                    "cnt_titles": "104"
                },
                {
                    "v": "Association for Conflict Resolution",
                    "k": "723792",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "Backbeat",
                    "k": "780774",
                    "t": "pip_id",
                    "f": "",
                    "c": "294",
                    "cnt_titles": "294"
                },
                {
                    "v": "BACKBEAT BOOKS",
                    "k": "423757",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "249",
                    "cnt_titles": "249"
                },
                {
                    "v": "Center for Strategic &amp; International Studies",
                    "k": "262188",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "433",
                    "cnt_titles": "433"
                },
                {
                    "v": "Centre for European Policy Studies",
                    "k": "713243",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "49",
                    "cnt_titles": "49"
                },
                {
                    "v": "Cowley Publications",
                    "k": "164829",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "14",
                    "cnt_titles": "14"
                },
                {
                    "v": "Hamilton Books",
                    "k": "193977",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "697",
                    "cnt_titles": "697"
                },
                {
                    "v": "Humanities Press",
                    "k": "720474",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "12",
                    "cnt_titles": "12"
                },
                {
                    "v": "Jason Aronson",
                    "k": "359562",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "1305",
                    "cnt_titles": "1305"
                },
                {
                    "v": "Limelight",
                    "k": "780769",
                    "t": "pip_id",
                    "f": "",
                    "c": "59",
                    "cnt_titles": "59"
                },
                {
                    "v": "Limelight Editions",
                    "k": "423761",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "142",
                    "cnt_titles": "142"
                },
                {
                    "v": "Madison Books",
                    "k": "359529",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "134",
                    "cnt_titles": "134"
                },
                {
                    "v": "National Art Educators Association",
                    "k": "776251",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "National Association for Interpretation",
                    "k": "741234",
                    "t": "pip_id",
                    "f": "",
                    "c": "10",
                    "cnt_titles": "10"
                },
                {
                    "v": "Philosophical Library",
                    "k": "710522",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Playbill / Applause",
                    "k": "780772",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Policy Network",
                    "k": "713232",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "30",
                    "cnt_titles": "30"
                },
                {
                    "v": "Roberts Rinehart",
                    "k": "359564",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "161",
                    "cnt_titles": "161"
                },
                {
                    "v": "Rowman &amp; Littlefield",
                    "k": "127951",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "18624",
                    "cnt_titles": "18624"
                },
                {
                    "v": "Sheed &amp; Ward",
                    "k": "37946",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "401",
                    "cnt_titles": "401"
                },
                {
                    "v": "The Foundation for Critical Thinking",
                    "k": "710508",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "11",
                    "cnt_titles": "11"
                },
                {
                    "v": "United Synagogue of Conservati",
                    "k": "798673",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "University Press of America",
                    "k": "14079",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "558",
                    "cnt_titles": "558"
                },
                {
                    "v": "Urban Institute Press",
                    "k": "311308",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "moved",
            "c": 23803,
            "cnt_imprints": 28,
            "cnt_titles": 23803
        },
        {
            "v": "United Synagogue Of Conservative Judaism",
            "k": "United Synagogue Of Conservative Judaism",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "United Synagogue Of Conservative Judaism",
                    "k": "432495",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "42",
                    "cnt_titles": "42"
                }
            ],
            "f": "moved",
            "c": 42,
            "cnt_imprints": 1,
            "cnt_titles": 42
        },
        {
            "v": "Brookings Institution Press",
            "k": "Brookings Institution Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Brookings Inst",
                    "k": "402581",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Brookings Inst. Press/Carnegie Endowment",
                    "k": "546236",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Brookings Inst. Press/Chatham House",
                    "k": "398699",
                    "t": "pip_id",
                    "f": "",
                    "c": "12",
                    "cnt_titles": "12"
                },
                {
                    "v": "Brookings Inst. Press/Harvard JCHS",
                    "k": "546235",
                    "t": "pip_id",
                    "f": "",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "Brookings Inst. Press/World Peace Fdn.",
                    "k": "370866",
                    "t": "pip_id",
                    "f": "",
                    "c": "13",
                    "cnt_titles": "13"
                },
                {
                    "v": "Brookings Institution Press",
                    "k": "557",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1367",
                    "cnt_titles": "1367"
                },
                {
                    "v": "Brookings Institution Press &amp; MIT Press",
                    "k": "546227",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Brookings Institution Press and ADBI",
                    "k": "364250",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Brookings Institution Press and AEI",
                    "k": "370864",
                    "t": "pip_id",
                    "f": "",
                    "c": "15",
                    "cnt_titles": "15"
                },
                {
                    "v": "Brookings Institution Press/Ash Center",
                    "k": "519863",
                    "t": "pip_id",
                    "f": "",
                    "c": "15",
                    "cnt_titles": "15"
                },
                {
                    "v": "Brookings Institution Press/Nomura Inst.",
                    "k": "546234",
                    "t": "pip_id",
                    "f": "",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "Carnegie Endowment for Int'l Peace",
                    "k": "544342",
                    "t": "pip_id",
                    "f": "",
                    "c": "68",
                    "cnt_titles": "68"
                },
                {
                    "v": "Economica",
                    "k": "463448",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Institute of Latin American Studies",
                    "k": "520644",
                    "t": "pip_id",
                    "f": "",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "International Labor Office",
                    "k": "534080",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Shorenstein Asia-Pacific Research Center",
                    "k": "437810",
                    "t": "pip_id",
                    "f": "",
                    "c": "30",
                    "cnt_titles": "30"
                },
                {
                    "v": "United Nations University Press",
                    "k": "534013",
                    "t": "pip_id",
                    "f": "",
                    "c": "106",
                    "cnt_titles": "106"
                }
            ],
            "f": "moved",
            "c": 1662,
            "cnt_imprints": 17,
            "cnt_titles": 1662
        },
        {
            "v": "American Alliance Of Museums",
            "k": "American Alliance Of Museums",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "American Alliance Of Museums",
                    "k": "432677",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "116",
                    "cnt_titles": "116"
                }
            ],
            "f": "moved finished",
            "c": 116,
            "cnt_imprints": 1,
            "cnt_titles": 116
        },
        {
            "v": "Hal Leonard Publishing Corporation",
            "k": "Hal Leonard Publishing Corporation",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Alfred Pubn",
                    "k": "521975",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Amsco Music",
                    "k": "584018",
                    "t": "pip_id",
                    "f": "",
                    "c": "9",
                    "cnt_titles": "9"
                },
                {
                    "v": "Approaching Inc",
                    "k": "124690",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Berklee Pr",
                    "k": "204991",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Boosey &amp; Hawkes",
                    "k": "145243",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Boston Music",
                    "k": "781766",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Centerstream Pub",
                    "k": "404455",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Cherry Lane Music Co",
                    "k": "136976",
                    "t": "pip_id",
                    "f": "",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "Chester Music",
                    "k": "480575",
                    "t": "pip_id",
                    "f": "",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "Emi Cmg",
                    "k": "362822",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Faber Piano",
                    "k": "250446",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Gerard &amp; Sarzin Pub Co",
                    "k": "762409",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Hal Leonard Corp {LEONH}",
                    "k": "5029",
                    "t": "pip_id",
                    "f": "",
                    "c": "14",
                    "cnt_titles": "14"
                },
                {
                    "v": "Hal Leonard Pub Co",
                    "k": "4545",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "7194",
                    "cnt_titles": "7194"
                },
                {
                    "v": "Hitouch",
                    "k": "459110",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Jason Coleman Music",
                    "k": "588954",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Lorie Line",
                    "k": "175421",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Music Sales Amer {MSSAL}",
                    "k": "13228",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Music Sales Corp",
                    "k": "582309",
                    "t": "pip_id",
                    "f": "",
                    "c": "1030",
                    "cnt_titles": "1030"
                },
                {
                    "v": "Omnibus Pr",
                    "k": "205196",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Ossian",
                    "k": "24511",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Praxis Music Pubn",
                    "k": "490968",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Rock House",
                    "k": "404513",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Rubank Pubn",
                    "k": "360623",
                    "t": "pip_id",
                    "f": "",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "Schott Eamc",
                    "k": "419804",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Schott Japan",
                    "k": "198725",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Shawnee Pr Inc",
                    "k": "404624",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Time Line Prod",
                    "k": "143665",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Warner Brothers Pubn",
                    "k": "404310",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Wise Pubn",
                    "k": "630690",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Woods Music &amp; Books (ca)",
                    "k": "323982",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "moved",
            "c": 8311,
            "cnt_imprints": 31,
            "cnt_titles": 8311
        },
        {
            "v": "Madison House",
            "k": "Madison House",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Madison House",
                    "k": "777234",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Grove Atlantic",
            "k": "Grove Atlantic",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Atlantic Monthly Press",
                    "k": "252299",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "536",
                    "cnt_titles": "536"
                },
                {
                    "v": "Black Cat Books",
                    "k": "323820",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "159",
                    "cnt_titles": "159"
                },
                {
                    "v": "Grove Atlantic",
                    "k": "403871",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "29",
                    "cnt_titles": "29"
                },
                {
                    "v": "Grove Press",
                    "k": "11658",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "2699",
                    "cnt_titles": "2699"
                },
                {
                    "v": "Mysterious Press",
                    "k": "323821",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "215",
                    "cnt_titles": "215"
                },
                {
                    "v": "Roxane Gay Books",
                    "k": "777219",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "8",
                    "cnt_titles": "8"
                }
            ],
            "f": "renamed moved finished",
            "c": 3646,
            "cnt_imprints": 6,
            "cnt_titles": 3646
        },
        {
            "v": "Grove Press",
            "k": "Grove Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "380754",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Black Cat",
                    "k": "610308",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Black Cat/Outrider",
                    "k": "669376",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Canongate Us",
                    "k": "777233",
                    "t": "pip_id",
                    "f": "",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "Evergreen",
                    "k": "589574",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Read Books",
                    "k": "777220",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                }
            ],
            "f": "",
            "c": 22,
            "cnt_imprints": 6,
            "cnt_titles": 22
        },
        {
            "v": "MYSTERIOUS PRESS",
            "k": "MYSTERIOUS PRESS",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Ballantine Books {BALAT}",
                    "k": "49621",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Grand Central Pub {GDCTP}",
                    "k": "87508",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Grand Central Pub {GRDCT}",
                    "k": "87501",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Grand Central Publ",
                    "k": "725085",
                    "t": "pip_id",
                    "f": "",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "MYST                 Mysterious Press",
                    "k": "777230",
                    "t": "pip_id",
                    "f": "",
                    "c": "10",
                    "cnt_titles": "10"
                },
                {
                    "v": "MYST Mysterious Press",
                    "k": "777229",
                    "t": "pip_id",
                    "f": "",
                    "c": "29",
                    "cnt_titles": "29"
                },
                {
                    "v": "Mysterious Pr {MYSTE}",
                    "k": "777228",
                    "t": "pip_id",
                    "f": "",
                    "c": "92",
                    "cnt_titles": "92"
                },
                {
                    "v": "Mysterious Pr {MYSTI}",
                    "k": "639812",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "",
            "c": 143,
            "cnt_imprints": 8,
            "cnt_titles": 143
        },
        {
            "v": "Myst",
            "k": "Myst",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "328602",
                    "t": "pip_id",
                    "f": "",
                    "c": "13",
                    "cnt_titles": "13"
                },
                {
                    "v": "Mysterious Press",
                    "k": "777227",
                    "t": "pip_id",
                    "f": "",
                    "c": "44",
                    "cnt_titles": "44"
                }
            ],
            "f": "",
            "c": 57,
            "cnt_imprints": 2,
            "cnt_titles": 57
        },
        {
            "v": "Mysterious Pr {MYSTI}",
            "k": "Mysterious Pr {MYSTI}",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Mysterious Pr",
                    "k": "777226",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Mysterious Pr {MYSTI}",
                    "k": "777225",
                    "t": "pip_id",
                    "f": "",
                    "c": "19",
                    "cnt_titles": "19"
                }
            ],
            "f": "",
            "c": 20,
            "cnt_imprints": 2,
            "cnt_titles": 20
        },
        {
            "v": "Mysterious Pr {MYSTE}",
            "k": "Mysterious Pr {MYSTE}",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Mysterious Pr",
                    "k": "777224",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Mysterious Pr {MYSTE}",
                    "k": "777223",
                    "t": "pip_id",
                    "f": "",
                    "c": "34",
                    "cnt_titles": "34"
                },
                {
                    "v": "Warner Books",
                    "k": "17958",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 37,
            "cnt_imprints": 3,
            "cnt_titles": 37
        },
        {
            "v": "Open City Books",
            "k": "Open City Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Grove Press, Open City Books",
                    "k": "777222",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "16",
                    "cnt_titles": "16"
                }
            ],
            "f": "moved finished",
            "c": 16,
            "cnt_imprints": 1,
            "cnt_titles": 16
        },
        {
            "v": "Salty Days Media Company",
            "k": "Salty Days Media Company",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Salty Days Media Company",
                    "k": "772121",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Library Tales Publishing",
            "k": "Library Tales Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Lib Tales Pub",
                    "k": "776215",
                    "t": "pip_id",
                    "f": "",
                    "c": "11",
                    "cnt_titles": "11"
                },
                {
                    "v": "Library Tales Publishing",
                    "k": "769312",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "33",
                    "cnt_titles": "33"
                },
                {
                    "v": "Lightning Source Inc",
                    "k": "696419",
                    "t": "pip_id",
                    "f": "",
                    "c": "48",
                    "cnt_titles": "48"
                }
            ],
            "f": "",
            "c": 92,
            "cnt_imprints": 3,
            "cnt_titles": 92
        },
        {
            "v": "Dark Ink Publishing",
            "k": "Dark Ink Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Dark Ink Publishing",
                    "k": "771089",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "33",
                    "cnt_titles": "33"
                },
                {
                    "v": "Lightning Source Inc",
                    "k": "568300",
                    "t": "pip_id",
                    "f": "",
                    "c": "44",
                    "cnt_titles": "44"
                }
            ],
            "f": "moved",
            "c": 77,
            "cnt_imprints": 2,
            "cnt_titles": 77
        },
        {
            "v": "Wonderwell",
            "k": "Wonderwell",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "LifeTree",
                    "k": "535993",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "13",
                    "cnt_titles": "13"
                },
                {
                    "v": "Wonderwell",
                    "k": "532918",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "31",
                    "cnt_titles": "31"
                }
            ],
            "f": "moved finished",
            "c": 44,
            "cnt_imprints": 2,
            "cnt_titles": 44
        },
        {
            "v": "Fourth Chapter Books",
            "k": "Fourth Chapter Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "In Fact Books",
                    "k": "481928",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "3",
                    "cnt_titles": "3"
                }
            ],
            "f": "finished",
            "c": 3,
            "cnt_imprints": 1,
            "cnt_titles": 3
        },
        {
            "v": "Riddle Fence Publishing Inc",
            "k": "Riddle Fence Publishing Inc",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Riddle Fence",
                    "k": "755345",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "4",
                    "cnt_titles": "4"
                }
            ],
            "f": "finished",
            "c": 4,
            "cnt_imprints": 1,
            "cnt_titles": 4
        },
        {
            "v": "University Of Minnesota Press",
            "k": "University Of Minnesota Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Afton Historical Society Press",
                    "k": "484065",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Alphawood Exhibitions",
                    "k": "532467",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Big Picture Press",
                    "k": "617541",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "e-flux classics",
                    "k": "215539",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "eflux Architecture",
                    "k": "457416",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "Foundation for Minneapolis Parks",
                    "k": "179762",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Glessner House",
                    "k": "778555",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "International Arts and Artists",
                    "k": "189834",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "James Ford Bell Library",
                    "k": "351833",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Katherine E. Nash Gallery",
                    "k": "618335",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Kerlan Collection at the Univ of Minn",
                    "k": "432458",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Meson Press",
                    "k": "416071",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "13",
                    "cnt_titles": "13"
                },
                {
                    "v": "Minneapolis Institute Of Art",
                    "k": "71732",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "13",
                    "cnt_titles": "13"
                },
                {
                    "v": "Minnesota Landscape Arboretum",
                    "k": "351835",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Minnesota Marine Art Museum",
                    "k": "778556",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Minnesota Museum of American Art",
                    "k": "330616",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "National Museum of the American Indian",
                    "k": "365001",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Norwegian American Historical Assoc",
                    "k": "423802",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Smart Set",
                    "k": "517714",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Tweed Museum Of Art",
                    "k": "12664",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Univ Of Minnesota Press",
                    "k": "6081",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4205",
                    "cnt_titles": "4205"
                },
                {
                    "v": "Voyageurs Region Natl Park Assoc",
                    "k": "351832",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Weisman Art Museum",
                    "k": "12663",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "9",
                    "cnt_titles": "9"
                },
                {
                    "v": "Western Gallery &amp; Public Art Collection",
                    "k": "420071",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "ZKM Center for Media and Art",
                    "k": "442097",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "moved",
            "c": 4282,
            "cnt_imprints": 25,
            "cnt_titles": 4282
        },
        {
            "v": "University of Illinois Press",
            "k": "University of Illinois Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Krannert Art Museum",
                    "k": "776235",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Power Publications",
                    "k": "77113",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "University of Illinois Press",
                    "k": "2884",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4053",
                    "cnt_titles": "4053"
                }
            ],
            "f": "moved",
            "c": 4058,
            "cnt_imprints": 3,
            "cnt_titles": 4058
        },
        {
            "v": "PRI",
            "k": "PRI",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Princeton Univ Pr {UPRIN}",
                    "k": "773629",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "NYU Press",
            "k": "NYU Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Library of Arabic Literature",
                    "k": "773628",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "NYU Press",
                    "k": "4037",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "5700",
                    "cnt_titles": "5700"
                },
                {
                    "v": "Washington Mews Books/NYU Press",
                    "k": "805414",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                }
            ],
            "f": "moved",
            "c": 5708,
            "cnt_imprints": 3,
            "cnt_titles": 5708
        },
        {
            "v": "Paradise Editions",
            "k": "Paradise Editions",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Paradise Editions",
                    "k": "726691",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4",
                    "cnt_titles": "4"
                }
            ],
            "f": "moved finished",
            "c": 4,
            "cnt_imprints": 1,
            "cnt_titles": 4
        },
        {
            "v": "Merigold Independent",
            "k": "Merigold Independent",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Merigold Independent",
                    "k": "755341",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "5",
                    "cnt_titles": "5"
                }
            ],
            "f": "moved finished",
            "c": 5,
            "cnt_imprints": 1,
            "cnt_titles": 5
        },
        {
            "v": "Meekling Press",
            "k": "Meekling Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "773650",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Meekling Press",
                    "k": "490225",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "9",
                    "cnt_titles": "9"
                }
            ],
            "f": "",
            "c": 11,
            "cnt_imprints": 2,
            "cnt_titles": 11
        },
        {
            "v": "Masterthief Press",
            "k": "Masterthief Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Mastethief Press",
                    "k": "726637",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Malarkey Books",
            "k": "Malarkey Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Death of Print",
                    "k": "739870",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "Malarkey Books",
                    "k": "726690",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "31",
                    "cnt_titles": "31"
                }
            ],
            "f": "moved finished",
            "c": 38,
            "cnt_imprints": 2,
            "cnt_titles": 38
        },
        {
            "v": "Livingston Press",
            "k": "Livingston Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Livingston Press",
                    "k": "23913",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "65",
                    "cnt_titles": "65"
                }
            ],
            "f": "moved finished",
            "c": 65,
            "cnt_imprints": 1,
            "cnt_titles": 65
        },
        {
            "v": "Lithic Press",
            "k": "Lithic Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "779603",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "il verri edizioni",
                    "k": "779616",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Lithic Press",
                    "k": "215352",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "24",
                    "cnt_titles": "24"
                },
                {
                    "v": "LithicPress",
                    "k": "779601",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "moved",
            "c": 29,
            "cnt_imprints": 4,
            "cnt_titles": 29
        },
        {
            "v": "Lavender Ink / Dialogos",
            "k": "Lavender Ink / Dialogos",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Dialogos / Lavender Ink",
                    "k": "773624",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "9",
                    "cnt_titles": "9"
                }
            ],
            "f": "moved finished",
            "c": 9,
            "cnt_imprints": 1,
            "cnt_titles": 9
        },
        {
            "v": "Krupskaya",
            "k": "Krupskaya",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Krupskaya",
                    "k": "2715",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "finished",
            "c": 2,
            "cnt_imprints": 1,
            "cnt_titles": 2
        },
        {
            "v": "knife | fork | book",
            "k": "knife | fork | book",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "knife | fork | book",
                    "k": "726680",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "15",
                    "cnt_titles": "15"
                }
            ],
            "f": "moved",
            "c": 15,
            "cnt_imprints": 1,
            "cnt_titles": 15
        },
        {
            "v": "KERNPUNKT Press",
            "k": "KERNPUNKT Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "KERNPUNKT Press",
                    "k": "331066",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "34",
                    "cnt_titles": "34"
                }
            ],
            "f": "moved finished",
            "c": 34,
            "cnt_imprints": 1,
            "cnt_titles": 34
        },
        {
            "v": "June Road Press",
            "k": "June Road Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "June Road Press",
                    "k": "609206",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "8",
                    "cnt_titles": "8"
                }
            ],
            "f": "moved finished",
            "c": 8,
            "cnt_imprints": 1,
            "cnt_titles": 8
        },
        {
            "v": "Inside the Castle",
            "k": "Inside the Castle",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Inside the Castle",
                    "k": "726689",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "51",
                    "cnt_titles": "51"
                }
            ],
            "f": "moved finished",
            "c": 51,
            "cnt_imprints": 1,
            "cnt_titles": 51
        },
        {
            "v": "Insert Press",
            "k": "Insert Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Insert Press",
                    "k": "171579",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "31",
                    "cnt_titles": "31"
                }
            ],
            "f": "moved finished",
            "c": 31,
            "cnt_imprints": 1,
            "cnt_titles": 31
        },
        {
            "v": "House of Vlad Press",
            "k": "House of Vlad Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "House of Vlad Press",
                    "k": "726653",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "17",
                    "cnt_titles": "17"
                },
                {
                    "v": "House of Vlad Press/Cash 4 Gold Books",
                    "k": "739877",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "moved finished",
            "c": 18,
            "cnt_imprints": 2,
            "cnt_titles": 18
        },
        {
            "v": "Hanuman Editions",
            "k": "Hanuman Editions",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "726694",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "12",
                    "cnt_titles": "12"
                }
            ],
            "f": "finished",
            "c": 12,
            "cnt_imprints": 1,
            "cnt_titles": 12
        },
        {
            "v": "Green Linden Press",
            "k": "Green Linden Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "773639",
                    "t": "pip_id",
                    "f": "",
                    "c": "15",
                    "cnt_titles": "15"
                },
                {
                    "v": "Green Linden Press",
                    "k": "516672",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "11",
                    "cnt_titles": "11"
                }
            ],
            "f": "moved",
            "c": 26,
            "cnt_imprints": 2,
            "cnt_titles": 26
        },
        {
            "v": "Gaudy Boy",
            "k": "Gaudy Boy",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Bench Press",
                    "k": "773640",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Gaudy Boy",
                    "k": "770693",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "16",
                    "cnt_titles": "16"
                },
                {
                    "v": "Gaudy Boy Translates",
                    "k": "773641",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                }
            ],
            "f": "moved",
            "c": 21,
            "cnt_imprints": 3,
            "cnt_titles": 21
        },
        {
            "v": "Garden-Door Press",
            "k": "Garden-Door Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Lightning Source Inc",
                    "k": "716552",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Game Over Books",
            "k": "Game Over Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "776153",
                    "t": "pip_id",
                    "f": "",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "Game Over Books",
                    "k": "478245",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "40",
                    "cnt_titles": "40"
                }
            ],
            "f": "",
            "c": 46,
            "cnt_imprints": 2,
            "cnt_titles": 46
        },
        {
            "v": "Further Other Book Works",
            "k": "Further Other Book Works",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "770696",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Further Other Book Works",
                    "k": "215348",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "3",
                    "cnt_titles": "3"
                }
            ],
            "f": "finished",
            "c": 4,
            "cnt_imprints": 2,
            "cnt_titles": 4
        },
        {
            "v": "Fum d'Estampa Press",
            "k": "Fum d'Estampa Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Fum d'Estampa Press",
                    "k": "548106",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "26",
                    "cnt_titles": "26"
                }
            ],
            "f": "moved finished",
            "c": 26,
            "cnt_imprints": 1,
            "cnt_titles": 26
        },
        {
            "v": "Filthy Loot",
            "k": "Filthy Loot",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "773630",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Filthy Loot",
                    "k": "770682",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "26",
                    "cnt_titles": "26"
                },
                {
                    "v": "Filthy Loot / Talented Perverts",
                    "k": "770694",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "FL+TP",
                    "k": "773642",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "moved",
            "c": 31,
            "cnt_imprints": 4,
            "cnt_titles": 31
        },
        {
            "v": "Ex Ophidia Press",
            "k": "Ex Ophidia Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Ex Ophidia Press",
                    "k": "516670",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "8",
                    "cnt_titles": "8"
                }
            ],
            "f": "moved finished",
            "c": 8,
            "cnt_imprints": 1,
            "cnt_titles": 8
        },
        {
            "v": "Eulalia Books",
            "k": "Eulalia Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Eulalia Books",
                    "k": "449433",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "7",
                    "cnt_titles": "7"
                }
            ],
            "f": "finished",
            "c": 7,
            "cnt_imprints": 1,
            "cnt_titles": 7
        },
        {
            "v": "Essay Press",
            "k": "Essay Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "773657",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Essay Press",
                    "k": "159305",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "21",
                    "cnt_titles": "21"
                }
            ],
            "f": "moved",
            "c": 24,
            "cnt_imprints": 2,
            "cnt_titles": 24
        },
        {
            "v": "Equus Press",
            "k": "Equus Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Equus Press",
                    "k": "312925",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "24",
                    "cnt_titles": "24"
                },
                {
                    "v": "Independent Cat",
                    "k": "778997",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                }
            ],
            "f": "moved",
            "c": 27,
            "cnt_imprints": 2,
            "cnt_titles": 27
        },
        {
            "v": "Entre Rios Books",
            "k": "Entre Rios Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Entre Rios Books",
                    "k": "358152",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "moved finished",
            "c": 2,
            "cnt_imprints": 1,
            "cnt_titles": 2
        },
        {
            "v": "Ellipsis Press",
            "k": "Ellipsis Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "782498",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Ellipsis Press",
                    "k": "149438",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "14",
                    "cnt_titles": "14"
                }
            ],
            "f": "moved",
            "c": 15,
            "cnt_imprints": 2,
            "cnt_titles": 15
        },
        {
            "v": "Dulzorada Press",
            "k": "Dulzorada Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Dulzorada Press",
                    "k": "726636",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "18",
                    "cnt_titles": "18"
                }
            ],
            "f": "moved finished",
            "c": 18,
            "cnt_imprints": 1,
            "cnt_titles": 18
        },
        {
            "v": "Driftwood Press",
            "k": "Driftwood Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Lightning Source Inc",
                    "k": "397872",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "34",
                    "cnt_titles": "34"
                }
            ],
            "f": "finished",
            "c": 34,
            "cnt_imprints": 1,
            "cnt_titles": 34
        },
        {
            "v": "Delete Press",
            "k": "Delete Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "779602",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Delete Pr",
                    "k": "695255",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Delete Press",
                    "k": "331065",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "",
            "c": 6,
            "cnt_imprints": 3,
            "cnt_titles": 6
        },
        {
            "v": "Contra Mundum Press",
            "k": "Contra Mundum Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Contra Mundum Pr",
                    "k": "132075",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "76",
                    "cnt_titles": "76"
                }
            ],
            "f": "moved finished",
            "c": 76,
            "cnt_imprints": 1,
            "cnt_titles": 76
        },
        {
            "v": "Cloak",
            "k": "Cloak",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Cloak",
                    "k": "622618",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "14",
                    "cnt_titles": "14"
                }
            ],
            "f": "moved finished",
            "c": 14,
            "cnt_imprints": 1,
            "cnt_titles": 14
        },
        {
            "v": "Chatwin Books",
            "k": "Chatwin Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "726654",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "50",
                    "cnt_titles": "50"
                },
                {
                    "v": "Arundel Pr {ARUND}",
                    "k": "726641",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "3",
                    "cnt_titles": "3"
                }
            ],
            "f": "moved finished",
            "c": 53,
            "cnt_imprints": 2,
            "cnt_titles": 53
        },
        {
            "v": "Occidental Square Books",
            "k": "Occidental Square Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Lightning Source Inc",
                    "k": "417799",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Occidental Square",
                    "k": "773618",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "moved",
            "c": 2,
            "cnt_imprints": 2,
            "cnt_titles": 2
        },
        {
            "v": "Cortona Press",
            "k": "Cortona Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Cortona Press",
                    "k": "773617",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "moved",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Cardboard House Press",
            "k": "Cardboard House Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "773649",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Cardboard House Press",
                    "k": "317937",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "25",
                    "cnt_titles": "25"
                }
            ],
            "f": "",
            "c": 26,
            "cnt_imprints": 2,
            "cnt_titles": 26
        },
        {
            "v": "Canarium Books",
            "k": "Canarium Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Canarium Books",
                    "k": "183678",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "29",
                    "cnt_titles": "29"
                },
                {
                    "v": "Canarium Books {CNABK}",
                    "k": "773677",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 30,
            "cnt_imprints": 2,
            "cnt_titles": 30
        },
        {
            "v": "Rising Tide Productions",
            "k": "Rising Tide Productions",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Rising Tide Projects / Canarium Books",
                    "k": "773615",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "moved",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Calamari Archive",
            "k": "Calamari Archive",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "3rd bed",
                    "k": "726644",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Calamari Press",
                    "k": "726646",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "61",
                    "cnt_titles": "61"
                },
                {
                    "v": "Starcherone",
                    "k": "755339",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "15",
                    "cnt_titles": "15"
                }
            ],
            "f": "moved finished",
            "c": 77,
            "cnt_imprints": 3,
            "cnt_titles": 77
        },
        {
            "v": "Subito Press",
            "k": "Subito Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "TP",
                    "k": "697557",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "7",
                    "cnt_titles": "7"
                }
            ],
            "f": "moved",
            "c": 7,
            "cnt_imprints": 1,
            "cnt_titles": 7
        },
        {
            "v": "Burrow Press",
            "k": "Burrow Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Burrow Pr",
                    "k": "233689",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "37",
                    "cnt_titles": "37"
                }
            ],
            "f": "moved finished",
            "c": 37,
            "cnt_imprints": 1,
            "cnt_titles": 37
        },
        {
            "v": "Burnside Review Press",
            "k": "Burnside Review Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "779625",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Burnside Review",
                    "k": "779597",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Burnside Review Books",
                    "k": "779598",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Burnside Review Press",
                    "k": "309017",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "10",
                    "cnt_titles": "10"
                }
            ],
            "f": "",
            "c": 18,
            "cnt_imprints": 4,
            "cnt_titles": 18
        },
        {
            "v": "Bullshit Lit",
            "k": "Bullshit Lit",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "755350",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "HORNS",
                    "k": "779613",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 9,
            "cnt_imprints": 2,
            "cnt_titles": 9
        },
        {
            "v": "Boiler House Press",
            "k": "Boiler House Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Boiler House Press",
                    "k": "609209",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "21",
                    "cnt_titles": "21"
                },
                {
                    "v": "Lightning Source Inc",
                    "k": "553675",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "moved finished",
            "c": 22,
            "cnt_imprints": 2,
            "cnt_titles": 22
        },
        {
            "v": "BlazeVOX books",
            "k": "BlazeVOX books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "BlazeVOX books",
                    "k": "138739",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "10",
                    "cnt_titles": "10"
                }
            ],
            "f": "moved finished",
            "c": 10,
            "cnt_imprints": 1,
            "cnt_titles": 10
        },
        {
            "v": "Black Sun Lit",
            "k": "Black Sun Lit",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Black Sun Lit",
                    "k": "348100",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "18",
                    "cnt_titles": "18"
                }
            ],
            "f": "moved finished",
            "c": 18,
            "cnt_imprints": 1,
            "cnt_titles": 18
        },
        {
            "v": "Black Square Editions",
            "k": "Black Square Editions",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "776131",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "AntiSentiMental Society",
                    "k": "776135",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Black Square Editions",
                    "k": "69275",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "46",
                    "cnt_titles": "46"
                },
                {
                    "v": "Black Square Editions/Brooklyn Rail",
                    "k": "773596",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Off the Park Press",
                    "k": "776134",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "The Brooklyn Rail/Black Square",
                    "k": "776144",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "moved",
            "c": 58,
            "cnt_imprints": 6,
            "cnt_titles": 58
        },
        {
            "v": "Belle Point Press",
            "k": "Belle Point Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "726679",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Belle Point Pr",
                    "k": "785533",
                    "t": "pip_id",
                    "f": "",
                    "c": "10",
                    "cnt_titles": "10"
                },
                {
                    "v": "Belle Point Press",
                    "k": "779761",
                    "t": "pip_id",
                    "f": "",
                    "c": "28",
                    "cnt_titles": "28"
                }
            ],
            "f": "",
            "c": 39,
            "cnt_imprints": 3,
            "cnt_titles": 39
        },
        {
            "v": "Beautiful Days Press",
            "k": "Beautiful Days Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "764725",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "Urizen 2",
                    "k": "779622",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 7,
            "cnt_imprints": 2,
            "cnt_titles": 7
        },
        {
            "v": "Bard Books",
            "k": "Bard Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Bard Books",
                    "k": "764726",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Lightning Source Inc",
                    "k": "573245",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 3,
            "cnt_imprints": 2,
            "cnt_titles": 3
        },
        {
            "v": "Autofocus Books",
            "k": "Autofocus Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "807778",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Autofocus Books",
                    "k": "726677",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "20",
                    "cnt_titles": "20"
                }
            ],
            "f": "moved",
            "c": 21,
            "cnt_imprints": 2,
            "cnt_titles": 21
        },
        {
            "v": "Alternating Current Press",
            "k": "Alternating Current Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Alternating Current Press",
                    "k": "764718",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "37",
                    "cnt_titles": "37"
                }
            ],
            "f": "moved finished",
            "c": 37,
            "cnt_imprints": 1,
            "cnt_titles": 37
        },
        {
            "v": "Action Books",
            "k": "Action Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Action Books",
                    "k": "144617",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "76",
                    "cnt_titles": "76"
                }
            ],
            "f": "moved finished",
            "c": 76,
            "cnt_imprints": 1,
            "cnt_titles": 76
        },
        {
            "v": "11:11 Press",
            "k": "11:11 Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "726635",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "Independent Cat",
                    "k": "760652",
                    "t": "pip_id",
                    "f": "",
                    "c": "9",
                    "cnt_titles": "9"
                },
                {
                    "v": "Lightning Source Inc",
                    "k": "532185",
                    "t": "pip_id",
                    "f": "",
                    "c": "33",
                    "cnt_titles": "33"
                }
            ],
            "f": "moved",
            "c": 49,
            "cnt_imprints": 3,
            "cnt_titles": 49
        },
        {
            "v": "Fonograf Editions",
            "k": "Fonograf Editions",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "BUNNY",
                    "k": "776233",
                    "t": "pip_id",
                    "f": "",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "Fonograf Ed",
                    "k": "777747",
                    "t": "pip_id",
                    "f": "",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "Fonograf Editions",
                    "k": "486850",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "26",
                    "cnt_titles": "26"
                }
            ],
            "f": "",
            "c": 40,
            "cnt_imprints": 3,
            "cnt_titles": 40
        },
        {
            "v": "Milkweed Editions",
            "k": "Milkweed Editions",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Milkweed Editions",
                    "k": "19942",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "568",
                    "cnt_titles": "568"
                }
            ],
            "f": "renamed moved finished",
            "c": 568,
            "cnt_imprints": 1,
            "cnt_titles": 568
        },
        {
            "v": "Rescue Press",
            "k": "Rescue Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Rescue Press",
                    "k": "174048",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "46",
                    "cnt_titles": "46"
                }
            ],
            "f": "moved finished",
            "c": 46,
            "cnt_imprints": 1,
            "cnt_titles": 46
        },
        {
            "v": "Future Tense Books",
            "k": "Future Tense Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Future Tense Books",
                    "k": "180035",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "23",
                    "cnt_titles": "23"
                }
            ],
            "f": "finished",
            "c": 23,
            "cnt_imprints": 1,
            "cnt_titles": 23
        },
        {
            "v": "Rizzoli",
            "k": "Rizzoli",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Chelsea Green",
                    "k": "814258",
                    "t": "pip_id",
                    "f": "",
                    "c": "13",
                    "cnt_titles": "13"
                },
                {
                    "v": "Electa",
                    "k": "314593",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "280",
                    "cnt_titles": "280"
                },
                {
                    "v": "Gagosian / Rizzoli",
                    "k": "149369",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "121",
                    "cnt_titles": "121"
                },
                {
                    "v": "Mondadori",
                    "k": "314594",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "11",
                    "cnt_titles": "11"
                },
                {
                    "v": "PIE Books",
                    "k": "39384",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "PIE International",
                    "k": "160847",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Rizzoli",
                    "k": "14086",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "2028",
                    "cnt_titles": "2028"
                },
                {
                    "v": "Rizzoli Coedition",
                    "k": "197452",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Rizzoli Ex Libris",
                    "k": "154398",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "27",
                    "cnt_titles": "27"
                },
                {
                    "v": "Rizzoli Universe",
                    "k": "773593",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "905",
                    "cnt_titles": "905"
                }
            ],
            "f": "moved",
            "c": 3393,
            "cnt_imprints": 10,
            "cnt_titles": 3393
        },
        {
            "v": "Beaux Arts Editions",
            "k": "Beaux Arts Editions",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "208996",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Beaux Arts",
                    "k": "773595",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "moved",
            "c": 6,
            "cnt_imprints": 2,
            "cnt_titles": 6
        },
        {
            "v": "Universe Pub {UNVBK}",
            "k": "Universe Pub {UNVBK}",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Universe Pub {UNVBK}",
                    "k": "773594",
                    "t": "pip_id",
                    "f": "",
                    "c": "15",
                    "cnt_titles": "15"
                }
            ],
            "f": "",
            "c": 15,
            "cnt_imprints": 1,
            "cnt_titles": 15
        },
        {
            "v": "Chelsea Green Publishing",
            "k": "Chelsea Green Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Cal Earth Pr",
                    "k": "56433",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Chelsea Green",
                    "k": "21391",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "513",
                    "cnt_titles": "513"
                },
                {
                    "v": "Ecological Design Press",
                    "k": "800019",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Jenkins",
                    "k": "817156",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Slow Food Editore",
                    "k": "47403",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "renamed moved",
            "c": 517,
            "cnt_imprints": 5,
            "cnt_titles": 517
        },
        {
            "v": "Good Earth Publications",
            "k": "Good Earth Publications",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Good Earth Pubn",
                    "k": "773590",
                    "t": "pip_id",
                    "f": "",
                    "c": "6",
                    "cnt_titles": "6"
                }
            ],
            "f": "",
            "c": 6,
            "cnt_imprints": 1,
            "cnt_titles": 6
        },
        {
            "v": "Avalon House Pub.",
            "k": "Avalon House Pub.",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Seaworthy Pubn Inc",
                    "k": "773589",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Solar Survival Pr {SOLSU}",
            "k": "Solar Survival Pr {SOLSU}",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Chelsea Green Pub Co",
                    "k": "773566",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Solar Survival Pr {SOLSU}",
                    "k": "773567",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "",
            "c": 3,
            "cnt_imprints": 2,
            "cnt_titles": 3
        },
        {
            "v": "Green Books",
            "k": "Green Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "773564",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Green Books",
                    "k": "773565",
                    "t": "pip_id",
                    "f": "",
                    "c": "62",
                    "cnt_titles": "62"
                },
                {
                    "v": "Green Books Publ",
                    "k": "546141",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                }
            ],
            "f": "",
            "c": 68,
            "cnt_imprints": 3,
            "cnt_titles": 68
        },
        {
            "v": "Flower Press",
            "k": "Flower Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Flower Press",
                    "k": "773563",
                    "t": "pip_id",
                    "f": "",
                    "c": "13",
                    "cnt_titles": "13"
                },
                {
                    "v": "Flowerfield Enterprises",
                    "k": "583045",
                    "t": "pip_id",
                    "f": "",
                    "c": "8",
                    "cnt_titles": "8"
                }
            ],
            "f": "",
            "c": 21,
            "cnt_imprints": 2,
            "cnt_titles": 21
        },
        {
            "v": "Chelsea Green Publishing UK",
            "k": "Chelsea Green Publishing UK",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Chelsea Green Publishing UK",
                    "k": "704227",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "41",
                    "cnt_titles": "41"
                }
            ],
            "f": "finished",
            "c": 41,
            "cnt_imprints": 1,
            "cnt_titles": 41
        },
        {
            "v": "Hyperion",
            "k": "Hyperion",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Disney-Hyperion",
                    "k": "409812",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "ESPN Books",
                    "k": "687842",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Hachette Audio Pa",
                    "k": "772167",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Hyperion",
                    "k": "5849",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1170",
                    "cnt_titles": "1170"
                },
                {
                    "v": "Voice",
                    "k": "50481",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "21",
                    "cnt_titles": "21"
                }
            ],
            "f": "moved",
            "c": 1196,
            "cnt_imprints": 5,
            "cnt_titles": 1196
        },
        {
            "v": "Hachette Illustrated",
            "k": "Hachette Illustrated",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Hachette Illustrated",
                    "k": "65937",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "moved",
            "c": 2,
            "cnt_imprints": 1,
            "cnt_titles": 2
        },
        {
            "v": "Country Music Foundation",
            "k": "Country Music Foundation",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Country Music Hall of Fame",
                    "k": "231719",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Hal Leonard Pub Co",
                    "k": "246785",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "renamed moved",
            "c": 5,
            "cnt_imprints": 2,
            "cnt_titles": 5
        },
        {
            "v": "University Minnesota Design Institute",
            "k": "University Minnesota Design Institute",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Univ Minnesota Design Institute",
                    "k": "772135",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "moved",
            "c": 2,
            "cnt_imprints": 1,
            "cnt_titles": 2
        },
        {
            "v": "Green Lantern Press",
            "k": "Green Lantern Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Green Lantern Press",
                    "k": "211484",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "6",
                    "cnt_titles": "6"
                }
            ],
            "f": "moved",
            "c": 6,
            "cnt_imprints": 1,
            "cnt_titles": 6
        },
        {
            "v": "Pfeifer-Hamilton Pub",
            "k": "Pfeifer-Hamilton Pub",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "23027",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Min Pfeifer-Hamilton Pub",
                    "k": "772133",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "12",
                    "cnt_titles": "12"
                }
            ],
            "f": "moved",
            "c": 13,
            "cnt_imprints": 2,
            "cnt_titles": 13
        },
        {
            "v": "Lake View Press",
            "k": "Lake View Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Lakeview",
                    "k": "772132",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "Lightning Source Inc",
                    "k": "779478",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "moved",
            "c": 10,
            "cnt_imprints": 2,
            "cnt_titles": 10
        },
        {
            "v": "Krannert Art Museum",
            "k": "Krannert Art Museum",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Krannert Art Museum",
                    "k": "440331",
                    "t": "pip_id",
                    "f": "",
                    "c": "8",
                    "cnt_titles": "8"
                }
            ],
            "f": "",
            "c": 8,
            "cnt_imprints": 1,
            "cnt_titles": 8
        },
        {
            "v": "Lee &amp; Low Books",
            "k": "Lee & Low Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Children's Book Press",
                    "k": "338582",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "77",
                    "cnt_titles": "77"
                },
                {
                    "v": "Cinco Puntos Press",
                    "k": "575692",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "12",
                    "cnt_titles": "12"
                },
                {
                    "v": "Lee and Low Books",
                    "k": "338584",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "532",
                    "cnt_titles": "532"
                },
                {
                    "v": "Shen's Books",
                    "k": "161741",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "36",
                    "cnt_titles": "36"
                },
                {
                    "v": "Tu Books",
                    "k": "570528",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "32",
                    "cnt_titles": "32"
                }
            ],
            "f": "moved",
            "c": 689,
            "cnt_imprints": 5,
            "cnt_titles": 689
        },
        {
            "v": "Graphic Mundi",
            "k": "Graphic Mundi",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Graphic Mundi",
                    "k": "772118",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "39",
                    "cnt_titles": "39"
                }
            ],
            "f": "moved finished",
            "c": 39,
            "cnt_imprints": 1,
            "cnt_titles": 39
        },
        {
            "v": "Duke University Press",
            "k": "Duke University Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Duke University Press Books",
                    "k": "429088",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "8354",
                    "cnt_titles": "8354"
                },
                {
                    "v": "Scholars Pr",
                    "k": "773757",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "renamed moved",
            "c": 8356,
            "cnt_imprints": 2,
            "cnt_titles": 8356
        },
        {
            "v": "Duke Univ Pr {UDUKE}",
            "k": "Duke Univ Pr {UDUKE}",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Duke Univ Pr {UDUKE}",
                    "k": "772108",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                }
            ],
            "f": "",
            "c": 4,
            "cnt_imprints": 1,
            "cnt_titles": 4
        },
        {
            "v": "Duke Univ Pr {UDUKT}",
            "k": "Duke Univ Pr {UDUKT}",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Duke Univ Pr {UDUKT}",
                    "k": "772107",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "",
            "c": 2,
            "cnt_imprints": 1,
            "cnt_titles": 2
        },
        {
            "v": "Wharton School Press",
            "k": "Wharton School Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Wharton Digital Press",
                    "k": "772100",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "12",
                    "cnt_titles": "12"
                },
                {
                    "v": "Wharton School Press",
                    "k": "772098",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "61",
                    "cnt_titles": "61"
                }
            ],
            "f": "moved",
            "c": 73,
            "cnt_imprints": 2,
            "cnt_titles": 73
        },
        {
            "v": "Atria Books",
            "k": "Atria Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Atria Books",
                    "k": "741061",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "3992",
                    "cnt_titles": "3992"
                },
                {
                    "v": "Atria Books/Beyond Words",
                    "k": "741131",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "394",
                    "cnt_titles": "394"
                },
                {
                    "v": "Atria Books/Marble Arch Press",
                    "k": "741136",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "25",
                    "cnt_titles": "25"
                },
                {
                    "v": "Atria/Black Privilege Publishing",
                    "k": "741137",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "16",
                    "cnt_titles": "16"
                },
                {
                    "v": "Atria/Emily Bestler Books",
                    "k": "741130",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "452",
                    "cnt_titles": "452"
                },
                {
                    "v": "Atria/Emily Bestler Books/Alloy Entertainment",
                    "k": "741138",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "Atria/Enliven Books",
                    "k": "741133",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "40",
                    "cnt_titles": "40"
                },
                {
                    "v": "Atria/Keywords Press",
                    "k": "741134",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "28",
                    "cnt_titles": "28"
                },
                {
                    "v": "Atria/Leopoldo &amp; Co.",
                    "k": "741135",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "17",
                    "cnt_titles": "17"
                },
                {
                    "v": "Atria/One Signal Publishers",
                    "k": "741132",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "96",
                    "cnt_titles": "96"
                },
                {
                    "v": "Atria/Primero Sueno Press",
                    "k": "762287",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "52",
                    "cnt_titles": "52"
                },
                {
                    "v": "Howard Books",
                    "k": "762288",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "864",
                    "cnt_titles": "864"
                },
                {
                    "v": "Washington Square Press",
                    "k": "741147",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "177",
                    "cnt_titles": "177"
                }
            ],
            "f": "moved finished",
            "c": 6160,
            "cnt_imprints": 13,
            "cnt_titles": 6160
        },
        {
            "v": "1517 Media",
            "k": "1517 Media",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Augsburg Fortress",
                    "k": "688021",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "263",
                    "cnt_titles": "263"
                },
                {
                    "v": "Fortress Press",
                    "k": "687892",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "31",
                    "cnt_titles": "31"
                },
                {
                    "v": "Sparkhouse",
                    "k": "702838",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "3",
                    "cnt_titles": "3"
                }
            ],
            "f": "moved finished",
            "c": 297,
            "cnt_imprints": 3,
            "cnt_titles": 297
        },
        {
            "v": "1517 Publishing",
            "k": "1517 Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "1517 Academic",
                    "k": "695105",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "1517 Publishing",
                    "k": "685171",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "123",
                    "cnt_titles": "123"
                },
                {
                    "v": "Jagged Word Books",
                    "k": "686556",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Let My People Go",
                    "k": "685172",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Madgeburg Press",
                    "k": "695097",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "New Reformation Publications",
                    "k": "685170",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "42",
                    "cnt_titles": "42"
                }
            ],
            "f": "finished",
            "c": 174,
            "cnt_imprints": 6,
            "cnt_titles": 174
        },
        {
            "v": "Inspire Book",
            "k": "Inspire Book",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Inspire Books",
                    "k": "154378",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Inspire Us",
                    "k": "770698",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "4",
                    "cnt_titles": "4"
                }
            ],
            "f": "moved",
            "c": 7,
            "cnt_imprints": 2,
            "cnt_titles": 7
        },
        {
            "v": "Parallax Press",
            "k": "Parallax Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Palm Leaves Press",
                    "k": "311863",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "Parallax Press",
                    "k": "22922",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "261",
                    "cnt_titles": "261"
                },
                {
                    "v": "Plum Blossom",
                    "k": "43175",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "27",
                    "cnt_titles": "27"
                }
            ],
            "f": "moved finished",
            "c": 296,
            "cnt_imprints": 3,
            "cnt_titles": 296
        },
        {
            "v": "Europa Editions",
            "k": "Europa Editions",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Europa Compass",
                    "k": "422502",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "25",
                    "cnt_titles": "25"
                },
                {
                    "v": "Europa Editions",
                    "k": "72699",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "487",
                    "cnt_titles": "487"
                },
                {
                    "v": "The Passenger",
                    "k": "454097",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "19",
                    "cnt_titles": "19"
                },
                {
                    "v": "World Noir",
                    "k": "381475",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "39",
                    "cnt_titles": "39"
                }
            ],
            "f": "moved finished",
            "c": 570,
            "cnt_imprints": 4,
            "cnt_titles": 570
        },
        {
            "v": "BookBaby",
            "k": "BookBaby",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "101 Pieces",
                    "k": "772281",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "1127 Press",
                    "k": "532537",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "153 Media Group",
                    "k": "459888",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "17708 Publishing LLC",
                    "k": "494252",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "1st Edition by UMI",
                    "k": "672875",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "212 Degrees Of Motivational TV, INC.",
                    "k": "460021",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "26 Book Group",
                    "k": "658385",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "2AM Publications",
                    "k": "459901",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "3MCStudios",
                    "k": "701580",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "4 A STAR / BookBaby",
                    "k": "460124",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "8 Minute Education",
                    "k": "494253",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "9Points, LLC",
                    "k": "509211",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "A Baby Faye Book",
                    "k": "637077",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "A Guide for Life",
                    "k": "466733",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "A History of a Pedophile's Wife",
                    "k": "459927",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "A PART Book",
                    "k": "648908",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "A.S. Drayton Books LLC",
                    "k": "577818",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Aarin Cummings",
                    "k": "736498",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Aaron's Rainbow Project",
                    "k": "459916",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Abiding Network Publishing",
                    "k": "568621",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Abraham's Ink Publishing House",
                    "k": "728370",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "AbSeeS",
                    "k": "459911",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Accelerated Strategies Press",
                    "k": "562593",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Adakin Press",
                    "k": "633506",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "ADDRESS PUBLISHING HOUSE LTD",
                    "k": "535986",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "AeroSphere Research",
                    "k": "525707",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Aetatis Press",
                    "k": "459920",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Ahooga Publications",
                    "k": "685173",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Ajax Publishing",
                    "k": "460119",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "AL MADDIN",
                    "k": "693582",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Alani Publishing",
                    "k": "554417",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Albatross Publishing",
                    "k": "434304",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Alcohology",
                    "k": "459915",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Alec Millsap Book Publishing",
                    "k": "762361",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Alexander Technique Centre Ireland",
                    "k": "459895",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Alexon Books",
                    "k": "460127",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Alpine Mountain Books, LLC",
                    "k": "494242",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Alsut",
                    "k": "459986",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "ALVA Press Inc",
                    "k": "512791",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "American Fisheries Society - MT Chapter",
                    "k": "739951",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Amy Jean",
                    "k": "510591",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "An Skrifor",
                    "k": "647672",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Anastasis Books",
                    "k": "460011",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Ancient Juvenile Minds",
                    "k": "437272",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Angel Kiss Publishing",
                    "k": "460036",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Angier Publishing",
                    "k": "441547",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Animal Heart Press",
                    "k": "592520",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Anomolaic Press",
                    "k": "633500",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "ANR PUBLISHING",
                    "k": "498067",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Antarctica Arts",
                    "k": "581433",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Antastic Media",
                    "k": "459909",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Anterior Books",
                    "k": "459944",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Antrim House",
                    "k": "498069",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Antrim Press",
                    "k": "540759",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Apostolic Pentecostal Alliance Books LLC",
                    "k": "460032",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Apples-of-Gold Inc.",
                    "k": "439241",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "ARS Poetica (Anne Ross Studios)",
                    "k": "770713",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Artemisia Books",
                    "k": "487513",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Arthur Kurzweil Books",
                    "k": "443074",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Artisson Press",
                    "k": "460017",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Autonomous Publications",
                    "k": "454314",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Aviva Publishing NY",
                    "k": "488204",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Avuncular LLC",
                    "k": "454175",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Ayres &amp; McClain Publishing",
                    "k": "451765",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Aziri Books",
                    "k": "460121",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Aziza Kibibi McGill Ayinde",
                    "k": "459918",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "B &amp; E Books",
                    "k": "489623",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "B&amp;E Books",
                    "k": "742410",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "b6cr",
                    "k": "695111",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Baby Bosses LLC",
                    "k": "586943",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "BabyBook",
                    "k": "498062",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Barfooza! Books",
                    "k": "643488",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Barnwell Publishing",
                    "k": "532538",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Baumgardner Press",
                    "k": "459983",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Bayswater-Queensway Books",
                    "k": "592530",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "BB Global Ventures, LLC",
                    "k": "459976",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "BCE Publishing",
                    "k": "686561",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Beaumont Counseling Services",
                    "k": "459028",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "BeeZee Vision, LLC",
                    "k": "725081",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Beggar's Body Press",
                    "k": "434259",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Bella Esperanza Publishing, LLC",
                    "k": "486348",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Belpid Books",
                    "k": "459917",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Ben ODonnell",
                    "k": "460000",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Benimer House",
                    "k": "469962",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Best of Sedona Productions",
                    "k": "500500",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Better Call Doc Inc.",
                    "k": "457426",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Betula Press",
                    "k": "554407",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Between Lions Press",
                    "k": "459963",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Beverly Hills Publishing",
                    "k": "665726",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Beyondvia Technologies",
                    "k": "460028",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Bezalel Media",
                    "k": "660665",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Bianca Williams Books",
                    "k": "460020",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "BIG BUDDHA BOOKS AND MEDIA",
                    "k": "530924",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Big Red House Publishing",
                    "k": "662265",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Bill Powers &amp; Assoc. LLC",
                    "k": "459959",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Birdhouse Press",
                    "k": "460026",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Birds &amp; Bees &amp; Kids",
                    "k": "614350",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Biscuette Books",
                    "k": "449748",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Bizzy Beez Books",
                    "k": "566210",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "BL NK P ges Publications",
                    "k": "764788",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "BLACK ART SPEAKS",
                    "k": "633505",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Black Horse Press",
                    "k": "674040",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Black Ink Communications Inc.",
                    "k": "526363",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Black Ink Press",
                    "k": "526367",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "BlackBox",
                    "k": "757671",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Bleeding Neck LLC",
                    "k": "469976",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "BLR Productions Design Agency",
                    "k": "676293",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Blue Fish Point Publishing",
                    "k": "460139",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Blue Light Press",
                    "k": "762363",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Blue Tudor Books",
                    "k": "460081",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Blueberry Kale Yoga Books",
                    "k": "460018",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Book Baby",
                    "k": "460103",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Book Peacock",
                    "k": "460029",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "BookBaby",
                    "k": "53135",
                    "t": "pip_id",
                    "f": "",
                    "c": "17324",
                    "cnt_titles": "17324"
                },
                {
                    "v": "BookBaby &amp; WillPower Publishing",
                    "k": "709405",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "BookBaby-Medical Mechanics",
                    "k": "460116",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "BookBaby/Carmelita Publishing",
                    "k": "692158",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "BookBaby/Sandstone Publishing LLC",
                    "k": "566219",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "BookMart Publishing",
                    "k": "460128",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Bordista Press",
                    "k": "775011",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Bottom Turtle Press",
                    "k": "460070",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Brandimar",
                    "k": "500501",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Breachway Investments, LLC",
                    "k": "436116",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Breakwater Press",
                    "k": "722246",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Bret Kamrud",
                    "k": "459958",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Brian Hall",
                    "k": "462735",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "BRIDE Publishing",
                    "k": "459979",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Briggs &amp; Briggs Marketing Services, LLC",
                    "k": "460075",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Brinkworth Models",
                    "k": "477565",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Brio Multimedia",
                    "k": "487514",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Bruk Out Media",
                    "k": "522944",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Bubbleland Books",
                    "k": "459912",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Build Your Marriage",
                    "k": "460156",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Bungi Abrams Publishing",
                    "k": "460135",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Burkwood Media Group",
                    "k": "505025",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Buttercup Brown Publishing",
                    "k": "459906",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "C&amp;V 4 Seasons Publishing",
                    "k": "459957",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "C3 Excellence Publishing",
                    "k": "460082",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "CAAO",
                    "k": "610421",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Cabbage Palm Publishing",
                    "k": "577813",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Cabinart Books",
                    "k": "593400",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Captain Thomas Publishing",
                    "k": "440576",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Carl Reinhart",
                    "k": "433328",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Carpenter Create",
                    "k": "434302",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Carrot Ranch Literary Community",
                    "k": "460076",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Cassandra Mason",
                    "k": "479861",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "CATERPILLAR BUTTERFLY LLC",
                    "k": "613296",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "CatholicInternet.org",
                    "k": "460031",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Catymatt Productions",
                    "k": "453065",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "CB&amp;A Creations",
                    "k": "760961",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "CCSS Publications",
                    "k": "621944",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Cecilia Domeyko",
                    "k": "457415",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Celebrity Chef Hancock",
                    "k": "757672",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Celine Bundy-Kahn",
                    "k": "460003",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "CENTAUR",
                    "k": "569534",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Center for Cultural Competence, Inc.",
                    "k": "459931",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "CESA Press",
                    "k": "714830",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "ChaiLatte Press",
                    "k": "502656",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Chair-o-plane Press",
                    "k": "648916",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Charis Clarion Publishing",
                    "k": "644518",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Cherished Legacy Publishing",
                    "k": "459886",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Cheryl Matsen",
                    "k": "459955",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Cheshire House",
                    "k": "680169",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Chester Springs Publishing Company",
                    "k": "464284",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Chhandayan Inc.",
                    "k": "459883",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Chiaro LLC",
                    "k": "620914",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Chickens on the Moon Publishing",
                    "k": "647669",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Christ-life Fellowship",
                    "k": "614336",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Cinasphere",
                    "k": "459877",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Cincinnati Book Publishing",
                    "k": "440573",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Clarewood Press",
                    "k": "442950",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Cleve Laing",
                    "k": "459977",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Cloudbreak Publishing",
                    "k": "592519",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Cloudless LLC",
                    "k": "760962",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Cocoa Kids Books",
                    "k": "563683",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Cognize Consulting LLC",
                    "k": "460097",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Collaborative Technologies, LLC",
                    "k": "530928",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Colorado Plateau Publishers",
                    "k": "460148",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Commonwealth Council Publishing",
                    "k": "648917",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Confrontation Press of Long Island Univ",
                    "k": "459884",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Connected Thoughts Publishing",
                    "k": "487521",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Connections Press",
                    "k": "718336",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Conscious Capital Press",
                    "k": "692150",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Constant Hope Publishing",
                    "k": "633498",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Cottonwood Books",
                    "k": "460073",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Covenant House LLC",
                    "k": "454174",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Covenant House Publishing",
                    "k": "460083",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Covenant House Publishing LLC",
                    "k": "570961",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Crans Image House",
                    "k": "446544",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Creative Caboose",
                    "k": "460064",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Creative Capital Press",
                    "k": "710818",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Creative Force Fund",
                    "k": "510577",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Creative Group Publishing",
                    "k": "434285",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Creative Society Media, LLC",
                    "k": "502651",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Crest Publishing",
                    "k": "637936",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Crewest Studio LLC",
                    "k": "682742",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Crony Books",
                    "k": "474515",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Crooked Creek Publishing",
                    "k": "460095",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Cross Paw Publishing",
                    "k": "701572",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "CSJB books",
                    "k": "566212",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "CSLI Press",
                    "k": "484069",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "CTS Graphic Designs",
                    "k": "460078",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Cub Creek Press",
                    "k": "460143",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "cutethings",
                    "k": "459880",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Daffodil",
                    "k": "662252",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Dagaz Press",
                    "k": "535985",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Dapa Publishing LLC",
                    "k": "613288",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Dark Owl Fantasy",
                    "k": "572766",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "DCC Publishing",
                    "k": "459990",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Deathwave Records",
                    "k": "682744",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Deborah King Center",
                    "k": "498066",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Deborah King Publishing",
                    "k": "736496",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Debra Madonna",
                    "k": "570962",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Deck Night Press",
                    "k": "610430",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Deep Cute Publishing",
                    "k": "460002",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Demand Accelerated LLC",
                    "k": "540767",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Demand Accelerated, LLC",
                    "k": "539178",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Denny Smith Training &amp; Development",
                    "k": "459876",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "DeoSonic Press",
                    "k": "517716",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Descanso Music",
                    "k": "666910",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Desert Heart Multimedia",
                    "k": "558820",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "DesignWise Studios",
                    "k": "674056",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Diversity Crew Press",
                    "k": "666883",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Dollar-Strategies",
                    "k": "665448",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Donna Alice Evleth",
                    "k": "592529",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Doodle Publishing House",
                    "k": "672881",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Double Eagle Publishing LLC",
                    "k": "481284",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Dr. C Speaks, LLC",
                    "k": "442965",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Dream of the Woods Publishing",
                    "k": "581432",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "DUKEScomics",
                    "k": "671739",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "E.B.I.Q.",
                    "k": "460071",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Earful Tower Publishing",
                    "k": "474521",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Earth Afloat",
                    "k": "505031",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Eastern Lake Books",
                    "k": "677659",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Eburn Press",
                    "k": "460113",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Editorial Eye",
                    "k": "460130",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "EhpicPublishing",
                    "k": "540768",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Eight Point Press",
                    "k": "535994",
                    "t": "pip_id",
                    "f": "",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "Ekpen Media",
                    "k": "574869",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "El Bethel Publishing Group LLC",
                    "k": "720476",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "ELEUSIS PRESS",
                    "k": "442949",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Eleven Ninety Seven Inc.",
                    "k": "515807",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Ellis Michael Publishing",
                    "k": "459887",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Embodiment Celestial",
                    "k": "660669",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Emery Publishing",
                    "k": "459898",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "EmpaThee Lane LLC",
                    "k": "490294",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "EMR Productions",
                    "k": "535987",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Endicott Press",
                    "k": "570586",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "EOJ PUBLISHING",
                    "k": "524220",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "EP Publishing",
                    "k": "450252",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Epikoros Publishing",
                    "k": "535992",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Equality Press",
                    "k": "481285",
                    "t": "pip_id",
                    "f": "",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "Equistar Publishing",
                    "k": "459997",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Equitable Workplace Institute Publishing",
                    "k": "660660",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Essence of Love Imprint",
                    "k": "460024",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "ETA Publishing",
                    "k": "576919",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Everbold Press",
                    "k": "460159",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Everness, LLC",
                    "k": "692160",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Every Nation Resources",
                    "k": "716763",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Evolved Teacher Press",
                    "k": "501512",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Executive Outcomes Press",
                    "k": "459919",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Expect The Fresh, LLC",
                    "k": "460035",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Express Yourself Publishing",
                    "k": "695112",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Ezis Press",
                    "k": "460040",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Face Reader USA",
                    "k": "459934",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Faithful Friends Publishing",
                    "k": "554520",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "feliFRESH Books",
                    "k": "460122",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Fertileworks",
                    "k": "460052",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "FictionFire",
                    "k": "434194",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Find A Tree Publishing",
                    "k": "459896",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Fins Publishing",
                    "k": "434298",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Finstock &amp; Tew",
                    "k": "662253",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Firehouse Church",
                    "k": "460055",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "First Circle Press",
                    "k": "460047",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Fisher Creary House",
                    "k": "660682",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Five Wisdoms Press",
                    "k": "532534",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Flagrante Delicto Press",
                    "k": "436670",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Flint Michigan Rules The World",
                    "k": "433330",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "FLT Publishing",
                    "k": "483542",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Flying Geese",
                    "k": "459950",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Follow My Bliss Publishing",
                    "k": "498065",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Food Fight Studios",
                    "k": "666908",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Forrestville",
                    "k": "660664",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Francilia Films, LLC",
                    "k": "460085",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Fred &amp; Barney Press",
                    "k": "560102",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Freedom's Price Publishing",
                    "k": "510589",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "FreeSpirit Ink. Publishing",
                    "k": "510578",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Friends and Family Publishing",
                    "k": "461970",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "From the Heart Press",
                    "k": "760952",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Full Media Publishing",
                    "k": "606475",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Future Legs Press",
                    "k": "709400",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "FWGNA Project",
                    "k": "710802",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Garret Enterrises LLC",
                    "k": "459947",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Garret Mountain Books",
                    "k": "658380",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Garry L. Smith",
                    "k": "642340",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Gary Kirby",
                    "k": "460125",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Gemeza Books",
                    "k": "609298",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Gentle Joyous Industries",
                    "k": "458182",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Georgia Manufacturing Alliance",
                    "k": "554405",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Geotraveler Media",
                    "k": "460013",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Get Warrior Tough Media",
                    "k": "460120",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Give Good UX",
                    "k": "459970",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Glenmar",
                    "k": "751537",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Global Outreach Book Publishing",
                    "k": "460074",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Go For It Publishing",
                    "k": "481936",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Golden Acorn Press",
                    "k": "466732",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Golden Juno Press",
                    "k": "459991",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Golden Thread Press",
                    "k": "690664",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Good Fruit Publishing, LLC",
                    "k": "500502",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Gorilla Books",
                    "k": "459995",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Gowanus Press",
                    "k": "460126",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Grandpa's Press",
                    "k": "557374",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Great Leader Books",
                    "k": "460061",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Greater Good Gallery",
                    "k": "460050",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Green Horizon Press",
                    "k": "556111",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Greenridge",
                    "k": "441548",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Grokker Innovation Labs Press",
                    "k": "522953",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Gust of Wind Publishers",
                    "k": "459904",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Guy Bonnivier",
                    "k": "522952",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "H.R.M. Publishers",
                    "k": "456510",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "H2Growth Strategies Publishing",
                    "k": "442370",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Hagbah Publishing",
                    "k": "460099",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Hands Up Press",
                    "k": "643482",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Harmonic Learning, LLC",
                    "k": "587877",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Harvard House Publishing",
                    "k": "526365",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Hayley Hewitt",
                    "k": "642337",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Headstone Press",
                    "k": "620913",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Heightened &amp; Enlightened Publishing",
                    "k": "461974",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Helene Ferreira",
                    "k": "583982",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "HET HRU PUBLISHING LLC",
                    "k": "517715",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Hi-Fex",
                    "k": "488783",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Hicks &amp; Associates",
                    "k": "459940",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Hiddenite Publishing",
                    "k": "469974",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "High Command Publishing House",
                    "k": "606474",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Hope Publishing",
                    "k": "578939",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "HORROR.DIGITAL",
                    "k": "460025",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Horus House Press",
                    "k": "469975",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Hui O Kuapa",
                    "k": "650028",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Hungry Hungry Publishing",
                    "k": "459973",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "HyperFocus Media",
                    "k": "460065",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Hysterical Dementia",
                    "k": "642338",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "I keep 100% ownership of my book",
                    "k": "693590",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "I liked what they offered me.",
                    "k": "660670",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "I'm Here. I'm Queer. What The Hell Do I",
                    "k": "460112",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "IF6WAS9 Publishing",
                    "k": "459953",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "IITVS",
                    "k": "522943",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Illinois Caucus for Adolescent Health",
                    "k": "459962",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "illume Media / New Street Communications",
                    "k": "701579",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Images by KPG",
                    "k": "434291",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Imagilore Publishing",
                    "k": "532546",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Imaginal Light Publishing",
                    "k": "436671",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Independent Cat",
                    "k": "764908",
                    "t": "pip_id",
                    "f": "",
                    "c": "40",
                    "cnt_titles": "40"
                },
                {
                    "v": "Indian Rock Universal Inc.",
                    "k": "459891",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Indicia Press",
                    "k": "460037",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Infinity Ventures Inc.",
                    "k": "459902",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "INKLING Productions",
                    "k": "642339",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Inky Books",
                    "k": "540761",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "innovad",
                    "k": "484070",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Insight for Special Children, LLC",
                    "k": "459952",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Integrity Web Studios LLC",
                    "k": "460058",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Intro 2 Power",
                    "k": "620893",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "iOS Maui",
                    "k": "459975",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "IPO Institute",
                    "k": "548099",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Island Crow Publishing",
                    "k": "517717",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Israel Nelson",
                    "k": "672880",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Ivan P. Kovak",
                    "k": "459967",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "J.D. O'Hara",
                    "k": "459945",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Jabiru Books Belize LLC",
                    "k": "558831",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Jamii Publishing",
                    "k": "770712",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Jammin II Inc. &amp; BookBaby",
                    "k": "575849",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Jared Green",
                    "k": "436663",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Jay Kitchen Media, LLC",
                    "k": "585405",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "JC Publishers",
                    "k": "517720",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "JDelano Publishing",
                    "k": "482715",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Jean Publications, LLC",
                    "k": "490292",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Jean Shepard Publishing LLC",
                    "k": "510588",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Jeff R. Spalsbury",
                    "k": "459954",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Jeffrey Park Press",
                    "k": "557375",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Jesus Abundant Hope Publishing",
                    "k": "460063",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Jewish Metaphysics",
                    "k": "760960",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Jilley",
                    "k": "459897",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Jim Henson",
                    "k": "459987",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "JKS COVER TO COVER LLC",
                    "k": "637938",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Joan Jackson Kelly Freeman",
                    "k": "697670",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Joe Buff Inc.",
                    "k": "530934",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Joseph Casner",
                    "k": "460118",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Journey to a Trauma Informed Life LLC",
                    "k": "526374",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Jovon Joseph",
                    "k": "482714",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "JOY Just Own Yours",
                    "k": "720485",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "JSto Publishing",
                    "k": "580555",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Judgmental Owl Press",
                    "k": "574871",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Julia Kelly",
                    "k": "588848",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Junior Cam Publishing",
                    "k": "681248",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Justin Moroyan",
                    "k": "770716",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Kanvi House Publishing LLC",
                    "k": "438550",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Katherine Girsch",
                    "k": "526356",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Kathleen E. Suits-Smith",
                    "k": "459892",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "KATYAMUSIC",
                    "k": "460005",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "KC Publishing",
                    "k": "459890",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Keeter Real Estate Investing",
                    "k": "459907",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Kenneth Benelli",
                    "k": "642342",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Key Books, LLC",
                    "k": "442902",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Key to Biz Press",
                    "k": "460129",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "KH Publishers",
                    "k": "554576",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Kibera Press",
                    "k": "494247",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Kindness Media",
                    "k": "454324",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Kingdomaire Publishing",
                    "k": "460067",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Kingsley Books",
                    "k": "540756",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "KinurPub",
                    "k": "494251",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Kishimoto.Gordon.Dalaya PC",
                    "k": "460066",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "KLJ Publishing",
                    "k": "451178",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Kloudsville",
                    "k": "460104",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Know Better Publishing",
                    "k": "713261",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Koch-Tye Holdings, Inc.",
                    "k": "460092",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Kreadiv Agency",
                    "k": "637937",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Kross Roads Publishing, LLC",
                    "k": "637935",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "La Finca Films/Books",
                    "k": "442111",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Lambs Gap Press",
                    "k": "720483",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Landfish Media",
                    "k": "460053",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "LAVIESURLACOTE",
                    "k": "755382",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "LDA Press",
                    "k": "618407",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Leaders of a Beautiful Struggle",
                    "k": "460108",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Leah Michelle Hamilton Music Publishing",
                    "k": "459882",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Learning Energy",
                    "k": "454176",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Legacy Press Books",
                    "k": "648912",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Legal Easy Books",
                    "k": "460093",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Legal Master Series",
                    "k": "643479",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Let It Flow Publishing Company",
                    "k": "433315",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Letting Go Press",
                    "k": "437279",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "LHC Press",
                    "k": "652133",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Life in Progress",
                    "k": "685166",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Life's Pages, LLC",
                    "k": "562592",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Liger Press",
                    "k": "520731",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Lightbulb Creations",
                    "k": "459992",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Lion Press, New York",
                    "k": "610417",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Lioness",
                    "k": "772270",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Lioness Books",
                    "k": "437287",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Liquid Statue Productions",
                    "k": "459964",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Literary Forum Press",
                    "k": "590530",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Little Whoo Books",
                    "k": "669482",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "LivingTotality",
                    "k": "678894",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Liza Gino",
                    "k": "505032",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Lizard Head Publishing",
                    "k": "571605",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "LMR Publishing",
                    "k": "460007",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "LNJ Publishing",
                    "k": "436115",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Logo Express",
                    "k": "460049",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Lora Rosemon",
                    "k": "718333",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Lost Whale Press",
                    "k": "459027",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "LotusArt",
                    "k": "450254",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Loumark Press",
                    "k": "735670",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Love Ya, Mean It Press",
                    "k": "713260",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "LPNR",
                    "k": "460016",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Lua Library, LLC",
                    "k": "510592",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Lunar Maria LLC",
                    "k": "650026",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "M.R. Publishing",
                    "k": "624663",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "m3 American Publishing",
                    "k": "650029",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "m3 Global Scholar Book",
                    "k": "643480",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "M3llow_K",
                    "k": "620906",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Magis Media, LLC",
                    "k": "460027",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Mahoskiss Press",
                    "k": "459966",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Maisie Meadow Publishing",
                    "k": "772276",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Maison Bijon",
                    "k": "728380",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "MAJESTIC PUBLISHING GROUP, LLC",
                    "k": "460090",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "MaJor News Publishing",
                    "k": "509214",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "MAKB Publishers",
                    "k": "610418",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Manam Publishing",
                    "k": "578940",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Mandate.World",
                    "k": "606481",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Maplewood Productions LLC",
                    "k": "460106",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Marblestone Publishing",
                    "k": "471745",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "MarcJan Press",
                    "k": "682743",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Marlin Goode",
                    "k": "633507",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Max Greiner, Jr. Designs",
                    "k": "487520",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "McGuckin Group LLC",
                    "k": "751242",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "MCOI Publishing LLC",
                    "k": "474520",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "MCS Publications",
                    "k": "491237",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "MeadowPress",
                    "k": "460109",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "megansdesk.net",
                    "k": "469973",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Meier Publishing",
                    "k": "690679",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Melissa T Walker LLC",
                    "k": "554408",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Memoir International, LLC",
                    "k": "726764",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Mercer &amp; Loeb Publishing House",
                    "k": "617544",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Michael Hendricks",
                    "k": "607878",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Michelle Lagos",
                    "k": "459981",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "MiddayPress",
                    "k": "660667",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Middle Market Press",
                    "k": "566211",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Mind Cali Publishing",
                    "k": "741226",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "MIND-GRIND PUBLISHING",
                    "k": "677653",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "MindFlower",
                    "k": "460132",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Mindfully Move Press",
                    "k": "460089",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Mini Muffin Books",
                    "k": "764787",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Mobius Pathways Press",
                    "k": "505026",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Modern Blues Harmonica",
                    "k": "459972",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Modern Knight Publishing",
                    "k": "461973",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Modern Memoirs, Inc.",
                    "k": "459026",
                    "t": "pip_id",
                    "f": "",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "Mojave River Press",
                    "k": "434303",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "MOME Media Inc.",
                    "k": "554403",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "MOME Publishing Inc.",
                    "k": "454325",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Moments+Peace",
                    "k": "510576",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Moon Finder",
                    "k": "493342",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Moon Finder Press",
                    "k": "539174",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Morgan's Magical Media",
                    "k": "770717",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Mount Tallac Publishing",
                    "k": "460069",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Mountain Messenger Press",
                    "k": "692159",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Mountain Muse Studio, Provo, Utah",
                    "k": "585402",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "MPULSE LLC",
                    "k": "751538",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Mustang International",
                    "k": "460141",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "MVP Elite Coaching LLC",
                    "k": "498068",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "My Humboldt Diary LLC",
                    "k": "459939",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "N.A.M.E. Brand Records",
                    "k": "579110",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "N.A.T.D. Media LLC",
                    "k": "459913",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Nacirfa LaYai",
                    "k": "437280",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Nakasero Hill Press",
                    "k": "460134",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Naked Truth Publishing",
                    "k": "583988",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "National League of American Pen Women",
                    "k": "557389",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "NBG Print Publish LLC",
                    "k": "657300",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Nerdzilla Media",
                    "k": "434305",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Neuro-Navigation",
                    "k": "773720",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "New Era Music Ent LLC",
                    "k": "577819",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Nicky Gurret",
                    "k": "468429",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "NLITX",
                    "k": "469977",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "No-Udder Productions",
                    "k": "460102",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Nocturnes and Requiems",
                    "k": "569525",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Nor-Mar Press",
                    "k": "755381",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "North Edge Arts",
                    "k": "571597",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Northern Beaver Ink Inc.",
                    "k": "459925",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Northern Soul Publishing",
                    "k": "776265",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Notology Publishing",
                    "k": "460012",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "NUBLE+ARCH",
                    "k": "493336",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "NYC Bodene",
                    "k": "509216",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Obsidian Media Publications",
                    "k": "604826",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Occidental Photography",
                    "k": "714831",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Off Book Publishing",
                    "k": "584135",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "OhBoyBooks",
                    "k": "462737",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Ola Abina",
                    "k": "459889",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Old Logging Publishing",
                    "k": "741243",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Olivarez Media, Inc.",
                    "k": "460041",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "ONE of Kind Media",
                    "k": "726763",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "One One 7 Publishing",
                    "k": "459923",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "OneCorp llc.",
                    "k": "460030",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Opus One Studios",
                    "k": "593405",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Orange River Books",
                    "k": "459878",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Other Worldly Women Press",
                    "k": "623665",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "P &amp; B Publishers",
                    "k": "460046",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Pacific Press International",
                    "k": "604592",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Paddleford Publishing Company",
                    "k": "554568",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Pan-African Press LLC",
                    "k": "583981",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Panacea Press",
                    "k": "440568",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Pandosia Entertainment",
                    "k": "440560",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Pantry Music",
                    "k": "449749",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Patricia Johnson",
                    "k": "460045",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Pay the Creator",
                    "k": "459946",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "PBP Publishing",
                    "k": "459994",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Peace Publications",
                    "k": "460086",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Peaceful Dragon Press",
                    "k": "502664",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Peaceful Possibilities Press",
                    "k": "460068",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Peninsula Family Acupuncture",
                    "k": "459978",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Pensando Press LLC",
                    "k": "460094",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "People Can Change",
                    "k": "459910",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Performance Research Press",
                    "k": "554402",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Petals &amp; Pages Publishing",
                    "k": "757670",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Peter Duysings",
                    "k": "439239",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Peter E. Randall Publisher",
                    "k": "607882",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "PHFD Global Solutions Publishing",
                    "k": "660668",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Philly Flaneur Publishing",
                    "k": "718334",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Photographic Gallery of Fine Art Books",
                    "k": "460054",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Phyllis Publications",
                    "k": "459935",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Pierucci Publishing",
                    "k": "672879",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Pigeon Face Publishing",
                    "k": "460051",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Pine Meadow Partners, LLC",
                    "k": "440575",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Pinewood Press",
                    "k": "576910",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Plant-Based Diets of Medicine",
                    "k": "522942",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Plum Branch Press",
                    "k": "620904",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Poetic Productions",
                    "k": "584139",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Polyglotkidz Press",
                    "k": "459989",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "POWERPEOPLES LLC",
                    "k": "554572",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "PP Savage",
                    "k": "637939",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Prairial Press",
                    "k": "554410",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Prestige Publishing",
                    "k": "615382",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Primula Print",
                    "k": "494246",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Profiling Evil",
                    "k": "532540",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Projectivity, LLC",
                    "k": "510584",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Pruitt Motivational",
                    "k": "520728",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Psalms Group Studios",
                    "k": "494245",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Publerati",
                    "k": "526364",
                    "t": "pip_id",
                    "f": "",
                    "c": "10",
                    "cnt_titles": "10"
                },
                {
                    "v": "Puddy Tat LLC",
                    "k": "460157",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Pulmonary Wellness",
                    "k": "460039",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Pumpkin's Place",
                    "k": "554409",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Pure Vanity Presse",
                    "k": "459922",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Purple Fox Entertainment",
                    "k": "648918",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Quantum-Touch",
                    "k": "569526",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Quiet Creek Publishing",
                    "k": "620905",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Quill of Dreams Publishing",
                    "k": "494244",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "R R Bowker Llc",
                    "k": "511945",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "R&amp;N Academic Press",
                    "k": "460100",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Rafe Colflesh",
                    "k": "459971",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Raise the Sparks Press",
                    "k": "433327",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "RaiseUp LLC",
                    "k": "540760",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Ramirez and Clark, Publishers",
                    "k": "490293",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Randall Meyers",
                    "k": "459942",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "RAP Books",
                    "k": "459930",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Rascal Books",
                    "k": "677660",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Rasika",
                    "k": "459921",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Read or Green Books",
                    "k": "633509",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Real Wealth Books",
                    "k": "510587",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "RealFaith Ministries",
                    "k": "642343",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Reality Collision Publishing",
                    "k": "459881",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Reality Marketing And Design",
                    "k": "606476",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Rebecca House Publisher International",
                    "k": "459875",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Rebel Miller Books",
                    "k": "460010",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Reckoning Man Publishing",
                    "k": "459905",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Recto y Verso Editions, Inc.",
                    "k": "460110",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Red Dahlia",
                    "k": "762356",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Red Sky Presents",
                    "k": "459899",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "ReddHouse",
                    "k": "693580",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "ReddHouse Publishers",
                    "k": "742414",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "ReFour Publications",
                    "k": "460158",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Reginald H. McLaughlin",
                    "k": "459956",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Reinfred Addo Central",
                    "k": "593406",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Release The Ink Publishing",
                    "k": "683941",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Relentless Press",
                    "k": "460131",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Renaissance institute Press",
                    "k": "479127",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Rev. Dr. J. Sherman Pelt Foundation, Inc",
                    "k": "459969",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Reynolds Street Press",
                    "k": "690680",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "RH Publishing Group",
                    "k": "449747",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "RibbitBooks",
                    "k": "540758",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Rising Lark Press",
                    "k": "695102",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "River Place Press",
                    "k": "535991",
                    "t": "pip_id",
                    "f": "",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "River Rock Books",
                    "k": "442112",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Riverlet Press LLC",
                    "k": "571606",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "RLH Publishing Company, Inc.",
                    "k": "481286",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Roath Publishing",
                    "k": "657320",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Robin Butterflies Company",
                    "k": "637080",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Rock N Roll Law",
                    "k": "460145",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Rodney Fisher",
                    "k": "557383",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Ronald V. Wilson",
                    "k": "459879",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Ronlewis Design",
                    "k": "643483",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Rosehip26",
                    "k": "652131",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Rowayat",
                    "k": "460137",
                    "t": "pip_id",
                    "f": "",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "Royal Oak Press",
                    "k": "762359",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "RSI",
                    "k": "460133",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "RSTvictory.com",
                    "k": "460009",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Ruby Dolphin Books, LLC",
                    "k": "660671",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Ruxunn",
                    "k": "460023",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Rx Publishers",
                    "k": "459938",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Ryan Hanley",
                    "k": "459948",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "RYM Publishing",
                    "k": "460107",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "S3 Publishing LLC",
                    "k": "436114",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "SAFE Encouragement",
                    "k": "520734",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Salty Books Publishing",
                    "k": "433329",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Sam's Young Readers",
                    "k": "459982",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Samantha Jordan",
                    "k": "459903",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Samizdat Press",
                    "k": "489627",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "SangrelinJ LLC",
                    "k": "775027",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Sapphira Publishing House",
                    "k": "458180",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Sarantos Melogia, LLC",
                    "k": "459933",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Sarge Publications",
                    "k": "517719",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "SBHX Books",
                    "k": "762362",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Scajaquada Press, New York, New York",
                    "k": "764789",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Scenic Lake Publishing",
                    "k": "526366",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "SDK Publishing LLC",
                    "k": "623667",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Second To None Publishing",
                    "k": "687848",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Selby HQ Publishing",
                    "k": "735672",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "self published",
                    "k": "423791",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Self-Published",
                    "k": "604905",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Sentrel",
                    "k": "460048",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Sepia Tree",
                    "k": "441541",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Sera Tasileta Chan-Cheuk Moss",
                    "k": "459974",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Serio Controla Records",
                    "k": "686560",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Servants of Christ",
                    "k": "657312",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Seth On Survival Presents",
                    "k": "460060",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Seventh Step Publishing",
                    "k": "760953",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Sextant Press",
                    "k": "606473",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Sexual Wellness Press",
                    "k": "459984",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "SF Books",
                    "k": "707823",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Shakira McGee",
                    "k": "654927",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Shalom for Everyone",
                    "k": "479863",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Shaphan Press",
                    "k": "459980",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Shay Better Publishing, Inc",
                    "k": "440567",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Shine PR Etc.",
                    "k": "509217",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Sholder Healthcare Executive Consulting",
                    "k": "772280",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Sibin Lu",
                    "k": "474514",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Sic Semper Serpent Books",
                    "k": "454034",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Silent Owl Publishing",
                    "k": "762360",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Silk Rhode Books",
                    "k": "563687",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Silver Spear Publications",
                    "k": "460057",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "SINGULARITY PRESS",
                    "k": "554406",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "SitaRam Press",
                    "k": "764786",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Slack Publishing",
                    "k": "459943",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "SLM Publishing",
                    "k": "460144",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "SMA Creative",
                    "k": "435149",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Snowy Owl - Port Ludlow, WA",
                    "k": "509210",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Socialtrade Corporation",
                    "k": "658386",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Soheila Adelipour",
                    "k": "583987",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "SongTown Press",
                    "k": "450253",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Soterian Publishing",
                    "k": "570968",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Sovereign Thinkers LLC",
                    "k": "739952",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Speak It To Book",
                    "k": "460038",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Speak To Me Books",
                    "k": "515806",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Spring Hearts Publishing",
                    "k": "681249",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Springboard Education",
                    "k": "668392",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "St. Helena Press",
                    "k": "633508",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "St. Louis Enterprise LLC",
                    "k": "642341",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "St. Michaels University School",
                    "k": "459988",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "StandTogether",
                    "k": "460001",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Starfeather Publishing",
                    "k": "459949",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Stargate Publishing",
                    "k": "439772",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Starr Scream Ent.",
                    "k": "460088",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Steven A. Richards",
                    "k": "526373",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Stingheart Press",
                    "k": "449746",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Stneccca Press",
                    "k": "459932",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Stoic Owl Press",
                    "k": "728378",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Story To Tell Books",
                    "k": "522949",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "StoryShoals.com",
                    "k": "459993",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "STRIKE Multimedia &amp; Publishing",
                    "k": "437286",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "StriveOn Publishing",
                    "k": "460091",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Sukha Life",
                    "k": "650027",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Sunflower Books",
                    "k": "483541",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Sunshine and Wind",
                    "k": "510590",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "SUNWISE MEDIA",
                    "k": "479862",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Suricata Press",
                    "k": "460056",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Surmountable Publishing",
                    "k": "515810",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Sursum Corda Press, LLC",
                    "k": "585401",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Swamp's Edge Publishing",
                    "k": "460117",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "SWW Press",
                    "k": "442951",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "T's Pot of Poems",
                    "k": "592505",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "T. Heritage Gallery",
                    "k": "650030",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Tadem Press",
                    "k": "695103",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Takman Publishing",
                    "k": "491236",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Tamborrel Publishing",
                    "k": "460105",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Tandem Lane Editions",
                    "k": "492390",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Tandem River Press",
                    "k": "759544",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Tangled Publishing",
                    "k": "460136",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Tanx God! Productions",
                    "k": "458170",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "TC PUBLISHING",
                    "k": "619187",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "TCF Business Group",
                    "k": "439244",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Telios Press",
                    "k": "530927",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Terri Terrell",
                    "k": "741242",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Tessi Xie Publishing",
                    "k": "438549",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "THAWORLD ANDME I.M.G.",
                    "k": "460022",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "The Babak Govan Creative Company, LLC",
                    "k": "454315",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "The Bee's Knees Press, LLC",
                    "k": "460098",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "The Concord Writer",
                    "k": "460080",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "The Ferrance Group",
                    "k": "460096",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "The Ginger Press",
                    "k": "459937",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "The Helen Foundation",
                    "k": "633504",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "The HillHelen Group LLC",
                    "k": "459900",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "The Leader Is YOU!",
                    "k": "460114",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "THE LIFESTYLE AGENCY PUBLISHING",
                    "k": "716762",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "The Love Story Media, Inc.",
                    "k": "460101",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "The Maine Coon Life Books",
                    "k": "460138",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "The Pelt Foundation",
                    "k": "502653",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "The Roxbury Institute",
                    "k": "704440",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "THE STORYTELLER BOOKS",
                    "k": "434300",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "The Sword &amp; The Ring Publishing Company",
                    "k": "770715",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "The Trotters of Tweeville, LLC",
                    "k": "459965",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Theolenn Media LLC",
                    "k": "583986",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "They are the best",
                    "k": "505029",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Third Chapter Press",
                    "k": "459893",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Threefold Publishing",
                    "k": "554465",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Tiered",
                    "k": "690678",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Tiffany Ray",
                    "k": "466735",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Tiny Mammoth Press",
                    "k": "588847",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "TK Publishing",
                    "k": "660666",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Toran Enterprises, LLC",
                    "k": "576916",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Torchpost",
                    "k": "643481",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Tour Head Press",
                    "k": "643486",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Townsend Press",
                    "k": "704428",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "ToyShelf Services LLC",
                    "k": "751024",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Transformations Press",
                    "k": "460008",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Transgender Equality of America, INC.",
                    "k": "568627",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Tried and True Publishing",
                    "k": "665728",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "TROPAEUM PRESS",
                    "k": "615370",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Trustfall Collaborations",
                    "k": "574867",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Two Hearts Nutrition",
                    "k": "558822",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Type A Books",
                    "k": "460004",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "UBICA",
                    "k": "464288",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Unbearable Truth Publications",
                    "k": "567906",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Underdog Innovations",
                    "k": "449744",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Unique Value Proposition",
                    "k": "460033",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "University of Lasting Impact",
                    "k": "692151",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Unlimited Genius Ltd.",
                    "k": "460146",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Untold Stories Press LLC",
                    "k": "669481",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "UrbisMedia-Ltd.",
                    "k": "459928",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "UrbisMedia-Ltd. Bamboo Books",
                    "k": "454163",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "URM Education Silicon Valley",
                    "k": "572765",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "USU RESTORATION CONSORTIUM",
                    "k": "439240",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Uy Woody Photography",
                    "k": "560108",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "V. Green &amp; Company",
                    "k": "563688",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Vada Azeem Co.",
                    "k": "485230",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Valley Girl Network",
                    "k": "435148",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Varhola Productions",
                    "k": "460115",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Variance Media Enterprises, LLC",
                    "k": "585396",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Vigor",
                    "k": "459951",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "VinChaRo Ventures",
                    "k": "435150",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "VinChaRo Ventures, LLC",
                    "k": "460084",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Vitality Matters, LLC",
                    "k": "459936",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Voice of the Rainbow",
                    "k": "697669",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "VoicED",
                    "k": "666909",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "W House Digital",
                    "k": "439243",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "W.L. Strickland Publishing",
                    "k": "586188",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Walk With Me Publications",
                    "k": "460062",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Water Street Press",
                    "k": "446411",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "wbtylercom, llc",
                    "k": "439242",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Weaving the Vine",
                    "k": "658379",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Weeping Willow Books",
                    "k": "459960",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Well-Fed Artist Press",
                    "k": "705894",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Wellstone Press",
                    "k": "741237",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "West Africa Times, Inc.",
                    "k": "650031",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Westcor Publishing",
                    "k": "460087",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "What's Next Publishing",
                    "k": "620903",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "White Poppy Press",
                    "k": "441549",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Wild Leaf",
                    "k": "460077",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Wilde Griffen Publishing",
                    "k": "460079",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Windstream Publishing Company",
                    "k": "459961",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Windwalker Books",
                    "k": "577820",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Winged Words Publishing",
                    "k": "460140",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Wisdom Feast",
                    "k": "574872",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "WISR Press",
                    "k": "434297",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Wolf-Wise Press",
                    "k": "741225",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Wonderwall Books",
                    "k": "526368",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Wood Free",
                    "k": "718337",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Woodpecker Press, LLC",
                    "k": "517722",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Woodthrush Productions",
                    "k": "446566",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Wright Publishing Group, Inc.",
                    "k": "554575",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Write Camp L.L.C.",
                    "k": "625617",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Write Mind Publishing",
                    "k": "460111",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "WRLitzman Grace Media, Inc.",
                    "k": "460042",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "WymerNovels",
                    "k": "446396",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Wynkyn Worde Publishing",
                    "k": "716768",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "X-Iota Development",
                    "k": "681243",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Xaos Books/Chaos Warrior Productions",
                    "k": "460019",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Yinscribed Books",
                    "k": "728379",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Yondering Star",
                    "k": "604981",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "YouWho Books",
                    "k": "621936",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Zoltan Entertainment",
                    "k": "436672",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 18407,
            "cnt_imprints": 835,
            "cnt_titles": 18407
        },
        {
            "v": "David &amp; Charles Publishers",
            "k": "David & Charles Publishers",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "David &amp; Charles",
                    "k": "457337",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1077",
                    "cnt_titles": "1077"
                },
                {
                    "v": "Sewandso",
                    "k": "456601",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "21",
                    "cnt_titles": "21"
                }
            ],
            "f": "moved finished",
            "c": 1098,
            "cnt_imprints": 2,
            "cnt_titles": 1098
        },
        {
            "v": "DAV",
            "k": "DAV",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "11783",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "David &amp; Charles {DAVIC}",
                    "k": "770665",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Davus Pub {DAVUS}",
                    "k": "19830",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "F.A. Davis Co.",
                    "k": "11778",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 7,
            "cnt_imprints": 4,
            "cnt_titles": 7
        },
        {
            "v": "D&amp;C",
            "k": "D&C",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "770664",
                    "t": "pip_id",
                    "f": "",
                    "c": "275",
                    "cnt_titles": "275"
                },
                {
                    "v": "Ages 6 ans",
                    "k": "525691",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 276,
            "cnt_imprints": 2,
            "cnt_titles": 276
        },
        {
            "v": "David",
            "k": "David",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "770663",
                    "t": "pip_id",
                    "f": "",
                    "c": "346",
                    "cnt_titles": "346"
                },
                {
                    "v": "Essence",
                    "k": "127195",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 347,
            "cnt_imprints": 2,
            "cnt_titles": 347
        },
        {
            "v": "Holland House",
            "k": "Holland House",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Holland House",
                    "k": "155662",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Holland House Books",
                    "k": "404614",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "",
            "c": 5,
            "cnt_imprints": 2,
            "cnt_titles": 5
        },
        {
            "v": "Oxford University Press",
            "k": "Oxford University Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "2147",
                    "t": "pip_id",
                    "f": "",
                    "c": "33",
                    "cnt_titles": "33"
                },
                {
                    "v": "Aberdeen University Press",
                    "k": "53198",
                    "t": "pip_id",
                    "f": "",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "Academic: Law - Academic",
                    "k": "480500",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Amer Philologicl Assn Book",
                    "k": "794370",
                    "t": "pip_id",
                    "f": "",
                    "c": "21",
                    "cnt_titles": "21"
                },
                {
                    "v": "American Historical Association",
                    "k": "305603",
                    "t": "pip_id",
                    "f": "",
                    "c": "33",
                    "cnt_titles": "33"
                },
                {
                    "v": "Arnold",
                    "k": "663599",
                    "t": "pip_id",
                    "f": "",
                    "c": "125",
                    "cnt_titles": "125"
                },
                {
                    "v": "Azimuth Editions/Sir John Soane's Museum",
                    "k": "127933",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Blackstone Press",
                    "k": "444379",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "48",
                    "cnt_titles": "48"
                },
                {
                    "v": "British Academy",
                    "k": "2446",
                    "t": "pip_id",
                    "f": "",
                    "c": "16",
                    "cnt_titles": "16"
                },
                {
                    "v": "Clarendon Press",
                    "k": "2247",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4036",
                    "cnt_titles": "4036"
                },
                {
                    "v": "Cold Spring Harbor Laboratory",
                    "k": "434015",
                    "t": "pip_id",
                    "f": "",
                    "c": "282",
                    "cnt_titles": "282"
                },
                {
                    "v": "Dent",
                    "k": "5574",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Early English Text Society",
                    "k": "2436",
                    "t": "pip_id",
                    "f": "",
                    "c": "26",
                    "cnt_titles": "26"
                },
                {
                    "v": "Goodheart-Willcox Publisher",
                    "k": "15060",
                    "t": "pip_id",
                    "f": "",
                    "c": "136",
                    "cnt_titles": "136"
                },
                {
                    "v": "Hurst &amp; Co",
                    "k": "794342",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Hurst Publishers",
                    "k": "189495",
                    "t": "pip_id",
                    "f": "",
                    "c": "41",
                    "cnt_titles": "41"
                },
                {
                    "v": "Hurst Publishing",
                    "k": "413777",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "International Agency for Research on Cancer",
                    "k": "47630",
                    "t": "pip_id",
                    "f": "",
                    "c": "14",
                    "cnt_titles": "14"
                },
                {
                    "v": "IRL Press",
                    "k": "2548",
                    "t": "pip_id",
                    "f": "",
                    "c": "19",
                    "cnt_titles": "19"
                },
                {
                    "v": "Jacketed Hardcover",
                    "k": "806954",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Kibo Foundation",
                    "k": "40250",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "McClelland and Stewart",
                    "k": "2416",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Nelson Thornes",
                    "k": "794658",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Oceana TM",
                    "k": "4469",
                    "t": "pip_id",
                    "f": "",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "OUP",
                    "k": "2510",
                    "t": "pip_id",
                    "f": "",
                    "c": "45",
                    "cnt_titles": "45"
                },
                {
                    "v": "OUP in association with the Institute of Ismaili Studies",
                    "k": "127081",
                    "t": "pip_id",
                    "f": "",
                    "c": "9",
                    "cnt_titles": "9"
                },
                {
                    "v": "OUP/BA",
                    "k": "350889",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "OUP/Birmingham Oratory",
                    "k": "132589",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "OUP/British Academy",
                    "k": "2443",
                    "t": "pip_id",
                    "f": "",
                    "c": "269",
                    "cnt_titles": "269"
                },
                {
                    "v": "OUP/German Historical Institute London",
                    "k": "209880",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "OUP/German Historical Institute,London",
                    "k": "303005",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "OUP/Institute of Ismaili Studies",
                    "k": "209801",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "OUP/OPEC",
                    "k": "155558",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "OUP/The Bibliographical Society of London",
                    "k": "130653",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Oxford Children's",
                    "k": "682726",
                    "t": "pip_id",
                    "f": "",
                    "c": "119",
                    "cnt_titles": "119"
                },
                {
                    "v": "Oxford Institute for Energy Studies",
                    "k": "135475",
                    "t": "pip_id",
                    "f": "",
                    "c": "13",
                    "cnt_titles": "13"
                },
                {
                    "v": "Oxford Paperbacks",
                    "k": "2243",
                    "t": "pip_id",
                    "f": "",
                    "c": "1170",
                    "cnt_titles": "1170"
                },
                {
                    "v": "Oxford Univ Pr",
                    "k": "696",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "3019",
                    "cnt_titles": "3019"
                },
                {
                    "v": "Oxford Univ Pr Esl",
                    "k": "764739",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "25",
                    "cnt_titles": "25"
                },
                {
                    "v": "Oxford Univ Pr USA",
                    "k": "751552",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "615",
                    "cnt_titles": "615"
                },
                {
                    "v": "Oxford Univ Pr {UOXFX}",
                    "k": "2308",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Oxford Univ Press-Trade/Coll",
                    "k": "53233",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Oxford University Press",
                    "k": "675",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "63179",
                    "cnt_titles": "63179"
                },
                {
                    "v": "Oxford University Press/International Union of Crystallography",
                    "k": "2507",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Practitioner Law",
                    "k": "764740",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Sambrook &amp; Tooze Pubn at Oxfo",
                    "k": "794341",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Sinauer Associates is an imprint of Oxford University Press",
                    "k": "350387",
                    "t": "pip_id",
                    "f": "",
                    "c": "75",
                    "cnt_titles": "75"
                },
                {
                    "v": "Spektrum Academic Publishers",
                    "k": "8509",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "The Arcadian Library",
                    "k": "2433",
                    "t": "pip_id",
                    "f": "",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "The Bibliographical Society",
                    "k": "2434",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "University Press plc, Nigeria",
                    "k": "2429",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Victoria County History",
                    "k": "81356",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                }
            ],
            "f": "moved",
            "c": 73429,
            "cnt_imprints": 52,
            "cnt_titles": 73429
        },
        {
            "v": "Liverpool University Press",
            "k": "Liverpool University Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Historic England",
                    "k": "742391",
                    "t": "pip_id",
                    "f": "",
                    "c": "58",
                    "cnt_titles": "58"
                },
                {
                    "v": "Liverpool Univ Pr",
                    "k": "14332",
                    "t": "pip_id",
                    "f": "",
                    "c": "84",
                    "cnt_titles": "84"
                },
                {
                    "v": "Liverpool University Press",
                    "k": "14336",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "3031",
                    "cnt_titles": "3031"
                },
                {
                    "v": "National Museums Liverpool",
                    "k": "683948",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Pavilion Poetry",
                    "k": "742390",
                    "t": "pip_id",
                    "f": "",
                    "c": "27",
                    "cnt_titles": "27"
                },
                {
                    "v": "The Littman Library of Jewish Civilization",
                    "k": "742389",
                    "t": "pip_id",
                    "f": "",
                    "c": "240",
                    "cnt_titles": "240"
                },
                {
                    "v": "Univ of Exeter",
                    "k": "495408",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Voltaire Foundation",
                    "k": "742392",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "111",
                    "cnt_titles": "111"
                },
                {
                    "v": "XJTLU",
                    "k": "743634",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "moved",
            "c": 3556,
            "cnt_imprints": 9,
            "cnt_titles": 3556
        },
        {
            "v": "Manchester University Press",
            "k": "Manchester University Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Manchester University Press",
                    "k": "8028",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "5322",
                    "cnt_titles": "5322"
                }
            ],
            "f": "moved",
            "c": 5322,
            "cnt_imprints": 1,
            "cnt_titles": 5322
        },
        {
            "v": "Otago University Press",
            "k": "Otago University Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Otago University Press",
                    "k": "40308",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "239",
                    "cnt_titles": "239"
                }
            ],
            "f": "finished",
            "c": 239,
            "cnt_imprints": 1,
            "cnt_titles": 239
        },
        {
            "v": "VICTO",
            "k": "VICTO",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "764738",
                    "t": "pip_id",
                    "f": "",
                    "c": "54",
                    "cnt_titles": "54"
                }
            ],
            "f": "",
            "c": 54,
            "cnt_imprints": 1,
            "cnt_titles": 54
        },
        {
            "v": "Orion Publishing Group",
            "k": "Orion Publishing Group",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Gateway",
                    "k": "257323",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "20",
                    "cnt_titles": "20"
                },
                {
                    "v": "Gollancz",
                    "k": "6507",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "827",
                    "cnt_titles": "827"
                },
                {
                    "v": "Laurence King Publishing",
                    "k": "801222",
                    "t": "pip_id",
                    "f": "",
                    "c": "20",
                    "cnt_titles": "20"
                },
                {
                    "v": "Orion",
                    "k": "9507",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "2811",
                    "cnt_titles": "2811"
                },
                {
                    "v": "Peter Crawley",
                    "k": "58211",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "23",
                    "cnt_titles": "23"
                },
                {
                    "v": "Phoenix",
                    "k": "5581",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "330",
                    "cnt_titles": "330"
                },
                {
                    "v": "Seven Dials",
                    "k": "361125",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "137",
                    "cnt_titles": "137"
                },
                {
                    "v": "Spring",
                    "k": "321559",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "97",
                    "cnt_titles": "97"
                },
                {
                    "v": "The Murder Room",
                    "k": "341449",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Trapeze",
                    "k": "320803",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "169",
                    "cnt_titles": "169"
                },
                {
                    "v": "Weidenfeld &amp; Nicolson",
                    "k": "39017",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "588",
                    "cnt_titles": "588"
                },
                {
                    "v": "White Rabbit",
                    "k": "465033",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "53",
                    "cnt_titles": "53"
                }
            ],
            "f": "moved",
            "c": 5080,
            "cnt_imprints": 12,
            "cnt_titles": 5080
        },
        {
            "v": "Everyman Paperback Classics",
            "k": "Everyman Paperback Classics",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "English Heritage",
                    "k": "699655",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Everyman Paperback",
                    "k": "398176",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "52",
                    "cnt_titles": "52"
                },
                {
                    "v": "Everyman Paperback Classics",
                    "k": "140226",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "moved",
            "c": 54,
            "cnt_imprints": 3,
            "cnt_titles": 54
        },
        {
            "v": "Orenda Books",
            "k": "Orenda Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Orenda Books",
                    "k": "220944",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "198",
                    "cnt_titles": "198"
                }
            ],
            "f": "finished",
            "c": 198,
            "cnt_imprints": 1,
            "cnt_titles": 198
        },
        {
            "v": "Outland Entertainment",
            "k": "Outland Entertainment",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Outland Entertainment",
                    "k": "394401",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "81",
                    "cnt_titles": "81"
                },
                {
                    "v": "Ragnarok Publiations",
                    "k": "435113",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "3",
                    "cnt_titles": "3"
                }
            ],
            "f": "moved finished",
            "c": 84,
            "cnt_imprints": 2,
            "cnt_titles": 84
        },
        {
            "v": "Quince &amp; Co.",
            "k": "Quince & Co.",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Quince &amp; Co.",
                    "k": "276648",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "9",
                    "cnt_titles": "9"
                }
            ],
            "f": "finished",
            "c": 9,
            "cnt_imprints": 1,
            "cnt_titles": 9
        },
        {
            "v": "Quill Driver Books",
            "k": "Quill Driver Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Quill Driver Books",
                    "k": "42304",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "10",
                    "cnt_titles": "10"
                }
            ],
            "f": "finished",
            "c": 10,
            "cnt_imprints": 1,
            "cnt_titles": 10
        },
        {
            "v": "Quarto Library",
            "k": "Quarto Library",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Ivy Kids a Quarto Library",
                    "k": "705865",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "MoonDance Press a Quarto Library",
                    "k": "705871",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "QEB Publishing a Quarto Library",
                    "k": "705864",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "211",
                    "cnt_titles": "211"
                },
                {
                    "v": "Quarry a Quarto Library",
                    "k": "705872",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "17",
                    "cnt_titles": "17"
                },
                {
                    "v": "Seagrass Press a Quarto Library",
                    "k": "705873",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Voyageur Press a Quarto Library",
                    "k": "705867",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "Walter Foster Jr. a Quarto Library",
                    "k": "705853",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "94",
                    "cnt_titles": "94"
                }
            ],
            "f": "moved finished",
            "c": 346,
            "cnt_imprints": 7,
            "cnt_titles": 346
        },
        {
            "v": "Crestline Books",
            "k": "Crestline Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Crestline Books",
                    "k": "9672",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "22",
                    "cnt_titles": "22"
                }
            ],
            "f": "moved finished",
            "c": 22,
            "cnt_imprints": 1,
            "cnt_titles": 22
        },
        {
            "v": "CAST Professional Publishing",
            "k": "CAST Professional Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "CAST Professional Publishing",
                    "k": "290907",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "35",
                    "cnt_titles": "35"
                }
            ],
            "f": "moved finished",
            "c": 35,
            "cnt_imprints": 1,
            "cnt_titles": 35
        },
        {
            "v": "Rockpool Publishing",
            "k": "Rockpool Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Gelding Street Press",
                    "k": "714745",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "Rockpool Publishing",
                    "k": "158722",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "379",
                    "cnt_titles": "379"
                },
                {
                    "v": "Sweet Hearts Press",
                    "k": "806795",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                }
            ],
            "f": "",
            "c": 390,
            "cnt_imprints": 3,
            "cnt_titles": 390
        },
        {
            "v": "University of Ottawa Press",
            "k": "University of Ottawa Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Harvest House",
                    "k": "150047",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "50",
                    "cnt_titles": "50"
                },
                {
                    "v": "Invenire",
                    "k": "637808",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "64",
                    "cnt_titles": "64"
                },
                {
                    "v": "Les Presses de l'Universite d'Ottawa",
                    "k": "46270",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "322",
                    "cnt_titles": "322"
                },
                {
                    "v": "Mercury-Mercure",
                    "k": "616456",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "28",
                    "cnt_titles": "28"
                },
                {
                    "v": "University of Ottawa Press",
                    "k": "10483",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "455",
                    "cnt_titles": "455"
                }
            ],
            "f": "moved finished",
            "c": 919,
            "cnt_imprints": 5,
            "cnt_titles": 919
        },
        {
            "v": "WaveMaker Press, Ltd.",
            "k": "WaveMaker Press, Ltd.",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "764693",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "WaveMaker Press, Ltd.",
                    "k": "759416",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "12",
                    "cnt_titles": "12"
                }
            ],
            "f": "moved finished",
            "c": 16,
            "cnt_imprints": 2,
            "cnt_titles": 16
        },
        {
            "v": "Priddy Bicknell Books {PRIDB}",
            "k": "Priddy Bicknell Books {PRIDB}",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Priddy Bicknell Books {PRIDB}",
                    "k": "762286",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "PRIDDY BICKNELL",
            "k": "PRIDDY BICKNELL",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "762285",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Priddy Books",
            "k": "Priddy Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Priddy Books",
                    "k": "347809",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "674",
                    "cnt_titles": "674"
                }
            ],
            "f": "moved finished",
            "c": 674,
            "cnt_imprints": 1,
            "cnt_titles": 674
        },
        {
            "v": "Blink",
            "k": "Blink",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "566136",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Blink",
                    "k": "760916",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "85",
                    "cnt_titles": "85"
                }
            ],
            "f": "",
            "c": 86,
            "cnt_imprints": 2,
            "cnt_titles": 86
        },
        {
            "v": "Jacqui Small",
            "k": "Jacqui Small",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Jacqui Small",
                    "k": "177742",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4",
                    "cnt_titles": "4"
                }
            ],
            "f": "moved finished",
            "c": 4,
            "cnt_imprints": 1,
            "cnt_titles": 4
        },
        {
            "v": "Akashic Books",
            "k": "Akashic Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Akashic Books",
                    "k": "43193",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "27",
                    "cnt_titles": "27"
                },
                {
                    "v": "Infamous Books",
                    "k": "231386",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Kaylie Jones Books",
                    "k": "242526",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "RDV Books",
                    "k": "56833",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "finished",
            "c": 32,
            "cnt_imprints": 4,
            "cnt_titles": 32
        },
        {
            "v": "Castle Books",
            "k": "Castle Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Booksales Inc Remainders",
                    "k": "10751",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Castle Books",
                    "k": "10741",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "61",
                    "cnt_titles": "61"
                }
            ],
            "f": "moved",
            "c": 63,
            "cnt_imprints": 2,
            "cnt_titles": 63
        },
        {
            "v": "Walter Foster Publishing",
            "k": "Walter Foster Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Walter Foster Pub Inc",
                    "k": "404081",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Walter Foster Publishing",
                    "k": "35627",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "66",
                    "cnt_titles": "66"
                }
            ],
            "f": "moved",
            "c": 71,
            "cnt_imprints": 2,
            "cnt_titles": 71
        },
        {
            "v": "Belle Publishing",
            "k": "Belle Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Belle Pub Llc",
                    "k": "434700",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "moved",
            "c": 2,
            "cnt_imprints": 1,
            "cnt_titles": 2
        },
        {
            "v": "Amphorae Publishing Group, LLC",
            "k": "Amphorae Publishing Group, LLC",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Blank Slate Press",
                    "k": "53780",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "49",
                    "cnt_titles": "49"
                },
                {
                    "v": "Goldminds Publishing",
                    "k": "398550",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "58",
                    "cnt_titles": "58"
                },
                {
                    "v": "Reputation Books",
                    "k": "542430",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "15",
                    "cnt_titles": "15"
                },
                {
                    "v": "Treehouse Publishing Group",
                    "k": "214865",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "10",
                    "cnt_titles": "10"
                },
                {
                    "v": "Walrus Publishing",
                    "k": "192086",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "21",
                    "cnt_titles": "21"
                }
            ],
            "f": "moved finished",
            "c": 153,
            "cnt_imprints": 5,
            "cnt_titles": 153
        },
        {
            "v": "Diamond Publishing",
            "k": "Diamond Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Diamond Pub",
                    "k": "505717",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Vecchia Pub",
                    "k": "571069",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "moved",
            "c": 7,
            "cnt_imprints": 2,
            "cnt_titles": 7
        },
        {
            "v": "Green City Books",
            "k": "Green City Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Green City Books",
                    "k": "740306",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "7",
                    "cnt_titles": "7"
                }
            ],
            "f": "finished",
            "c": 7,
            "cnt_imprints": 1,
            "cnt_titles": 7
        },
        {
            "v": "Omnibus Press",
            "k": "Omnibus Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "BMG Books",
                    "k": "791389",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Diamond Publishing",
                    "k": "762332",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "DTM Entertainment",
                    "k": "772252",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Ecstatic Peace Library",
                    "k": "434294",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Fashionbeast Editions",
                    "k": "762336",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Fast Western",
                    "k": "762315",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Omnibus Press",
                    "k": "8274",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "362",
                    "cnt_titles": "362"
                },
                {
                    "v": "Schirmer Trade Books",
                    "k": "631",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "43",
                    "cnt_titles": "43"
                },
                {
                    "v": "The Last Music Company",
                    "k": "762318",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "This Day In Music Books",
                    "k": "434265",
                    "t": "pip_id",
                    "f": "",
                    "c": "9",
                    "cnt_titles": "9"
                }
            ],
            "f": "moved",
            "c": 430,
            "cnt_imprints": 10,
            "cnt_titles": 430
        },
        {
            "v": "Vision On",
            "k": "Vision On",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Vision On",
                    "k": "759518",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "moved",
            "c": 2,
            "cnt_imprints": 1,
            "cnt_titles": 2
        },
        {
            "v": "Ossian",
            "k": "Ossian",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Ossian",
                    "k": "24514",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "6",
                    "cnt_titles": "6"
                }
            ],
            "f": "moved",
            "c": 6,
            "cnt_imprints": 1,
            "cnt_titles": 6
        },
        {
            "v": "Music Sales",
            "k": "Music Sales",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Accent on Music",
                    "k": "22703",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Amsco Music",
                    "k": "584013",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "97",
                    "cnt_titles": "97"
                },
                {
                    "v": "Boosey &amp; Hawkes",
                    "k": "263846",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Boston Music",
                    "k": "369322",
                    "t": "pip_id",
                    "f": "",
                    "c": "10",
                    "cnt_titles": "10"
                },
                {
                    "v": "Chester Music",
                    "k": "69562",
                    "t": "pip_id",
                    "f": "",
                    "c": "137",
                    "cnt_titles": "137"
                },
                {
                    "v": "Cross Harp Pr",
                    "k": "59756",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Gerard &amp; Sarzin Pub Co",
                    "k": "26092",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Hal Leonard Pub Co",
                    "k": "20344",
                    "t": "pip_id",
                    "f": "",
                    "c": "17",
                    "cnt_titles": "17"
                },
                {
                    "v": "Homespun Tapes Ltd",
                    "k": "158042",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Iplay Music",
                    "k": "414632",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Music Sales Amer {MSSAL}",
                    "k": "8276",
                    "t": "pip_id",
                    "f": "",
                    "c": "14",
                    "cnt_titles": "14"
                },
                {
                    "v": "Music Sales Corp",
                    "k": "582659",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "489",
                    "cnt_titles": "489"
                },
                {
                    "v": "Novello &amp; Co Ltd",
                    "k": "404288",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Omnibus Pr",
                    "k": "196427",
                    "t": "pip_id",
                    "f": "",
                    "c": "10",
                    "cnt_titles": "10"
                },
                {
                    "v": "Ossian",
                    "k": "24508",
                    "t": "pip_id",
                    "f": "",
                    "c": "19",
                    "cnt_titles": "19"
                },
                {
                    "v": "Praxis Music Pubn",
                    "k": "42268",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Rock House",
                    "k": "404479",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Sanctuary Records",
                    "k": "414645",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Taigh Na Teud Music Publ",
                    "k": "54960",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Warner Brothers Pubn",
                    "k": "404211",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Wise Pubn",
                    "k": "8321",
                    "t": "pip_id",
                    "f": "",
                    "c": "15",
                    "cnt_titles": "15"
                },
                {
                    "v": "Yorktown Pr",
                    "k": "414629",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "moved",
            "c": 830,
            "cnt_imprints": 22,
            "cnt_titles": 830
        },
        {
            "v": "Bobcat Books",
            "k": "Bobcat Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Bobcat Books",
                    "k": "759516",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "5",
                    "cnt_titles": "5"
                }
            ],
            "f": "moved",
            "c": 5,
            "cnt_imprints": 1,
            "cnt_titles": 5
        },
        {
            "v": "unknown dvd",
            "k": "unknown dvd",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "759514",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "9",
                    "cnt_titles": "9"
                }
            ],
            "f": "moved",
            "c": 9,
            "cnt_imprints": 1,
            "cnt_titles": 9
        },
        {
            "v": "Uphill Books",
            "k": "Uphill Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Propriometrics Press",
                    "k": "739868",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "10",
                    "cnt_titles": "10"
                },
                {
                    "v": "Uphill Books",
                    "k": "723738",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "5",
                    "cnt_titles": "5"
                }
            ],
            "f": "moved finished",
            "c": 15,
            "cnt_imprints": 2,
            "cnt_titles": 15
        },
        {
            "v": "Third State Books",
            "k": "Third State Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Third St Books",
                    "k": "800987",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Third State Books",
                    "k": "702901",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "10",
                    "cnt_titles": "10"
                }
            ],
            "f": "",
            "c": 11,
            "cnt_imprints": 2,
            "cnt_titles": 11
        },
        {
            "v": "Willsow Limited",
            "k": "Willsow Limited",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Willsow Ltd",
                    "k": "718265",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "6",
                    "cnt_titles": "6"
                }
            ],
            "f": "finished",
            "c": 6,
            "cnt_imprints": 1,
            "cnt_titles": 6
        },
        {
            "v": "Neem Tree Press",
            "k": "Neem Tree Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Neem Tree Press",
                    "k": "718264",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "3",
                    "cnt_titles": "3"
                }
            ],
            "f": "moved finished",
            "c": 3,
            "cnt_imprints": 1,
            "cnt_titles": 3
        },
        {
            "v": "Wilderness Press",
            "k": "Wilderness Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Diamond Valley Co",
                    "k": "77456",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Michel Digonnet",
                    "k": "760954",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Wilderness Press",
                    "k": "317137",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "360",
                    "cnt_titles": "360"
                }
            ],
            "f": "moved",
            "c": 365,
            "cnt_imprints": 3,
            "cnt_titles": 365
        },
        {
            "v": "Voice in the Wilderness Press, Inc.",
            "k": "Voice in the Wilderness Press, Inc.",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "759498",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Wilderness Pr {WILDR}",
                    "k": "759499",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 3,
            "cnt_imprints": 2,
            "cnt_titles": 3
        },
        {
            "v": "Pacific Press Publishing Association",
            "k": "Pacific Press Publishing Association",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Pacific Pr (ny)",
                    "k": "12639",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "10",
                    "cnt_titles": "10"
                },
                {
                    "v": "Pacific Pr Pub Assn",
                    "k": "404007",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "284",
                    "cnt_titles": "284"
                }
            ],
            "f": "moved",
            "c": 294,
            "cnt_imprints": 2,
            "cnt_titles": 294
        },
        {
            "v": "Shelter Publications",
            "k": "Shelter Publications",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Shelter Publications",
                    "k": "22582",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "31",
                    "cnt_titles": "31"
                }
            ],
            "f": "moved finished",
            "c": 31,
            "cnt_imprints": 1,
            "cnt_titles": 31
        },
        {
            "v": "Nature Study Guides",
            "k": "Nature Study Guides",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Nature Study",
                    "k": "395934",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4",
                    "cnt_titles": "4"
                }
            ],
            "f": "renamed moved finished",
            "c": 4,
            "cnt_imprints": 1,
            "cnt_titles": 4
        },
        {
            "v": "Nodin Press",
            "k": "Nodin Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Acta Pubn",
                    "k": "790401",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Nodin Pr",
                    "k": "759496",
                    "t": "pip_id",
                    "f": "",
                    "c": "116",
                    "cnt_titles": "116"
                }
            ],
            "f": "",
            "c": 117,
            "cnt_imprints": 2,
            "cnt_titles": 117
        },
        {
            "v": "Menasha Ridge Press",
            "k": "Menasha Ridge Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Menasha Ridge Press",
                    "k": "317130",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "361",
                    "cnt_titles": "361"
                }
            ],
            "f": "moved finished",
            "c": 361,
            "cnt_imprints": 1,
            "cnt_titles": 361
        },
        {
            "v": "Candlewick Press",
            "k": "Candlewick Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Big Picture Press",
                    "k": "257454",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "92",
                    "cnt_titles": "92"
                },
                {
                    "v": "Candlewick Entertainment",
                    "k": "241997",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "143",
                    "cnt_titles": "143"
                },
                {
                    "v": "Candlewick Press",
                    "k": "5023",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4852",
                    "cnt_titles": "4852"
                },
                {
                    "v": "Candlewick Studio",
                    "k": "567850",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "87",
                    "cnt_titles": "87"
                },
                {
                    "v": "Holiday House",
                    "k": "803623",
                    "t": "pip_id",
                    "f": "",
                    "c": "177",
                    "cnt_titles": "177"
                },
                {
                    "v": "Margaret Ferguson Books",
                    "k": "803621",
                    "t": "pip_id",
                    "f": "",
                    "c": "18",
                    "cnt_titles": "18"
                },
                {
                    "v": "Margaret Quinlin Books",
                    "k": "803631",
                    "t": "pip_id",
                    "f": "",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "MIT Kids Press",
                    "k": "613191",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "55",
                    "cnt_titles": "55"
                },
                {
                    "v": "MITeen Press",
                    "k": "609115",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "10",
                    "cnt_titles": "10"
                },
                {
                    "v": "Neal Porter Books",
                    "k": "803622",
                    "t": "pip_id",
                    "f": "",
                    "c": "42",
                    "cnt_titles": "42"
                },
                {
                    "v": "Peachtree",
                    "k": "803630",
                    "t": "pip_id",
                    "f": "",
                    "c": "37",
                    "cnt_titles": "37"
                },
                {
                    "v": "Peachtree Teen",
                    "k": "803632",
                    "t": "pip_id",
                    "f": "",
                    "c": "16",
                    "cnt_titles": "16"
                },
                {
                    "v": "Pixel+Ink",
                    "k": "803629",
                    "t": "pip_id",
                    "f": "",
                    "c": "23",
                    "cnt_titles": "23"
                },
                {
                    "v": "Templar",
                    "k": "142757",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "187",
                    "cnt_titles": "187"
                },
                {
                    "v": "Walker Books US",
                    "k": "441356",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "182",
                    "cnt_titles": "182"
                }
            ],
            "f": "moved",
            "c": 5929,
            "cnt_imprints": 15,
            "cnt_titles": 5929
        },
        {
            "v": "Nosy Crow",
            "k": "Nosy Crow",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Nosy Crow",
                    "k": "74194",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "201",
                    "cnt_titles": "201"
                }
            ],
            "f": "moved finished",
            "c": 201,
            "cnt_imprints": 1,
            "cnt_titles": 201
        },
        {
            "v": "Icon Books",
            "k": "Icon Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "CORINTHIAN BOOKS",
                    "k": "759458",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Icon Books",
                    "k": "20937",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "846",
                    "cnt_titles": "846"
                },
                {
                    "v": "Wizard Books",
                    "k": "759457",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "3",
                    "cnt_titles": "3"
                }
            ],
            "f": "moved",
            "c": 850,
            "cnt_imprints": 3,
            "cnt_titles": 850
        },
        {
            "v": "Anthology Editions",
            "k": "Anthology Editions",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Anthology Ed",
                    "k": "386150",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "Anthology Editions",
                    "k": "762247",
                    "t": "pip_id",
                    "f": "",
                    "c": "44",
                    "cnt_titles": "44"
                }
            ],
            "f": "",
            "c": 52,
            "cnt_imprints": 2,
            "cnt_titles": 52
        },
        {
            "v": "Berrett-Koehler Publishers",
            "k": "Berrett-Koehler Publishers",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Berrett-Koehler Publishers",
                    "k": "36817",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1150",
                    "cnt_titles": "1150"
                }
            ],
            "f": "moved finished",
            "c": 1150,
            "cnt_imprints": 1,
            "cnt_titles": 1150
        },
        {
            "v": "Skyhorse Publishing Inc.",
            "k": "Skyhorse Publishing Inc.",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Allworth",
                    "k": "759397",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "490",
                    "cnt_titles": "490"
                },
                {
                    "v": "Allworth Press",
                    "k": "224134",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "13",
                    "cnt_titles": "13"
                },
                {
                    "v": "Arcade Publishing",
                    "k": "209835",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1187",
                    "cnt_titles": "1187"
                },
                {
                    "v": "Carrel Books",
                    "k": "336911",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "37",
                    "cnt_titles": "37"
                },
                {
                    "v": "Childrens Health Defense Books",
                    "k": "759407",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "12",
                    "cnt_titles": "12"
                },
                {
                    "v": "Clydesdale",
                    "k": "418695",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "50",
                    "cnt_titles": "50"
                },
                {
                    "v": "Final Battle Books",
                    "k": "759396",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Gateway Editions",
                    "k": "759404",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "48",
                    "cnt_titles": "48"
                },
                {
                    "v": "Good Books",
                    "k": "22265",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "621",
                    "cnt_titles": "621"
                },
                {
                    "v": "Hot Books",
                    "k": "336913",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "66",
                    "cnt_titles": "66"
                },
                {
                    "v": "Lifeline Press",
                    "k": "759401",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "10",
                    "cnt_titles": "10"
                },
                {
                    "v": "Little Patriot Press",
                    "k": "759402",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "Night Shade Books",
                    "k": "364891",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "537",
                    "cnt_titles": "537"
                },
                {
                    "v": "Not For Tourists",
                    "k": "246279",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "92",
                    "cnt_titles": "92"
                },
                {
                    "v": "Peakpoint Press",
                    "k": "759395",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "10",
                    "cnt_titles": "10"
                },
                {
                    "v": "Racehorse for Young Readers",
                    "k": "364894",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "115",
                    "cnt_titles": "115"
                },
                {
                    "v": "Racehorse Publishing",
                    "k": "336912",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "187",
                    "cnt_titles": "187"
                },
                {
                    "v": "Rat Pack Books",
                    "k": "759408",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Regnery",
                    "k": "759391",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "472",
                    "cnt_titles": "472"
                },
                {
                    "v": "Regnery Capital",
                    "k": "759403",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "Regnery Faith",
                    "k": "759394",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "20",
                    "cnt_titles": "20"
                },
                {
                    "v": "Regnery Gateway",
                    "k": "759399",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "57",
                    "cnt_titles": "57"
                },
                {
                    "v": "Regnery History",
                    "k": "759392",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "198",
                    "cnt_titles": "198"
                },
                {
                    "v": "Regnery Kids",
                    "k": "759400",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "27",
                    "cnt_titles": "27"
                },
                {
                    "v": "Salem Books",
                    "k": "759398",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "120",
                    "cnt_titles": "120"
                },
                {
                    "v": "Sky Pony Press",
                    "k": "210029",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1175",
                    "cnt_titles": "1175"
                },
                {
                    "v": "Skyhorse Publishing",
                    "k": "63149",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "5650",
                    "cnt_titles": "5650"
                },
                {
                    "v": "Sports Publishing",
                    "k": "222030",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "596",
                    "cnt_titles": "596"
                },
                {
                    "v": "Talos",
                    "k": "336943",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "95",
                    "cnt_titles": "95"
                },
                {
                    "v": "War Room Books",
                    "k": "759406",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "16",
                    "cnt_titles": "16"
                },
                {
                    "v": "World Almanac",
                    "k": "759393",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "40",
                    "cnt_titles": "40"
                },
                {
                    "v": "Yucca",
                    "k": "759405",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "45",
                    "cnt_titles": "45"
                },
                {
                    "v": "Yucca Publishing",
                    "k": "336922",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "moved finished",
            "c": 12003,
            "cnt_imprints": 33,
            "cnt_titles": 12003
        },
        {
            "v": "IDW Publishing",
            "k": "IDW Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Black Crown",
                    "k": "369967",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "10",
                    "cnt_titles": "10"
                },
                {
                    "v": "IDW Artist's Editions",
                    "k": "586955",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "38",
                    "cnt_titles": "38"
                },
                {
                    "v": "IDW Publishing",
                    "k": "327866",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "2014",
                    "cnt_titles": "2014"
                },
                {
                    "v": "Library of American Comics",
                    "k": "348293",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "140",
                    "cnt_titles": "140"
                },
                {
                    "v": "Top Shelf Productions",
                    "k": "330370",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "356",
                    "cnt_titles": "356"
                },
                {
                    "v": "Yoe Books",
                    "k": "344673",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "59",
                    "cnt_titles": "59"
                }
            ],
            "f": "moved finished",
            "c": 2617,
            "cnt_imprints": 6,
            "cnt_titles": 2617
        },
        {
            "v": "Idw Games",
            "k": "Idw Games",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Diamond Comics Gift &amp; Game",
                    "k": "757639",
                    "t": "pip_id",
                    "f": "",
                    "c": "31",
                    "cnt_titles": "31"
                },
                {
                    "v": "Funko",
                    "k": "416718",
                    "t": "pip_id",
                    "f": "",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "Idw Games",
                    "k": "381816",
                    "t": "pip_id",
                    "f": "",
                    "c": "18",
                    "cnt_titles": "18"
                },
                {
                    "v": "Psi",
                    "k": "798579",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 56,
            "cnt_imprints": 4,
            "cnt_titles": 56
        },
        {
            "v": "Idea and Design Works",
            "k": "Idea and Design Works",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Idea and Design Works",
                    "k": "757638",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Idea &amp; Design Works",
            "k": "Idea & Design Works",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Idea &amp; Design Works Llc",
                    "k": "757637",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Idea &amp; Design Works Llc",
            "k": "Idea & Design Works Llc",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Idea &amp; Design Works Llc",
                    "k": "757636",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "",
            "c": 2,
            "cnt_imprints": 1,
            "cnt_titles": 2
        },
        {
            "v": "Archie Comic Publications",
            "k": "Archie Comic Publications",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Archie Blue Ribbon",
                    "k": "614174",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Archie Comics",
                    "k": "78291",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "313",
                    "cnt_titles": "313"
                },
                {
                    "v": "Dark Circle",
                    "k": "202832",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "10",
                    "cnt_titles": "10"
                }
            ],
            "f": "moved finished",
            "c": 325,
            "cnt_imprints": 3,
            "cnt_titles": 325
        },
        {
            "v": "Chicken Scratch Books",
            "k": "Chicken Scratch Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Independent Cat",
                    "k": "813093",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Lightning Source Inc",
                    "k": "529299",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "19",
                    "cnt_titles": "19"
                }
            ],
            "f": "",
            "c": 20,
            "cnt_imprints": 2,
            "cnt_titles": 20
        },
        {
            "v": "Image Comics",
            "k": "Image Comics",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "38188",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Image Comics",
                    "k": "38182",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "3013",
                    "cnt_titles": "3013"
                },
                {
                    "v": "Todd McFarlane Productions",
                    "k": "711145",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Top Cow Productions",
                    "k": "711146",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "7",
                    "cnt_titles": "7"
                }
            ],
            "f": "moved",
            "c": 3022,
            "cnt_imprints": 4,
            "cnt_titles": 3022
        },
        {
            "v": "Enchanted Lion",
            "k": "Enchanted Lion",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Enchanted Lion",
                    "k": "692140",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "256",
                    "cnt_titles": "256"
                }
            ],
            "f": "moved finished",
            "c": 256,
            "cnt_imprints": 1,
            "cnt_titles": 256
        },
        {
            "v": "Enchanted Lion Books",
            "k": "Enchanted Lion Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Enchanted Lion Books",
                    "k": "67600",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "moved",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Stray Books Ltd.",
            "k": "Stray Books Ltd.",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "411974",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "4",
                    "cnt_titles": "4"
                }
            ],
            "f": "finished",
            "c": 4,
            "cnt_imprints": 1,
            "cnt_titles": 4
        },
        {
            "v": "Shadowpaw Press",
            "k": "Shadowpaw Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Endless Sky Books",
                    "k": "693568",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "Shadowpaw Pr",
                    "k": "719179",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Shadowpaw Press",
                    "k": "693567",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "30",
                    "cnt_titles": "30"
                },
                {
                    "v": "Shadowpaw Press Premiere",
                    "k": "683914",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Shadowpaw Press Reprise",
                    "k": "693566",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "29",
                    "cnt_titles": "29"
                },
                {
                    "v": "Stark Publishing",
                    "k": "770689",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "moved",
            "c": 70,
            "cnt_imprints": 6,
            "cnt_titles": 70
        },
        {
            "v": "Little Ghosts Books",
            "k": "Little Ghosts Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "755313",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Magic Hour Pr",
                    "k": "794752",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                }
            ],
            "f": "",
            "c": 7,
            "cnt_imprints": 2,
            "cnt_titles": 7
        },
        {
            "v": "Melange Books",
            "k": "Melange Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Fire &amp; Ice",
                    "k": "208060",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "65",
                    "cnt_titles": "65"
                },
                {
                    "v": "Melange Books",
                    "k": "477595",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "321",
                    "cnt_titles": "321"
                },
                {
                    "v": "Published By You Lulu Inc",
                    "k": "177425",
                    "t": "pip_id",
                    "f": "",
                    "c": "22",
                    "cnt_titles": "22"
                },
                {
                    "v": "Satin Romance",
                    "k": "468069",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "228",
                    "cnt_titles": "228"
                }
            ],
            "f": "moved",
            "c": 636,
            "cnt_imprints": 4,
            "cnt_titles": 636
        },
        {
            "v": "ANANS",
            "k": "ANANS",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "755290",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                }
            ],
            "f": "",
            "c": 3,
            "cnt_imprints": 1,
            "cnt_titles": 3
        },
        {
            "v": "House of Anansi Press",
            "k": "House of Anansi Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "A List",
                    "k": "333386",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "46",
                    "cnt_titles": "46"
                },
                {
                    "v": "Ambrosia",
                    "k": "333388",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "19",
                    "cnt_titles": "19"
                },
                {
                    "v": "Anansi",
                    "k": "333382",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "594",
                    "cnt_titles": "594"
                },
                {
                    "v": "Anansi International",
                    "k": "333383",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "99",
                    "cnt_titles": "99"
                },
                {
                    "v": "Arachnide Editions",
                    "k": "333387",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "34",
                    "cnt_titles": "34"
                },
                {
                    "v": "Astoria",
                    "k": "333385",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "46",
                    "cnt_titles": "46"
                },
                {
                    "v": "Spiderline",
                    "k": "333384",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "63",
                    "cnt_titles": "63"
                },
                {
                    "v": "The Walrus Books",
                    "k": "465550",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "6",
                    "cnt_titles": "6"
                }
            ],
            "f": "moved finished",
            "c": 907,
            "cnt_imprints": 8,
            "cnt_titles": 907
        },
        {
            "v": "House of Anansi Pr {HOUSA}",
            "k": "House of Anansi Pr {HOUSA}",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "House of Anansi Pr {HOUSA}",
                    "k": "755289",
                    "t": "pip_id",
                    "f": "",
                    "c": "11",
                    "cnt_titles": "11"
                }
            ],
            "f": "",
            "c": 11,
            "cnt_imprints": 1,
            "cnt_titles": 11
        },
        {
            "v": "Soho Press",
            "k": "Soho Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Hell's Hundred",
                    "k": "726719",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "11",
                    "cnt_titles": "11"
                },
                {
                    "v": "Soho Constable",
                    "k": "166940",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "28",
                    "cnt_titles": "28"
                },
                {
                    "v": "Soho Crime",
                    "k": "36785",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "815",
                    "cnt_titles": "815"
                },
                {
                    "v": "Soho Press",
                    "k": "23143",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "318",
                    "cnt_titles": "318"
                },
                {
                    "v": "Soho Syndicate",
                    "k": "264217",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "29",
                    "cnt_titles": "29"
                },
                {
                    "v": "Soho Teen",
                    "k": "192435",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "111",
                    "cnt_titles": "111"
                }
            ],
            "f": "moved finished",
            "c": 1312,
            "cnt_imprints": 6,
            "cnt_titles": 1312
        },
        {
            "v": "HarperCollins",
            "k": "HarperCollins",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "0",
                    "k": "689343",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Adams Media",
                    "k": "798438",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Allida",
                    "k": "626743",
                    "t": "pip_id",
                    "f": "",
                    "c": "9",
                    "cnt_titles": "9"
                },
                {
                    "v": "Amistad Books for Young Readers",
                    "k": "624685",
                    "t": "pip_id",
                    "f": "",
                    "c": "71",
                    "cnt_titles": "71"
                },
                {
                    "v": "Avon Books",
                    "k": "817175",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Avon Books {AVONH}",
                    "k": "467800",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Avon Inspire Impulse",
                    "k": "467813",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Balzer + Bray",
                    "k": "751477",
                    "t": "pip_id",
                    "f": "",
                    "c": "934",
                    "cnt_titles": "934"
                },
                {
                    "v": "Blackstone Pub",
                    "k": "404353",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Clarion",
                    "k": "613256",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Collins {CLLIS}",
                    "k": "467807",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Dennis Lehane Books/Ecco",
                    "k": "467819",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Etch Hmh Books for Young Reade",
                    "k": "798436",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Findaway World Llc",
                    "k": "788954",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Fourth Estate",
                    "k": "403857",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Gingko Pr Inc {GINGK}",
                    "k": "467797",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Harcourt Childrens Books {HRCTJ}",
                    "k": "585322",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Harcourt on Demand {HARCD}",
                    "k": "587696",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Harper &amp; Row (Trade Div.)",
                    "k": "713222",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Harper Business",
                    "k": "459033",
                    "t": "pip_id",
                    "f": "",
                    "c": "630",
                    "cnt_titles": "630"
                },
                {
                    "v": "Harper Collins",
                    "k": "453566",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Harper Isp",
                    "k": "578595",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Harper Large Print",
                    "k": "652136",
                    "t": "pip_id",
                    "f": "",
                    "c": "656",
                    "cnt_titles": "656"
                },
                {
                    "v": "Harper Thorsons",
                    "k": "403856",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Harper Trophy",
                    "k": "588814",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "HarperAlley",
                    "k": "467808",
                    "t": "pip_id",
                    "f": "",
                    "c": "303",
                    "cnt_titles": "303"
                },
                {
                    "v": "HarperChildrensAudio",
                    "k": "467809",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "HarperCol",
                    "k": "492399",
                    "t": "pip_id",
                    "f": "",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "Harpercollins 360",
                    "k": "402788",
                    "t": "pip_id",
                    "f": "",
                    "c": "142",
                    "cnt_titles": "142"
                },
                {
                    "v": "Harpercollins Audio",
                    "k": "402848",
                    "t": "pip_id",
                    "f": "",
                    "c": "9",
                    "cnt_titles": "9"
                },
                {
                    "v": "Harpercollins Childrens Books {HARJU}",
                    "k": "454281",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "HarperCollins Espanol",
                    "k": "401424",
                    "t": "pip_id",
                    "f": "",
                    "c": "409",
                    "cnt_titles": "409"
                },
                {
                    "v": "Harpercollins India",
                    "k": "668835",
                    "t": "pip_id",
                    "f": "",
                    "c": "202",
                    "cnt_titles": "202"
                },
                {
                    "v": "Harpercollins {HARPE}",
                    "k": "347168",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "HarperElement",
                    "k": "426360",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "HarperLegend",
                    "k": "467827",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "HarperOne Large Print",
                    "k": "800327",
                    "t": "pip_id",
                    "f": "",
                    "c": "64",
                    "cnt_titles": "64"
                },
                {
                    "v": "HarperVia",
                    "k": "467828",
                    "t": "pip_id",
                    "f": "",
                    "c": "411",
                    "cnt_titles": "411"
                },
                {
                    "v": "Harvest",
                    "k": "585320",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "686",
                    "cnt_titles": "686"
                },
                {
                    "v": "Hearst Book Intl {HRTSB}",
                    "k": "467801",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Heartdrum",
                    "k": "467792",
                    "t": "pip_id",
                    "f": "",
                    "c": "55",
                    "cnt_titles": "55"
                },
                {
                    "v": "Mariner Books",
                    "k": "585321",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "2801",
                    "cnt_titles": "2801"
                },
                {
                    "v": "Mariner Books Classics",
                    "k": "637942",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "282",
                    "cnt_titles": "282"
                },
                {
                    "v": "Michael di Capua Books",
                    "k": "467829",
                    "t": "pip_id",
                    "f": "",
                    "c": "9",
                    "cnt_titles": "9"
                },
                {
                    "v": "Morrow Gift",
                    "k": "467831",
                    "t": "pip_id",
                    "f": "",
                    "c": "24",
                    "cnt_titles": "24"
                },
                {
                    "v": "Newmarket for It Books",
                    "k": "467818",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Quill Tree Books",
                    "k": "467782",
                    "t": "pip_id",
                    "f": "",
                    "c": "482",
                    "cnt_titles": "482"
                },
                {
                    "v": "Random House",
                    "k": "788960",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Snicket",
                    "k": "467815",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "St Media Group Intl Inc {STPUB}",
                    "k": "467798",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Storytide",
                    "k": "815678",
                    "t": "pip_id",
                    "f": "",
                    "c": "42",
                    "cnt_titles": "42"
                },
                {
                    "v": "Susan Rich",
                    "k": "659382",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "TN Nelson Books",
                    "k": "681150",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Versify",
                    "k": "585323",
                    "t": "pip_id",
                    "f": "",
                    "c": "92",
                    "cnt_titles": "92"
                },
                {
                    "v": "William Morrow Large Print",
                    "k": "792577",
                    "t": "pip_id",
                    "f": "",
                    "c": "1148",
                    "cnt_titles": "1148"
                },
                {
                    "v": "Zondervan",
                    "k": "657324",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                }
            ],
            "f": "moved",
            "c": 9520,
            "cnt_imprints": 56,
            "cnt_titles": 9520
        },
        {
            "v": "Harper Collins Canada",
            "k": "Harper Collins Canada",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Amistad",
                    "k": "670468",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Angus &amp; Robert",
                    "k": "428332",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Balzer &amp; Bray",
                    "k": "751476",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Ecco",
                    "k": "663547",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Eminent Lives",
                    "k": "671651",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Greenwillow Books",
                    "k": "377890",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Harper Business",
                    "k": "666924",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Harper Paperbacks",
                    "k": "378264",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "HarperBusiness",
                    "k": "377772",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "HarperVia",
                    "k": "671650",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 11,
            "cnt_imprints": 10,
            "cnt_titles": 11
        },
        {
            "v": "Balzer &amp; Bray/Harperteen",
            "k": "Balzer & Bray/Harperteen",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Balzer &amp; Bray",
                    "k": "751475",
                    "t": "pip_id",
                    "f": "",
                    "c": "107",
                    "cnt_titles": "107"
                }
            ],
            "f": "",
            "c": 107,
            "cnt_imprints": 1,
            "cnt_titles": 107
        },
        {
            "v": "Phidal *not",
            "k": "Phidal *not",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "751418",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "72",
                    "cnt_titles": "72"
                }
            ],
            "f": "moved",
            "c": 72,
            "cnt_imprints": 1,
            "cnt_titles": 72
        },
        {
            "v": "Phidal Publishing Inc.",
            "k": "Phidal Publishing Inc.",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Phidal Publishing Inc.",
                    "k": "690594",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "298",
                    "cnt_titles": "298"
                }
            ],
            "f": "moved finished",
            "c": 298,
            "cnt_imprints": 1,
            "cnt_titles": 298
        },
        {
            "v": "Grupo Nivel Uno",
            "k": "Grupo Nivel Uno",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Casa Creacion",
                    "k": "742374",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "110",
                    "cnt_titles": "110"
                },
                {
                    "v": "Nivel Uno",
                    "k": "742375",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "41",
                    "cnt_titles": "41"
                }
            ],
            "f": "moved",
            "c": 151,
            "cnt_imprints": 2,
            "cnt_titles": 151
        },
        {
            "v": "Simon Pulse",
            "k": "Simon Pulse",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "741140",
                    "t": "pip_id",
                    "f": "",
                    "c": "17",
                    "cnt_titles": "17"
                },
                {
                    "v": "Simon Pulse",
                    "k": "741139",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "507",
                    "cnt_titles": "507"
                },
                {
                    "v": "Simon Pulse/Beyond Words",
                    "k": "741145",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "22",
                    "cnt_titles": "22"
                },
                {
                    "v": "Simon Pulse/Mercury Ink",
                    "k": "741146",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "18",
                    "cnt_titles": "18"
                }
            ],
            "f": "moved",
            "c": 564,
            "cnt_imprints": 4,
            "cnt_titles": 564
        },
        {
            "v": "Simon Pulse {SIMPU}",
            "k": "Simon Pulse {SIMPU}",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Simon Pulse",
                    "k": "741142",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Simon Pulse {SIMPU}",
                    "k": "741141",
                    "t": "pip_id",
                    "f": "",
                    "c": "120",
                    "cnt_titles": "120"
                }
            ],
            "f": "",
            "c": 123,
            "cnt_imprints": 2,
            "cnt_titles": 123
        },
        {
            "v": "Scribner % Macmillan",
            "k": "Scribner % Macmillan",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Scribner % Macmillan",
                    "k": "741126",
                    "t": "pip_id",
                    "f": "",
                    "c": "7",
                    "cnt_titles": "7"
                }
            ],
            "f": "",
            "c": 7,
            "cnt_imprints": 1,
            "cnt_titles": 7
        },
        {
            "v": "SCR",
            "k": "SCR",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "7635",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "S&amp;S Trade",
                    "k": "428084",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Scribner Paperback Fiction {SCRBN}",
                    "k": "7674",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Scribner {SCRIB}",
                    "k": "741123",
                    "t": "pip_id",
                    "f": "",
                    "c": "37",
                    "cnt_titles": "37"
                },
                {
                    "v": "Scribner's Cloth",
                    "k": "741124",
                    "t": "pip_id",
                    "f": "",
                    "c": "29",
                    "cnt_titles": "29"
                },
                {
                    "v": "Scribner's TP",
                    "k": "741125",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Simon &amp; Schuster {SIMON}",
                    "k": "7303",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 75,
            "cnt_imprints": 7,
            "cnt_titles": 75
        },
        {
            "v": "Scribne",
            "k": "Scribne",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Simon &amp; Schuster {SIMEX}",
                    "k": "741122",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Pocket Star",
            "k": "Pocket Star",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "629060",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Pocket",
                    "k": "133021",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Pocket Books",
                    "k": "741120",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Pocket Star",
                    "k": "741121",
                    "t": "pip_id",
                    "f": "",
                    "c": "444",
                    "cnt_titles": "444"
                }
            ],
            "f": "",
            "c": 447,
            "cnt_imprints": 4,
            "cnt_titles": 447
        },
        {
            "v": "Pocket Books/Star Trek",
            "k": "Pocket Books/Star Trek",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Pocket Books/Star Trek",
                    "k": "741119",
                    "t": "pip_id",
                    "f": "",
                    "c": "354",
                    "cnt_titles": "354"
                },
                {
                    "v": "Star Trek",
                    "k": "84800",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "",
            "c": 356,
            "cnt_imprints": 2,
            "cnt_titles": 356
        },
        {
            "v": "Pocket Books {POCTP}",
            "k": "Pocket Books {POCTP}",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Pocket Books",
                    "k": "70411",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Pocket Books {POCTP}",
                    "k": "741118",
                    "t": "pip_id",
                    "f": "",
                    "c": "115",
                    "cnt_titles": "115"
                },
                {
                    "v": "Total Health Care Pub",
                    "k": "126110",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 117,
            "cnt_imprints": 3,
            "cnt_titles": 117
        },
        {
            "v": "Pocket Books {POCKE}",
            "k": "Pocket Books {POCKE}",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Pocket",
                    "k": "7212",
                    "t": "pip_id",
                    "f": "",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "Pocket Books",
                    "k": "741116",
                    "t": "pip_id",
                    "f": "",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "Pocket Books {POCKE}",
                    "k": "741117",
                    "t": "pip_id",
                    "f": "",
                    "c": "1056",
                    "cnt_titles": "1056"
                },
                {
                    "v": "Star Trek",
                    "k": "7418",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 1070,
            "cnt_imprints": 4,
            "cnt_titles": 1070
        },
        {
            "v": "Pocket Books",
            "k": "Pocket Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "741113",
                    "t": "pip_id",
                    "f": "",
                    "c": "166",
                    "cnt_titles": "166"
                },
                {
                    "v": "Archway",
                    "k": "772257",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Createspace",
                    "k": "226368",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Kangaroo",
                    "k": "632197",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Pocket",
                    "k": "7232",
                    "t": "pip_id",
                    "f": "",
                    "c": "17",
                    "cnt_titles": "17"
                },
                {
                    "v": "Pocket Books",
                    "k": "741114",
                    "t": "pip_id",
                    "f": "",
                    "c": "2067",
                    "cnt_titles": "2067"
                },
                {
                    "v": "PocketStar",
                    "k": "741115",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Simon &amp; Schuster Export Ed",
                    "k": "84796",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Washington Square Press",
                    "k": "639750",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 2257,
            "cnt_imprints": 9,
            "cnt_titles": 2257
        },
        {
            "v": "Pocket",
            "k": "Pocket",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "53057",
                    "t": "pip_id",
                    "f": "",
                    "c": "162",
                    "cnt_titles": "162"
                },
                {
                    "v": "Documents, recits, essais",
                    "k": "757633",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Pocket",
                    "k": "741112",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Pocket Books",
                    "k": "453559",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Pocket. Evolution",
                    "k": "667997",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 170,
            "cnt_imprints": 5,
            "cnt_titles": 170
        },
        {
            "v": "PKB",
            "k": "PKB",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "7231",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Atria Books",
                    "k": "741111",
                    "t": "pip_id",
                    "f": "",
                    "c": "25",
                    "cnt_titles": "25"
                },
                {
                    "v": "PB Hardcover",
                    "k": "7190",
                    "t": "pip_id",
                    "f": "",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "PB Trade",
                    "k": "7196",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Pocket Books",
                    "k": "7164",
                    "t": "pip_id",
                    "f": "",
                    "c": "24",
                    "cnt_titles": "24"
                },
                {
                    "v": "Pocket Books {PCKHC}",
                    "k": "7163",
                    "t": "pip_id",
                    "f": "",
                    "c": "18",
                    "cnt_titles": "18"
                },
                {
                    "v": "Pocket Books {POCKE}",
                    "k": "7198",
                    "t": "pip_id",
                    "f": "",
                    "c": "9",
                    "cnt_titles": "9"
                },
                {
                    "v": "Pocket Books {POCTP}",
                    "k": "7208",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Star Trek {STRTK}",
                    "k": "7251",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Wash. Square Press",
                    "k": "7336",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 91,
            "cnt_imprints": 10,
            "cnt_titles": 91
        },
        {
            "v": "Pocke",
            "k": "Pocke",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "741110",
                    "t": "pip_id",
                    "f": "",
                    "c": "700",
                    "cnt_titles": "700"
                },
                {
                    "v": "Pocket Books",
                    "k": "141803",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 701,
            "cnt_imprints": 2,
            "cnt_titles": 701
        },
        {
            "v": "Paraview Pocket Books",
            "k": "Paraview Pocket Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Paraview Pocket Books",
                    "k": "741109",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                }
            ],
            "f": "",
            "c": 4,
            "cnt_imprints": 1,
            "cnt_titles": 4
        },
        {
            "v": "HWD",
            "k": "HWD",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "58278",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Howard Pub Co {HOWPU}",
                    "k": "741108",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "",
            "c": 3,
            "cnt_imprints": 2,
            "cnt_titles": 3
        },
        {
            "v": "Howard Pub Co {HOWPU}",
            "k": "Howard Pub Co {HOWPU}",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Howard Books",
                    "k": "741106",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Howard Pub Co {HOWPU}",
                    "k": "741107",
                    "t": "pip_id",
                    "f": "",
                    "c": "56",
                    "cnt_titles": "56"
                }
            ],
            "f": "",
            "c": 57,
            "cnt_imprints": 2,
            "cnt_titles": 57
        },
        {
            "v": "Howard Pub Co Inc",
            "k": "Howard Pub Co Inc",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Howard Pub Co Inc",
                    "k": "741105",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Howard Books",
            "k": "Howard Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Howard Pub Co Inc",
                    "k": "741104",
                    "t": "pip_id",
                    "f": "",
                    "c": "8",
                    "cnt_titles": "8"
                }
            ],
            "f": "",
            "c": 8,
            "cnt_imprints": 1,
            "cnt_titles": 8
        },
        {
            "v": "Gallery/Scout Press",
            "k": "Gallery/Scout Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Gallery/Scout Press",
                    "k": "741102",
                    "t": "pip_id",
                    "f": "",
                    "c": "148",
                    "cnt_titles": "148"
                }
            ],
            "f": "",
            "c": 148,
            "cnt_imprints": 1,
            "cnt_titles": 148
        },
        {
            "v": "Gallery/Omnific Publishing",
            "k": "Gallery/Omnific Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Gallery/Omnific Publishing",
                    "k": "741101",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Gallery/Jeter Publishing",
            "k": "Gallery/Jeter Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Gallery/Jeter Publishing",
                    "k": "741100",
                    "t": "pip_id",
                    "f": "",
                    "c": "15",
                    "cnt_titles": "15"
                }
            ],
            "f": "",
            "c": 15,
            "cnt_imprints": 1,
            "cnt_titles": 15
        },
        {
            "v": "Gallery Books/Karen Hunter Publishing",
            "k": "Gallery Books/Karen Hunter Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Gallery Books/Karen Hunter Publishing",
                    "k": "741099",
                    "t": "pip_id",
                    "f": "",
                    "c": "34",
                    "cnt_titles": "34"
                }
            ],
            "f": "",
            "c": 34,
            "cnt_imprints": 1,
            "cnt_titles": 34
        },
        {
            "v": "Gallery Books {GALLB}",
            "k": "Gallery Books {GALLB}",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Gallery Books {GALLB}",
                    "k": "741098",
                    "t": "pip_id",
                    "f": "",
                    "c": "164",
                    "cnt_titles": "164"
                }
            ],
            "f": "",
            "c": 164,
            "cnt_imprints": 1,
            "cnt_titles": 164
        },
        {
            "v": "Gallery Books/G-Unit",
            "k": "Gallery Books/G-Unit",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Gallery Books/G-Unit",
                    "k": "741097",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "",
            "c": 2,
            "cnt_imprints": 1,
            "cnt_titles": 2
        },
        {
            "v": "Gallery Books",
            "k": "Gallery Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "741093",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Gallery Books",
                    "k": "741094",
                    "t": "pip_id",
                    "f": "",
                    "c": "3903",
                    "cnt_titles": "3903"
                },
                {
                    "v": "Illumination Arts Inc",
                    "k": "741095",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Pocket",
                    "k": "741096",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Skybound Books",
                    "k": "436092",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 3911,
            "cnt_imprints": 5,
            "cnt_titles": 3911
        },
        {
            "v": "Free Pr",
            "k": "Free Pr",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "741091",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Free Pr",
                    "k": "741092",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                }
            ],
            "f": "",
            "c": 6,
            "cnt_imprints": 2,
            "cnt_titles": 6
        },
        {
            "v": "Free Press",
            "k": "Free Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "741088",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Free Pr",
                    "k": "741089",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Free Press",
                    "k": "741090",
                    "t": "pip_id",
                    "f": "",
                    "c": "1485",
                    "cnt_titles": "1485"
                },
                {
                    "v": "Urano Pub Inc",
                    "k": "190043",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 1492,
            "cnt_imprints": 4,
            "cnt_titles": 1492
        },
        {
            "v": "Free Pr {FREE}",
            "k": "Free Pr {FREE}",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Free Pr",
                    "k": "741086",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Free Pr {FREE}",
                    "k": "741087",
                    "t": "pip_id",
                    "f": "",
                    "c": "115",
                    "cnt_titles": "115"
                },
                {
                    "v": "Free Press",
                    "k": "655",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "",
            "c": 121,
            "cnt_imprints": 3,
            "cnt_titles": 121
        },
        {
            "v": "Folger Shakespeare Lib {FOLGL}",
            "k": "Folger Shakespeare Lib {FOLGL}",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Folger Shakespeare Lib {FOLGL}",
                    "k": "741085",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Fireside {FIRES}",
            "k": "Fireside {FIRES}",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Fireside",
                    "k": "7316",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Fireside Books",
                    "k": "741083",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Fireside {FIRES}",
                    "k": "741084",
                    "t": "pip_id",
                    "f": "",
                    "c": "123",
                    "cnt_titles": "123"
                }
            ],
            "f": "",
            "c": 126,
            "cnt_imprints": 3,
            "cnt_titles": 126
        },
        {
            "v": "DISTICAN",
            "k": "DISTICAN",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Atheneum/Anne Schwartz Books",
                    "k": "7983",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Atheneum/Richard Jackson Books",
                    "k": "570456",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Free Press",
                    "k": "741076",
                    "t": "pip_id",
                    "f": "",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "National Geographic",
                    "k": "502617",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Pocket",
                    "k": "741077",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Scribner",
                    "k": "741079",
                    "t": "pip_id",
                    "f": "",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "Simon &amp; Schuster",
                    "k": "741080",
                    "t": "pip_id",
                    "f": "",
                    "c": "9",
                    "cnt_titles": "9"
                },
                {
                    "v": "Touchstone",
                    "k": "741081",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "World Wrestling Entertainment",
                    "k": "428094",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 34,
            "cnt_imprints": 9,
            "cnt_titles": 34
        },
        {
            "v": "Diamond Comic Distributors, Inc.",
            "k": "Diamond Comic Distributors, Inc.",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "A Wave Blue World Inc",
                    "k": "656043",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Abiogenesis",
                    "k": "322670",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Ad House",
                    "k": "509109",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Ait Planet Lar",
                    "k": "491240",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Albatross Funnybooks",
                    "k": "373332",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Antarctic",
                    "k": "458111",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Ape Entertainment",
                    "k": "322686",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Black Mask Comics",
                    "k": "322740",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "ComicsOne",
                    "k": "656034",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Diamond Comic Distributors Inc",
                    "k": "458107",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Digital Manga",
                    "k": "458113",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Dynamite Entertainment",
                    "k": "322682",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Eureka Prod",
                    "k": "509111",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Eureka Productions",
                    "k": "656020",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Fiery Studios",
                    "k": "656039",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "G T Labs (mi)",
                    "k": "458103",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Graphic Sha",
                    "k": "477533",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Hatter Entertainment",
                    "k": "322748",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Hermes Press",
                    "k": "322658",
                    "t": "pip_id",
                    "f": "",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "King Hell",
                    "k": "509103",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "King Hell Press",
                    "k": "656016",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Oni Pr",
                    "k": "586897",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Paizo Inc.",
                    "k": "347790",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Paizo Pub",
                    "k": "458114",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Panini UK LTD",
                    "k": "322689",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Pocket Books",
                    "k": "741073",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Slg Pub",
                    "k": "469932",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "SLG Publishing",
                    "k": "656014",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Toonhound Studios LLC",
                    "k": "656033",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Top Cow Prod",
                    "k": "458116",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Two Morrows",
                    "k": "458109",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "TwoMorrows Publishing",
                    "k": "322662",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Wildside Pr",
                    "k": "458106",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 59,
            "cnt_imprints": 33,
            "cnt_titles": 59
        },
        {
            "v": "Beyond Words Publishing",
            "k": "Beyond Words Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "190276",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Beyond Words",
                    "k": "435161",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Beyond Words Pub Co {BEYWO}",
                    "k": "23694",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Beyond Words Pub Inc",
                    "k": "139856",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "BEYOND WORDS PUBLISHING",
                    "k": "741071",
                    "t": "pip_id",
                    "f": "",
                    "c": "20",
                    "cnt_titles": "20"
                },
                {
                    "v": "Createspace",
                    "k": "272897",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 35,
            "cnt_imprints": 6,
            "cnt_titles": 35
        },
        {
            "v": "Atria Books {ATRIA}",
            "k": "Atria Books {ATRIA}",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Atria",
                    "k": "741062",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Atria Books {ATRIA}",
                    "k": "741063",
                    "t": "pip_id",
                    "f": "",
                    "c": "16",
                    "cnt_titles": "16"
                }
            ],
            "f": "",
            "c": 17,
            "cnt_imprints": 2,
            "cnt_titles": 17
        },
        {
            "v": "Atria Book Publishing",
            "k": "Atria Book Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Atria Book Pub",
                    "k": "741058",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                }
            ],
            "f": "",
            "c": 4,
            "cnt_imprints": 1,
            "cnt_titles": 4
        },
        {
            "v": "Atria",
            "k": "Atria",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "741057",
                    "t": "pip_id",
                    "f": "",
                    "c": "36",
                    "cnt_titles": "36"
                }
            ],
            "f": "",
            "c": 36,
            "cnt_imprints": 1,
            "cnt_titles": 36
        },
        {
            "v": "Nine Ten Publications",
            "k": "Nine Ten Publications",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Nine Ten Publications",
                    "k": "739901",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "5",
                    "cnt_titles": "5"
                }
            ],
            "f": "finished",
            "c": 5,
            "cnt_imprints": 1,
            "cnt_titles": 5
        },
        {
            "v": "Arcadia Publishing",
            "k": "Arcadia Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "America Through Time",
                    "k": "739908",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "630",
                    "cnt_titles": "630"
                },
                {
                    "v": "Applewood Books",
                    "k": "764651",
                    "t": "pip_id",
                    "f": "",
                    "c": "39",
                    "cnt_titles": "39"
                },
                {
                    "v": "Arcadia Children''s Books",
                    "k": "764653",
                    "t": "pip_id",
                    "f": "",
                    "c": "24",
                    "cnt_titles": "24"
                },
                {
                    "v": "Arcadia Children's Books",
                    "k": "524197",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "167",
                    "cnt_titles": "167"
                },
                {
                    "v": "Arcadia Publishing",
                    "k": "739909",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "12023",
                    "cnt_titles": "12023"
                },
                {
                    "v": "Belt Publishing",
                    "k": "764658",
                    "t": "pip_id",
                    "f": "",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "Borders Group",
                    "k": "739921",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Commonwealth Editions",
                    "k": "586119",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "220",
                    "cnt_titles": "220"
                },
                {
                    "v": "Pelican",
                    "k": "471674",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1154",
                    "cnt_titles": "1154"
                },
                {
                    "v": "The History Press",
                    "k": "739905",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "5060",
                    "cnt_titles": "5060"
                }
            ],
            "f": "moved",
            "c": 19326,
            "cnt_imprints": 10,
            "cnt_titles": 19326
        },
        {
            "v": "Wildsam",
            "k": "Wildsam",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Wildsam",
                    "k": "787417",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Wildsam Field Guides",
                    "k": "739906",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "68",
                    "cnt_titles": "68"
                }
            ],
            "f": "renamed moved",
            "c": 69,
            "cnt_imprints": 2,
            "cnt_titles": 69
        },
        {
            "v": "Arcadia Publishing (SC)",
            "k": "Arcadia Publishing (SC)",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Applewood",
                    "k": "742518",
                    "t": "pip_id",
                    "f": "",
                    "c": "19",
                    "cnt_titles": "19"
                },
                {
                    "v": "Arcadia Lib Ed",
                    "k": "739907",
                    "t": "pip_id",
                    "f": "",
                    "c": "108",
                    "cnt_titles": "108"
                }
            ],
            "f": "",
            "c": 127,
            "cnt_imprints": 2,
            "cnt_titles": 127
        },
        {
            "v": "Barrington Stoke Ltd",
            "k": "Barrington Stoke Ltd",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Barrington Stoke",
                    "k": "73715",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "463",
                    "cnt_titles": "463"
                }
            ],
            "f": "moved finished",
            "c": 463,
            "cnt_imprints": 1,
            "cnt_titles": 463
        },
        {
            "v": "Mythic Roads Press",
            "k": "Mythic Roads Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Mythic Roads Press",
                    "k": "736455",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "finished",
            "c": 2,
            "cnt_imprints": 1,
            "cnt_titles": 2
        },
        {
            "v": "Tundra Book Group",
            "k": "Tundra Book Group",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "FENN-TUNDRA",
                    "k": "737609",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "13",
                    "cnt_titles": "13"
                },
                {
                    "v": "Livres Toundra",
                    "k": "737610",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "9",
                    "cnt_titles": "9"
                },
                {
                    "v": "Penguin Teen Canada",
                    "k": "620748",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "104",
                    "cnt_titles": "104"
                },
                {
                    "v": "Puffin Canada",
                    "k": "620741",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "175",
                    "cnt_titles": "175"
                },
                {
                    "v": "Seal Books",
                    "k": "620749",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "20",
                    "cnt_titles": "20"
                },
                {
                    "v": "Tundra Books",
                    "k": "620747",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1244",
                    "cnt_titles": "1244"
                }
            ],
            "f": "moved",
            "c": 1565,
            "cnt_imprints": 6,
            "cnt_titles": 1565
        },
        {
            "v": "PRH Canada Young Readers",
            "k": "PRH Canada Young Readers",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Doubleday Canada",
                    "k": "321700",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "125",
                    "cnt_titles": "125"
                },
                {
                    "v": "Penguin Canada",
                    "k": "321698",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "27",
                    "cnt_titles": "27"
                },
                {
                    "v": "Penguin Teen",
                    "k": "388972",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Puffin Canada",
                    "k": "321697",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "104",
                    "cnt_titles": "104"
                },
                {
                    "v": "Razorbill Canada",
                    "k": "321718",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "56",
                    "cnt_titles": "56"
                },
                {
                    "v": "Seal Books",
                    "k": "321701",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "17",
                    "cnt_titles": "17"
                }
            ],
            "f": "moved",
            "c": 330,
            "cnt_imprints": 6,
            "cnt_titles": 330
        },
        {
            "v": "Doubleday Canada",
            "k": "Doubleday Canada",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "4604",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Anchor Canada",
                    "k": "4556",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "843",
                    "cnt_titles": "843"
                },
                {
                    "v": "Bond Street Books",
                    "k": "132469",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "141",
                    "cnt_titles": "141"
                },
                {
                    "v": "Doubleday Canada",
                    "k": "4555",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "900",
                    "cnt_titles": "900"
                },
                {
                    "v": "Random House Canada",
                    "k": "663623",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Seal Books",
                    "k": "10335",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "345",
                    "cnt_titles": "345"
                },
                {
                    "v": "Vintage Canada",
                    "k": "4557",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "11",
                    "cnt_titles": "11"
                }
            ],
            "f": "moved",
            "c": 2242,
            "cnt_imprints": 7,
            "cnt_titles": 2242
        },
        {
            "v": "Tundra",
            "k": "Tundra",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Tundra Books",
                    "k": "17217",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "moved",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Tiger Tales",
            "k": "Tiger Tales",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "360 Degrees",
                    "k": "304351",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "22",
                    "cnt_titles": "22"
                },
                {
                    "v": "Tiger Tales",
                    "k": "38711",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "947",
                    "cnt_titles": "947"
                }
            ],
            "f": "moved finished",
            "c": 969,
            "cnt_imprints": 2,
            "cnt_titles": 969
        },
        {
            "v": "Peter Pauper Press Inc.",
            "k": "Peter Pauper Press Inc.",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Peter Pauper Press Inc.",
                    "k": "537506",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "2657",
                    "cnt_titles": "2657"
                }
            ],
            "f": "moved finished",
            "c": 2657,
            "cnt_imprints": 1,
            "cnt_titles": 2657
        },
        {
            "v": "VARIOUS TRADE",
            "k": "VARIOUS TRADE",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "9838",
                    "t": "pip_id",
                    "f": "",
                    "c": "92",
                    "cnt_titles": "92"
                },
                {
                    "v": "Brimax Books Ltd {BRMXB}",
                    "k": "14578",
                    "t": "pip_id",
                    "f": "",
                    "c": "15",
                    "cnt_titles": "15"
                },
                {
                    "v": "Clark Boardman Callaghan {BOARD}",
                    "k": "85704",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Friedman/Fairfax Pub {FRIFA}",
                    "k": "35897",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Gallery Books {GALLB}",
                    "k": "85905",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "HP Books (TRD)",
                    "k": "18225",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Hp Books {HPBKS}",
                    "k": "51970",
                    "t": "pip_id",
                    "f": "",
                    "c": "31",
                    "cnt_titles": "31"
                },
                {
                    "v": "Peter Pauper",
                    "k": "582757",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Peter Pauper Pr {PAUPE}",
                    "k": "737608",
                    "t": "pip_id",
                    "f": "",
                    "c": "38",
                    "cnt_titles": "38"
                },
                {
                    "v": "Peter Pauper Press, Inc.",
                    "k": "783744",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Price Stern Sloan Inc",
                    "k": "582729",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "R S Means Co {MEANS}",
                    "k": "16010",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Richardson &amp; Steirman &amp; Black {RICST}",
                    "k": "93240",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Rutledge Hill Pr",
                    "k": "66606",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Rutledge Hill Pr {RUTHI}",
                    "k": "22228",
                    "t": "pip_id",
                    "f": "",
                    "c": "25",
                    "cnt_titles": "25"
                },
                {
                    "v": "Smithmark Pub {SMIPB}",
                    "k": "77819",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "State House Pr {STATO}",
                    "k": "22975",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Sunflower Books {SUNFB}",
                    "k": "85907",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Troubador Pr {TROUB}",
                    "k": "704283",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "U S Games Systems Inc",
                    "k": "16549",
                    "t": "pip_id",
                    "f": "",
                    "c": "38",
                    "cnt_titles": "38"
                },
                {
                    "v": "U S Games Systems {USGAM}",
                    "k": "16555",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "VARIOUS TRADE",
                    "k": "35416",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "",
            "c": 272,
            "cnt_imprints": 22,
            "cnt_titles": 272
        },
        {
            "v": "Mussio Ventures Ltd.",
            "k": "Mussio Ventures Ltd.",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Backroad Mapbooks",
                    "k": "333740",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "34",
                    "cnt_titles": "34"
                },
                {
                    "v": "Mussio Ventures",
                    "k": "189449",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "104",
                    "cnt_titles": "104"
                }
            ],
            "f": "moved finished",
            "c": 138,
            "cnt_imprints": 2,
            "cnt_titles": 138
        },
        {
            "v": "MOUNTAIN VISION PUBLISHING",
            "k": "MOUNTAIN VISION PUBLISHING",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "205925",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "8",
                    "cnt_titles": "8"
                }
            ],
            "f": "moved",
            "c": 8,
            "cnt_imprints": 1,
            "cnt_titles": 8
        },
        {
            "v": "Michael Roger Press",
            "k": "Michael Roger Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "228159",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "168",
                    "cnt_titles": "168"
                }
            ],
            "f": "moved",
            "c": 168,
            "cnt_imprints": 1,
            "cnt_titles": 168
        },
        {
            "v": "LUMINOUS COMPOSITIONS",
            "k": "LUMINOUS COMPOSITIONS",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "48857",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "12",
                    "cnt_titles": "12"
                }
            ],
            "f": "moved finished",
            "c": 12,
            "cnt_imprints": 1,
            "cnt_titles": 12
        },
        {
            "v": "GM Johnson",
            "k": "GM Johnson",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "225314",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "112",
                    "cnt_titles": "112"
                }
            ],
            "f": "moved finished",
            "c": 112,
            "cnt_imprints": 1,
            "cnt_titles": 112
        },
        {
            "v": "FRONT",
            "k": "FRONT",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "737601",
                    "t": "pip_id",
                    "f": "",
                    "c": "39",
                    "cnt_titles": "39"
                }
            ],
            "f": "",
            "c": 39,
            "cnt_imprints": 1,
            "cnt_titles": 39
        },
        {
            "v": "Frontenac House",
            "k": "Frontenac House",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Frontenac House",
                    "k": "195828",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "32",
                    "cnt_titles": "32"
                },
                {
                    "v": "Frontenac House Poetry",
                    "k": "610259",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "109",
                    "cnt_titles": "109"
                },
                {
                    "v": "Neil Petrunia",
                    "k": "764688",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Quartet",
                    "k": "764670",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Quartet 2016",
                    "k": "764687",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "moved",
            "c": 148,
            "cnt_imprints": 5,
            "cnt_titles": 148
        },
        {
            "v": "Corax Press",
            "k": "Corax Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "261366",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "3",
                    "cnt_titles": "3"
                }
            ],
            "f": "finished",
            "c": 3,
            "cnt_imprints": 1,
            "cnt_titles": 3
        },
        {
            "v": "Nimbus Publishing",
            "k": "Nimbus Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Doodle Lovely Inc.",
                    "k": "614197",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "8",
                    "cnt_titles": "8"
                },
                {
                    "v": "George Fischer",
                    "k": "635910",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "20",
                    "cnt_titles": "20"
                },
                {
                    "v": "International Maritime Economic History Associatio",
                    "k": "811081",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "MacIntyre Purcell Publishing Inc.",
                    "k": "759503",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Nimbus Publishing",
                    "k": "10443",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1255",
                    "cnt_titles": "1255"
                },
                {
                    "v": "Vagrant Press",
                    "k": "72737",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "66",
                    "cnt_titles": "66"
                }
            ],
            "f": "moved",
            "c": 1351,
            "cnt_imprints": 6,
            "cnt_titles": 1351
        },
        {
            "v": "Nimbus Publishing Limited &amp; Cape Breton University Press",
            "k": "Nimbus Publishing Limited & Cape Breton University Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Nimbus Publishing Limited &amp; Cape Breton University Press",
                    "k": "645702",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Clerisy Press",
            "k": "Clerisy Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Clerisy Press",
                    "k": "317121",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "105",
                    "cnt_titles": "105"
                },
                {
                    "v": "Clerisy Press, Guild Press of Indiana",
                    "k": "127402",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "moved finished",
            "c": 107,
            "cnt_imprints": 2,
            "cnt_titles": 107
        },
        {
            "v": "Morgan James Publishing",
            "k": "Morgan James Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "48 Days Press",
                    "k": "443053",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Aitia Press",
                    "k": "477555",
                    "t": "pip_id",
                    "f": "",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "EntrePastors Press",
                    "k": "621814",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "F.I.T. in Faith Press",
                    "k": "690903",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Glazer Kennedy Pub",
                    "k": "199440",
                    "t": "pip_id",
                    "f": "",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "Grove Street Publishing",
                    "k": "620694",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "KWE Publishing",
                    "k": "616420",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Magic Press",
                    "k": "610313",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "McCall Press",
                    "k": "807740",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Milton Rae Press",
                    "k": "178807",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Morgan James Faith",
                    "k": "321007",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "317",
                    "cnt_titles": "317"
                },
                {
                    "v": "Morgan James Fiction",
                    "k": "321008",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "171",
                    "cnt_titles": "171"
                },
                {
                    "v": "Morgan James Kids",
                    "k": "321004",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "113",
                    "cnt_titles": "113"
                },
                {
                    "v": "Morgan James Publishing",
                    "k": "2530",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "2333",
                    "cnt_titles": "2333"
                },
                {
                    "v": "Morgan James YA Fiction",
                    "k": "642316",
                    "t": "pip_id",
                    "f": "",
                    "c": "12",
                    "cnt_titles": "12"
                },
                {
                    "v": "Mount Tabor Media",
                    "k": "451728",
                    "t": "pip_id",
                    "f": "",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "Panta Press",
                    "k": "581380",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Wordstream Publishing",
                    "k": "202471",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "World Challenge Press",
                    "k": "642317",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "moved",
            "c": 2993,
            "cnt_imprints": 19,
            "cnt_titles": 2993
        },
        {
            "v": "Middleway Press",
            "k": "Middleway Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Middleway Press",
                    "k": "31778",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "22",
                    "cnt_titles": "22"
                }
            ],
            "f": "moved finished",
            "c": 22,
            "cnt_imprints": 1,
            "cnt_titles": 22
        },
        {
            "v": "Meteoor Books",
            "k": "Meteoor Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Meteoor Books",
                    "k": "295432",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "45",
                    "cnt_titles": "45"
                }
            ],
            "f": "finished",
            "c": 45,
            "cnt_imprints": 1,
            "cnt_titles": 45
        },
        {
            "v": "Legend Press",
            "k": "Legend Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Hero",
                    "k": "484061",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "28",
                    "cnt_titles": "28"
                },
                {
                    "v": "Legend Press",
                    "k": "484058",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "233",
                    "cnt_titles": "233"
                },
                {
                    "v": "University of Buckingham Press",
                    "k": "484062",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "36",
                    "cnt_titles": "36"
                }
            ],
            "f": "moved finished",
            "c": 297,
            "cnt_imprints": 3,
            "cnt_titles": 297
        },
        {
            "v": "Legends Press",
            "k": "Legends Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Legend Pr",
                    "k": "728346",
                    "t": "pip_id",
                    "f": "",
                    "c": "22",
                    "cnt_titles": "22"
                },
                {
                    "v": "Legend Pr Ltd",
                    "k": "252064",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Legends Pr",
                    "k": "193742",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Out of This World Pub",
                    "k": "63574",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 29,
            "cnt_imprints": 4,
            "cnt_titles": 29
        },
        {
            "v": "Korero Books",
            "k": "Korero Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Korero Books",
                    "k": "126768",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "7",
                    "cnt_titles": "7"
                }
            ],
            "f": "finished",
            "c": 7,
            "cnt_imprints": 1,
            "cnt_titles": 7
        },
        {
            "v": "Korero Press",
            "k": "Korero Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Korero Press",
                    "k": "125429",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "28",
                    "cnt_titles": "28"
                },
                {
                    "v": "Suffolk &amp; Watt",
                    "k": "757660",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "",
            "c": 30,
            "cnt_imprints": 2,
            "cnt_titles": 30
        },
        {
            "v": "Keep It Simple Books",
            "k": "Keep It Simple Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Keep It Simple Books",
                    "k": "25189",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "27",
                    "cnt_titles": "27"
                }
            ],
            "f": "moved finished",
            "c": 27,
            "cnt_imprints": 1,
            "cnt_titles": 27
        },
        {
            "v": "Kaepernick Publishing",
            "k": "Kaepernick Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Kaepernick Publishing",
                    "k": "546334",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "3",
                    "cnt_titles": "3"
                }
            ],
            "f": "finished",
            "c": 3,
            "cnt_imprints": 1,
            "cnt_titles": 3
        },
        {
            "v": "Just World Books",
            "k": "Just World Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Just World Books",
                    "k": "207122",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "44",
                    "cnt_titles": "44"
                }
            ],
            "f": "finished",
            "c": 44,
            "cnt_imprints": 1,
            "cnt_titles": 44
        },
        {
            "v": "Independent Institute",
            "k": "Independent Institute",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Independent Inst",
                    "k": "340592",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Independent Institute",
                    "k": "24505",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "118",
                    "cnt_titles": "118"
                },
                {
                    "v": "Liberty Tree Press",
                    "k": "451165",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "moved",
            "c": 124,
            "cnt_imprints": 3,
            "cnt_titles": 124
        },
        {
            "v": "Hachette Livre Bnf",
            "k": "Hachette Livre Bnf",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "424608",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "74",
                    "cnt_titles": "74"
                },
                {
                    "v": "Hachette",
                    "k": "424601",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "41",
                    "cnt_titles": "41"
                },
                {
                    "v": "Hachette Jeunesse",
                    "k": "424599",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "98",
                    "cnt_titles": "98"
                },
                {
                    "v": "Hachette Livre",
                    "k": "254775",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "269425",
                    "cnt_titles": "269425"
                },
                {
                    "v": "Hachette Livre Editions Du Chene",
                    "k": "728345",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Hachette {HACHE}",
                    "k": "424607",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "4",
                    "cnt_titles": "4"
                }
            ],
            "f": "moved",
            "c": 269646,
            "cnt_imprints": 6,
            "cnt_titles": 269646
        },
        {
            "v": "Hachette Audio",
            "k": "Hachette Audio",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Balance",
                    "k": "576165",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Basic Books",
                    "k": "344072",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "BookShots",
                    "k": "291849",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "27",
                    "cnt_titles": "27"
                },
                {
                    "v": "Business Plus",
                    "k": "59844",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Center Street",
                    "k": "154018",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "28",
                    "cnt_titles": "28"
                },
                {
                    "v": "FaithWords",
                    "k": "63474",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "98",
                    "cnt_titles": "98"
                },
                {
                    "v": "Forever",
                    "k": "677835",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Grand Central Publishing",
                    "k": "36887",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "354",
                    "cnt_titles": "354"
                },
                {
                    "v": "Hachette Audio",
                    "k": "34648",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1307",
                    "cnt_titles": "1307"
                },
                {
                    "v": "Hachette Go",
                    "k": "464120",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Hyperion",
                    "k": "224960",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "12",
                    "cnt_titles": "12"
                },
                {
                    "v": "JellyTelly Press",
                    "k": "424991",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Jimmy Patterson",
                    "k": "163407",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "83",
                    "cnt_titles": "83"
                },
                {
                    "v": "Little Brown &amp; Co",
                    "k": "662483",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Little, Brown &amp; Company",
                    "k": "38489",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "385",
                    "cnt_titles": "385"
                },
                {
                    "v": "Little, Brown Books for Young Readers",
                    "k": "162609",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "67",
                    "cnt_titles": "67"
                },
                {
                    "v": "Little, Brown Spark",
                    "k": "433102",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Mulholland Books",
                    "k": "210511",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "19",
                    "cnt_titles": "19"
                },
                {
                    "v": "Orbit",
                    "k": "218589",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "PublicAffairs",
                    "k": "324583",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Reagan Arthur Books",
                    "k": "192333",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Redhook",
                    "k": "512723",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Running Press Adult",
                    "k": "458912",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Twelve",
                    "k": "316748",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "15",
                    "cnt_titles": "15"
                },
                {
                    "v": "Voracious",
                    "k": "435792",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Windblown Media",
                    "k": "252325",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Workman Pr",
                    "k": "644062",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Worthy Books",
                    "k": "531276",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "moved",
            "c": 2424,
            "cnt_imprints": 28,
            "cnt_titles": 2424
        },
        {
            "v": "Hachette Children's",
            "k": "Hachette Children's",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Asterix",
                    "k": "162525",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "54",
                    "cnt_titles": "54"
                },
                {
                    "v": "Franklin Watts",
                    "k": "149191",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "315",
                    "cnt_titles": "315"
                },
                {
                    "v": "Hachette Children's",
                    "k": "4137",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "760",
                    "cnt_titles": "760"
                },
                {
                    "v": "Hodder Children's",
                    "k": "326032",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "11",
                    "cnt_titles": "11"
                },
                {
                    "v": "Little, Brown Books for Young Readers UK",
                    "k": "236301",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "17",
                    "cnt_titles": "17"
                },
                {
                    "v": "Orchard Books",
                    "k": "37084",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "447",
                    "cnt_titles": "447"
                },
                {
                    "v": "Orion Children's Books &amp; Indigo",
                    "k": "9171",
                    "t": "pip_id",
                    "f": "renamed moved finished",
                    "c": "315",
                    "cnt_titles": "315"
                },
                {
                    "v": "Pat-a-Cake",
                    "k": "378526",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "7",
                    "cnt_titles": "7"
                },
                {
                    "v": "Quercus Children's Books UK",
                    "k": "454295",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "12",
                    "cnt_titles": "12"
                },
                {
                    "v": "Wayland",
                    "k": "73425",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "223",
                    "cnt_titles": "223"
                },
                {
                    "v": "Wren &amp; Rook",
                    "k": "381476",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "30",
                    "cnt_titles": "30"
                }
            ],
            "f": "moved finished",
            "c": 2191,
            "cnt_imprints": 11,
            "cnt_titles": 2191
        },
        {
            "v": "Grand Central Publishing",
            "k": "Grand Central Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "5 Spot",
                    "k": "131328",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "54",
                    "cnt_titles": "54"
                },
                {
                    "v": "Aspect",
                    "k": "5441",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "249",
                    "cnt_titles": "249"
                },
                {
                    "v": "Balance",
                    "k": "526876",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "149",
                    "cnt_titles": "149"
                },
                {
                    "v": "Business Plus",
                    "k": "5449",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "118",
                    "cnt_titles": "118"
                },
                {
                    "v": "Forever",
                    "k": "1577",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1621",
                    "cnt_titles": "1621"
                },
                {
                    "v": "Forever Yours",
                    "k": "183940",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "174",
                    "cnt_titles": "174"
                },
                {
                    "v": "Grand Central Life &amp; Style",
                    "k": "5448",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "211",
                    "cnt_titles": "211"
                },
                {
                    "v": "Grand Central Publishing",
                    "k": "2915",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "6804",
                    "cnt_titles": "6804"
                },
                {
                    "v": "Legacy Lit",
                    "k": "558845",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "104",
                    "cnt_titles": "104"
                },
                {
                    "v": "Mysterious Press",
                    "k": "570820",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "267",
                    "cnt_titles": "267"
                },
                {
                    "v": "Popular Library",
                    "k": "151839",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Sixth Avenue Books",
                    "k": "46164",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Springboard Press",
                    "k": "152907",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Twelve",
                    "k": "15543",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "420",
                    "cnt_titles": "420"
                },
                {
                    "v": "Vision",
                    "k": "5443",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "273",
                    "cnt_titles": "273"
                },
                {
                    "v": "Walk Worthy Press",
                    "k": "5496",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "23",
                    "cnt_titles": "23"
                },
                {
                    "v": "Wellness Central",
                    "k": "63253",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "5",
                    "cnt_titles": "5"
                }
            ],
            "f": "moved finished",
            "c": 10482,
            "cnt_imprints": 17,
            "cnt_titles": 10482
        },
        {
            "v": "Hachette Books",
            "k": "Hachette Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Black Dog &amp; Leventhal",
                    "k": "713125",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Da Capo Lifelong Books",
                    "k": "425500",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "722",
                    "cnt_titles": "722"
                },
                {
                    "v": "Da Capo Press",
                    "k": "424614",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "2152",
                    "cnt_titles": "2152"
                },
                {
                    "v": "Hachette Books",
                    "k": "10906",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1706",
                    "cnt_titles": "1706"
                },
                {
                    "v": "Hachette Go",
                    "k": "462676",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "370",
                    "cnt_titles": "370"
                },
                {
                    "v": "Vanguard Press",
                    "k": "424973",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "137",
                    "cnt_titles": "137"
                }
            ],
            "f": "moved",
            "c": 5089,
            "cnt_imprints": 6,
            "cnt_titles": 5089
        },
        {
            "v": "ESPN Books",
            "k": "ESPN Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "ESPN Books",
                    "k": "78247",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "12",
                    "cnt_titles": "12"
                }
            ],
            "f": "moved finished",
            "c": 12,
            "cnt_imprints": 1,
            "cnt_titles": 12
        },
        {
            "v": "Hachette Ireland",
            "k": "Hachette Ireland",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Hachette Ireland",
                    "k": "167809",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "212",
                    "cnt_titles": "212"
                }
            ],
            "f": "moved finished",
            "c": 212,
            "cnt_imprints": 1,
            "cnt_titles": 212
        },
        {
            "v": "Marvel Press",
            "k": "Marvel Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Marvel Press",
                    "k": "191532",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "230",
                    "cnt_titles": "230"
                }
            ],
            "f": "moved finished",
            "c": 230,
            "cnt_imprints": 1,
            "cnt_titles": 230
        },
        {
            "v": "Holy Trinity Publications",
            "k": "Holy Trinity Publications",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Holy Trinity Publications",
                    "k": "78158",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Holy Trinity Seminary Press",
                    "k": "436094",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "18",
                    "cnt_titles": "18"
                },
                {
                    "v": "The Printshop of St Job of Pochaev",
                    "k": "436093",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "97",
                    "cnt_titles": "97"
                }
            ],
            "f": "finished",
            "c": 119,
            "cnt_imprints": 3,
            "cnt_titles": 119
        },
        {
            "v": "Center Street",
            "k": "Center Street",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Center Street",
                    "k": "459850",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "579",
                    "cnt_titles": "579"
                },
                {
                    "v": "Ctr Street",
                    "k": "583445",
                    "t": "pip_id",
                    "f": "",
                    "c": "125",
                    "cnt_titles": "125"
                }
            ],
            "f": "moved",
            "c": 704,
            "cnt_imprints": 2,
            "cnt_titles": 704
        },
        {
            "v": "FaithWords",
            "k": "FaithWords",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "FaithWords",
                    "k": "459853",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1259",
                    "cnt_titles": "1259"
                },
                {
                    "v": "JellyTelly Press",
                    "k": "423193",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "23",
                    "cnt_titles": "23"
                },
                {
                    "v": "Jericho Books",
                    "k": "459854",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "31",
                    "cnt_titles": "31"
                },
                {
                    "v": "Worthy Books",
                    "k": "617726",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "moved finished",
            "c": 1314,
            "cnt_imprints": 4,
            "cnt_titles": 1314
        },
        {
            "v": "Orbit",
            "k": "Orbit",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Orbit",
                    "k": "8453",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1557",
                    "cnt_titles": "1557"
                },
                {
                    "v": "Redhook",
                    "k": "206894",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "207",
                    "cnt_titles": "207"
                }
            ],
            "f": "moved finished",
            "c": 1764,
            "cnt_imprints": 2,
            "cnt_titles": 1764
        },
        {
            "v": "Hatchette New Zealand",
            "k": "Hatchette New Zealand",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Hatchette New Zealand",
                    "k": "35986",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "moved finished",
            "c": 2,
            "cnt_imprints": 1,
            "cnt_titles": 2
        },
        {
            "v": "Kinkajou",
            "k": "Kinkajou",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Kinkajou",
                    "k": "523086",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "5",
                    "cnt_titles": "5"
                }
            ],
            "f": "moved finished",
            "c": 5,
            "cnt_imprints": 1,
            "cnt_titles": 5
        },
        {
            "v": "Hyperion Books For Children",
            "k": "Hyperion Books For Children",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "191195",
                    "t": "pip_id",
                    "f": "",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Disney Press",
                    "k": "125122",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Hyperion Books for Children",
                    "k": "409810",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "154",
                    "cnt_titles": "154"
                },
                {
                    "v": "Jump At The Sun",
                    "k": "10855",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Little Brown &amp; Co {LTBRJ}",
                    "k": "485217",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Michael Di Capua Books",
                    "k": "10872",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Volo",
                    "k": "10868",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "6",
                    "cnt_titles": "6"
                }
            ],
            "f": "moved",
            "c": 169,
            "cnt_imprints": 7,
            "cnt_titles": 169
        },
        {
            "v": "Disney-Hyperion",
            "k": "Disney-Hyperion",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Disney-Hyperion",
                    "k": "10850",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1030",
                    "cnt_titles": "1030"
                },
                {
                    "v": "Jump At The Sun",
                    "k": "10854",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "17",
                    "cnt_titles": "17"
                },
                {
                    "v": "Melissa de la Cruz Studio",
                    "k": "585572",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "16",
                    "cnt_titles": "16"
                },
                {
                    "v": "Michael Di Capua Books",
                    "k": "10870",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Rick Riordan Presents",
                    "k": "364738",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "88",
                    "cnt_titles": "88"
                },
                {
                    "v": "Volo",
                    "k": "10865",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "76",
                    "cnt_titles": "76"
                }
            ],
            "f": "moved finished",
            "c": 1229,
            "cnt_imprints": 6,
            "cnt_titles": 1229
        },
        {
            "v": "CRW Publishing Limited",
            "k": "CRW Publishing Limited",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "74197",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "CRW Publishing Ltd.",
                    "k": "728320",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "moved",
            "c": 4,
            "cnt_imprints": 2,
            "cnt_titles": 4
        },
        {
            "v": "Fox Chapel Publishing",
            "k": "Fox Chapel Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "150894",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "CompanionHouse Books",
                    "k": "366292",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "443",
                    "cnt_titles": "443"
                },
                {
                    "v": "Creative Homeowner",
                    "k": "55458",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "319",
                    "cnt_titles": "319"
                },
                {
                    "v": "Curious Fox Books",
                    "k": "689290",
                    "t": "pip_id",
                    "f": "",
                    "c": "120",
                    "cnt_titles": "120"
                },
                {
                    "v": "Design Originals",
                    "k": "72283",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "767",
                    "cnt_titles": "767"
                },
                {
                    "v": "Fox Chapel Publishing",
                    "k": "9857",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "859",
                    "cnt_titles": "859"
                },
                {
                    "v": "Happy Fox Books",
                    "k": "367766",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "237",
                    "cnt_titles": "237"
                },
                {
                    "v": "Heliconia Press",
                    "k": "13914",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "46",
                    "cnt_titles": "46"
                },
                {
                    "v": "IMM Lifestyle Books",
                    "k": "20791",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "150",
                    "cnt_titles": "150"
                },
                {
                    "v": "Inkspire",
                    "k": "566173",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Old Pond Books",
                    "k": "521819",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "100",
                    "cnt_titles": "100"
                },
                {
                    "v": "Quiet Fox Designs",
                    "k": "369158",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "62",
                    "cnt_titles": "62"
                }
            ],
            "f": "moved",
            "c": 3108,
            "cnt_imprints": 12,
            "cnt_titles": 3108
        },
        {
            "v": "Consumer Reports Books {CONRB}",
            "k": "Consumer Reports Books {CONRB}",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Consumer Reports",
                    "k": "347787",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "Consumer Reports Books {CONRB}",
                    "k": "17556",
                    "t": "pip_id",
                    "f": "",
                    "c": "7",
                    "cnt_titles": "7"
                }
            ],
            "f": "moved",
            "c": 9,
            "cnt_imprints": 2,
            "cnt_titles": 9
        },
        {
            "v": "Chouette Publishing, Inc.",
            "k": "Chouette Publishing, Inc.",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Caillou",
                    "k": "313558",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "128",
                    "cnt_titles": "128"
                },
                {
                    "v": "Chouette Publishing",
                    "k": "423792",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "72",
                    "cnt_titles": "72"
                },
                {
                    "v": "City Monsters",
                    "k": "326112",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "18",
                    "cnt_titles": "18"
                },
                {
                    "v": "CrackBoom! Books",
                    "k": "359610",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "301",
                    "cnt_titles": "301"
                },
                {
                    "v": "Dragon Books",
                    "k": "367858",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "moved finished",
            "c": 520,
            "cnt_imprints": 5,
            "cnt_titles": 520
        },
        {
            "v": "Girl Friday Books",
            "k": "Girl Friday Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Bird Upstairs Books",
                    "k": "520060",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "9",
                    "cnt_titles": "9"
                },
                {
                    "v": "Flashpoint",
                    "k": "520658",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "27",
                    "cnt_titles": "27"
                },
                {
                    "v": "Girl Friday Books",
                    "k": "520059",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "65",
                    "cnt_titles": "65"
                }
            ],
            "f": "moved finished",
            "c": 101,
            "cnt_imprints": 3,
            "cnt_titles": 101
        },
        {
            "v": "Gemstone Publishing",
            "k": "Gemstone Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Gemstone Publishing",
                    "k": "728319",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "62",
                    "cnt_titles": "62"
                }
            ],
            "f": "moved finished",
            "c": 62,
            "cnt_imprints": 1,
            "cnt_titles": 62
        },
        {
            "v": "Flyaway Books",
            "k": "Flyaway Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Flyaway Books",
                    "k": "429437",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "28",
                    "cnt_titles": "28"
                }
            ],
            "f": "moved finished",
            "c": 28,
            "cnt_imprints": 1,
            "cnt_titles": 28
        },
        {
            "v": "Fayetteville Mafia Press",
            "k": "Fayetteville Mafia Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Fayetteville Mafia Press",
                    "k": "403909",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "9",
                    "cnt_titles": "9"
                }
            ],
            "f": "moved finished",
            "c": 9,
            "cnt_imprints": 1,
            "cnt_titles": 9
        },
        {
            "v": "Cedar Lane Press",
            "k": "Cedar Lane Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Cedar Lane Press",
                    "k": "457408",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "47",
                    "cnt_titles": "47"
                },
                {
                    "v": "Spring House Press",
                    "k": "619128",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "34",
                    "cnt_titles": "34"
                }
            ],
            "f": "moved finished",
            "c": 81,
            "cnt_imprints": 2,
            "cnt_titles": 81
        },
        {
            "v": "Cedar Grove Publishing",
            "k": "Cedar Grove Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Cedar Grove Publishing",
                    "k": "329532",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "19",
                    "cnt_titles": "19"
                }
            ],
            "f": "moved finished",
            "c": 19,
            "cnt_imprints": 1,
            "cnt_titles": 19
        },
        {
            "v": "CAEZIK",
            "k": "CAEZIK",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "CAEZIK Academic",
                    "k": "764675",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                },
                {
                    "v": "CAEZIK Romance",
                    "k": "477554",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "9",
                    "cnt_titles": "9"
                },
                {
                    "v": "CAEZIK SF &amp; Fantasy",
                    "k": "477552",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "82",
                    "cnt_titles": "82"
                },
                {
                    "v": "Curiosity Creations",
                    "k": "773562",
                    "t": "pip_id",
                    "f": "",
                    "c": "6",
                    "cnt_titles": "6"
                }
            ],
            "f": "moved",
            "c": 99,
            "cnt_imprints": 4,
            "cnt_titles": 99
        },
        {
            "v": "Phoenix Pick",
            "k": "Phoenix Pick",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Phoenix Pick",
                    "k": "155690",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "270",
                    "cnt_titles": "270"
                }
            ],
            "f": "moved finished",
            "c": 270,
            "cnt_imprints": 1,
            "cnt_titles": 270
        },
        {
            "v": "Artemesia Publishing, LLC",
            "k": "Artemesia Publishing, LLC",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Artemesia Publishing, LLC",
                    "k": "398549",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "62",
                    "cnt_titles": "62"
                },
                {
                    "v": "Kinkajou Press",
                    "k": "398551",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "23",
                    "cnt_titles": "23"
                },
                {
                    "v": "Shadow Dragon Press",
                    "k": "398552",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "14",
                    "cnt_titles": "14"
                }
            ],
            "f": "finished",
            "c": 99,
            "cnt_imprints": 3,
            "cnt_titles": 99
        },
        {
            "v": "Goodman Games",
            "k": "Goodman Games",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "794321",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Goodman Games",
                    "k": "64871",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "255",
                    "cnt_titles": "255"
                },
                {
                    "v": "Psi",
                    "k": "798623",
                    "t": "pip_id",
                    "f": "",
                    "c": "18",
                    "cnt_titles": "18"
                },
                {
                    "v": "Studio 9 Games",
                    "k": "783869",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Studio 9 Pub",
                    "k": "784808",
                    "t": "pip_id",
                    "f": "",
                    "c": "2",
                    "cnt_titles": "2"
                }
            ],
            "f": "",
            "c": 279,
            "cnt_imprints": 5,
            "cnt_titles": 279
        },
        {
            "v": "Atelier Editions",
            "k": "Atelier Editions",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Atelier Editions",
                    "k": "425028",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "15",
                    "cnt_titles": "15"
                }
            ],
            "f": "finished",
            "c": 15,
            "cnt_imprints": 1,
            "cnt_titles": 15
        },
        {
            "v": "Microcosm Publishing",
            "k": "Microcosm Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Birdcage Bottom",
                    "k": "728303",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "21",
                    "cnt_titles": "21"
                },
                {
                    "v": "Don Giovanni Records",
                    "k": "728301",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "Elly Blue Publishing",
                    "k": "208934",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "18",
                    "cnt_titles": "18"
                },
                {
                    "v": "Goblinko",
                    "k": "728302",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4",
                    "cnt_titles": "4"
                },
                {
                    "v": "Microcosm Publishing",
                    "k": "67518",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "833",
                    "cnt_titles": "833"
                }
            ],
            "f": "moved finished",
            "c": 882,
            "cnt_imprints": 5,
            "cnt_titles": 882
        },
        {
            "v": "Pacifique Nord-Ouest",
            "k": "Pacifique Nord-Ouest",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "471738",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "17",
                    "cnt_titles": "17"
                }
            ],
            "f": "moved finished",
            "c": 17,
            "cnt_imprints": 1,
            "cnt_titles": 17
        },
        {
            "v": "Smiling Eye Press",
            "k": "Smiling Eye Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Lightning Source Inc",
                    "k": "367248",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "finished",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "Longbridge Books",
            "k": "Longbridge Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "728300",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Longbridge Books",
                    "k": "772182",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 2,
            "cnt_imprints": 2,
            "cnt_titles": 2
        },
        {
            "v": "Kersplebedeb",
            "k": "Kersplebedeb",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "55836",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Kersplebedeb",
                    "k": "49755",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "48",
                    "cnt_titles": "48"
                }
            ],
            "f": "moved",
            "c": 49,
            "cnt_imprints": 2,
            "cnt_titles": 49
        },
        {
            "v": "Torch Graphic Press",
            "k": "Torch Graphic Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Torch Graphic Press",
                    "k": "467480",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "141",
                    "cnt_titles": "141"
                }
            ],
            "f": "finished",
            "c": 141,
            "cnt_imprints": 1,
            "cnt_titles": 141
        },
        {
            "v": "Cherry Blossom Press",
            "k": "Cherry Blossom Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Cherry Blossom Press",
                    "k": "357000",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "473",
                    "cnt_titles": "473"
                }
            ],
            "f": "finished",
            "c": 473,
            "cnt_imprints": 1,
            "cnt_titles": 473
        },
        {
            "v": "Cherry Blossom Pr",
            "k": "Cherry Blossom Pr",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Cherry Blossom Pr",
                    "k": "728282",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Lightning Source Inc",
                    "k": "728283",
                    "t": "pip_id",
                    "f": "moved",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "moved",
            "c": 2,
            "cnt_imprints": 2,
            "cnt_titles": 2
        },
        {
            "v": "45th Parallel Press",
            "k": "45th Parallel Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "45th Parallel Press",
                    "k": "347766",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "847",
                    "cnt_titles": "847"
                }
            ],
            "f": "finished",
            "c": 847,
            "cnt_imprints": 1,
            "cnt_titles": 847
        },
        {
            "v": "Cherry Lake Publishing",
            "k": "Cherry Lake Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "",
                    "k": "773668",
                    "t": "pip_id",
                    "f": "",
                    "c": "9",
                    "cnt_titles": "9"
                },
                {
                    "v": "Cherry Lake Publishing",
                    "k": "311237",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "4542",
                    "cnt_titles": "4542"
                }
            ],
            "f": "moved",
            "c": 4551,
            "cnt_imprints": 2,
            "cnt_titles": 4551
        },
        {
            "v": "Sleeping Bear Press",
            "k": "Sleeping Bear Press",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Sleeping Bear Pr",
                    "k": "804005",
                    "t": "pip_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Sleeping Bear Press",
                    "k": "38428",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "894",
                    "cnt_titles": "894"
                }
            ],
            "f": "moved",
            "c": 895,
            "cnt_imprints": 2,
            "cnt_titles": 895
        },
        {
            "v": "Central Avenue Publishing",
            "k": "Central Avenue Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Central Avenue Poetry",
                    "k": "741467",
                    "t": "pip_id",
                    "f": "",
                    "c": "57",
                    "cnt_titles": "57"
                },
                {
                    "v": "Central Avenue Publishing",
                    "k": "197446",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "53",
                    "cnt_titles": "53"
                },
                {
                    "v": "Central Avenue Teen",
                    "k": "776234",
                    "t": "pip_id",
                    "f": "",
                    "c": "22",
                    "cnt_titles": "22"
                }
            ],
            "f": "moved",
            "c": 132,
            "cnt_imprints": 3,
            "cnt_titles": 132
        },
        {
            "v": "Zando",
            "k": "Zando",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Get Lifted Books",
                    "k": "661593",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "Zando",
                    "k": "571945",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "36",
                    "cnt_titles": "36"
                },
                {
                    "v": "Zando ? 8th Note Press",
                    "k": "791419",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Zando ? Atlantic Editions",
                    "k": "762270",
                    "t": "pip_id",
                    "f": "",
                    "c": "11",
                    "cnt_titles": "11"
                },
                {
                    "v": "Zando ? Crooked Media Reads",
                    "k": "762325",
                    "t": "pip_id",
                    "f": "",
                    "c": "5",
                    "cnt_titles": "5"
                },
                {
                    "v": "Zando ? Get Lifted Books",
                    "k": "762324",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Zando ? Gillian Flynn Books",
                    "k": "762323",
                    "t": "pip_id",
                    "f": "",
                    "c": "13",
                    "cnt_titles": "13"
                },
                {
                    "v": "Zando ? Hillman Grad Books",
                    "k": "762326",
                    "t": "pip_id",
                    "f": "",
                    "c": "6",
                    "cnt_titles": "6"
                },
                {
                    "v": "Zando ? SJP Lit",
                    "k": "762327",
                    "t": "pip_id",
                    "f": "",
                    "c": "9",
                    "cnt_titles": "9"
                },
                {
                    "v": "Zando ? Slowburn",
                    "k": "762329",
                    "t": "pip_id",
                    "f": "",
                    "c": "13",
                    "cnt_titles": "13"
                },
                {
                    "v": "Zando ? Sweet July Books",
                    "k": "762328",
                    "t": "pip_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Zando Young Readers",
                    "k": "610386",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "14",
                    "cnt_titles": "14"
                }
            ],
            "f": "",
            "c": 119,
            "cnt_imprints": 12,
            "cnt_titles": 119
        },
        {
            "v": "Massive Publishing",
            "k": "Massive Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Massive Publishing",
                    "k": "724306",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "17",
                    "cnt_titles": "17"
                }
            ],
            "f": "finished",
            "c": 17,
            "cnt_imprints": 1,
            "cnt_titles": 17
        },
        {
            "v": "80/20 Publishing",
            "k": "80/20 Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "80/20 Publishing",
                    "k": "720073",
                    "t": "pip_id",
                    "f": "finished",
                    "c": "6",
                    "cnt_titles": "6"
                }
            ],
            "f": "finished",
            "c": 6,
            "cnt_imprints": 1,
            "cnt_titles": 6
        },
        {
            "v": "Omnidawn Publishing",
            "k": "Omnidawn Publishing",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Omnidawn",
                    "k": "122372",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "226",
                    "cnt_titles": "226"
                }
            ],
            "f": "moved finished",
            "c": 226,
            "cnt_imprints": 1,
            "cnt_titles": 226
        },
        {
            "v": "Red Barn Books",
            "k": "Red Barn Books",
            "t": "pip_publisher",
            "s": [
                {
                    "v": "Red Barn Books",
                    "k": "511642",
                    "t": "pip_id",
                    "f": "moved finished",
                    "c": "9",
                    "cnt_titles": "9"
                }
            ],
            "f": "moved finished",
            "c": 9,
            "cnt_imprints": 1,
            "cnt_titles": 9
        }
    ],
    "tell_user_they_must_use_a_filter": 0,
    "isbn_prefix_bad": 0
}


export const linked_publishers = {
    "rows": [
        {
            "v": "Univ of Hawaii Pr",
            "k": "Univ of Hawaii Pr",
            "t": "pipm_publisher",
            "s": [
                {
                    "v": "Univ of Hawaii Pr",
                    "k": "13144",
                    "t": "pipm_id",
                    "f": "",
                    "c": "9",
                    "cnt_titles": "9"
                }
            ],
            "f": "",
            "c": 9,
            "cnt_imprints": 1,
            "cnt_titles": 9
        },
        {
            "v": "Univ of Hawaii Pr {UHAWI}",
            "k": "Univ of Hawaii Pr {UHAWI}",
            "t": "pipm_publisher",
            "s": [
                {
                    "v": "Univ of Hawaii Pr",
                    "k": "13140",
                    "t": "pipm_id",
                    "f": "",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Univ of Hawaii Pr {UHAWI}",
                    "k": "1786",
                    "t": "pipm_id",
                    "f": "",
                    "c": "16",
                    "cnt_titles": "16"
                }
            ],
            "f": "",
            "c": 19,
            "cnt_imprints": 2,
            "cnt_titles": 19
        },
        {
            "v": "University of Hawai'i Press",
            "k": "University of Hawai'i Press",
            "t": "pipm_publisher",
            "s": [
                {
                    "v": "Createspace",
                    "k": "340293",
                    "t": "pipm_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                },
                {
                    "v": "University of Hawai'i Press",
                    "k": "433871",
                    "t": "pipm_id",
                    "f": "",
                    "c": "6",
                    "cnt_titles": "6"
                }
            ],
            "f": "",
            "c": 7,
            "cnt_imprints": 2,
            "cnt_titles": 7
        },
        {
            "v": "University of Hawaii Press",
            "k": "University of Hawaii Press",
            "t": "pipm_publisher",
            "s": [
                {
                    "v": "Huia Pub",
                    "k": "125481",
                    "t": "pipm_id",
                    "f": "cant_reset",
                    "c": "3",
                    "cnt_titles": "3"
                },
                {
                    "v": "Latitude 20",
                    "k": "433886",
                    "t": "pipm_id",
                    "f": "cant_reset",
                    "c": "52",
                    "cnt_titles": "52"
                },
                {
                    "v": "Univ of Hawaii Pr",
                    "k": "1785",
                    "t": "pipm_id",
                    "f": "",
                    "c": "144",
                    "cnt_titles": "144"
                },
                {
                    "v": "University of Hawaii Press",
                    "k": "433873",
                    "t": "pipm_id",
                    "f": "cant_reset",
                    "c": "2480",
                    "cnt_titles": "2480"
                }
            ],
            "f": "",
            "c": 2679,
            "cnt_imprints": 4,
            "cnt_titles": 2679
        },
        {
            "v": "University of Hawaii Press, Andrew W. Mellon Foundation, as part of Sustainable History Monograph Pilot",
            "k": "University of Hawaii Press, Andrew W. Mellon Foundation, as part of Sustainable History Monograph Pilot",
            "t": "pipm_publisher",
            "s": [
                {
                    "v": "University of Hawaii Press",
                    "k": "610492",
                    "t": "pipm_id",
                    "f": "",
                    "c": "9",
                    "cnt_titles": "9"
                }
            ],
            "f": "",
            "c": 9,
            "cnt_imprints": 1,
            "cnt_titles": 9
        },
        {
            "v": "University of Hawaii Press, Humanities Open Books program, a joint initiative of the National Endowment for the Humanities and t",
            "k": "University of Hawaii Press, Humanities Open Books program, a joint initiative of the National Endowment for the Humanities and t",
            "t": "pipm_publisher",
            "s": [
                {
                    "v": "University of Hawaii Press",
                    "k": "433893",
                    "t": "pipm_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "University of Hawaii Press, Knowledge Unlatched",
            "k": "University of Hawaii Press, Knowledge Unlatched",
            "t": "pipm_publisher",
            "s": [
                {
                    "v": "University of Hawaii Press",
                    "k": "436161",
                    "t": "pipm_id",
                    "f": "",
                    "c": "40",
                    "cnt_titles": "40"
                }
            ],
            "f": "",
            "c": 40,
            "cnt_imprints": 1,
            "cnt_titles": 40
        },
        {
            "v": "University of Hawaii Press, University of Hawai?i Foundation",
            "k": "University of Hawaii Press, University of Hawai?i Foundation",
            "t": "pipm_publisher",
            "s": [
                {
                    "v": "University of Hawaii Press",
                    "k": "434986",
                    "t": "pipm_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        },
        {
            "v": "University of Hawaii-West Oahu",
            "k": "University of Hawaii-West Oahu",
            "t": "pipm_publisher",
            "s": [
                {
                    "v": "Univ of Hawaii Pr",
                    "k": "201181",
                    "t": "pipm_id",
                    "f": "",
                    "c": "1",
                    "cnt_titles": "1"
                }
            ],
            "f": "",
            "c": 1,
            "cnt_imprints": 1,
            "cnt_titles": 1
        }
    ],
    "tell_user_they_must_use_a_filter": 0,
    "isbn_prefix_bad": 0
}