import { Checkbox, Col, Divider, Form, Layout, Radio, Row, Space, Spin, Tooltip, Typography, Select, Card, message, Modal } from "antd";
import React, { useEffect, useState } from "react";

import PrefixMissingData from '../../components/reports/PrefixMissingData.js';
import PubdateMissingData from '../../components/reports/PubdateMissingData.js';
import ValidMissingData from '../../components/reports/ValidMissingData.js';
import { apiCall } from "../../utils/Api";
import conditional from '../../utils/conditional.js';
import LayoutHeader from "../../layouts/partials/LayoutHeader.js";

export default function MissingDataPage() {

    const [distributors, setDistributors] = useState([]);
    const [currentDist, setCurrentDist] = useState("");
    const [loading, setLoading] = useState(false);
    const [initLoaded, setInitLoaded] = useState(false);
    const [validPageData, setValidPageData] = useState([]);
    const [prefixPageData, setPrefixPageData] = useState([]);
    const [pubdatePageData, setPubdatePageData] = useState([]);
    const [groupBy, setGroupBy] = useState("valid")

    const initialIsbnValues = {
        all_isbns: true,
        nyr_isbns: true,
        rank_isbns: true,
        ito_isbns: true,
        oh_isbns: true,
        oo_isbns: true,
    }

    const initialDataValues = {
        no_title: true,
        no_img: true,
        no_desc: true,
        no_bio: true,
        no_digitals: false,
        no_annotations: false,
        no_related_isbns: false,
        no_interiors: false,
    }

    const [isbnValues, setIsbnValues] = useState(initialIsbnValues)
    const [dataValues, setDataValues] = useState(initialDataValues)


    const getMissingData = () => {
        if (!currentDist) { return; }

        setLoading(true);
        let form_data = {
            supplier: currentDist,
            group: groupBy
        }

        apiCall("missing_data/get", form_data, (_status, _results) => {
            if (_status) {
                if (groupBy === "valid") {
                    setValidPageData(_results)
                } else if (groupBy === "prefix") {
                    setPrefixPageData(_results)
                } else {
                    setPubdatePageData(_results)
                }
            }
            setLoading(false);
            setInitLoaded(true);
        })
    }

    useEffect(getMissingData, [currentDist, groupBy])

    const grabDistributors = () => {
        setLoading(true)
        apiCall("missing_data/getDistributors", {}, (_status, _results) => {
            if (_status) {
                setDistributors(_results)
                setCurrentDist(_results.supplier)
            }
        })
    }

    useEffect(grabDistributors, [])

    function getDrilldown(grouping_row, group, drilldown_value, _count) {


        if (!_count || _count === "0") {


            Modal.warn({ title: "Zero titles to display" });
            return;
        }

        let args = {
            supplier: currentDist, // easy
            grouping_row: grouping_row, // grouping_row is y/n, the date, or the prefix
            group: group, // group is valid (none), prefix, or pubdate
            drilldown: drilldown_value, // drilldown is the specific oo_isbn, oh_isbn, etc.
        }
        apiCall("missing_data/getBrowseKeyForDrilldown", args, (_status, _results) => {
            if (_status) {
                //once the API returns the list back, this will pop open a new page in a separate tab
                window.open("/browse/filter/x/" + _results.id + "/v/sequence", "_blank");
            }
        })
    }

    function distributorSelectOptions() {
        if (distributors.hasOwnProperty("distributor_list")) {
            let distributor_list = distributors.distributor_list.sort()
            return distributor_list.map((item, index) => {
                return (<Select.Option key={index} value={item}><small>{item}</small></Select.Option>)
            })
        }
    }

    const drawISBNGroupBoxes = () => {
        return (
            <Space direction="vertical">
                <Space>
                    <Checkbox defaultChecked={isbnValues.all_isbns} onChange={(e) => setIsbnValues({ ...isbnValues, "all_isbns": e.target.checked })}>All ISBNs</Checkbox>
                    <Checkbox defaultChecked={isbnValues.nyr_isbns} onChange={(e) => setIsbnValues({ ...isbnValues, "nyr_isbns": e.target.checked })}>NYR ISBNs</Checkbox>
                    <Checkbox defaultChecked={isbnValues.rank_isbns} onChange={(e) => setIsbnValues({ ...isbnValues, "rank_isbns": e.target.checked })}>Rank ISBNs</Checkbox>
                </Space>
                <Space>
                    <Checkbox defaultChecked={isbnValues.ito_isbns} onChange={(e) => setIsbnValues({ ...isbnValues, "ito_isbns": e.target.checked })}>ITO ISBNs</Checkbox>
                    <Checkbox defaultChecked={isbnValues.oh_isbns} onChange={(e) => setIsbnValues({ ...isbnValues, "oh_isbns": e.target.checked })}>OH ISBNs</Checkbox>
                    <Checkbox defaultChecked={isbnValues.oo_isbns} onChange={(e) => setIsbnValues({ ...isbnValues, "oo_isbns": e.target.checked })}>OO ISBNs</Checkbox>
                </Space>
            </Space>
        )
    }

    const drawISBNIndividualInfoBoxes = () => {
        return (
            <Space direction='vertical'>
                <Space>
                    <Checkbox defaultChecked={dataValues.no_title} onChange={(e) => setDataValues({ ...dataValues, "no_title": e.target.checked })}><small>No Titles</small></Checkbox>
                    <Checkbox defaultChecked={dataValues.no_img} onChange={(e) => setDataValues({ ...dataValues, "no_img": e.target.checked })}><small>No Cover</small></Checkbox>
                    <Checkbox defaultChecked={dataValues.no_bio} onChange={(e) => setDataValues({ ...dataValues, "no_bio": e.target.checked })}><small>No Bio</small></Checkbox>
                    <Checkbox defaultChecked={dataValues.no_desc} onChange={(e) => setDataValues({ ...dataValues, "no_desc": e.target.checked })}><small>No Desc</small></Checkbox>
                    <Checkbox defaultChecked={dataValues.no_interiors} onChange={(e) => setDataValues({ ...dataValues, "no_interiors": e.target.checked })}><small>No Interiors</small></Checkbox>
                    <Checkbox defaultChecked={dataValues.no_digitals} onChange={(e) => setDataValues({ ...dataValues, "no_digitals": e.target.checked })}><small>No DRCs/ARCs</small></Checkbox>
                    <Checkbox defaultChecked={dataValues.no_annotations} onChange={(e) => setDataValues({ ...dataValues, "no_annotations": e.target.checked })}><small>No Reviews</small></Checkbox>
                    <Checkbox defaultChecked={dataValues.no_related_isbns} onChange={(e) => setDataValues({ ...dataValues, "no_related_isbns": e.target.checked })}><small>No Comparables</small></Checkbox>
                </Space>

            </Space>
        )
    }

    const drawTitle = () => {
        if (groupBy === "prefix") {
            return (
                <div>
                    Missing Data <small><em>Sorted by ISBN Prefix</em></small>
                </div>
            )
        }
        if (groupBy === "pubdate") {
            return (
                <div>
                    Missing Data <small><em>Sorted by Pubdate</em></small>
                </div>
            )
        }
        return (
            <div>
                Missing Data <small><em>Sorted by Summary of ISBN Type</em></small>
            </div>
        )
    }



    const drawFilters = () => {
        return (
            <div>

                <Form size="small" layout="vertical">
                    {/* <Space wrap size={[10, 0]}> */}
                    <Space size={20} >
                        <div style={{ "width": "200px" }}>
                            <Form.Item initialValue={groupBy} label={<small>Group Results By:</small>} name="group">
                                <Radio.Group
                                    value={groupBy}
                                    buttonStyle="solid"
                                    onChange={(e) => setGroupBy(e.target.value)}>
                                    <Tooltip title="Data displayed in a summarized view, split between being a Valid ISBN and Non-Valid ISBN">
                                        <Radio.Button value={"valid"}><small>Summary</small></Radio.Button>
                                    </Tooltip>
                                    <Tooltip title="ISBNs missing data listed by ISBN Prefixes">
                                        <Radio.Button value={"prefix"}><small>ISBN Prefix</small></Radio.Button>
                                    </Tooltip>
                                    <Tooltip title="ISBNs grouped by their publish dates">
                                        <Radio.Button value={"pubdate"}><small>Pubdate</small></Radio.Button>
                                    </Tooltip>
                                </Radio.Group>
                            </Form.Item>
                        </div>


                        <conditional.true value={distributors.distributor_list.length > 1}>
                            <div style={{ "width": "80px" }}>
                                <Form.Item label={<small><Tooltip title={<small>Pick a Pubstock Vendor to see their Reports</small>}>Vendor</Tooltip></small>} name="supplier" initialValue={currentDist}>
                                    <Select value={currentDist} initialValue={currentDist} onChange={(e) => setCurrentDist(e)}>
                                        {distributorSelectOptions()}
                                    </Select>
                                </Form.Item>
                            </div>
                        </conditional.true>

                        <div style={{ "marginTop": "-26px", "position": "relative", "right": "0px" }}>
                            <Tooltip title="Some of the different types of data that can be missing from an ISBN">
                                <div className="c" style={{ "fontSize": "14px", "marginTop": "2px" }}><small>Data Types</small></div>
                            </Tooltip>
                            <div className="shim" style={{"height" : "12px"}} />
                            {drawISBNIndividualInfoBoxes()}
                        </div>

                    </Space>
                    <div className="shim"></div>
                </Form>

            </div>
        )
    }

    const drawValid = () => {
        return (
            <>
                <ValidMissingData
                    vendor={currentDist}
                    loading={loading}
                    getDrilldown={getDrilldown}
                    validPageData={validPageData}
                    isbnValues={isbnValues}
                    dataValues={dataValues}
                />
            </>
        )
    }
    const drawPrefix = () => {
        return (
            <>
                <PrefixMissingData
                    loading={loading}
                    setLoading={setLoading}
                    getDrilldown={getDrilldown}
                    group={groupBy}
                    supplier={currentDist}
                    prefixPageData={prefixPageData}
                    isbnValues={isbnValues}
                    dataValues={dataValues}
                />
            </>
        )
    }
    const drawPubdate = () => {
        return (
            <>
                <PubdateMissingData
                    loading={loading}
                    setLoading={setLoading}
                    getDrilldown={getDrilldown}
                    group={groupBy}
                    supplier={currentDist}
                    pubdatePageData={pubdatePageData}
                    isbnValues={isbnValues}
                    dataValues={dataValues}
                />
            </>
        )
    }

    //draws the layout of the page depending on the radioDisplayValue
    return (
        <>
            {/* Pre-load page, to display before all the APIs are done loading as they can take lots of time*/}
            {!initLoaded && <Layout className="layout">
                <Layout.Content className="layoutHeader" style={{ "paddingTop": "150px", "textAlign": "center" }} >
                    <Spin
                        size="large"
                        tip="Retrieving large amounts of data... please wait"
                        style={{ "minHeight": "200px" }}
                        spinning={!initLoaded}></Spin>
                </Layout.Content>
            </Layout>}

            {initLoaded && <Layout className="layout">


                <LayoutHeader
                    title={drawTitle()}

                    filters={<>{drawFilters()}<br /></>}
                />


                <Layout.Content className="layoutHeader" style={{ "padding": "0px 15px" }} >


                    <conditional.true value={groupBy === "valid"}>
                        {drawValid()}
                    </conditional.true>
                    <conditional.true value={groupBy === "prefix"}>
                        {drawPrefix()}
                    </conditional.true>
                    <conditional.true value={groupBy === "pubdate"}>
                        {drawPubdate()}
                    </conditional.true>

                </Layout.Content>
            </Layout>}



        </>
    )
}