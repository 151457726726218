import { Button, Col, Divider, Form, Input, Layout, message, Radio, Row, Select, Space, Spin, Switch, Table, Tabs, Tag, Tooltip, Typography, Upload } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";

import Overlay from "../../components/Overlay";
import MultiSelectExcel from "../../components/title/multi_select/MultiSelectExcel";
import LayoutHeader from "../../layouts/partials/LayoutHeader";
import conditional from "../../tools/conditional";
import { apiCall } from "../../utils/Api";
import { disabledColor } from "../../utils/Utils";
import { pluralize } from "../../utils/Utils";
import ListUpload from "../../components/lists/ListUpload";

export default function TrackingISBNsPage() {


    const [isbns, setIsbns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isbnsSubset, setIsbnsSubset] = useState([]);
    const [useSubset, setUseSubset] = useState(false);
    const [view, setView] = useState("isbns")
    const [ISBNs, setISBNs] = useState("");
    const [isValidSub, setIsValidSub] = useState(true)
    //If Uploading a file is required, look at ListUpload.js for reference

    const [subscription, setSubscription] = useState({
        changed_ts: 0,
        expires_ts: 0,
        countdown: 0
    });

    const getSubscription = () => {
        apiCall("isbn_tracking/getSubscription", {}, (_status, _result) => {
            if (_status) {
                setSubscription(_result);
            } else {
                setIsValidSub(false);
                message.error(_result.error)
            }
            getIsbns();
        })
    }

    const getIsbns = () => {
        apiCall("isbn_tracking/getIsbns", {}, (_status, _result) => {
            // ---------Results---------
            // date_created: 1677527009
            // eisbn: "fL1Fm1B4rfEK80yqt7U5RQ"
            // is_seen: true
            // isbn: "9780385697378"
            // title: "Lessons in Chemistry"
            if (_status) {
                setIsbns(_result);
            } else {
                message.error(_result.error);
            }
            setLoading(false);
        });
    }

    useEffect(getSubscription, []);

    const isbnsToString = () => {
        let ret = ""
        isbns.forEach(item => {
            ret += item.isbn + "\t\t" + item.title + "\n";
        })
        return ret;
    }

    const singleRemove = (isbn_to_be_removed) => {
        let temp_isbns = isbns.filter((item) => isbn_to_be_removed !== item.isbn)
        let isbns_json = temp_isbns.map((item) => {
            return item.isbn;
        })
        apiCall("isbn_tracking/trackIsbns", { isbns_json: JSON.stringify(isbns_json) }, (_status, _result) => {
            if (_status) {
                message.success("Successfully removed ISBN")
                getIsbns();
            } else {
                message.error(_result.error)
            }
        })
    }

    const columns = [
        {
            title: <small></small>,
            dataIndex: 'eisbn',
            key: 'eisbn',
            width: 50,
            render: (e) => {
                return <img className="lightshadow" style={{ "maxHeight": "34px", "maxWidth": "30px" }} src={"https://bookmanager.com/i/slider_im.php?b=" + e} />
            }
        },
        {
            title: <small>ISBN</small>,
            sorter: (a, b) => a.isbn - b.isbn,
            dataIndex: 'isbn',
            key: 'isbn',
            width: 125
        },
        {
            title: <small>Title</small>,
            dataIndex: 'title',
            key: 'title',
        },
        {
            title:
                <Tooltip title="First time the sales of the ISBN were viewed">
                    <small>First Viewed</small>
                </Tooltip>,
            dataIndex: 'first_seen',
            key: 'first_seen',
            sorter: (a, b) => a.first_seen - b.first_seen,
            width: 175,
            render: (e, f) => {
                if (!f.is_seen) {
                    return ("Not yet viewed")
                } else {
                    return (moment(f.first_seen * 1000).format("MMM Do YYYY"))
                }
            }
        },
        {
            title:
                <Tooltip title="If an ISBN is removeable, a button will be displayed">
                    <small>Removable</small>
                </Tooltip>,
            dataIndex: 'is_seen',
            key: 'is_seen',
            width: 175,
            render: (e, f) => {
                if (!f.is_seen) {
                    return (<Button
                        disabled={loading}
                        danger
                        size="small"
                        onClick={(e) => singleRemove(f.isbn)}>
                        Remove
                    </Button>)
                } else {
                    return (<>Viewed, Not Removable</>)
                }
            }
        },
    ];

    const viewInBrowse = () => {
        let temp_isbns = isbns.map(item => {
            return item.isbn;
        })
        apiCall("browse/getCacheKeyForIsbns", { isbns: JSON.stringify(temp_isbns), name: 'Tracked ISBNs' }, (_status, _result) => {
            if (_status) {
                window.open("/browse/filter/x/" + _result.id + "/v/sequence", "_blank");
            } else {
                message.error(_result.error);
            }
        });
    }

    const trackNewISBN = (e) => {
        let isbns_json = "";
        let temp_isbns = isbns.map(item => {
            return item.isbn;
        })
        if (e.hasOwnProperty("isbn_bulk_input")) {
            isbns_json = JSON.stringify(e.isbn_bulk_input.split('\n').concat(temp_isbns))
        } else {
            isbns_json = JSON.stringify([e.isbn_input].concat(temp_isbns))
        }
        apiCall("isbn_tracking/trackIsbns", { isbns_json: isbns_json }, (_status, _result) => {
            if (_status) {
                message.success("ISBNs successfully added to your current list.")
                getIsbns();
            } else {
                message.error(_result.error)
            }
        })
    }

    // const openExcelModal = () => {
    //     return (

    //     )
    // }

    const copyISBNListToClipboard = () => {
        let clipboard_array = ""
        isbns.forEach(item => {
            clipboard_array += item.isbn + "\n";
        })
        navigator.clipboard.writeText(clipboard_array);
        message.success("ISBN List copied to clipboard")
    }

    const allRemove = () => {
        apiCall("isbn_tracking/trackIsbns", { isbns_json: JSON.stringify([]) }, (_status, _result) => {
            if (_status) {
                message.success("Removed " + _result.deleted + pluralize(_result.deleted, " ISBN", "s"))
                getIsbns();
            } else {
                message.error(_result.error)
            }
        })
    }


    const updateList = (_arr) => {

        let _current = isbns.map(item => item.isbn);

        apiCall("isbn_tracking/trackIsbns", { isbns_json: JSON.stringify(_current.concat(_arr)) }, (_status, _result) => {
            if (_status) {
                message.success("Updated successfully.")
                getIsbns();
            } else {
                message.error(_result.error)
            }
        })



    }


    const getExcel = () => {
        apiCall("isbn_tracking/getExcel", {}, (_status, _result) => {
            if (_status) {
                window.open("/excel/" + _result.progress_report_id, "_blank")
            } else {
                message.error(_result.error)
            }
        })
    }

    const drawContent = () => {
        return (
            <>


                <div style={{ "paddingBottom": "10px" }}>
                    You are tracking <strong>{isbns.length.toString()}</strong> {pluralize(isbns.length, "ISBN", "s")}. You
                    have {subscription.countdown > 0 && <strong>
                        {subscription.countdown - isbns.length.toString()}
                    </strong>} available.
                    {determineSubscriptionEndDate()}
                </div>
                <div className="float-flex">
                    <Space>
                        <Button
                            size="small"
                            onClick={viewInBrowse}
                            type="primary"><small>View ISBNs in Browse</small>
                        </Button>
                        <Button
                            size="small"
                            onClick={() => getExcel()}
                            color={disabledColor}>
                            <small>Excel</small>

                        </Button>
                        <Tooltip title="Clicking this will automatically copy only the ISBNs to your clipboard, so you can paste them somewhere else">
                            <Button
                                size="small"
                                onClick={copyISBNListToClipboard}>
                                <small>Copy ISBNs</small>
                            </Button>
                        </Tooltip>
                    </Space>
                    <div>
                        <Tooltip title="Only ISBNs that have not yet been viewed will be removed">
                            <Button
                                disabled={loading}
                                style={{ "float": "right" }}
                                danger
                                type="primary"
                                size="small"
                                onClick={allRemove}>
                                <small>Remove All Possible</small>
                            </Button>
                        </Tooltip>
                    </div>
                </div>
                <div className="shim" /><div className="shim" /><div className="shim" />
                <ListUpload remove={singleRemove} showWarning={false} augment updateList={updateList} highlight={false} isbns={isbns} />
            </>
        )
    }

    function determineSubscriptionEndDate() {
        if (isValidSub) {
            return (<>
                {" "} Your access is available until <strong>
                    {moment(subscription.expires_ts * 1000).format("MMM Do YYYY")}
                </strong>. You may track up to <strong>
                    {subscription.countdown}
                </strong> {pluralize(subscription.countdown, " ISBN", "s")}.
            </>)
        }
        return (<>
            {" "} Your subscription has expired, you cannot track any new ISBNs.
        </>)
    }

    return (
        <>
            {(!loading && <Layout className="layout">
                <LayoutHeader
                    title={<>Tracked ISBNs</>}
                    description={<>You can access sales and stock trend information for any ISBN entered here. Such information is only accessible with a current subscription. If you have any questions about the titles you are currently tracking, please contact <a href="mailto:data@bookmanager.com">data@bookmanager.com.</a></>} />
                <Layout.Content style={{ "padding": "20px" }}>



                    <Spin spinning={loading}>
                        {drawContent()}
                    </Spin>
                </Layout.Content>
            </Layout>)}
        </>
    )
}