import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { CaretDownOutlined, CaretUpOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { Button, Checkbox, Layout, Space, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import SANListCreateEdit from '../../components/admin/SANListCreateEdit';
import Overlay from "../../components/Overlay"
import LayoutHeader from "../../layouts/partials/LayoutHeader";
import { apiCall } from "../../utils/Api";
import { useSession } from "../../utils/Session";
import { disabledColor, peerColor } from "../../utils/Utils";

// APIs are below:
// https://gitlab.bookmanager.com/web/api/-/commit/8e5761dd0db781207d51bf7239546a38a03973ec

export default function SANLists() {
    const [session, setSession] = useSession();
    const [allActive, setAllActive] = useState();
    const [allLists, setAllLists] = useState();
    const [initLoaded, setInitLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState({contact_lists: [], active_stores: []})

    const isSelected = (group_list_row_data, _id) =>{
        return (group_list_row_data.rows.some(store => store.id === _id)) ? 1 : 0;       
    }

    const getAllListsAndContacts = () => {
        let args = {};
        apiCall("contact_list/getAll", args, (_status, _result) => {
            if(_status){
                setResults(_result)
                setAllActive(_result.active_stores);
                setAllLists(_result.contact_lists);
            }
            setInitLoaded(true);
        })
    }

    useEffect(getAllListsAndContacts, [])

    const deleteSANList = (is_picked) => {
        apiCall("contact_list/delete", { id: is_picked.id }, (_status, _result) => {
            if (_status) {
                let temp_all_lists_array = allLists.filter((list) => list.id !== is_picked.id);
                setAllLists(temp_all_lists_array)
            }
        });
    }

    const drawCreateButton = () => {

        return (
            <Button type="primary" >
                <Overlay 
                    width={900} 
                    component={
                        <SANListCreateEdit 
                            create={true}
                            results={results}
                            getAllListsAndContacts={getAllListsAndContacts}
                        />}>
                    Create New Group List
                </Overlay>
            </Button>   
        )
    }

    const drawEditIcon = (group_list_row_data) => {

        return (
            <Tooltip title="Edit the Group List">
                    <Overlay 
                        width={900} 
                        maskCloseable={false}
                        component={
                            <SANListCreateEdit 
                                results={results}
                                group_list_row_data={group_list_row_data}
                                getAllListsAndContacts={getAllListsAndContacts}
                                />}>
                                <Button 
                                    size="small" 
                                    type="link" 
                                    icon={(<EditOutlined style={{ "color": disabledColor }} />)}
                                />
                    </Overlay>
            </Tooltip>
        )
    }

    const drawDeleteIcon = (row_data) => {
        return (
            <Tooltip title="Trash this List/Catalogue internally so it is no longer Featured or kept in any other folder.">
                <div onClick={() => deleteSANList(row_data)}>
                    <Button 
                        size="small" 
                        type="link" 
                        icon={(<DeleteOutlined style={{ "color": disabledColor }} />)}/>
                </div>
            </Tooltip>
        )
    }

    const columns = [
        {
            title: <small></small>, width : 10, dataIndex: 'blank', key: 'blank',
        },
        {
            title: <small>Catalogue Name</small>, dataIndex: 'name', key: 'name',
        },
        {
            title: <small>Store Count</small>,  key: 'count', render: (e, f) => {
                return (f.rows.length)
            }
        },
        {
            title: <small>Store Names</small>, dataIndex: 'contact_id', key: 'contact_id', render: (e, f) => {
                return (
                    f.rows.map((row) => {
                        return <>{row.name}; </>
                    })
                )
            }
        },
        {
            title: <small>Actions</small>, width: "160px", dataIndex: 'folder', key: 'folder', render: (e, f) => {
                return (
                    <Space align="end">
                        {drawEditIcon(f)}
                        {drawDeleteIcon(f)}
                        {/* remove plus other actions will be here. For now, just delete */}
                    </Space>
                )
            }
        },
    ]

    return (
        <>
            <Layout className="layout">
                <LayoutHeader
                    title="Group Lists"
                    description={<>
                    Create lists of your store accounts that can be used when making and sharing catalogues.
                        <div className="shim" /><div className="shim" />
                        <Space>
                            {drawCreateButton()}
                        </Space>
                    </>}
                />
                <Layout.Content>
                    <div className="bc bcg2">
                        <Table
                            // rowKey="list_id"
                            size="small"
                            columns={columns}
                            loading={loading}
                            setLoading={setLoading}
                            
                            dataSource={allLists}
                        />
                    </div>
                </Layout.Content>
            </Layout>
            
        </>
    )
}