import { MoreOutlined } from '@ant-design/icons';
import { Badge, Button, Card, Checkbox, Col, Divider, Form, Input, Layout, message, Popover, Radio, Row, Select, Space, Spin, Typography, Upload } from "antd";
import React, { useEffect, useState } from "react";

import LayoutHeader from "../../layouts/partials/LayoutHeader";
import { apiCall } from "../../utils/Api";
import PublisherCard from './PublisherCard';

import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import PublisherRow from './PublisherRow';
import Overlay from '../../components/Overlay';
import { final_publishers, linked_publishers, transformFinal } from '../../misc';
import { pluralize } from '../../utils/Utils';
import Html from '../../utils/Html';


export default function MyPublishersPage() {


    const [loading, setLoading] = useState(true);
  


    const getColor = (_type) => {

        switch (_type) {

            case "finished":
                return "#99ff99"
                break;
            case "pipm_publisher":
                return "#ffb866"
                break;
            case "pipm_id":
                return "#D9B982"
                break;
            default: // dirty
                return "#ffbbbb"
                break;

        }

    }


    const fetchPublisherImprints = () => {

        let _filters = {
            supplier : "",
            publisher_filter : "",
            imprint_filt : "",
        }
        apiCall("publisher_imprint/getFinalPublisherImprints", _filters, (_status, _result) => {
            if(_status){    
                setFP(_result);
                setLoading(false)
            } else {

            }
        })

    }

    useEffect(fetchPublisherImprints, []);



    const [fp, setFP] = useState({rows : []}); 
    // const fp = { ...final_publishers, "rows": transformFinal(final_publishers.rows) }
    const lp = { ...linked_publishers, "rows": transformFinal(linked_publishers.rows) }


    // {
    //     "v": "Klutz",
    //     "k": "Klutz",
    //     "t": "pip_publisher",
    //     "s": [
    //         {
    //             "v": "Chicken Socks",
    //             "k": "131259",
    //             "t": "pip_id",
    //             "f": "moved finished",
    //             "c": "8",
    //             "cnt_titles": "8"
    //         },
    //         {
    //             "v": "Klutz",
    //             "k": "9119",
    //             "t": "pip_id",
    //             "f": "moved finished",
    //             "c": "283",
    //             "cnt_titles": "283"
    //         }
    //     ],
    //     "f": "moved finished",
    //     "c": 291,
    //     "cnt_imprints": 2,
    //     "cnt_titles": 291
    // }


    const [dragging, setDragging] = useState(false);

    const onDrop = (item, target) => {

        alert(JSON.stringify(target))

    };


    const PubItem = (props) => {

        const { data = {} } = props;

        const {
            label = "",
            id = "",
            type = "",
            imprints = [],
            status = "",
            count = null,
            imprint_count = null,
            title_count = null
        } = data;

        const [show, setShow] = useState(false)

        return (
            <div style={{ "borderBottom": "1px solid #eee" }}>
                <div style={{ "padding": "4px 10px" }} className='float-flex'>
                    <div style={{ "width": "100%" }}>
                        <div><small><Html html={label} /></small></div>
                        <div className='float-flex'>
                            <div>&nbsp;</div>
                            <Space>
                                <a onClick={() => setShow(!show)}><small>{imprint_count} {pluralize(imprint_count, "imprint", "s")}</small></a>
                                <a><small>{title_count} {pluralize(title_count, "title", "s")}</small></a>
                            </Space>
                        </div>
                    </div>
                    <a>
                        <div style={{ "width": "20px", "padding": "5px" }}>
                            <Popover overlayStyle={{ "padding": "0px" }} placement="right" trigger="click" content={<div>
                                <Space size={0} direction="vertical">
                                    <Button className="mini-btn" style={{ "width": "60px" }} block type="primary" size='small'><small>Finished</small></Button>
                                    <Button className="mini-btn" block danger type="primary" size='small'><small>Delete</small></Button>
                                    <Button className="mini-btn" block type="primary" size='small'><small>Rename</small></Button>
                                </Space>
                            </div>}><MoreOutlined style={{ "fontSize": "18px", "marginTop": "50%" }} /></Popover>
                        </div>
                    </a>
                </div>
                <div className={(show) ? 'expand' : "contract"} >
                    {(show &&
                        <div>
                            {
                                imprints.map(item => {
                                    return (
                                        <PublisherRow>
                                            <ImprintItem data={item} />
                                        </PublisherRow>
                                    )
                                })
                            }
                        </div>
                    )}
                </div>
            </div>
        )
    }


    const ImprintItem = (props) => {

        const { data = {} } = props;

        const {
            label = "",
            id = "",
            type = "",
            status = "",
            count = null,
            title_count = null
        } = data;

        return (
            <div style={{ "borderTop": "1px dashed #eee", "padding": "4px 0px", "backgroundColor": "#00000005" }}>
                <div style={{ "padding": "4px 10px" }} className='float-flex'>
                    <div style={{ "width": "100%" }}>
                        <div style={{ "margin": "-5px" }}><small><Space size={10}><Badge dot /><em><Html html={label} /></em></Space></small></div>
                        <div className='float-flex'>
                            <div>&nbsp;</div>
                            <Space>
                                <a><small>{title_count}  {pluralize(title_count, "title", "s")}</small></a>
                            </Space>
                        </div>
                    </div>
                    <a>
                        <div style={{ "width": "20px", "padding": "5px" }}>
                            <Popover overlayStyle={{ "padding": "0px" }} placement="right" trigger="click" content={<div>
                                <Space size={0} direction="vertical">
                                    <Button className="mini-btn" style={{ "width": "60px" }} block type="primary" size='small'><small>Finished</small></Button>
                                    <Button className="mini-btn" block danger type="primary" size='small'><small>Delete</small></Button>
                                    <Button className="mini-btn" block type="primary" size='small'><small>Rename</small></Button>
                                </Space>
                            </div>}><MoreOutlined style={{ "fontSize": "18px", "marginTop": "50%" }} /></Popover>
                        </div>
                    </a>
                </div>
            </div>
        )
    }



    const FinalPublisherFilters = () => {


        


        const drawNew = () => {
            return (
                <Overlay width={300}
                    component={
                        <div style={{ "padding": "20px" }}>
                            <strong>Create new Entity</strong>
                            <Divider style={{ "margin": "15px 0px" }} dashed />
                            <Form layout="vertical">
                                <Form.Item label="Name">
                                    <Input placeholder='Name...' />
                                </Form.Item>
                            </Form>
                            <div className='float-flex'>
                                <div>&nbsp;</div>
                                <Space>
                                    <Button>Cancel</Button>
                                    <Button type="primary">Create</Button>
                                </Space>
                            </div>
                        </div>
                    }>
                    <Button style={{ "lineHeight": "10px", "height": "18px", "marginTop": "3px" }} type='primary' size='small'><small>New</small></Button>
                </Overlay>
            )
        }

        return (
            <Spin spinning={loading}>
                <div style={{ "height": "23px" }} className='float-flex'>
                    <div>Final publishers</div>
                    {drawNew()}
                </div>
                <Divider style={{ "margin": "5px -10px", "position": "absolute" }} />
                <div className='shim' /><div className='shim' />
                <div>
                    <Space size={0}>
                        <Checkbox><small>Clean</small></Checkbox>
                        <Checkbox><small>Dirty</small></Checkbox>
                        <Checkbox><small>Finished</small></Checkbox>
                        <Checkbox><small>Unfinished</small></Checkbox>
                    </Space>
                </div>
                <div className='shim' />
                <div><Input placeholder={'Publisher...'} allowClear size='small' /></div><div className='shim' />
                <div><Input placeholder={'Imprint...'} allowClear size='small' /></div><div className='shim' />
                <div><Input placeholder={'ISBN Prefix...'} allowClear size='small' /></div><div className='shim' />
                <div>
                    <Select placeholder="Pubstock filter..." size='small' style={{ "width": "100%" }}>
                        <Select.Option><small>Option</small></Select.Option>
                    </Select>
                </div>
                <div className='shim' />
                <div className='float-flex'>
                    <div>&nbsp;</div>
                    <div>
                        <Radio.Group size='small'>
                            <Radio value={1}><small>Alphabetical</small></Radio>
                            <Radio value={2}><small># Titles</small></Radio>
                            <Radio value={3}><small>Date Edited</small></Radio>
                        </Radio.Group>
                    </div>
                </div>
                <div className='shim' /><div className='shim' />

                <div style={{ "height": "400px", "overflow": "auto", "overflowX": "hidden", "border": "1px solid #eee", "borderRadius": "5px" }}>
                    {fp.rows.map(item => {
                        return (
                            <PublisherRow dragging={dragging} setDragging={setDragging} id={item.id} onDrop={onDrop} >
                                <PubItem data={item} />
                            </PublisherRow>
                        )
                    })}
                </div>
                <div className='shim' />

            </Spin>
        )
    }


    const LinkedPublisherFilters = () => {



        return (
            <>

                <div style={{ "height": "23px" }} className='float-flex'>
                    <div>Publishers linked to : XXX</div>
                </div>
                <Divider style={{ "margin": "5px -10px", "position": "absolute" }} />
                <div className='shim' /><div className='shim' /><div className='shim' /><div className='shim' />
                <div><Input placeholder={'Publisher...'} allowClear size='small' /></div><div className='shim' />
                <div><Input placeholder={'Imprint...'} allowClear size='small' /></div><div className='shim' />
                <div>
                    <Select placeholder="Pubstock filter..." size='small' style={{ "width": "100%" }}>
                        <Select.Option><small>Option</small></Select.Option>
                    </Select>
                </div>
                <div className='shim' />

                <div className='shim' />

                <div style={{ "height": "400px", "overflow": "auto", "overflowX": "hidden", "border": "1px solid #eee", "borderRadius": "5px" }}>
                    {fp.rows.map(item => {
                        return (
                            <PublisherRow dragging={dragging} setDragging={setDragging} id={item.id} onDrop={onDrop} >
                                <PubItem data={item} />
                            </PublisherRow>
                        )
                    })}
                </div>
                <div className='shim' />

            </>
        )
    }


    const ALLPublisherFilters = () => {


        const ap = { rows: [] };


        const drawImport = () => {
            return (
                <Overlay width={300}
                    component={
                        <div style={{ "padding": "20px" }}>
                            <strong>Create new Entity</strong>
                            <Divider style={{ "margin": "15px 0px" }} dashed />
                            <Form layout="vertical">
                                <Form.Item label="Name">
                                    <Input placeholder='Name...' />
                                </Form.Item>
                            </Form>
                            <div className='float-flex'>
                                <div>&nbsp;</div>
                                <Space>
                                    <Button>Cancel</Button>
                                    <Button type="primary">Create</Button>
                                </Space>
                            </div>
                        </div>
                    }>
                    <Button style={{ "lineHeight": "10px", "height": "18px", "marginTop": "3px" }} type='primary' size='small'><small>Import</small></Button>
                </Overlay>
            )
        }

        return (
            <>

                <div style={{ "height": "23px" }} className='float-flex'>
                    <div>All publishers / imprints</div>
                    {drawImport()}
                </div>
                <Divider style={{ "margin": "5px -10px", "position": "absolute" }} />
                <div className='shim' /><div className='shim' /><div className='shim' /><div className='shim' />


                <div><Input placeholder={'Publisher...'} allowClear size='small' /></div><div className='shim' />
                <div><Input placeholder={'Imprint...'} allowClear size='small' /></div><div className='shim' />
                <div><Input placeholder={'ISBN Prefix...'} allowClear size='small' /></div><div className='shim' />
                <div>
                    <Select placeholder="Pubstock filter..." size='small' style={{ "width": "100%" }}>
                        <Select.Option><small>Option</small></Select.Option>
                    </Select>
                </div>
                <div className='shim' />
                <div className='float-flex'>
                    <div>&nbsp;</div>
                    <div>
                        <Radio.Group size='small'>
                            <Radio value={1}><small>Alphabetical</small></Radio>
                            <Radio value={2}><small># Titles</small></Radio>
                        </Radio.Group>
                    </div>
                </div>
                <div className='shim' /><div style={{ "height": "34px" }} className='shim' />

                <div style={{ "height": "400px", "overflow": "auto", "overflowX": "hidden", "border": "1px solid #eee", "borderRadius": "5px" }}>
                    {ap.rows.map(item => {
                        return (
                            <PublisherRow dragging={dragging} setDragging={setDragging} id={item.id} onDrop={onDrop} >
                                <PubItem data={item} />
                            </PublisherRow>
                        )
                    })}
                </div>
                <div className='shim' />

            </>
        )
    }


    const PublisherPrefixes = () => {


        const ap = { rows: [] };


        const drawImport = () => {
            return (
                <Overlay width={300}
                    component={
                        <div style={{ "padding": "20px" }}>
                            <strong>Create new Entity</strong>
                            <Divider style={{ "margin": "15px 0px" }} dashed />
                            <Form layout="vertical">
                                <Form.Item label="Name">
                                    <Input placeholder='Name...' />
                                </Form.Item>
                            </Form>
                            <div className='float-flex'>
                                <div>&nbsp;</div>
                                <Space>
                                    <Button>Cancel</Button>
                                    <Button type="primary">Create</Button>
                                </Space>
                            </div>
                        </div>
                    }>
                    <Button style={{ "lineHeight": "10px", "height": "18px", "marginTop": "3px" }} type='primary' size='small'><small>Import</small></Button>
                </Overlay>
            )
        }

        return (
            <>

                <div style={{ "height": "23px" }} className='float-flex'>
                    <div>Prefixes for Publisher</div>
                    {drawImport()}
                </div>
                <Divider style={{ "margin": "5px -10px", "position": "absolute" }} />
                <div className='shim' /><div className='shim' /><div className='shim' /><div className='shim' />


                <div className='float-flex'>
                    <div>
                        <Radio.Group size='small'>
                            <Radio value={1}><small>Alphabetical</small></Radio>
                            <Radio value={2}><small># Titles</small></Radio>
                        </Radio.Group>
                    </div>
                    <div>&nbsp;</div>
                </div>
                <div className='shim' />

                <div style={{ "height": "400px", "overflow": "auto", "overflowX": "hidden", "border": "1px solid #eee", "borderRadius": "5px" }}>
                    {ap.rows.map(item => {
                        return (
                            <PublisherRow dragging={dragging} setDragging={setDragging} id={item.id} onDrop={onDrop} >
                                <PubItem data={item} />
                            </PublisherRow>
                        )
                    })}
                </div>
                <div className='shim' />

            </>
        )
    }




    return (
        <>

            <Layout className="layout" >
                <LayoutHeader
                    title={<div className='float-flex'><div>Publishers & Imprints</div>
                        <Space>
                            <Badge style={{ "backgroundColor": getColor(""), "color": "#333" }} count="Dirty" />
                            <Badge style={{ "backgroundColor": getColor("finished"), "color": "#333" }} count="Cleaned" />
                            <Badge style={{ "backgroundColor": getColor("pipm_publisher"), "color": "#333" }} count="Raw Publisher" />
                            <Badge style={{ "backgroundColor": getColor("pipm_id"), "color": "#333" }} count="Raw Imprint" />
                        </Space>
                    </div>}

                // description="Use this page to manage publishers and imprints."
                />
                <Layout.Content style={{ "padding": "0px" }} >
                    <div style={{ "padding": "20px" }}>
                        <DndProvider backend={HTML5Backend}>
                            <Row gutter={10}>
                                <Col span={6}>
                                    <PublisherCard drop_type="search" id="1" onDrop={onDrop} >
                                        <FinalPublisherFilters />
                                    </PublisherCard>
                                </Col>
                                <Col span={6}>
                                    <PublisherCard drop_type="BOX" id="2" onDrop={onDrop} >
                                        <LinkedPublisherFilters />
                                    </PublisherCard>
                                </Col>
                                <Col span={6}>
                                    <PublisherCard drop_type="SEARCH" id="3" onDrop={onDrop} >
                                        <ALLPublisherFilters />
                                    </PublisherCard>
                                </Col>
                                <Col span={6}>
                                    <PublisherCard drop_type="SEARCH" id="4" onDrop={onDrop} >
                                        <PublisherPrefixes id="4" onDrop={onDrop} />
                                    </PublisherCard>
                                </Col>
                            </Row>
                        </DndProvider>

                    </div>
                </Layout.Content>
            </Layout>
        </>
    )
}