import { DownOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Form, Select, Space, Typography } from "antd";
import React, { useState } from "react";

import { apiCall } from "../../utils/Api";
import { pdfoptions } from '../../data';
import { useSession } from '../../utils/Session';

export default function SuggestedCartPdf(props) {

    const [session, setSession] = useSession(); 
    const { 
        count = 0, 
        list_id = "lWdPKvwwjI8", 
        close = () => { } 
    } = props;

    const layout = [
        {
            label: "15 per page (3 columns)",
            key: "threebyfive",
            value: "15"
        },

        {
            label: "8 per page (2 columns)",
            key: "twobyfour",
            value: "8"
        },

        {
            label: "5 per page (1 column)",
            key: "onebyfive",
            value: "5"
        },

        {
            label: "2 per page (1 column)",
            key: "onebytwo",
            value: "2"
        },

        {
            label: "1 per page (1 column)",
            key: "onebyone",
            value: "15"
        }
    ];

    
    
    let _options = (!session?.subscriptions.salesisbn && !session?.subscriptions.catalogue) ? pdfoptions : pdfoptions.filter(item => !item?.filter_salesisbn);

    const [options, setOptions] = useState(_options)
    
    const [selectedLayout, setSelectedLayout] = useState(layout[0].key);
    const [form] = Form.useForm();

    const onFormFinish = (_f) => {
        _f["list_id"] = list_id;
        apiCall("titlelist/pdf/build", _f, (_status, _result) =>{
            if(_status){
                window.open("/pdf/" + _result.progress_report_id, "_blank")
            }
        })
    }

    const isDisabled = (_item) => {
        let a = (selectedLayout === "threebyfive" || selectedLayout === "twobyfour");
        let b = (selectedLayout === "onebyfive");
        let c = (selectedLayout === "onebytwo");
        switch (_item.value) {
            case "show_public_comments":
                return a;
            case "show_private_comments":
                return a;
            case "show_reviews":
                return (a || b);
            case "show_biographies":
                if (selectedLayout === "onebyone") {
                    return true;
                }
                return (a || b);
            case "show_marketing":
                return (a || b || c);
            default:
                return false;
        }
    }

    const onChange = (_val, _ind) => {
        let _options = [...options];
        if (_ind === 3 && !_val) {
            _options[4].label = "Show List Price";
        }
        if (_ind === 3 && _val) {
            _options[4].label = "Fallback to List Price if not in stock";
        }
        _options[_ind].checked = _val;
        setOptions(_options);
    }

    const onSelectionChange = (_sel) => {
        switch (_sel) {
            case "onebyone":
                form.setFieldsValue({ show_biographies: true });
                break;
            case "onebytwo":
                form.setFieldsValue({ show_marketing: "" });
                break;
            case "onebyfive":
                form.setFieldsValue({ show_reviews: "" });
                form.setFieldsValue({ show_biographies: "" });
                form.setFieldsValue({ show_marketing: "" });
                break;
            default:
                form.setFieldsValue({ show_public_comments: "" });
                form.setFieldsValue({ show_private_comments: "" });
                form.setFieldsValue({ show_reviews: "" });
                form.setFieldsValue({ show_biographies: "" });
                form.setFieldsValue({ show_marketing: "" });
                break
        }
        setSelectedLayout(_sel)
    }

    return (
        <>
            <div style={{ "padding": "20px" }}>
                <Typography.Title level={5}>Create PDF ({count.toString()} titles)</Typography.Title>
                <Divider />
                <Form form={form} onFinish={(f) => onFormFinish(f)}>
                    <Form.Item name="layout" label="Layout Option" initialValue={selectedLayout}>
                        <Select getPopupContainer={trigger => trigger.parentElement} className='ant-select-with-custom-arrow' onChange={(e) => onSelectionChange(e)} style={{ "width": "200px" }}>
                            {layout.map(item => {
                                return (<Select.Option value={item.key}>{item.label}</Select.Option>)
                            })}
                        </Select>
                    </Form.Item>
                    <Space direction="vertical">
                        {options.map((item, index) => {
                            return (
                                <Form.Item valuePropName="checked" initialValue={item.checked} name={item.value} noStyle >
                                    <Checkbox disabled={isDisabled(item)} onChange={(e) => onChange(!item.checked, index)}  >&nbsp; 
                                    <span className={(item.value === "show_biographies" && selectedLayout === "onebyone") ? "c" : ""}>{item.label}</span>
                                    </Checkbox>
                                </Form.Item>
                            )
                        })}
                    </Space>
                    <Divider />
                    <div style={{ "float": "right" }}>
                        <Space>
                            <Button onClick={(e) => close()}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Create PDF</Button>
                        </Space>
                    </div>
                    <br clear="all" />
                </Form>
            </div>
        </>
    )
}