import { Card, Empty, Row, Space, Statistic, Table, Typography } from "antd"

import { ucfirst } from "../../utils/Utils";

export default function ListReportCardSimplifiedView(props) {

    const {
        reportCardData = {},
    } = props

    const total = reportCardData.total;
    const individual_counts = Object.entries(reportCardData.individual_counts);

    const drawEmpty = () => {
        // chek if there are any values...
        if(individual_counts.some(item => Number.isInteger(item[1]) && item[1] > 0)){ return <></>}
        return (
            <>
                <Table
                    size="small"
                    style={{ "width": "100%" }}
                    dataSource={[]}
                    columns={[{ title: <>&nbsp;</> }]}
                    >
                </Table>
            </>
        )
    }

    // intercept related...
    const getTitle = (_str) => {

        if(_str === "related"){
            return "Comparables";
        }

        return _str; 
    }

    return (
        <Row>
            {drawEmpty()}
            {individual_counts.map((data) => {
                if (data[1] < total) {
                    return (
                        <Card
                            style={{ "margin": "10px", "width": "300px" }}
                            title={<Typography.Title level={5}>{ucfirst(getTitle(data[0]))}</Typography.Title>}>
                                
                            <Statistic
                                style={{ "backgroundColor": "inherit" }}
                                value={total - data[1]}
                                suffix={"/" + total + " missing"}
                            />
                        </Card>
                    )
                }
            })}
        </Row>
    )
}